import { Component, Input, OnInit } from '@angular/core';
import { SidebarOptions } from '../sidebar.types';
import { Select, Store } from '@ngxs/store';
import { MobileSidebar, MobileSidebarSubMenu } from '../../../../state/user-interface/mobile-sidebar/mobile-sidebar.types';
import { UpdateMobileSidebar } from '../../../../state/user-interface/mobile-sidebar/mobile-sidebar.action';
import { APP_STATE } from '../../../../state/app.state';
import { USER_INTERFACE_STATE } from '../../../../state/user-interface/user-interface.state';
import { MOBILE_SIDEBAR_STATE } from '../../../../state/user-interface/mobile-sidebar/mobile-sidebar.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-sidebar-submenu',
  templateUrl: './mobile-sidebar-submenu.component.html',
  styleUrls: ['./mobile-sidebar-submenu.component.scss']
})
export class MobileSidebarSubmenuComponent {
  @Input() sidebarOptions: SidebarOptions;

  @Select(state => state[APP_STATE][USER_INTERFACE_STATE][MOBILE_SIDEBAR_STATE])
  mobileSidebar$: Observable<MobileSidebar>;

  constructor(private store: Store) { }

  backClicked(): void {
    const mobileSidebarOpts: MobileSidebar = {
      display: true,
      submenu: MobileSidebarSubMenu.None
    };
    this.store.dispatch(new UpdateMobileSidebar(mobileSidebarOpts));
  }

  showProfileSubmenu(submenu: MobileSidebarSubMenu): boolean {
    return (submenu === MobileSidebarSubMenu.Profile);
  }
}
