export class Company {
  agvanceCompanyID: string;
  companyName: string;
  legacyDbID: string;
  ssiDbId?: string;
  city?: string;
  stateProvince?: string;
  companyFeatures?: CompanyFeature[];
}

export class CompanyFeature {
  id: string;
  featureId: string;
  companyId: string;
}

