import {CustomerData} from './state/data/customer-data/customer-data.types';
import {Grower360AccountResponse} from './core/core-services/account/account.types';
import {Company} from './core/core-services/company/company.types';
import {UserInfo} from './shared/shared-services/user-info/user-info.types';
import {InvoiceData} from './state/data/invoice-data/invoice-data.types';
import {PaymentData} from './state/data/payment-data/payment-data.types';
import {GrainBalanceData} from './state/data/grain-balance-data/grain-balance-data.types';
import {GrainScaleTicketData} from './state/data/grain-scale-ticket-data/grain-scale-ticket-data.types';
import {GrainPurchaseContractData} from './state/data/grain-purchase-contract-data/grain-purchase-contract-data.types';
import {GrainSettlementData} from './state/data/grain-settlement-data/grain-settlement-data.types';
import {LinksMetaData, MetaResponseData, PaginatedResponse} from './state/paginated-response/paginated-response.types';
import {GrowerIdResponseDto} from './core/core-services/grower-id/grower-id.service';
import {GrainScaleTicketSplitsData} from './core/pages/grain/scale-tickets-details/splits/grain-scale-ticket-splits-data.types';
import {GrainScaleTicketDetailsData} from './core/pages/grain/scale-tickets-details/scale-tickets-details.types';
import {GradeFactorData} from './core/pages/grain/scale-tickets-details/grade-factors/grade-factors.types';
import {CashBidData} from './state/data/cash-bid-data/cash-bid-data.types';
import {BarchartsApiQuoteData} from './state/data/cash-bid-data/barcharts-api-quote-data.types';
import * as moment from 'moment';
import { BarchartsApiSymbolData } from './state/data/cash-bid-data/barcharts-api-symbol-data.types';
import {
  ProductTotalGroupsResponse,
  ProductTotalGroup,
  CustomerWithProductTotalsCsv,
  CustomerWithProductsWithInvoiceTotalsCsv,
  ProductWithInvoiceTotalsCsv
} from './state/data/product-totals-data/product-totals-data.types';
import {PaymentPreference} from './core/core-services/payment-preference/payment-preference.types';
import { GrainAssemblyData } from './state/data/grain-assembly-data/grain-assembly-data.types';
import { GrainCommodityData } from './state/data/grain-commodity-data/grain-commodity-data.types';
import { GrowerInfoResponseDto } from './core/core-services/grower-id/grower-info-list.service';
import { ScaleTicketsExportCsvData } from './state/data/grain-scale-ticket-export-csv-data/grain-scale-ticket-export-csv-data.types';
import { FarmData } from './state/data/farm.data/farm-data.types';

export function createDependency(normalServiceClass: any, stubServiceClass: any, envName: string): any {
  return {
    provide: normalServiceClass,
    useClass: envName === 'stub' ? stubServiceClass : normalServiceClass }; }

const dunderCompanyId = 'b3d0537a-c867-484c-b130-b4ce10c206ca';
const dunderLegacyId = '112233';

// SubdomainGuardStubService#canActivate
export const stubUserInfo: UserInfo = {
  companyId: dunderCompanyId,
  email: 'jSmith@acmeag.com',
  name: 'Jim Smith',
  roles: 'normal-user',
  sub: 'c3d0537a-c867-484c-b130-b4ce10c206ca',
  userId: 'c3d0537a-c867-484c-b130-b4ce10c206ca'
};

// CustomerStubService#retrieveCustomer
export const stubCustomerResponse: CustomerData = {
  firstName: 'Smith',
  lastName: 'Farms',
  budgetDueBalance: 2356.78,
  prepayBalance: 9876.23,
  regularBalance: 4069.34,
  unappliedCashBalance: 3456.87,
  get name(): string {
    return this.firstName + ' ' + this.lastName;
  }
} as CustomerData;

// AccountStubService#retrieveAccounts
export const stubAcctResponse: Grower360AccountResponse = {
  agvanceCustomerId: dunderLegacyId,
  grower360UserId: 'c03e1f1b-b94f-436f-b872-e495b60c1d96',
  id: 'd03e1f1b-b94f-436f-b872-e495b60c1d96'
};

export const stubGrowerIdsResponse: GrowerIdResponseDto[] = [
  {
    growId: '1FroFa',
    growerGuid: dunderLegacyId,
  },
  {
    growId: '1FroGa',
    growerGuid: dunderLegacyId
  }
];

// CompanyStubService#retrieveCompany
export const stubCompany: Company = {
  agvanceCompanyID: dunderCompanyId,
  companyName: 'Acme Ag',
  legacyDbID: dunderLegacyId,
  ssiDbId: '223344',
  city: 'Shelbyville',
  stateProvince: 'IL'
};

// CustomerInvoicesStubService#retrieveInvoices
export const stubCustomerInvoices: PaginatedResponse<InvoiceData> = {
    data:  [
      {number: 20001, amountDue: 158.00, date: new Date('06/01/2018'), location: 'Shelbyville',
        dueDate: new Date('06/30/2018'), originalAmount: 158.00, status: 'Open', hasPdfData: true,
        discount: 100., fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80'} as InvoiceData,
      {number: 20002, amountDue: 158.00, date: new Date('07/01/2018'), location: 'Shelbyville',
        dueDate: new Date('07/30/2018'), originalAmount: 158.00, status: 'Open', hasPdfData: true,
        discount: 0, fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80'} as InvoiceData,
      {number: 20003, amountDue: 567.89, date: new Date('06/17/2018'), location: 'Shelbyville',
        dueDate: new Date('07/17/2018'), originalAmount: 1287.45, status: 'Paid', hasPdfData: false,
        discount: -100000000, fieldId: 'NORTH', fieldDescription: 'Dwight\'s North 40'} as InvoiceData,
      {number: 20004, amountDue: 158.00, date: new Date('08/17/2018'), location: 'Shelbyville',
        dueDate: new Date('08/30/2018'), originalAmount: 158.00, status: 'Open', hasPdfData: true,
        discount: 1000000, fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80', field: 'Longest Field Description allo'} as InvoiceData,
      {number: 20005, amountDue: 400.00, date: new Date('08/01/2018'), location: 'Shelbyville',
        dueDate: new Date('08/30/2018'), originalAmount: 1287.45, status: 'Open', hasPdfData: false,
        fieldId: 'NORTH', fieldDescription: 'Dwight\'s North 40'} as InvoiceData,
      {number: 20006, amountDue: 100.00, date: new Date('04/17/2018'), location: 'Shelbyville',
        dueDate: new Date('09/30/2018'), originalAmount: 1287.45, status: 'Reversed By 20007', hasPdfData: true,
        fieldId: 'NORTH', fieldDescription: 'Dwight\'s North 40'} as InvoiceData,
      {number: 20007, amountDue: -100.00, date: new Date('05/17/2018'), location: 'Shelbyville',
        dueDate: new Date('10/30/2018'), originalAmount: 1287.45, status: 'Reverse Inv 20006', hasPdfData: true,
        fieldId: 'NORTH', fieldDescription: 'Dwight\'s North 40'} as InvoiceData,
      {number: 20008, amountDue: 0, date: new Date('12/17/2018'), location: 'Shelbyville',
        dueDate: new Date('12/30/2018'), originalAmount: 1287.45, status: 'Paid', hasPdfData: true,
        fieldId: 'NORTH', fieldDescription: 'Dwight\'s North 40'} as InvoiceData,
      {number: 20009, amountDue: 400.00, date: new Date('10/17/2018'), location: 'Shelbyville',
        dueDate: new Date('11/30/2018'), originalAmount: 1287.45, status: 'Paid', hasPdfData: false,
        fieldId: 'SOUTH', fieldDescription: 'Dwight\'s South 80'} as InvoiceData,
      {number: 20010, amountDue: 900.00, date: new Date('10 /17/2018'), location: 'Shelbyville',
        dueDate: new Date('10/30/2018'), originalAmount: 1287.45, status: 'Paid', hasPdfData: false,
        fieldId: 'SOUTH', fieldDescription: 'Dwight\'s South 80'} as InvoiceData,
      {number: 20011, amountDue: 700.00, date: new Date('12/11/2018'), location: 'Shelbyville',
        dueDate: new Date('10/19/2018'), originalAmount: 14456.45, status: 'Paid', hasPdfData: true,
        fieldId: 'SOUTH', fieldDescription: 'Dwight\'s South 80'} as InvoiceData,
      {number: 20012, amountDue: 400.89, date: new Date('06/17/2018'), location: 'Shelbyville',
        dueDate: new Date(), originalAmount: 1287.45, status: 'Paid', hasPdfData: true,
        fieldId: 'SOUTH', fieldDescription: 'Dwight\'s South 80'} as InvoiceData,
    ],
    metaResponseData: new MetaResponseData(),
    linksMetaData: new LinksMetaData()
  };

// CustomerInvoicesStubService#retrieveInvoices RecentActivity
export const stubRecentActivityCustomerInvoices: PaginatedResponse<InvoiceData> = {
  data:  [
    {number: 20001, amountDue: 45.67, date: new Date('03/03/2018'), location: 'Shelbyville',
      dueDate: new Date('04/04/2018'), originalAmount: 567.87, status: 'Open', hasPdfData: true,
      fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80'} as InvoiceData,
    {number: 20002, amountDue: 0, date: new Date('03/03/2018'), location: 'Shelbyville',
      dueDate: new Date('04/04/2018'), originalAmount: 934.56, status: 'Paid', hasPdfData: false,
      fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80'} as InvoiceData,
    {number: 20003, amountDue: 567.89, date: new Date('02/02/2018'), location: 'Shelbyville',
      dueDate: new Date('03/03/2018'), originalAmount: 1287.45, status: 'Open', hasPdfData: true,
      fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80'} as InvoiceData,
  ],
  metaResponseData: new MetaResponseData(),
  linksMetaData: new LinksMetaData()
};

// CustomerMemoInvoicesStubService#retrieveInvoices
export const stubCustomerMemoInvoices: InvoiceData[] = [
  {number: 40001, amountDue: 158.00, date: new Date('06/01/2018'),
    dueDate: new Date('06/30/2018'), originalAmount: 158.00, status: 'Open', location: 'Shelbyville', hasPdfData: false,
    fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80'} as InvoiceData,
  {number: 40002, amountDue: 158.00, date: new Date('07/01/2018'),
    dueDate: new Date('07/30/2018'), originalAmount: 158.00, status: 'Open', location: 'Shelbyville', hasPdfData: false,
    fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80'} as InvoiceData,
  {number: 40003, amountDue: 158.00, date: new Date('08/01/2018'),
    dueDate: new Date('08/30/2018'), originalAmount: 158.00, status: 'Open', location: 'Shelbyville', hasPdfData: false,
    fieldId: 'WEST', fieldDescription: 'Dwight\'s West 80'} as InvoiceData
];

// CustomerPaymentsStubService#retrievePayments
export const stubCustomerPayments: PaymentData[] = [
  {number: 10001, amount: 634.91, date: new Date('02/02/2016'), type: 'R', discount: 0, creditApplied: 0,
    firstPayMethodReferenceNumber: 'R1002948', firstPayMethodType: 'Mastercard', payMethodCount: 2, totalCredit: 634.91,
    location: 'Main', s3FileNameGUID: '12345'},
  {number: 10002, amount: 2202.83, date: new Date('01/01/2016'), type: 'P', discount: 0, creditApplied: -1000.00,
    firstPayMethodReferenceNumber: '928577F4', firstPayMethodType: 'Adjustment', payMethodCount: 4, totalCredit: 2202.83,
    location: 'Main'},
  {number: 20001, amount: 634.91, date: new Date('12/12/2015'), type: 'B', discount: 3.50, creditApplied: 0,
    firstPayMethodReferenceNumber: '6785432', firstPayMethodType: 'Check', payMethodCount: 1, totalCredit: 638.41,
    location: 'Main'},
  {number: 20002, amount: 500.91, date: new Date('02/02/2018'), type: 'R', discount: 500.00, creditApplied: -987.65,
    firstPayMethodReferenceNumber: '9876453', firstPayMethodType: 'Credit Applied', payMethodCount: 5, totalCredit: 1000.91,
    location: 'Main'},
  {number: 30301, amount: 1000.25, date: new Date('02/02/2018'), type: 'P', discount: 400.32, creditApplied: -10.00,
    firstPayMethodReferenceNumber: '9000890', firstPayMethodType: 'Budget Billing', payMethodCount: 3, totalCredit: 1400.57,
    location: 'Main'},
  {number: 30333, amount: 234.56, date: new Date('02/02/2018'), type: 'B', discount: 0, creditApplied: -123.45,
    firstPayMethodReferenceNumber: '1234567', firstPayMethodType: 'Check', payMethodCount: 2, totalCredit: 234.56,
    location: 'Main'},
  {number: 40506, amount: 2202.83, date: new Date('02/02/2012'), type: 'P', discount: 0, creditApplied: 0,
    firstPayMethodReferenceNumber: '1000001', firstPayMethodType: 'Credit Applied', payMethodCount: 30, totalCredit: 2202.83,
    location: 'Main'},
  {number: 20402, amount: 2202.83, date: new Date('03/03/2013'), type: 'P', discount: 2006.23, creditApplied: 0,
    firstPayMethodReferenceNumber: '3828389', firstPayMethodType: 'Adjustment', payMethodCount: 10, totalCredit: 4209.06,
    location: 'Main'},
  {number: 33332, amount: 2202.83, date: new Date('04/04/2014'), type: 'P', discount: 399.99, creditApplied: -2300.50,
    firstPayMethodReferenceNumber: '1000288', firstPayMethodType: 'Adjustment', payMethodCount: 5, totalCredit: 2602.82,
    location: 'North'},
  {number: 40002, amount: 2202.83, date: new Date('05/05/2018'), type: 'R', discount: 432.10, creditApplied: -1200.00,
    firstPayMethodReferenceNumber: '4827228', firstPayMethodType: 'Credit Applied', payMethodCount: 2, totalCredit: 2622.93,
    location: 'North'},
  {number: 908, amount: 10283.25, date: new Date('01/13/2018'), type: 'B', discount: 9004.31, creditApplied: 0,
    firstPayMethodReferenceNumber: '5700001', firstPayMethodType: 'Budget Billing', payMethodCount: 1, totalCredit: 19287.56,
    location: 'North'}
];

// CustomerGrainBalancesStubService#retrieveGrainBalances
export const stubCustomerGrainBalances: GrainBalanceData[] = [
  {commodityName: 'Corn', dispositions: [
      { dailyPositionReport: 'Basis Fixed', unitOfMeasure: 'Bu', units: '1014.66', contracts: []},
      { dailyPositionReport: 'Non-negotiable WR', unitOfMeasure: 'Bu', units: '4034.12', contracts: []},
      { dailyPositionReport: 'Priced Later', unitOfMeasure: 'Bu', units: '200.4', contracts: []},
      { dailyPositionReport: 'Futures Fixed', unitOfMeasure: 'Bu', units: '2413.21', contracts: []},
      { dailyPositionReport: 'Priced', unitOfMeasure: 'Bu', units: '101.57', contracts: []},
      { dailyPositionReport: 'Open Storage', unitOfMeasure: 'Bu', units: '2892.79', contracts: []}]},
  {commodityName: 'Soybeans', dispositions: [
      { dailyPositionReport: 'Basis Fixed', unitOfMeasure: 'Bu', units: '34.97', contracts: []},
      { dailyPositionReport: 'Non-negotiable WR', unitOfMeasure: 'Bu', units: '2573.97', contracts: []}]}
];

// CustomerGrainScaleTicketsStublinksMetaData#setNextLink
export const stublinksMetaData: LinksMetaData = {
  next: null, prev: null, self: null, last: null, first: null
};

// CustomerGrainScaleTicketsStubService#retrieveGrainScaleTickets
export const stubCustomerGrainScaleTickets: PaginatedResponse<GrainScaleTicketData> = {
  data: [
  { ticketNumber: '13256',
    location: 'Shelbyville, IL', commodityName: 'Corn', grossUnit: 2403.33, assembly: '1000',
    netUnit: 873.32, date: new Date('02/02/2018'), farmName: 'Dunder Mifflin Farm', decimals: 0,
    unitOfMeasure: 'Bu', comment: 'Some comments', isInProcess: true, isTransferred: true,
    yourSplit: 410.23
  },
  { ticketNumber: '13257',
    location: 'Shelbyville, IL', commodityName: 'Soybeans', grossUnit: 1553.3, assembly: '1000',
    netUnit: 4737.38, date: new Date('04/04/2018'), farmName: 'Dunder Mifflin Farm', decimals: 1,
    unitOfMeasure: 'Bu', comment: 'Some comments', isInProcess: true, isTransferred: false,
    yourSplit: 2100.87
  },
  { ticketNumber: '13258',
    location: 'Shelbyville, IL', commodityName: 'Soybeans', grossUnit: 430.33, assembly: '1000',
    netUnit: 3777.32, date: new Date('04/04/2018'), farmName: 'Dunder Mifflin Farm', decimals: 2,
    unitOfMeasure: 'Bu', comment: 'Some comments', isInProcess: true, isTransferred: true,
    yourSplit: 117.59
  },
  { ticketNumber: '13259',
    location: 'Shelbyville, IL', commodityName: 'Corn', grossUnit: 543.33, assembly: '1000',
    netUnit: 473.32, date: new Date('05/05/2018'), farmName: 'Dunder Mifflin Farm', decimals: 3,
    unitOfMeasure: 'Bu', comment: 'Some comments', isInProcess: false, isTransferred: false,
    yourSplit: 272.22
  },
  { ticketNumber: '13745',
    location: 'Shelbyville, IL', commodityName: 'Corn', grossUnit: 1234.65, assembly: '1000',
    netUnit: 1178.92, date: new Date('05/05/2018'), farmName: 'Dunder Mifflin Farm', decimals: 4,
    unitOfMeasure: 'Bu', comment: 'Some comments', isInProcess: false, isTransferred: true,
    yourSplit: 109.93
  },
  { ticketNumber: '13782',
    location: 'Shelbyville, IL', commodityName: 'Corn', grossUnit: 1198.31, assembly: '1000',
    netUnit: 1121.09, date: new Date('05/05/2018'), farmName: 'Dunder Mifflin Farm', decimals: 5,
    unitOfMeasure: 'Bu', comment: '', isInProcess: true, isTransferred: true,
    yourSplit: 28.1
  },
  { ticketNumber: '13821',
    location: 'Shelbyville, IL', commodityName: 'Corn', grossUnit: 523.76, assembly: '1000',
    netUnit: 489.04, date: new Date('05/05/2018'), farmName: 'Dunder Mifflin Farm', decimals: 4,
    unitOfMeasure: 'Bu', comment: '', isInProcess: true, isTransferred: false,
    yourSplit: 18.53
  },
  { ticketNumber: '13833',
    location: 'Shelbyville, IL', commodityName: 'Corn', grossUnit: 1288.58, assembly: '1000',
    netUnit: 1201.75, date: new Date('05/05/2018'), farmName: 'Dunder Mifflin Farm', decimals: 2,
    unitOfMeasure: 'Bu', comment: '', isInProcess: false, isTransferred: true,
    yourSplit: 30.36
  }
  ],
  metaResponseData: new MetaResponseData(),
  linksMetaData:  stublinksMetaData
};

// CustomerGrainScaleTicketsStubService#retrieveGrainScaleTicketsExportCsv
export const stubGrainScaleTicketsExportCsvData: ScaleTicketsExportCsvData = {
  areRecordsAvailable: true,
  // tslint:disable-next-line: max-line-length
  csvDataInString: `"Scale Ticket number","Scale Ticket date","Assembly number","Commodity name","Location name","Farm name","Field name","Gross weight","Tare weight","Net weight","Gross Units","Shrink Units","Dock Units","Net Units"↵"808080","08/01/2020","9090","Alyssa Clover","Main","","","60000","41000","19000","422.22","0","0","422.22"↵"909090","07/01/2020","9090","Alyssa Clover","Main","","","65000","47000","18000","400","0","0","400"↵"9898","06/23/2020","1026","Soybeans","East","","","50000","32000","18000","300","0","0","300"↵"56568","06/23/2020","1026","Soybeans","Main","","","45333","28999","16334","272.23","0","0","272.23"↵`,
  totalRecordCount: 4
};

// CustomerGrainAssemblyStubService#retrieveGrainAssemblies
export const stubCustomerGrainAssemblies: PaginatedResponse<GrainAssemblyData> = {
  data: [
    {
      number: '15010',
      location: 'Main',
      commodityName: 'Soybeans',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 283.33,
      yourSplit: 70.83,
      unsettledUnits: 70.83
    },
    {
      number: '123456',
      location: 'Main',
      commodityName: 'Corn',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 446.43,
      yourSplit: 111.61,
      unsettledUnits: 111.61
    },
    {
      number: '1015',
      location: 'East',
      commodityName: 'Corn',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 1294.94,
      yourSplit: 1294.93,
      unsettledUnits: 1069.94
    },
    {
      number: '1011',
      location: 'East',
      commodityName: 'Soybeans',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 1614.98,
      yourSplit: 1614.98,
      unsettledUnits: 1614.98
    },
    {
      number: '1010',
      location: 'East',
      commodityName: 'Soybeans',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 3118.18,
      yourSplit: 3118.18,
      unsettledUnits: 3118.18
    },
    {
      number: '1004',
      location: 'East',
      commodityName: 'Soybeans',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 10166.67,
      yourSplit: 10166.67,
      unsettledUnits: 0.0
    },
    {
      number: '1003',
      location: 'East',
      commodityName: 'Corn',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 13232.14,
      yourSplit: 13232.14,
      unsettledUnits: 0.0
    }
  ],
  metaResponseData: new MetaResponseData(),
  linksMetaData: {
    first: null,
    last: null,
    next: 'nextpage.com', // This is to make sure that next page is available
    prev: null,
    self: null
  }
};

// CustomerGrainAssemblyStubService#retrieveGrainAssembliesByUrl
export const stubNextPageOfCustomerGrainAssemblies: PaginatedResponse<GrainAssemblyData> = {
  data: [
    {
      number: '200000',
      location: 'Main',
      commodityName: 'Soybeans',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 283.33,
      yourSplit: 70.83,
      unsettledUnits: 70.83
    },
    {
      number: '300000',
      location: 'Main',
      commodityName: 'Corn',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 446.43,
      yourSplit: 111.61,
      unsettledUnits: 111.61
    },
    {
      number: '999999',
      location: 'Main',
      commodityName: 'Corn',
      unitOfMeasure: 'Bu',
      assemblyDate: new Date('01/01/2019'),
      netUnits: 446.43,
      yourSplit: 111.61,
      unsettledUnits: 111.61
    }
  ],
  metaResponseData: new MetaResponseData(),
  linksMetaData: new LinksMetaData()
};

export const stubCustomerGrainCommodities: PaginatedResponse<GrainCommodityData> = {
  data: [
    {
      commodityId: 'CN',
      commodityName: 'Corn',
      unitOfMeasure: 'BU',
      grossUnits: 3281.56,
      netUnits: 3141.13,
      yourSplit: 2543.89,
      unsettledUnits: 1111.36
    },
    {
      commodityId: 'SB',
      commodityName: 'Soybeans',
      unitOfMeasure: 'Bu',
      grossUnits: 48092.31,
      netUnits: 48047.73,
      yourSplit: 48047.73,
      unsettledUnits: 5306.73
    },
    {
      commodityId: 'Coffee',
      commodityName: 'American coffee',
      unitOfMeasure: 'Cup',
      grossUnits: 12345,
      netUnits: 12345,
      yourSplit: 12345,
      unsettledUnits: 12345
    },
    {
      commodityId: 'C1',
      commodityName: 'Commodity1',
      unitOfMeasure: 'Bu',
      grossUnits: 0,
      netUnits: 0,
      yourSplit: 0,
      unsettledUnits: 0
    },
    {
      commodityId: 'C2',
      commodityName: 'Commodity2',
      unitOfMeasure: 'Bu',
      grossUnits: 912.69,
      netUnits: 912.69,
      yourSplit: 912.69,
      unsettledUnits: 912.69
    },
    {
      commodityId: 'CNL',
      commodityName: 'Corn Lates',
      unitOfMeasure: 'Bu',
      grossUnits: 15706.21,
      netUnits: 15354.11,
      yourSplit: 14638.69,
      unsettledUnits: 1181.55
    },
    {
      commodityId: 'Tea',
      commodityName: 'Hari Tea',
      unitOfMeasure: 'Cup',
      grossUnits: 20000,
      netUnits: 20000,
      yourSplit: 20000,
      unsettledUnits: 20000
    }
  ],
  metaResponseData: new MetaResponseData(),
  linksMetaData: {
    first: null,
    last: null,
    next: 'nextpage.com', // This is to make sure that next page is available
    prev: null,
    self: null
  }
};

export const stubNextPageOfCustomerGrainCommodities: PaginatedResponse<GrainCommodityData> = {
  data: [
    {
      commodityId: 'WT',
      commodityName: 'Wheat',
      unitOfMeasure: 'Bu',
      grossUnits: 10233.56,
      netUnits: 9237.13,
      yourSplit: 445.89,
      unsettledUnits: 348.36
    }
  ],
  metaResponseData: new MetaResponseData(),
  linksMetaData: new LinksMetaData()
};

// FarmService#fetch
export const stubFarmData: FarmData[] = [
  {
    farmName: 'AndFred',
    description: 'Fred Anderson Farm'
  },
  {
    farmName: 'BarbWire',
    description: 'Barry\'s Wire Farm'
  },
  {
    farmName: 'SouthFar',
    description: 'South Farm'
  },
  {
    farmName: 'APIFarm',
    description: 'Farm Description'
  },
  {
    farmName: 'andy',
    description: 'Description for Farm from Import for zzzzzz'
  },
  {
    farmName: 'South',
    description: ''
  },
  {
    farmName: 'APICTe',
    description: 'Andrea’s C# Test'
  },
  {
    farmName: 'APICTest',
    description: 'Andrea’s C# Test'
  },
  {
    farmName: 'APIFarm2',
    description: 'Farm Descriptions'
  },
  {
    farmName: 'APIFarm3',
    description: 'Farm Descriptions'
  }
];

// CustomerGrainScaleTicketsDetailsStubService#retrieveGrainScaleTicketDetails
export const stubCustomerGrainScaleTicketDetails: GrainScaleTicketDetailsData = {
  ticketNumber: null, commodity: null, location: null, grossWeight: 500000,
  tareWeight: 0, netWeight: 500000, grossUnits: 10714.29, dockUnits: 0, shrinkUnits: 714.29, netUnits: 10000
};

// GrainScaleTicketSplitsStubService#retrieveSplits
export const stubGrainScaleTicketSplits: GrainScaleTicketSplitsData[] = [{
  firstName: 'Barry',
  lastName: 'Anderson',
  contract: '5551212',
  dprStatus: 'Priced',
  units: 25,
  splitPercent: 0.25
},
{
  firstName: 'Tom',
  lastName: 'Smith',
  contract: '5551213',
  dprStatus: 'Priced Later',
  units: 75,
  splitPercent: 0.75
}];

// CustomerGradeFactorsStubService#retrieveGradeFactors
export const stubCustomerGradeFactors: GradeFactorData[] = [{
  testType: 'Damage', testResult: 1.000, premiumOrDiscount: 1.000
},
{
  testType: 'Moisture', testResult: 13.900, premiumOrDiscount: -1.000
},
{
  testType: 'TWT', testResult: 52.200, premiumOrDiscount: 0.000
}];

export const stubCustomerGrainPurchaseContracts: GrainPurchaseContractData[] = [
  {
    commodityName: 'Corn',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bsh', units: '10816.46',
        contracts: [{
          contractNumber: '518', location: 'Main', dailyPositionReport: 'Priced', dateEstablished : new Date('09/16/2018'),
          contractQuantity: 5000.00, remainingQuantity: 4928.57, receivedQuantity: 71.43, price: 4.58,
          lastDeliveryEndDate: new Date('09/20/2018'), signatureStatus: 'ReadyForSignature', unitOfMeasure: 'Bu', commodityName: 'Corn',
          s3FileNameGUID: '1234'
        }, {
            contractNumber: '530', location: 'four', dailyPositionReport: 'Priced', dateEstablished : new Date('09/18/2018'),
            contractQuantity: 6567.00, remainingQuantity: 5887.89, receivedQuantity: 679.11, price: 6.00,
            lastDeliveryEndDate: new Date('09/21/2018'), signatureStatus: 'Signed'
        }]
      }, {
        dailyPositionReport: 'Basis Fixed', unitOfMeasure: 'Bsh', units: '8800.24',
        contracts: [{
          contractNumber: '520', location: 'Main', dailyPositionReport: 'Basis Fixed', dateEstablished : new Date('09/20/2018'),
          contractQuantity: 4500, remainingQuantity: 2952.39, receivedQuantity: 1547.61, price: 5.99,
          lastDeliveryEndDate: new Date('10/01/2018'), signatureStatus: 'ReadyForSignature', unitOfMeasure: 'Bu', commodityName: 'Corn',
          s3FileNameGUID: '12345'
        }, {
          contractNumber: '526', location: 'five', dailyPositionReport: 'Basis Fixed', dateEstablished : new Date('09/15/2018'),
          contractQuantity: 3333.00, remainingQuantity: 2440.14, receivedQuantity: 892.86, price: 7.00,
          lastDeliveryEndDate: new Date('10/02/2018')
        }, {
          contractNumber: '532', location: 'four', dailyPositionReport: 'Basis Fixed', dateEstablished : new Date('09/19/2018'),
          contractQuantity: 4444.00, remainingQuantity: 3407.71, receivedQuantity: 1036.29, price: 2.00,
          lastDeliveryEndDate: new Date('10/10/2018')
        }]
      }]
  }, {
    commodityName: 'Soybeans',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bushel', units: '3989.37',
        contracts: [{
          contractNumber: '524', location: 'Main', dailyPositionReport: 'Priced', dateEstablished : new Date('09/21/2018'),
          contractQuantity: 5454.00, remainingQuantity: 3452.00, receivedQuantity: 0.00, price: 2.00, lastDeliveryEndDate: null
        }]
      }, {
        dailyPositionReport: 'Hedged-to-Arrive', unitOfMeasure: 'Bushel', units: '8787.00',
        contracts: [{
          contractNumber: '528', location: 'Main', dailyPositionReport: 'Hedged-to-Arrive', dateEstablished : new Date('09/19/2018'),
          contractQuantity: 3452.00, remainingQuantity: 8787.00, receivedQuantity: 0.00, price: 3.00,
          lastDeliveryEndDate: null, signatureStatus: 'ReadyForSignature', unitOfMeasure: 'Bu', commodityName: 'Soybeans',
          s3FileNameGUID: '12346'
        }]
    }]
  }, {
    commodityName: 'Spring Wheat',
    dispositions: [
      {
        dailyPositionReport: 'Futures Fixed', unitOfMeasure: 'Bu', units: '3343.01',
        contracts: [{
          contractNumber: '527', location: 'five', dailyPositionReport: 'Futures Fixed', dateEstablished : new Date('09/18/2018'),
          contractQuantity: 4444.00, remainingQuantity: 1224.89, receivedQuantity: 3219.11, price: 4.00,
          lastDeliveryEndDate: new Date('09/28/2018')
      }, {
          contractNumber: '5533', location: 'four', dailyPositionReport: 'Futures Fixed', dateEstablished : new Date('09/23/2018'),
          contractQuantity: 3333.00, remainingQuantity: 2118.12, receivedQuantity: 1214.88, price: 8.00,
          lastDeliveryEndDate: new Date('10/15/2018'), signatureStatus: 'ReadyForSignature', unitOfMeasure: 'Bu', commodityName: 'Wheat'
      }]
    }]
  }, {
    commodityName: 'Barley',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bu', units: '123.21',
        contracts: [{
          contractNumber: '12345', location: 'Shelbyville', dailyPositionReport: 'Priced', dateEstablished : new Date('12/12/2018'),
          contractQuantity: 123.544, remainingQuantity: 23.544, receivedQuantity: 100.000, price: 1.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '12346', location: 'Buffalo', dailyPositionReport: 'Priced', dateEstablished : new Date('11/11/2018'),
          contractQuantity: 42536.504, remainingQuantity: 40000.000, receivedQuantity: 2536.504, price: 1.00,
          lastDeliveryEndDate: new Date('02/02/2018')
        }]
      }, {
        dailyPositionReport: 'Non-Priced', unitOfMeasure: 'Bu', units: '7642.98',
        contracts: [{
          contractNumber: '20006', location: 'Shelbyville', dailyPositionReport: 'Non-Priced', dateEstablished : new Date('12/12/2017'),
          contractQuantity: 10000.123, remainingQuantity: 0.123, receivedQuantity: 10000.000, price: 2.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '20007', location: 'Chicago', dailyPositionReport: 'Non-Priced', dateEstablished : new Date('11/11/2016'),
          contractQuantity: 803.765, remainingQuantity: 100.765, receivedQuantity: 703.000, price: 3.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }]
      }]
  }, {
    commodityName: 'Oats',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '12345', location: 'Shelbyville', dailyPositionReport: 'Priced', dateEstablished : new Date('01/01/2017'),
          contractQuantity: 123.544, remainingQuantity: 23.544, receivedQuantity: 100.000, price: 1.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '12346', location: 'Buffalo', dailyPositionReport: 'Priced', dateEstablished : null,
          contractQuantity: 42536.504, remainingQuantity: 40000.000, receivedQuantity: 2536.504, price: 1.00,
          lastDeliveryEndDate: new Date('02/02/2018')
        }]
      }, {
        dailyPositionReport: 'Non-Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '20006', location: 'Shelbyville', dailyPositionReport: 'Non-Priced', dateEstablished : null,
          contractQuantity: 10000.123, remainingQuantity: 0.123, receivedQuantity: 10000.000, price: 2.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '20007', location: 'Chicago', dailyPositionReport: 'Non-Priced', dateEstablished : new Date('11/11/2015'),
          contractQuantity: 803.765, remainingQuantity: 100.765, receivedQuantity: 703.000, price: 3.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }]
      }]
  }, {
    commodityName: 'Rye',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '12345', location: 'Shelbyville', dailyPositionReport: 'Priced', dateEstablished : new Date('01/01/2017'),
          contractQuantity: 123.544, remainingQuantity: 23.544, receivedQuantity: 100.000, price: 1.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '12346', location: 'Buffalo', dailyPositionReport: 'Priced', dateEstablished : null,
          contractQuantity: 42536.504, remainingQuantity: 40000.000, receivedQuantity: 2536.504, price: 1.00,
          lastDeliveryEndDate: new Date('02/02/2018')
        }]
      }, {
        dailyPositionReport: 'Non-Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '20006', location: 'Shelbyville', dailyPositionReport: 'Non-Priced', dateEstablished : null,
          contractQuantity: 10000.123, remainingQuantity: 0.123, receivedQuantity: 10000.000, price: 2.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '20007', location: 'Chicago', dailyPositionReport: 'Non-Priced', dateEstablished : new Date('11/11/2015'),
          contractQuantity: 803.765, remainingQuantity: 100.765, receivedQuantity: 703.000, price: 3.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }]
      }]
  }, {
    commodityName: 'Flax',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '12345', location: 'Shelbyville', dailyPositionReport: 'Priced', dateEstablished : new Date('01/01/2017'),
          contractQuantity: 123.544, remainingQuantity: 23.544, receivedQuantity: 100.000, price: 1.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '12346', location: 'Buffalo', dailyPositionReport: 'Priced', dateEstablished : null,
          contractQuantity: 42536.504, remainingQuantity: 40000.000, receivedQuantity: 2536.504, price: 1.00,
          lastDeliveryEndDate: new Date('02/02/2018')
        }]
      }, {
        dailyPositionReport: 'Non-Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '20006', location: 'Shelbyville', dailyPositionReport: 'Non-Priced', dateEstablished : null,
          contractQuantity: 10000.123, remainingQuantity: 0.123, receivedQuantity: 10000.000, price: 2.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '20007', location: 'Chicago', dailyPositionReport: 'Non-Priced', dateEstablished : new Date('11/11/2015'),
          contractQuantity: 803.765, remainingQuantity: 100.765, receivedQuantity: 703.000, price: 3.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }]
      }]
  }, {
    commodityName: 'Sunflowers',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '12345', location: 'Shelbyville', dailyPositionReport: 'Priced', dateEstablished : new Date('01/01/2017'),
          contractQuantity: 123.544, remainingQuantity: 23.544, receivedQuantity: 100.000, price: 1.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '12346', location: 'Buffalo', dailyPositionReport: 'Priced', dateEstablished : null,
          contractQuantity: 42536.504, remainingQuantity: 40000.000, receivedQuantity: 2536.504, price: 1.00,
          lastDeliveryEndDate: new Date('02/02/2018')
        }]
      }, {
        dailyPositionReport: 'Non-Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '20006', location: 'Shelbyville', dailyPositionReport: 'Non-Priced', dateEstablished : null,
          contractQuantity: 10000.123, remainingQuantity: 0.123, receivedQuantity: 10000.000, price: 2.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '20007', location: 'Chicago', dailyPositionReport: 'Non-Priced', dateEstablished : new Date('11/11/2015'),
          contractQuantity: 803.765, remainingQuantity: 100.765, receivedQuantity: 703.000, price: 3.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }]
      }]
  }, {
    commodityName: 'Canola',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '12345', location: 'Shelbyville', dailyPositionReport: 'Priced', dateEstablished : new Date('01/01/2017'),
          contractQuantity: 123.544, remainingQuantity: 23.544, receivedQuantity: 100.000, price: 1.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '12346', location: 'Buffalo', dailyPositionReport: 'Priced', dateEstablished : null,
          contractQuantity: 42536.504, remainingQuantity: 40000.000, receivedQuantity: 2536.504, price: 1.00,
          lastDeliveryEndDate: new Date('02/02/2018')
        }]
      }, {
        dailyPositionReport: 'Non-Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '20006', location: 'Shelbyville', dailyPositionReport: 'Non-Priced', dateEstablished : null,
          contractQuantity: 10000.123, remainingQuantity: 0.123, receivedQuantity: 10000.000, price: 2.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '20007', location: 'Chicago', dailyPositionReport: 'Non-Priced', dateEstablished : new Date('11/11/2015'),
          contractQuantity: 803.765, remainingQuantity: 100.765, receivedQuantity: 703.000, price: 3.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }]
      }]
  }, {
    commodityName: 'Rice',
    dispositions: [
      {
        dailyPositionReport: 'Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '12345', location: 'Shelbyville', dailyPositionReport: 'Priced', dateEstablished : new Date('01/01/2017'),
          contractQuantity: 123.544, remainingQuantity: 23.544, receivedQuantity: 100.000, price: 1.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '12346', location: 'Buffalo', dailyPositionReport: 'Priced', dateEstablished : null,
          contractQuantity: 42536.504, remainingQuantity: 40000.000, receivedQuantity: 2536.504, price: 1.00,
          lastDeliveryEndDate: new Date('02/02/2018')
        }]
      }, {
        dailyPositionReport: 'Non-Priced', unitOfMeasure: 'Bu', units: '4000.0',
        contracts: [{
          contractNumber: '20006', location: 'Shelbyville', dailyPositionReport: 'Non-Priced', dateEstablished : null,
          contractQuantity: 10000.123, remainingQuantity: 0.123, receivedQuantity: 10000.000, price: 2.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }, {
          contractNumber: '20007', location: 'Chicago', dailyPositionReport: 'Non-Priced', dateEstablished : new Date('11/11/2015'),
          contractQuantity: 803.765, remainingQuantity: 100.765, receivedQuantity: 703.000, price: 3.00,
          lastDeliveryEndDate: new Date('01/01/2018')
        }]
      }]
  }
];

export const stubGrainSettlements: PaginatedResponse<GrainSettlementData> = { data: [
    {
      achNumber: '', checkAmount: 827.34, checkNumber: '8473', commodityName: 'Corn',
      isACHPayment: false, locationName: 'Shelbyville', location: 'Main', settlementDate: new Date('03/01/2019'),
      settlementNumber: '329', unitOfMeasure: 'Bu', unitsSettled: 321.21
    },
    {
      achNumber: '947392', checkAmount: 4982.09, checkNumber: '', commodityName: 'Soybeans',
      isACHPayment: true, locationName: 'Shelbyville', location: 'Main', settlementDate: new Date('03/02/2019'),
      settlementNumber: '8923', unitOfMeasure: 'Bu', unitsSettled: 711.71
    },
    {
      achNumber: '947823', checkAmount: 6234.76, checkNumber: '', commodityName: 'Soybeans',
      isACHPayment: true, locationName: 'Shelbyville', location: 'Main', settlementDate: new Date('03/05/2019'),
      settlementNumber: '8976', unitOfMeasure: 'Bu', unitsSettled: 890.57
    },
    {
      achNumber: '947876', checkAmount: 3421.56, checkNumber: '', commodityName: 'Corn',
      isACHPayment: true, locationName: 'Shelbyville', location: 'Main', settlementDate: new Date('03/05/2019'),
      settlementNumber: '8923', unitOfMeasure: 'Bu', unitsSettled: 977.42
    },
  ],
  metaResponseData: new MetaResponseData(),
  linksMetaData: new LinksMetaData()
};

// CashBidsService#fetch
export const stubCashBids: CashBidData[] = [
  {
    commodityId: 'CN',
    basisPrice: -0.40,
    bidCriteria: 'Delivered',
    commoditySymbol: 'ZCN19',
    commodityName: 'Corn',
    startingDeliveryPeriod: moment().startOf('day').toISOString(),
    endingDeliveryPeriod: moment().startOf('day').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: moment().format('MMMM YYYY'),
    futureMonth: ''
  },
  {
    commodityId: 'CN',
    basisPrice: -0.40,
    bidCriteria: 'Delivered',
    commoditySymbol: 'ZCN19',
    commodityName: 'Corn',
    startingDeliveryPeriod: moment().startOf('day').toISOString(),
    endingDeliveryPeriod: moment().startOf('day').toISOString(),
    locationId: 'West',
    locationName: 'SSI Ag Supply & Services - West Plant',
    city: 'Wichita',
    state: 'KS',
    aliasDescription: '',
    futureMonth: ''
  },
  {
    commodityId: 'CN',
    basisPrice: -0.40,
    bidCriteria: 'Delivered',
    commoditySymbol: 'ZCN19',
    commodityName: 'Corn',
    startingDeliveryPeriod: moment().add(1, 'month').toISOString(),
    endingDeliveryPeriod: moment().add(2, 'month').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: 'New Crop ' + moment().add(1, 'month').format('MMMM YYYY'),
    futureMonth: ''
  },
  {
    commodityId: 'CN',
    basisPrice: -0.38,
    bidCriteria: 'Delivered',
    commoditySymbol: 'ZCZ19',
    commodityName: 'Corn',
    startingDeliveryPeriod: moment().add(2, 'month').toISOString(),
    endingDeliveryPeriod: moment().add(3, 'month').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: '',
    futureMonth: moment().add(2, 'month').format('MMMM YYYY')
  },
  {
    commodityId: 'WH',
    basisPrice: -0.44,
    bidCriteria: 'Delivered',
    commoditySymbol: 'ZWH19',
    commodityName: 'Wheat',
    startingDeliveryPeriod: moment().startOf('day').toISOString(),
    endingDeliveryPeriod: moment().startOf('day').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: '',
    futureMonth: moment().format('MMMM YYYY')
  },
  {
    commodityId: 'WH',
    basisPrice: -0.42,
    bidCriteria: 'Delivered',
    commoditySymbol: 'ZWH19',
    commodityName: 'Wheat',
    startingDeliveryPeriod: moment().add(1, 'month').toISOString(),
    endingDeliveryPeriod: moment().add(2, 'month').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: '',
    futureMonth: moment().add(1, 'month').format('MMMM YYYY')
  },
  {
    commodityId: 'SB',
    basisPrice: -0.60,
    bidCriteria: 'Delivered',
    commoditySymbol: 'ZSB19',
    commodityName: 'Soybean',
    startingDeliveryPeriod: moment().startOf('day').toISOString(),
    endingDeliveryPeriod: moment().startOf('day').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: '',
    futureMonth: moment().format('MMMM YYYY')
  },
  {
    commodityId: 'SB',
    basisPrice: -0.59,
    bidCriteria: 'Delivered',
    commoditySymbol: 'ZSB19',
    commodityName: 'Soybean',
    startingDeliveryPeriod: moment().add(1, 'month').toISOString(),
    endingDeliveryPeriod: moment().add(2, 'month').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: '',
    futureMonth:  moment().add(1, 'month').format('MMMM YYYY')
  },
  {
    commodityId: 'CN',
    basisPrice: -0.38,
    bidCriteria: 'Direct',
    commoditySymbol: 'ZCN19',
    commodityName: 'Corn',
    startingDeliveryPeriod: moment().startOf('day').toISOString(),
    endingDeliveryPeriod: moment().startOf('day').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: '',
    futureMonth: moment().format('MMMM YYYY')
  },
  {
    commodityId: 'WH',
    basisPrice: -0.42,
    bidCriteria: 'Direct',
    commoditySymbol: 'ZWH19',
    commodityName: 'Wheat',
    startingDeliveryPeriod: moment().startOf('day').toISOString(),
    endingDeliveryPeriod: moment().startOf('day').toISOString(),
    locationId: 'East',
    locationName: 'SSI Ag Supply & Services - East Plant',
    city: 'Shelbyville',
    state: 'IL',
    aliasDescription: '',
    futureMonth: moment().format('MMMM YYYY')
  }
];

// BarchartsApiQuoteService#fetch
export const stubBarchartsApiQuote: BarchartsApiQuoteData = {
  results: [
    { symbol: 'ZCN19', lastPrice: 3.3525, netChange: '.03', open: 3.3250,
      high: 3.38, low: 3.32, tradeTimestamp: moment().toISOString() },
    { symbol: 'ZCZ19', lastPrice: 3.3925, netChange: '.04', open: 3.3550,
      high: 3.41, low: 3.35, tradeTimestamp: moment().subtract(1, 'day').toISOString() },
    { symbol: 'ZWH19', lastPrice: 4.2225, netChange: '.32', open: 3.3250,
      high: 4.45, low: 4.2150, tradeTimestamp: moment().add(2, 'minute').toISOString() },
    { symbol: 'ZSB19', lastPrice: 7.5150, netChange: '.02', open: 7.4850,
      high: 7.53, low: 7.4850, tradeTimestamp: moment().toISOString() },
  ]
} as BarchartsApiQuoteData;

// BarchartsApiSymbolService#fetch
export const stubBarchartsApiSymbol: BarchartsApiSymbolData[] = [
  {symbol: 'ZCN19', name: 'Corn (June 19)'},
  {symbol: 'ZCZ19', name: 'Corn (Sept 19)'},
  {symbol: 'ZWH19', name: 'Wheat (June 19)'},
  {symbol: 'ZSB19', name: 'Soybeans (June 19)'},
];

// CustomerInvoicesStubService#retrieveCustomerProductTotals
export const stubProductTotalGroup: ProductTotalGroup[] = [
  {
    groupKey: {
      productId: 'productId1',
      productName: 'Urea(46-0-0)',
      unitOfMeasure: 'Lbs',
      departmentId: 'ABC',
      category: '1'
    },
    invoicesTotals: [
      {
        invoiceNumber: 1,
        productTotals: {
          quantity: 100,
          costTotals: {
            tax: 10,
            prepay: 20,
            subtotal: 200,
            total: 190,
            totalCost: 210
          }
        },
        invoiceDate: new Date(),
        invoiceLocation: 'Main'
      },
      {
        invoiceNumber: 2,
        productTotals: {
          quantity: 200,
          costTotals: {
            tax: 20,
            prepay: 30,
            subtotal: 400,
            total: 390,
            totalCost: 420
          }
        },
        invoiceDate: new Date(),
        invoiceLocation: 'Main'
      }
    ],
    totals: {
      quantity: 300,
      costTotals: {
        tax: 30,
        prepay: 50,
        subtotal: 600,
        total: 580,
        totalCost: 630
      }
    }
  },
  {
    groupKey: {
      productId: 'productId2',
      productName: 'Unleaded Gasoline',
      unitOfMeasure: 'Gal',
      departmentId: 'DEF',
      category: '2'
    },
    invoicesTotals: [
      {
        invoiceNumber: 1,
        productTotals: {
          quantity: 100,
          costTotals: {
            tax: 5,
            prepay: 10,
            subtotal: 100,
            total: 95,
            totalCost: 105
          }
        },
        invoiceDate: new Date(),
        invoiceLocation: 'Main'
      },
      {
        invoiceNumber: 2,
        productTotals: {
          quantity: 100,
          costTotals: {
            tax: 5,
            prepay: 25,
            subtotal: 100,
            total: 80,
            totalCost: 105
          }
        },
        invoiceDate: new Date(),
        invoiceLocation: 'Main'
      }
    ],
    totals: {
      quantity: 200,
      costTotals: {
        tax: 10,
        prepay: 35,
        subtotal: 200,
        total: 175,
        totalCost: 210
      }
    }
  },
  {
    groupKey: {
      productId: 'productId3',
      productName: 'Soymaster Clear Diesel',
      unitOfMeasure: 'Gal',
      departmentId: 'GHI',
      category: '3'
    },
    invoicesTotals: [
      {
        invoiceNumber: 1,
        productTotals: {
          quantity: 100,
          costTotals: {
            tax: 15,
            prepay: 100,
            subtotal: 250,
            total: 165,
            totalCost: 265
          }
        },
        invoiceDate: new Date(),
        invoiceLocation: 'Main'
      },
      {
        invoiceNumber: 2,
        productTotals: {
          quantity: 200,
          costTotals: {
            tax: 30,
            prepay: 0,
            subtotal: 500,
            total: 530,
            totalCost: 530
          }
        },
        invoiceDate: new Date(),
        invoiceLocation: 'Main'
      }
    ],
    totals: {
      quantity: 200,
      costTotals: {
        tax: 45,
        prepay: 100,
        subtotal: 750,
        total: 695,
        totalCost: 795
      }
    }
  },
  {
    groupKey: {
      productId: 'productId4',
      productName: 'Potash(0-0-60)',
      unitOfMeasure: 'Lbs',
      departmentId: 'ABC',
      category: '1'
    },
    invoicesTotals: [
      {
        invoiceNumber: 1,
        productTotals: {
          quantity: 50,
          costTotals: {
            tax: 25,
            prepay: 25,
            subtotal: 200,
            total: 200,
            totalCost: 225
          }
        },
        invoiceDate: new Date(),
        invoiceLocation: 'Main'
      },
      {
        invoiceNumber: 2,
        productTotals: {
          quantity: 150,
          costTotals: {
            tax: 75,
            prepay: 200,
            subtotal: 600,
            total: 475,
            totalCost: 675
          }
        },
        invoiceDate: new Date(),
        invoiceLocation: 'Main'
      }
    ],
    totals: {
      quantity: 200,
      costTotals: {
        tax: 100,
        prepay: 225,
        subtotal: 800,
        total: 675,
        totalCost: 900
      }
    }
  }
];
export const stubProductTotalGroupsResponse: ProductTotalGroupsResponse[] = [{
  customerId: '12ABC34',
  overallTotals: {
    tax: 10,
    prepay: 20,
    subtotal: 200,
    total: 2125.00,
    totalCost: 210
  },
  productGroupTotals: stubProductTotalGroup
}];

export const stubCustomerWithProductTotalsCsv: CustomerWithProductTotalsCsv[] = [
  {
    customerId: '12ABC34',
    productTotalsCsv: getProductTotalsStubCsv(stubProductTotalGroup)
  }
];

export const stubCustomerWithProductsWithInvoiceTotalsCsv: CustomerWithProductsWithInvoiceTotalsCsv[] = [
  {
    customerId: '12ABC34',
    productGroups: getProductWithInvoiceTotalsCsv(stubProductTotalGroup)
  }
];

function getProductTotalsStubCsv(productTotalGroups: ProductTotalGroup[]): string {
  let result = `Product Description, Quantity, U/M, Cost \n`;
  result += productTotalGroups.reduce((acc, value) => {
    acc += `${value.groupKey.productName}, ${value.totals.quantity}, ${value.groupKey.unitOfMeasure}, ${value.totals.costTotals.total} \n`;
    return acc;
  }, '');

  return result;
}

function getProductWithInvoiceTotalsCsv(productTotalGroups: ProductTotalGroup[]): ProductWithInvoiceTotalsCsv[] {
  const result: ProductWithInvoiceTotalsCsv[] = [];

  productTotalGroups.forEach(x => {
    const groupKey = x.groupKey;
    let csv = `Invoice Number, Invoice Date, Quantity, U/M, Tax, Subtotal, Prepay, Total \n`;

    csv += x.invoicesTotals.reduce((acc, y) => {
      const totals = y.productTotals.costTotals;
      acc += `${y.invoiceNumber}, ${moment(y.invoiceDate).format('MM/DD/YYYY')}, ${y.productTotals.quantity},`;
      acc += `${x.groupKey.unitOfMeasure}, ${totals.tax}, ${totals.subtotal}, ${totals.prepay}, ${totals.total} \n`;

      return acc;
    }, '');

    result.push({
      groupKey: groupKey,
      invoiceTotalsCsv: csv
    });

  });

  return result;
}
