import { State } from '@ngxs/store';
import { DataState } from './data/data.state';
import { DataStore } from './data/data.types';
import { UserInterfaceState } from './user-interface/user-interface.state';
import { UserInterfaceStore } from './user-interface/user-interface.types';
import { WindowSizeState } from './window-size/window-size.state';
import { WindowSize } from './window-size/window-size.types';

export const APP_STATE = 'appState';

export class AppStore {
  windowSize = new WindowSize();
  data = new DataStore();
  userInterface = new UserInterfaceStore();
}
@State<AppStore>({
  name: APP_STATE,
  children: [WindowSizeState, UserInterfaceState, DataState],
  defaults: new AppStore()
})
export class AppState {}
