<div class="notifications-container">
  <app-breadcrumb-navigation
    (crumbClicked)="handleBreadcrumbLink($event)"
    [breadcrumbs]="['Overview', 'Notifications']"></app-breadcrumb-navigation>
  <div class="container">
    <div class="greeting">
      Alert Notifications
    </div>
    <div class="search-section">
      <div class="search">
        <mat-icon class="search-icon" svgIcon="search-outline"></mat-icon>
        <input
          #searchField
          class="search-field active"
          data-id="NotificationSearch"
          id="txtCompanySearch"
          name="notificationSearch"
          placeholder="Search Notifications"
        />
      </div>
    </div>
    <div routerLink="/overview/settings">
      <div class="notification-settings-link">
        <mat-icon class="settings-icon" svgIcon="settings-outline"></mat-icon>
        <div class="notification-settings-text">Notification Settings</div>
      </div>
    </div>
  </div>
  <div class="notification-list-container">
    <div (scrolled)="onScroll()"
         [fromRoot]="true"
         [infiniteScrollContainer]="infiniteScrollSelector"
         data-id="notifications"
         infiniteScroll infiniteScrollDistance="2"
         infiniteScrollThrottle="80">
      <ng-container *ngIf="!(isLoading && notifications.length === 0); else spinner">
        <div *ngFor="let notification of notifications" [ngClass]="{'not-read': !notification.isRead}"
             class="notification">
          <div class="{{notification.isRead ? 'badge' : 'badge--blue'}}">
            <mat-icon
              svgIcon="{{notification.emailType === 'ContractReadyToSign' ? 'contract-filled' : 'notification-filled'}}"></mat-icon>
          </div>
          <div class="notification-content-wrapper">
            <div [ngClass]="{'not-read-timestamp': !notification.isRead}"
                 class="notification-timestamp">{{ notification.timeStamp | utcToLocalDate | date:'M-d-yy h:mm a' }}</div>
            <div class="notification-content">
              <div class="notification-subject">{{ notification.subject }}</div>
              <div class="notification-messagebody">
                <div [innerHTML]="notification.messageBody"></div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="noNotificationsFound && searchInput.nativeElement.value" class="no-notifications">
          <sky-illustration [headerText]="searchHeaderText" [illustration]="'noListEntries'"
                            [messageText]="getNotFoundText()"></sky-illustration>
        </div>
        <div *ngIf="noNotificationsFound && !searchInput.nativeElement.value" class="no-notifications">
          <sky-illustration [headerText]="noNotificationsHeader"
                            [illustration]="'noListEntries'" [messageText]="noNotificationsText"></sky-illustration>
        </div>
      </ng-container>

      <div class="skeleton-loader-wrapper-card">
        <div *ngIf="!isLastResult" class="notification-list-container-skeleton-loader">
          <div class="header-with-badge-loader">
            <div class="badge-loader"></div>
            <div class="skeleton-loader"></div>
          </div>
          <div class="content-loader">
            <div class="skeleton-loader"></div>
            <div class="skeleton-loader"></div>
            <div class="skeleton-loader"></div>
            <div class="skeleton-loader extra-column-loader"></div>
          </div>
        </div>
      </div>

      <div *ngIf="!noNotificationsFound && isLastResult" class="no-more-result-notifier"> No More Results</div>
    </div>
  </div>
  <ng-template #spinner>
    <div class="spinner">
      <ngst-spinner></ngst-spinner>
    </div>
  </ng-template>
</div>
