import {Injectable} from '@angular/core';
import {GrainScaleTicketData, ScaleTicketStatus, ScaleTicketStatusColor} from './grain-scale-ticket-data.types';

@Injectable()
export class GrainScaleTicketStatusService {
  scaleTicketStatus(scaleTicket: GrainScaleTicketData): ScaleTicketStatus {
    if (scaleTicket.isTransferred) {
      return ScaleTicketStatus.verified;
    } else if (scaleTicket.isInProcess) {
      return ScaleTicketStatus.inYard;
    } else {
      return ScaleTicketStatus.unverified;
    }
  }

  scaleTicketStatusColor(scaleTicket: GrainScaleTicketData) {
    if (this.scaleTicketStatus(scaleTicket) === ScaleTicketStatus.verified) {
      return ScaleTicketStatusColor.green;
    } else if (this.scaleTicketStatus(scaleTicket) === ScaleTicketStatus.inYard) {
      return ScaleTicketStatusColor.yellow;
    } else {
      return ScaleTicketStatusColor.red;
    }
  }
}
