import { Injectable } from '@angular/core';
import { IBrowserSettings, IClient } from '@splitsoftware/splitio/types/splitio';
import { SplitFactory } from '@splitsoftware/splitio';
import { SplitIOFactory, TreatmentService } from '../../types/treatment.service';
import { ENVIRONMENT } from '../../../../environments/environment';
import { UserInfoService } from '../user-info/user-info.service';
import { TreatmentServiceStub } from './treatment-service-stub';

@Injectable()
export class TreatmentServiceFactory {
  private readonly splitFactory: SplitIOFactory;
  private readonly clientStorage = new Map<string, IClient>();

  constructor(private userInfoService: UserInfoService, splitIOFactory?: SplitIOFactory) {
    this.splitFactory = splitIOFactory || SplitFactory;
  }

  async isTreatmentOn(treatmentName: string, username?: string): Promise<boolean> {
    const treatmentService = await this.getTreatmentService(username);
    await treatmentService.ready();
    return treatmentService.getTreatment(treatmentName) === 'on';
  }

  async isTreatmentOnForCompany(treatmentName: string, companyId?: string): Promise<boolean> {
    const treatmentService = await this.getTreatmentService(companyId, true);
    await treatmentService.ready();

    return treatmentService.getTreatment(treatmentName) === 'on';
  }

  private async getTreatmentService(treatmentKey?: string, isCompanyLevel: boolean = false): Promise<TreatmentService> {
    if (ENVIRONMENT.name === 'stub') {
      return Promise.resolve(new TreatmentServiceStub());
    }
    let key: string | null = null;
    if (!treatmentKey) {
      const userInfo = await this.userInfoService.retrieveUserInfo().toPromise();
      key = isCompanyLevel ? userInfo.companyId || '' : userInfo.email;
    }
    else {
      key = treatmentKey;
    }
    if (this.clientStorage.has(key)) {
      return this.clientStorage.get(key);
    }

    const settings: IBrowserSettings = {
      core: { authorizationKey: ENVIRONMENT.splitIoApiKey, key }
    };
    const splitFactory = this.splitFactory(settings);
    const client = splitFactory.client();
    this.clientStorage.set(key, client);
    return client;
  }
}
