
export abstract class AbstractLocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  readonly origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  abstract assign(url: string): void;
  abstract reload(): void;
  abstract replace(url: string): void;
}
