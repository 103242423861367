import {Injectable} from '@angular/core';
import {Base64ConverterService} from './base64-converter/base64-converter.service';
import {saveAs} from 'file-saver';

@Injectable()
export class PdfFileHandlerService {
  constructor(private base64Converter: Base64ConverterService) {}

  public openInNewWindow(fileData: string) {
    const fileUrl = window.URL.createObjectURL(this.convertFileData(fileData));
    window.open(fileUrl, '_blank');
  }

  public downloadToFile(fileData: string, fileName: string) {
    saveAs(this.convertFileData(fileData), fileName);
  }

  private convertFileData(fileData: string): Blob {
    const byteArray = this.base64Converter.toByteArray(fileData);
    return new Blob([byteArray], {type: 'application/pdf'});
  }
}
