import { Action, State, StateContext } from '@ngxs/store';
import { WindowSize } from './window-size.types';
import { UpdateWindowSize } from './window-size.actions';

export const WINDOW_SIZE_STATE = 'windowSize';

@State<WindowSize>({
  name: WINDOW_SIZE_STATE,
  defaults: {
    width: 0,
    height: 0
  }
})
export class WindowSizeState {

  @Action(UpdateWindowSize)
  updateWindowSize(context: StateContext<WindowSize>, payload: UpdateWindowSize) {
    context.setState(payload.windowSize);
  }
}
