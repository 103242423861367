import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { UserInfo } from './user-info.types';

@Injectable()
export class UserInfoStubService {
  retrieveUserInfo(): Observable<UserInfo> {
    return of({
      companyId: 'acmeAg',
      email: 'kdenton@agvance.net',
      name: 'Kris Denton',
      userId: 'userId',
      roles: 'myRole',
      sub: 'userId'
    });
  }
}
