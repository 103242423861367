import { Action, State, StateContext } from '@ngxs/store';
import { UpdateMemoInvoiceData} from './memo-invoice-data.actions';
import {InvoiceData} from '../invoice-data/invoice-data.types';

export const MEMO_INVOICE_DATA_STATE = 'memoInvoiceData';

@State<InvoiceData[]>({
  name: MEMO_INVOICE_DATA_STATE,
  defaults: []
})
export class MemoInvoiceDataState {
  @Action(UpdateMemoInvoiceData)
  updateMemoInvoiceData({ setState }: StateContext<InvoiceData[]>, payload: UpdateMemoInvoiceData) {
    setState(payload.memoInvoiceData);
  }
}
