import { Action, State, StateContext } from '@ngxs/store';
import { SidebarExpansion } from './sidebar-expansion.types';
import { UpdateSidebarExpansion } from './sidebar-expansion.actions';

export const SIDEBAR_EXPANSION_STATE = 'sidebarExpansion';

@State<SidebarExpansion>({
  name: SIDEBAR_EXPANSION_STATE,
  defaults: {
    expanded: true
  }
})
export class SidebarExpansionState {

  @Action(UpdateSidebarExpansion)
  updateSidebarExpansion({ setState }: StateContext<SidebarExpansion>, payload: UpdateSidebarExpansion) {
    setState(payload.sidebarExpansion);
  }
}
