import { Pipe, PipeTransform } from '@angular/core';
import { SidebarMenuOption } from '../../../../state/user-interface/sidebar-selection/sidebar-selection.types';

@Pipe({
  name: 'uniqueMenuHeaders'
})
export class UniqueMenuHeadersPipe implements PipeTransform {

  transform(items: SidebarMenuOption[]): string[] {
    if (!items) {
      return [];
    }

    const test = items.map(x => x.sectionName);

    return test.filter((x, i, a) => x && a.indexOf(x) === i);
  }

}
