import { Injectable } from '@angular/core';
import { NormalizeStorageKeyService } from './normalize-storage-key/normalize-storage-key.service';
import { EncodeStoredDataService } from './encode-stored-data/encode-stored-data.service';
import { ParseStorageExpirationService } from '../parse-storage-expiration/parse-storage-expiration.service';
import { DecodeStoredDataService } from './decode-stored-data/decode-stored-data.service';
import { ProvideTimeService } from '../provide-time/provide-time.service';
import { ProvideStorageKeyListService } from './provide-storage-key-list/provide-storage-key-list.service';
import { ValidateStorageDataService } from './validate-storage-data/validate-storage-data.service';

@Injectable()
export class StorageWithLifetimeService {
  static readonly StorageKeyPrefix = '__SWLS__';
  static readonly BadKeyMessage = 'No data object found with the provided key';
  static readonly ExpiredDataMessage = 'Data with provided key has expired';
  static readonly KeyPropertyAbbreviation = '_k';
  static readonly DataPropertyAbbreviation = '_d';
  static readonly ExpirationPropertyAbbreviation = '_e';

  constructor(
    private normalizeKey: NormalizeStorageKeyService,
    private validateData: ValidateStorageDataService,
    private encodeData: EncodeStoredDataService,
    private parseExpiration: ParseStorageExpirationService,
    private decodeData: DecodeStoredDataService,
    private provideTime: ProvideTimeService,
    private provideKeys: ProvideStorageKeyListService) { }

  addData(key: string, data: any, lifetime: string): Promise<any> {

    try {
      const normalizedKey = this.normalizeKey.normalize(key, StorageWithLifetimeService.StorageKeyPrefix);
      this.validateData.validate(data);
      const expirationTime = this.parseExpiration.parse(lifetime);

      const storageObject = {};
      storageObject[StorageWithLifetimeService.KeyPropertyAbbreviation] = normalizedKey;
      storageObject[StorageWithLifetimeService.DataPropertyAbbreviation] = data;
      storageObject[StorageWithLifetimeService.ExpirationPropertyAbbreviation] = expirationTime;
      const normalizedData = this.encodeData.encode(storageObject);

      localStorage.setItem(normalizedKey, normalizedData);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  getData<T>(key: string): Promise<T> {
    try {
      const normalizedKey = this.normalizeKey.normalize(key, StorageWithLifetimeService.StorageKeyPrefix);
      const storedData: string = localStorage.getItem(normalizedKey);
      if (storedData === null) {
        return Promise.reject(StorageWithLifetimeService.BadKeyMessage);
      }

      const decodedData = this.decodeData.decode<T>(storedData);
      const currentTime = this.provideTime.currentTime();
      if (decodedData[StorageWithLifetimeService.ExpirationPropertyAbbreviation] <= currentTime) {
        localStorage.removeItem(normalizedKey);
        return Promise.reject(StorageWithLifetimeService.ExpiredDataMessage);
      }

      return Promise.resolve(decodedData[StorageWithLifetimeService.DataPropertyAbbreviation]);

    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  removeData(key: string): Promise<string> {
    try {
      const normalizedKey = this.normalizeKey.normalize(key, StorageWithLifetimeService.StorageKeyPrefix);
      localStorage.removeItem(normalizedKey);
      return Promise.resolve(key);
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  clearData(): Promise<void> {
    const keyList: string[] = this.provideKeys.provide();
    keyList.forEach(key => {
      if (key.startsWith(StorageWithLifetimeService.StorageKeyPrefix)) {
        localStorage.removeItem(key);
      }
    });
    return Promise.resolve();
  }
}
