<div class="skeleton-container">
  <div class="skeleton-row">
    <div class="data-loading"></div>
    <div class="data-loading"></div>
    <div class="data-loading"></div>
    <div class="data-loading"></div>
  </div>
  <div class="skeleton-row">
    <div class="data-loading"></div>
    <div class="data-loading"></div>
    <div class="data-loading"></div>
    <div class="data-loading"></div>
  </div>
  <div class="skeleton-row">
    <div class="data-loading"></div>
    <div class="data-loading"></div>
    <div class="data-loading"></div>
    <div class="data-loading"></div>
  </div>
  <div class="skeleton-row">
    <div class="data-loading"></div>
    <div class="data-loading"></div>
    <div class="data-loading"></div>
    <div class="data-loading"></div>
  </div>
</div>
