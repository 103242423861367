<div class="header-container">
  <div class="feature-picker">
    <app-customer-picker [customerData]="customerData"
                         (selectedNewItem)="onCustomerSwitch($event)"></app-customer-picker>
  </div>

  <app-help-information></app-help-information>

  <app-notifications-bell [notifications]="notifications" [unreadCount]="unreadCount" [displayBell]="displayBell"></app-notifications-bell>
  <div class="header-account-dropdown">
    <app-account-dropdown></app-account-dropdown>
  </div>
  <div class="mobile-hamburger-menu" (click)="mobileMenuClicked()">
    <div class="hamburger-icon-wrapper">
      <div class="hamburger-icon"></div>
    </div>
  </div>
</div>
