import { Action, State, StateContext } from '@ngxs/store';
import { MobileSidebar, MobileSidebarSubMenu } from './mobile-sidebar.types';
import { UpdateMobileSidebar } from './mobile-sidebar.action';

export const MOBILE_SIDEBAR_STATE = 'mobileSidebar';

@State<MobileSidebar>({
  name: MOBILE_SIDEBAR_STATE,
  defaults: {
    display: false,
    submenu: MobileSidebarSubMenu.None
  }
})
export class MobileSidebarState {

  @Action(UpdateMobileSidebar)
  updateMobileSidebar({ setState }: StateContext<MobileSidebar>, payload: UpdateMobileSidebar) {
    setState(payload.mobileSidebar);
  }
}
