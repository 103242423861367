import { AppEnvironment } from './iEnvironment';

export const ENVIRONMENT: AppEnvironment = {
  name: 'develop',
  production: false,
  skydentityBaseUrl: 'https://skydentityapi-dev.agvancesky.com',
  grower360ApiBaseUrl: 'https://dev.api.agvancesky.com/grower360',
  gatewayApiBaseUrl: 'https://dev.api.agvancesky.com',
  splitIoApiKey: '5errb8kmck04anv30ujfrs3a7h4ljv0u4flj',
  staticContentBaseUrl: 'https://static-content.agvancesky.com/dev',
  selectPaymentAmountTreatment: 'Grower360_SelectPaymentAmount',
  splitTreatments: {
    SignInWithCognito: 'G360_SignInWithCognito'
  },
  skydentityClientInfo: {
    clientId: 'grower360-password-grant-client',
    clientScopes: 'grower360 openid profile',
    clientSecret: 'LEDLa6B2T0q97GC76-x7LA'
  }
};
