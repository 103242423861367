import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ENVIRONMENT } from '../../../../environments/environment';
import { InvoiceData } from '../../../state/data/invoice-data/invoice-data.types';
import { CustomerInvoicesParameters } from './customer-invoices-parameters';
import { GrowerIdService } from '../grower-id/grower-id.service';
import {PaginatedResponse} from '../../../state/paginated-response/paginated-response.types';
import * as moment from 'moment';
import {DexxHttp, DexxHttpHeaders} from '@ssiagvance/dexx';
import {LocalStorageService} from '../../../shared/shared-services/local-storage/local-storage.service';
import {
  ProductTotalGroupsRequest,
  ProductTotalGroupsResponse,
  CustomerWithProductTotalsCsv,
  CustomerWithProductsWithInvoiceTotalsCsv
} from '../../../state/data/product-totals-data/product-totals-data.types';

@Injectable()
export class CustomerInvoicesService {
  static readonly NoAuthTokenError = 'No authorization token found. Cannot complete request.';
  static readonly StorageTime = '0s';
  private readonly basePath = `${ENVIRONMENT.gatewayApiBaseUrl}/invoice`;

  constructor(private dexxHttp: DexxHttp,
              private growerIdService: GrowerIdService,
              private localStorageService: LocalStorageService) {}

  retrieveInvoices(agvanceCustomerId: string,
                   additionalParams?: CustomerInvoicesParameters): Observable<PaginatedResponse<InvoiceData>> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          let url =
            `${this.basePath}/invoices?customerid=${growerId}`;

          if (additionalParams) {
            additionalParams.applicableOnDate = moment(new Date()).format('YYYY-MM-DD');
            Object.keys(additionalParams).forEach(key => {
              url += `&${key}=${additionalParams[key]}`;
            });
          }

          const authToken = this.localStorageService.getItem(LocalStorageService.AUTH_TOKEN_KEY);

          const headers = { 'Authorization': authToken, 'Accept': 'application/json' };

          return this.dexxHttp.get(url, CustomerInvoicesService.StorageTime, undefined, headers)
            .then(response => response.body);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then((val: PaginatedResponse<InvoiceData>) => {
          if (val && val.data) {
            val.data = val.data.map(d => new InvoiceData(d as InvoiceData));
          }
          emitter.next(val);
          emitter.complete();
        });
    });
  }

  public async retrieveCustomerProductTotals(productTotalsRequest: ProductTotalGroupsRequest): Promise<ProductTotalGroupsResponse[]> {
    const url = `${this.basePath}/productTotals?${this.getQueryParams(productTotalsRequest as any)}`;
    const response = await  this.dexxHttp.get(url, '1m', undefined, this.getAuthHeadersForDexx());
    return response.body as ProductTotalGroupsResponse[];
  }

  public async getProductInvoicesTotalsCsv(productTotalsRequest: ProductTotalGroupsRequest):
    Promise<CustomerWithProductsWithInvoiceTotalsCsv[]> {
    const url = `${this.basePath}/productTotals/invoicescsv?${this.getQueryParams(productTotalsRequest as any)}`;
    const response = await  this.dexxHttp.get(url, '1m', undefined, this.getAuthHeadersForDexx());
    return response.body as CustomerWithProductsWithInvoiceTotalsCsv[];
  }

  public async getProductTotalsCSV(productTotalsRequest: ProductTotalGroupsRequest): Promise<CustomerWithProductTotalsCsv[]> {
    const url = `${this.basePath}/productTotals/productscsv?${this.getQueryParams(productTotalsRequest as any)}`;
    const response = await  this.dexxHttp.get(url, '1m', undefined, this.getAuthHeadersForDexx());
    return response.body as CustomerWithProductTotalsCsv[];
  }

  private getAuthHeadersForDexx(): DexxHttpHeaders {
    const authToken = this.localStorageService.getItem(LocalStorageService.AUTH_TOKEN_KEY);

    return { 'Authorization': authToken, 'Accept': 'application/json' };
  }

  private getQueryParams(parameters: {[paramName: string]: string | Date | Array<string>}): string {
    let result = '';

    for (const key in parameters) {
      if (parameters.hasOwnProperty(key) && Boolean(parameters[key])) {
        const value = parameters[key];
        if (Array.isArray(value)) {
          result += this.getQueryparamsForList(key, value);
          continue;
        }
        if (value instanceof Date) {
          result += `${key}=${value.toISOString()}&`;
          continue;
        }

        result += `${key}=${value}&`;
      }
    }

    return result;
  }

  private getQueryparamsForList(paramName: string, values: string[]): string {
    if (values && values.length > 0) {
      values.filter(x => Boolean(x));
      return values.reduce((acc, value, index) => acc += `${paramName}[${index}]=${value}&` , '');
    }

    return '';
  }
}
