import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { Company } from './company.types';
import { stubCompany } from '../../../app.stub-data';

@Injectable()
export class CompanyStubService {

  retrieveCompany(companyId: string): Observable<Company> {
    return of(stubCompany);
  }
}
