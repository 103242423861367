
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { Observable ,  throwError as _throw } from 'rxjs';
import { Grower360HttpOptions } from '../grower360-http/grower360-http-options.interface';
import { HttpHeaders } from '@angular/common/http';
import { Grower360HttpService } from '../grower360-http/grower360-http.service';
import { AuthToken, UserSignInRequest } from './user-sign-in.interface';
import { SubdomainService } from '../subdomain/subdomain.service';
import { ENVIRONMENT } from '../../../../environments/environment';

@Injectable()
export class AuthService {
  static readonly SignInApiUrlPath = `${ENVIRONMENT.grower360ApiBaseUrl}/user/signin`;

  constructor(private httpClient: Grower360HttpService, private subdomainService: SubdomainService) { }

  isSignedIn(): boolean {
    return (LocalStorageService.getAuthToken() !== null && LocalStorageService.getAuthToken() !== '');
  }

  signIn(email: string, password: string): Observable<boolean> {
    const options: Grower360HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })};

    const postBody: UserSignInRequest = {
      email: email,
      password: password,
      subdomain: this.subdomainService.parseSubdomain()
    };

    return this.httpClient.post(AuthService.SignInApiUrlPath, postBody, options).pipe(
      catchError((err: any) => _throw(err)),
      map((x: AuthToken) =>  {
        LocalStorageService.setAuthToken(x.authToken);

        return true;
      }));
  }
}
