import { Injectable } from '@angular/core';

@Injectable()
export class EncodeStoredDataService {
  static readonly NormalizeDataErrorMessage = 'An error occurred trying to encode data';

  encode(data: any): string {
    try {
      return JSON.stringify(data);
    } catch (e) {
      throw new Error(EncodeStoredDataService.NormalizeDataErrorMessage);
    }
  }
}
