import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CrmPdfInfo} from '../../../state/data/invoice-data/crm-pdf-info';
import {BookingLineItem} from '../../../state/data/booking-data/booking-data.types';
import {base64InvoicePdf} from './stub-pdfs/stub-invoice-pdf';
import {base64BookingPdf} from './stub-pdfs/stub-booking-pdf';
import {base64ContractPdf} from './stub-pdfs/stub-contract-pdf';
import {base64StatementPdf} from './stub-pdfs/stub-statement-pdf';
import {base64PaymentPdf} from './stub-pdfs/stub-payment-pdf';
import {base64SettlementPdf} from './stub-pdfs/stub-settlement-pdf';

const base64Pdf = `
JVBERi0xLjMKJeLjz9MKNSAwIG9iago8PAovTGVuZ3RoIDIxMzQKL0ZpbHRlciAvRmxhdGVEZWNv
ZGUKPj4Kc3RyZWFtCnictVlLbxzHEYZWpERyBYr0Lt+0NDJlekfytKbf3dcAQYAglxi8RTk5iE8y
YPv/A6nqmamqWfaGgRGLl1JPd72r+qveX5peadP0+DcRP345+vRDbH767agsNz/8ZSR+/enol6Ok
LP4rC5L+8Uvzpwc4mBvtVO+ah38f6fJBN1YrZ7Nvoo/KwZcvR//YPGuTStF4C1SvrPHe6M2i7Zwy
Ohu/ed522qgYgt3stTYp10e72cfv3rkY4buDUzEGef4FLPa9gcWXLZz2Lhug6MwBnTlsTVDGhiT4
fP4Zdw50lfsSSJ9jsmbzqp14fv61rIaY0uaYTr3mxSWTJ0wK807bjkSw3K9gg1Xahc1KCiD/rFuH
TKb/npE7D5izOLkunnGg+nkLEQM6/fPhr0O49BiumFRoUGo2zcO/IEYXrVapd95sLtGdITq9uaK1
Ih1UDXFz3XZB9dnkzU07EuLrsxZYuhT15rY1YJLTGSI1UedtN33+mhafT2yeIeds+hTkxldtN+1c
kmZL0mxmYqeVR7OMMm4w61kLGZp0skIgHz5gxd/QZ8gXCwG3Jd6TwGPeeVAllzVfLZh8K+ylxUY6
faTekcxGrHkflPamGAn22rmVfPqCTn9Da6fsTF5k6q62ccZxpN5LHclYzgHtVXDBbL4lU1e0756o
71gIHb5su6z64Hv/vxngk3vKgKuKa+WacOhjf55R1nBYT0TKCQsqARa58poOfd78Vw9csQfeVxx+
RV753DLLthKQC2kZlnxWOWDFdy4r03QerByM/NBCN7CmdB2tsvUuYbFB+7W9wbrDeAaI52GLfSJZ
bMS9isb6nLBRe+Vg3xqtCTrmvPnI5FKwnNZOylow2BvKxgyy123QvUpuUFdr0hcbG2j6t6OHD6WM
R10XxBgzLiibijKThvus4Qv4HpUFP+O9MJ0/QE/GqJPceoit2Gmf5+ynRUHCxWHx8oQ44Xk4bnUA
ayFdTAZJr+g8No+R3BMOJJ2PSx81ENLXwlcieKAnemJwwAntOSFmvHZKlDDqK7xrRlosryqnapQ4
shac0KzHvj5rIVNUzkVx1Pe8newUYZh5fPrO1l/AzqR62AE7pyhd0mdW/Iqoa6JupOt6vP9SsCaV
skY3YsYvUWiKSQvnH7PQW7Lsa7hzjHLaZrgXOshPrQ1WOKkPrXm0SQZvot61Ncd/82Ro7mSQH1ko
A7dV44TCXNaw7hvtXUFfaPW7NkNjsgn7xXZvKI1B54CdwUQow+HIAstNB+8w9NAGkgkl8uCUBA3u
lhePaedrrOnkoO2BnzHkOmF/mz5/X0qwkCtQyCUPp88FHFrOch9awpT632NjDTFHSCVctxmBFgl7
XvBB+c47V/x9pwyjFZVXV5FxUudxi/Jcti5vFPYFHa2eKfSqnb4L1RZSy2iQLojFWSWbHHRFn3LJ
v5HHMR98yasvqxvWvLrPpNDiLa8KM0TkbloApGC+4XaMKZKCcoDoKT9OKYJXfPY9yxGue3LDKZMi
ate7soFF3zzF+e73iiZyn4/NyCmTv+VCuMdccHgFLPj8W9q5P1NgrI7v2pSgNsN2Nq4A4OccXZkn
krapxymEuEIhEn0oByrhMplXAD0KRI4JdRtlNzhEWBfc/NKZDQmdA88DCZULLXBsop8myPKiAvPW
Nagv8JBYPQSPOGhT0UBFPEbRDLcGDGWBpxgFjictXiJiwWEozUWOn4cpxBfVCtbLHuYlmFSLLT0J
XtfQWx3onbWV0aSK+ZZoYk7KbDSWdg+RFGPRyN064b+DCpw28/gIiNTBoAx7Sut2aTBpBgrG64wv
dbgC6LapX/8Q64x4DOHhuPiRKLAyYsZ67GJdcA7WNTTPx+DsTQ1HKMZRK3FxT9Qn/tzT4l3tc011
XeLreo0e6bedsapcqLuc0YUA6xqROH03QzwQDtdufHCM7XuIna3KlJKupSQY5zFrBFxiFzaIUSJU
ecaKlwPgaCReqN5sW3r9h4TdFOtw9pliwG4QuGghz+ABD6lin8iFMVG9xhBPfjitxf2uYhvH3Zsp
7rXsuvzD4l7w4xyhVyTdCJ26DHZEE4U7Zdy9gmTyW1HX+DTSWyh2kJfGand0/rYSt/2ZHdAkInQe
tGN0YjVw94DyCJ5A/5qlGeepmIn2KlXP/LYmplGerykZBCxmhM7s5w4BLa2ZaecqVXTPVcRjGDOv
u2hBO2Gc7vsZXLaxiMXpd5BqS2/H29XhfRCSgf7NUFQgw3UVfs0BKm0+kRB3ghHn5aWwYESBWO4J
E63ofn/DAg7o+Bw2Q2J7uJMkgjwnW8RWAXOFUn7n22LnM1IQHz9ihr0WvZZAA8VXZu2BcE33ZP3u
ZaTwQjznbd+PpImGUjGNdfTK2dNN28sXFbpr+e1zNk+OOTbAqd/JIwyPTJytfOvDaXoI4xefXagM
iERwmF/nbvgpid/4ds2JoCzGEfSBgdGkaeqbHqSHF2Rj3OzJ+5ZWdzx+yzsKRLkmAPhx/3/mGkAc
OlOw3znkTiNM5x1WLN4viXpZKSV8n6GsLp0B2r0Lw7A0lEVD4D6O38vjLdYazErFEKzA0M94Ccyf
qqsz8F42wBZMd/EbQaFdhAsGGukkuvAIPhg/HxGmNoATwtgH9mhR8i03Ovh3+iami09iWz9rR4Wj
CZCkeAz6wWzvpZwixXg46tHXPl/SZ8kp89ZMW/e5X53RnHzRghVviAlWYWVe4+F+QexEcGcRG0ax
hDVBWKLWksVAKQwfBkrfZ41vt2PTHT0XY5wPnOyjnfOXgYPQusTL9AdqgKuhzVgeiwB8iolk+hnD
Uv+0VjRiHng+bv0wAp8nPtZbMYjdijbNj7Z/fjj6O/z9BxOQUHYKZW5kc3RyZWFtCmVuZG9iago2
IDAgb2JqCjIxMzQKZW5kb2JqCjQgMCBvYmoKPDwKL1R5cGUgL1BhZ2UKL01lZGlhQm94IFsgMCAw
IDYxMiA3OTIgXQovUm90YXRlIDAKL1BhcmVudCAzIDAgUgovUmVzb3VyY2VzIDw8Ci9Qcm9jU2V0
IFsgL1BERiAvVGV4dCBdCi9FeHRHU3RhdGUgMTIgMCBSCi9Gb250IDEzIDAgUgo+PgovQ29udGVu
dHMgWyA1IDAgUiAyMCAwIFIgXQo+PgplbmRvYmoKMyAwIG9iago8PAovVHlwZSAvUGFnZXMKL0tp
ZHMgWyA0IDAgUiBdCi9Db3VudCAxCi9Sb3RhdGUgMAo+PgplbmRvYmoKMSAwIG9iago8PAovVHlw
ZSAvQ2F0YWxvZwovUGFnZXMgMyAwIFIKL1BhZ2VNb2RlIC9Vc2VOb25lCi9WaWV3ZXJQcmVmZXJl
bmNlcyA8PAovSGlkZVRvb2xiYXIgZmFsc2UKL0hpZGVNZW51YmFyIGZhbHNlCi9IaWRlV2luZG93
VUkgZmFsc2UKPj4KL09wZW5BY3Rpb24gPDwKL1R5cGUgL0FjdGlvbgovUyAvR29UbwovRCBbIDQg
MCBSIC9YWVogMCA3OTIgMSBdCj4+Cj4+CmVuZG9iago3IDAgb2JqCjw8L1R5cGUvRXh0R1N0YXRl
Ci9PUE0gMT4+ZW5kb2JqCjEyIDAgb2JqCjw8L1I3CjcgMCBSPj4KZW5kb2JqCjEzIDAgb2JqCjw8
L1I5CjkgMCBSL1IxMQoxMSAwIFI+PgplbmRvYmoKMTQgMCBvYmoKPDwvTGVuZ3RoMSAzMzc4MC9G
aWx0ZXIvRmxhdGVEZWNvZGUvTGVuZ3RoIDE1IDAgUj4+c3RyZWFtCnicpLwJfFTV9Th+7337m3kz
b/Ytk5nJJDNJhpCQTAiBSF4gRCBCgiwmYCTs4EaCLIIKcUVABauCa4lWAXFhSAQSlhq1dWnrF6xL
0WpNW6xLTeXbUqRKZv7nvpkgftvv9/P5ff7zcu+527nLueeee8599wVhhJCCOhCDGhumF5ci/Xfr
e+DNWnDdvLZ0vMOOEH56weqVwdRrL1wKCR8jJLQublty3eGoZRdCYhVC/Ngl165dnC4f7EGounHp
onkLT9168GmEbj8KiSOXQoJ1vesehEw3Qzx36XUrb0yXv20Y1H/9tcsXzEvHW0Wow3jdvBvbXL+x
noTynbTS6+ddtyjTP6gfeduW37AyHb/9cZrftmJRG9Mw8kUo34eQ5Z/cYZSlu90oi42gLIRSp4Zc
clnqFM2jkHwFrfvTLvPrQs+j3+F8HETd+DvkQuewB49AkxCLvgVK7UOD6CFkRzPQdmxFuciJZqJJ
mIUyMXQPfiy1OvUlugT9BD2VOoRvS+2F/K3odXQOevAHFqMKNBXKz0SL0JfMZ6g59SgS0UZkQGPQ
5diJ5qEP4Pkn9OEB9CD6Ob45dQ5ataPboL4qVINqUq+kzqNCdA+7jTspHUD3oyOYTy1ILUPZKAdt
JrHUB6lPUQQ1o5+h56FPMdzHTkQhdA26Ez2MPczrEHoIPY2S2EhamPHcy9DSJDQLXY/WoM1oL/oV
tuJG7iR3OnVT6nPEIxvKhz4tQ1/icjyFPMMaU2NTH6E5qBe9CeOlTx87h93NzUlWp55IvYoc6BCW
8VH8ClfK3Td4a+rJ1IvICP0ZARSZCu3MR7ejV9Bb6L/R38mG1AY0EU2Hln+J/TiII0DxD4iHrCfr
mXfRcBhtC/R2FdqJEjAjh9ERdAxo83vUjz7DduzDk/F8fD/+OzGSheQ48xjzEvMei9lngd5hlAc0
WomeQQfRb9Db6DjmoP4S3IivxsvxDvwE7icJ8jX5lhXZ29nv2UEukuxPfp+amvonciMvugytQxuA
tj9D3egl9F/offR39A90Fqt4FF6Kn8QJ3I+/JhLJIQ2kjWwnz5AXmKnM/cwrbDk7jr2GfZv9iLuL
2yLME5LndyUfSL6QfCd1KPUO8I4J6o+gOqDorcAVz6CX0btQ+4foE/Qnyj9Q/xg8G18FrdyA78YP
4hfwL/E7+CsYJdKfHDKG1EKry8kKoNNt5AHyILR+HJ4T5CPyCfkr+SfDMTnMSKadeZJJMD3MCeYv
rMpG2OHsCLaBnc2mYGZKuUu56dwe7jnuVe40X8Uv5Nv4L4TbhDvE3wwWDv4hiZJLk4lkN/CuCJy0
DijxU/QU8P1LMAe/Aor+F/S4H52BWfDiEI5CvytxHa7HU/AV+Eq8CN+GN+Kf4IfxY/gp/CKMAMZA
BOh7jNSQ6WQeWUTuIBvJveQleA6Tt8gH5CQZgJ67mDATY0Ywk5jZzBzmehjDSmY9cwdQ9n5mL3Oc
eZf5nPmCGYBZc7HZ7Cp2HfsIu5t9iX2Hu4y7Dp6nuJe5Pu4d7jx3nie8l8/ii/mr+T38nwReGCk0
CpuE94R/iG04CxdCz4Pooh/xwBrMJnuJnd2AByDBj1lkhpHHYB6mw6r4B6pmkjAvJpoPfXMQD2uj
mLzGJgB/JT6CyvEv0QaeMCBV2X7UhT8m/exr5BL0Pm7FHnY3cz33KxJCz4E02kaOkiN4HHqJVJFZ
5HEG4c/wHvQZ8PuN6EF8Db4BPYcH8Gh8C67AG9B7xMlMx3egqtRThMUSnoRPI+gBupVdiK5C/+cP
V4K0/jL5U1Zhbwb51IO2w4w+jz7Fz6LvMJf6GqQbA9JoHkiZe4Df70RU6rXAOtsA69EDEuRa/jh6
CfMg8Sv4sew6dBr9C33JHQaOGgeS9PPkMvan7J9TFakiWGGwytAeWHdL0aWwYj4DLjkGcRq7Ela6
DLKkFFZ1I5qNFqJbQOrdn0qkHk/dnlqbWo5+Dbjf4WH4O9wJK6IHMKrQm/BsRR/iLbAOL/2/x/m/
/ZILUR/6CrtxHi6F9TDArea2cXu5l7ifc2/zI4Dad6DHgKP/BNwswwgWoHfQV+hbLMLceNAwFIf+
joK+N6FrSTNzDI3HXtQGazYf5Pi4zEhugFpuA+o9Duv5GKyN0yAnrkQ/RycxwS4Y0QJoX4R66oHO
c6H0LpjB23E3pCwEqV2I/grjNuFRZCW0p0FN20Fq9UGfPkZ/AWqn9H4NA7lQi2dBXd+iK9BCaGEk
asT7YQYOokqQrLXMb4DeuVhF43AOfhrwWmGFmpAfVXJ/xgQNS05NjSLLmGOwx6QgvRN2Lx+6BLdD
L8wwjkHkwA2oPHk59OFdzLAJ/Fu9F4+QRamNzJrktejX6FmYE41dLdSyK9g72e+1cTNnaNVjL6ka
M7pyVEV5vKx0REnx8KJhscKC/GgkLzecEwoGsv1ZPq/H7XI67DarRTWbFKNBlkSB51iGYDRsQriu
NZiItCbYSHjixCIaD8+DhHkXJbQmgpBU9+MyiWCrXiz445IalFz8P0pq6ZLahZJYDVahqqJhwQnh
YOLt2nCwB8+e1gThe2vDzcHEgB6eooe36WEFwqEQIAQnuJfWBhO4NTghUbd66eYJrbVQ3X6DPD48
fpFcNAztlw0QNEAo4Qq37ceusVgPENeE0fsJEhXoVMIbrp2Q8IRraQ8STN6EeQsTjdOaJtT6QqHm
omEJPH5BeH4ChcclzDG9CBqvN5PgxycEvZngMjoatCW4f1jf5nt6VDS/NWZcGF4478qmBDOvmbZh
iUG7tQnXulPuH6JQuXV808aLc33M5gnuZUEa3bx5YzDRN63p4twQ9ZuboQ7AJXl1rZvroOl7gIj1
04PQGrmzuSmB74Qmg3QkdFTp8S0KT6AprVcHE1J4XHjp5qtbYWq8mxPo8rWhLq9X6031I++E4OYZ
TeFQotoXbp5Xm7XfjjZfvrbbowU9P84pGrZftaQJu99kzgSMysWBRRfy9JBenIbqL79AWUx7FJ4E
DJEILghCT5rCMKZR1Fs0Cm1eMAqKwa8ZA1ZiIczIsoQ0vnWzOpqmU/wEl6eGg5v/iYADwgNf/zhl
XiaFz1P/iWiQ8skFVoP8oXAiFksUFlIWEcbDnEIfx+rx8qJhq3vIyHCbGgQA5EONQNt5zaOLgfyh
EJ3gLT0amg+RRMe0pnQ8iOb7upBWHGtOkFaa0zeU45hJczqGci6gt4aBk19CVPN3JMTIhT+z6rRN
WDo6gZ3/R/aidH799HD9tNlNwQmbWzO0rZ/xo1g6f9SFvEwoYRvfxPhIJkR8jJ4LTHnlhcI00mRM
sHnwx+tMvTDBAFPqCThYl1BbJ6b9ZjkU+l9xegTxIqSe1GmKpYMf0DK9TIyO/Tg+5kfxH/XOuJmB
/rIRUj9j9ubN8o/y6kAAbd5cFw7WbW7dPK8n1TE/HFTDm3vJbrJ7c9uE1qEJ7Ukd3uJL1N3TDINY
ikcDsxI0bn8Y3z1tv4bvnj67qVcFc+buGU1dBJPxreOa9+dCXlMv6CuankpoKk2kkSCNoHoMfN5F
RL28r1cDo03PZfUEPb6gByM9TRxKw2hBD0mnqemGInpDGmiaC3rYdI42VJqFNDGd1pEunZ8pLUKO
SnMOI5DpSM9M/6jQGD+j6WJ20NdYcxFVTUCnGJucisar6Lvvvlun6ik/+s2mKSoH9qkKFgQD3VJR
MVgRSKhPpSCGa4xoBrOPPqQc9roA8yLzAugNAeaFbt4f6KhRmOfRPnCACH4QXCc4BmnM892CUqr1
ALTaddjljJX2pvogMLpMTy96sLTjKPMcbNllkPxc10ya/Fy3Vluqw7IxaVg8QoddYjpbsJcGaryA
VgyOIHMm1ABuK7id4F4Gx0OHnkOfgkuBY5g9zFNddQGo4RmoyFxjZ56BoWvgHweXAsdA75+BsTyD
vsmksNCrn3VLRtr8z3QsH/MzwDKDr4LrALcP3HFwHFoO/k5wKXAMhECdB0eYp5gnu9SAWiMzP0Ub
wBHmUWTGGAWg9oe7VZ02j3SbbaVajco8hBrBEZRgpqA+cASqvR/Q7kcEitd3FY3QSVjfLZtKVSi/
BTq9BTqyBZrsBB/rcQ0cLb+l2+ak1d/eZbboeDd1lcTTgW7VXdoIVLgRYWYRcz0YdAEwBK4HdSnA
LABIp3o+sxApej+1brNa2gHtVUPxatCLCyC7hnGCthlgahkvaDq02KouU7qdVV35haUw4vGMWy9i
ZhRQ9AKMyAhdpYHgEUbTiX93t2Sg/bu7S3WUHmPuZAQwxANMB5RyBczHGBlmVtZHMqNbUkq31RiZ
GTDMGUCWAPQRA5Wv1yu6vgsqqrEwE5gsME4DzDWMHwzlAFPHZOtwN/MkmIQB5onuSFag7wjzgI71
E1opND82zVpjuxVTaV+NxIyF3ARzH0zAfXrj27ojo0CtjjD5qAQcARpvgNAGnek3Q2gzzNpmmKnN
MFOboVObgfsQswlyNkGZYmYdamPWoG3gdkKYspWjCwjaqwdy80t7GQ/jBsKoR4CUGFK93ZKJ9szd
ZbXpxdzdRlNp9THmBuDzG6BOjVnZ7XKXLj/CFOpDGdbt9lGEti5g12Ng5ulTA4hOOiXHmCwgBCWM
n8nucgQSNQGIU0YOIEx+RU5QIpF3yft0uqmlq8NfZ+DbGfhfaZjqIyfSi4L8lsL+mizyGVQ2l3yC
dkKIkCPkNVQCCB+RHtoL8iHpRdUAT0J8IcBegGUAD3eF3gz0kJ5uAND3x7oUJx0sea0rVpwJBPIy
AZcvE7A6S2vyyKvkFZQFVfwOYC7AV0gfygH4MkA3wD7Q9d8EeACk1hiAL2XgL8hRyuLkEDkIVkeA
dHeZaBcSXQIF+7p4Cl7sQulYY3HgKHmRPIe8UPSFrogXUvd0R3ID5iNQHybPkJVd/oC1RiZP4iZ8
Bgp1gk0CEFnJU10VtJJtXUeDgV6yjWzT3BVanlak7WJK8kqKSnYxwbxgUbAiuCtYo5L7QIDsJLB+
yRbwK1CQAPeA08BtI5u62IpEzSCMiY6LoA7wO/VQK/htegjsY6ReyD2th6rJnagBHIE61oPbAK4D
3K2w1Wwj68DdBO5mcLfoKSvBrQK3BqRJG2C0AUYbYLTpGG2A0QYYbYDRpmO06a2vAkcxWgGjFTBa
AaNVx2gFjFbAaAWMVh2D9rcVMFp1jEbAaASMRsBo1DEaAaMRMBoBo1HHaASMRsBo1DE0wNAAQwMM
TcfQAEMDDA0wNB1DAwwNMDQdowQwSgCjBDBKdIwSwCgBjBLAKNExSgCjBDBKdIwgYAQBIwgYQR0j
CBhBwAgCRlDHCAJGEDCCOoYKGCpgqICh6hgqYKiAoQKGqmOo+vysAkcx+gGjHzD6AaNfx+gHjH7A
6AeMfh2jHzD6AaOfrNnPnKj5JaCcAJQTgHJCRzkBKCcA5QSgnNBRTgDKCUA5kRn6Sp0YBNhmPbgN
4DrAUdw+wO0D3D7A7dNx+3T2WgWO4iYAIwEYCcBI6BgJwEgARgIwEjpGAjASgJHQMToBoxMwOgGj
U8foBIxOwOgEjE4do1Nn3FXgKMb/O1P+P08NuRU3ibDXkg5coMMN6GsdrkcndXgL2q/Dm9EuHd6E
btPhOlShwzUookOoT4crUUDEXYEKc40TREADuLngloPbCY4qSS+DE/TQcXCfgkuRci2HNQsNwk5h
n/CywO0T+gVi5hv4nfw+/mWe28f38yRY4yOKLkdBtKCtur8B/G/AwSYCfrUeqiZxaDcOcrYcnjiJ
a5aB4DeF+HghfrkQ7yvEWwtxjUQuxawu6YKoAlTGAG7SjJGxgZPgKiLRsSCZ7jv4tSvQFRkZ6MFH
06BAiwH8Gtx+cLvA3QauAlwpuCJweeACelohlG/ScjJVHgUXBRcCF6RNIKcTtEurRdR6iYJ3df9S
QRJtJ5oPeEe6oiUAerqiDQAOdUXnB2okfBBFqVaED8DMPQdwX1fgFGS/kAbPdwWOANjTFYgDaOmK
Dgcwpyv6dqBGwTNRgKWoMzJwOoybwsu7ArOg2LSuQAGAWFc0QksXQkN5kFuAm9ApgHkZrNx0S+Gu
wBgAOV2BSlpaRFE68ZhHRXr3OHAUMt3QoW96cROLNUNgIPBA4GtA/ysQFtjjw2APC+B4Xg+epcmB
o0U/hcI1ga4amZaH/WF/BiYoPBDYlbcp8BjUhfMOBh4JDA/cV9QjQvK90O9NehNdgdvAZH1OswU6
AiWBlUWnAjcEJgfmBS4PtORBelfgysBR2k3UjJvIcwcDjVDhJBhFXlfg0rwevYt1gbUBLRANVAaP
UvqiUel6K4qOUgqg0nTrw4C+hXk9lMdnVvRgi1YonBa2CXOEccIYISzkCNmCX7CLVlEVTaJRlEVR
5EVWJCIS7T2pfi1GbQw7r5saPEt9Vg+rhPokbZQQLBI0GSVsTD2pnz4O1yf6FqD6+cHE2enhHiyD
RciFx+GEtR7VzxiXGBWr7xFSlycqYvUJoXFO036M72uG1AS5GwyuGU09OEWT7vTRo5f9GN15r68X
Yey5897mZuR2rq52V1vHWirrav+D15rxYz/83BcH/Ynt9dObEnv9zYlSGkj5m+sTt9KDmV5iJsqE
2l5ioqC5qZdtI+YJl9N0tq22GYqd0osBN5ugGIpSAMXEcShIi4E8GUeLwRyly0UAHcqFKIBysoIi
ermIrOjlWEzL7T8ZnFC7PxjUy+QhdFIvczIPXVQGOAZwa/dHInqpcBA30VK4KRzUO1agVxQIQJGi
gF4Eg16nVxTAemOJ4h+K5GWKlF8oUq63xeAfygTSZez5Q2Xs+VAm9v/zt2hcDHePWLX+NXrW1Rqe
sAhca2LL6qXuRMf8YHD/+lWZQ7BI6/wFSymctyixKryoNrE+XBvcP+K1/5D9Gs0eEa7dj16bMKNp
/2vaotquEdqICeF5tc3d1VVNNT9qa9OFtpqq/kNlVbSyJtpWdc1/yK6h2dW0rRraVg1tq1qr1tua
sIzyfWPTfhGNax5/ZRp2E4MMPNzqCzWPc6ptYylD944Judf7DrMI70GGWHPCGB6XUMDRrKKaohqa
BeuMZpnogWYmy71+TMh3GO/JZKmQbAmPQ0OkRbRQfaJ8Wn0iNH12E2WVhDbvP8/ZDfSnZ7vRhGW1
8AfxlbqD5+KS6Ib/+Fv5n36rVq26gXqrYjcgVJ8onF6fGDkNeiII0FRrbTOkDR9KYxg9bb8kTehJ
9UFmDDqBV9LmaCiGY0BBTQarSyCdfKdAqKmwstvrL11+DHbwDeDAjiNruop185ms6c7Jo/bLyu7i
8jQEc5XCLm+oFFrorgBUCvPSULMUQWBb3raibRWdeZ1FnRU8pB7cBYmBXXQr7SrexaCVsRuGCAHB
lc1AbOgWbe/Jriy/3nAnDcRizbEbsE6vfyc2HiL6BcLekKn1Br36lUMTkk6/IVMJzES69VVDaKsy
SHrmKh0pXUk6dsH74bdyFa2K0lN/XUePijh4QO0S0LiXCE7yQg+p1myIY5MMkgU2iZFH5LkkYY7i
CJJwAruRO6aerRqsmqqeqZoyWIWqIayeB29EScgSsuSBBzsCOh9k+s5rHPoeBdk+aAtZU5+zc7h3
6TkCnqdtFFnBOlGeaGqSm0y82+jCdofixHar4iS2bKOL2DySF9v9kpfYkOjDdkb0EVvA6OJUi+Lk
VJPi5M0Go4s3Z0leTmVFH6fKkpc3C6KPN0te7ySfaPf5RMXpnOQy2l0uo9lkMhhkWRD4SVCHJRDI
ymJZroc8rs0ldofD7UZ4ErFZrdnZfj9DiOh0ubxen6wYjZKI7DabqprHKsbdrr86dyua2xtXtNxI
vFrBW5WdClGmhniOI3isT9rt/au4u8Sn+Vp9jG9q8KmbKb1aTg2eAnpVqVUQXhGLndGjEKP0A79a
D1sri/Ui9BnMhM4OJdCkoeBGbnjsFvUXG4e7KTD/j9+IEtxiC5eXgQvZypgy6hxhcCEmbAszYQxJ
j979UtVp7G/ob/hkyheNmw9V/SPZ3/DplD80/Ak/POYPo/F1H+PoJ/iu5DrqPkl++HE6xGxKfoij
wC91qTLWzZegIIqi4cSqbWKtnNNlbeKWOLmZ1jnOxdyS4BpulXV1cFXRRu5O68bgnUVGPuKMRHCF
tbyoDl9aNJMTV1tX2lYXMbJqGe5yC6Eo9nowGV4UjVhA+0BOX76XN4TkHLNJDQYKmGzkcuYXDC+y
WFWzbDAqbq8PBTGJRkI5Am9kPf41jdk4+zCehRAsbKszjugS9mbH0S5vPl3rEKTwAGTl73IfxgR5
8aRDLklZYzZhUw8OafmqBpmqBiWL1Wq1QWXUr+UQTQvpaaHqUEOICX0t9pDZh2SBk467sbuH5GhF
oudlz3EPmetZ7iFmT8BT7WE2eLZ6iCeWbdIMSnyn6bjpUxMTMBWbiKmHbNMUZU0gVhwjMZobg5SD
jXZs95bkUmkkQZqaG8wluXrRDexWlnzDpljCrsm+kJ8dzCbZNF/d4N/qJ8V+7NeMStzvKe7BU/a/
6o7BEo21t0wZaI+daWkHf6p6tn3KQKz9zEB7e8xirWxfsWJFcdVgC2XCrwdaYjF1ELwzLaeqT309
QGN6BFusrkrqUDpQeZFzVTKcWmWqgp9JpT5IAdTe0oJb8iyRSHl8ZEV5mcPpdAmRqMXpdNh5wREu
j0SieU6njecddqfLNnJkeTwSxV/+4tDx5+/fedQ76Jp3+sXfv3b/CyezMac2jRxTp9XcX39Vw+wP
8c9G/fmppz+1LFhg694eWl2YXFtz/KkD57KOHHSc/GVW40oW2VtHl8/JGiw1La8fP89L5drs1Efc
LpA6OWg4qMNEu9FgkYu9Fk9xRaCi5IncXaZnPc8En83dNdwosXzYw7rCeUyhPxIaVfJgeID5i9eQ
leX1+xWPxx0OB4uLS0aNUpTS4rCHGTYqy8uwkaAfM8iJGH5UcTjoz/J6FKm8YJ4Nl1/KG7ABeUdH
HlOdxU7i7MF3aIo87DGzGlC3qZ0qq/bgbM1c+phZDsglMiN7KqfcrE9Zy5TBwZYBmIqBqepfUHX1
lIHqAYurEuitzwSdAxrbqIpVAjgK9/P0DUH3qTAOg4beLZniXoCHADIqeFTotzTDzNB5gang+XAw
qs+OPkdlpfrM2EZWuHhecDpxBZ2ScA7Mj4vRZ6msdGQFt2vvLTc0v7k5+bfN1+zZXX/Z+7/8+QdX
P/mbXG8yMipY/NBg5LIZ0yaMv0wrmNe6ZuG4dRO7P7zkmsbLHl3z2L1/nN785MQ7e1+9p7lzUfIf
2pLRG28pHLaMMY6u0UZeNn5YfHJyw4jNE+fcEK+ipsM1yWlkKcyYiuo0U755N0NECSNJRVbxGM5B
Euj9ObCfPKjJ0j+MjwXZElgUPWR7t+WZa3RJOzB4ZkAdALpRQUlFIQ5HSLkKoysjxGG3upxk0SuP
dC6YdUffpiWXlIeT0z7Hf/8ShzDpP5Z8J3nF355O7nlsMe3JeOiJpvdkkuaOkqi8hCyRd5DdZI9J
kEA+wZ9VpX1CsDPqfXpJ/Af3mJH2xnr1eNqbgcFTP+6MbSxTHidMmdPqsAuEmTC9dnTW4k0v79g9
rv755LSun5/7dNXf8LO4+HfJ7HPvfJM8k/ye9mQmepedxb6HDGii5hEZiXDzSxiNIUwFmV+NGmB3
Fefzcg95SlM5PL+BbCWdhCEe45TxGY46A/vzKVTccmaw5VS6K3mW0IUHv5/swB0Y4VuTG97Ft2AB
35K8Pfmv5O207Rq8kSwjnbCWSrVQCdYwwRWwslQmyJQwLFPLqbAJlEC2h33mWtrcqZYp6l9aUPFA
y4gSG9ReQ/LxRuxJfk5rewC857EHiudqDjIKySRiBtOU1sBCDUtWDy0BBHw/oqQM8B+gV+QoNkGz
QIcwcX3IBAgPaPU3ynfLu/FeYa+023RIelMSZ1manc3eWYEllqXOpd4lAbGSVPIjpZHKJDKJnyDV
KbulX5O3+F9Iv1A+JL/n35PeUyyqO+gmbipU80DMu3eJSsBcbCZmKvTNuxDnP9nAYtabYz9p8ITe
TUvVKQNpUYqqQZpSR6cWgdgrdTktqsCHc5BFrRjpyuEF3qI69fUz0qJGIqT0/Ru3blvz/gfJ78Av
a3T64w1lacD1PfxScm6y9eB2PAnvwj89uP3LmhnXJeH3ilYz41ogO3mlBij4FBA/AjSQ0CxNuobc
RLbAVLM9uKB7LodBn7nqkChxGBkldASMRIIwadEUDrEBNsgmWJb1yIfxbtyJ0oSumkJ1OZD/1VVn
WgYqqYwIhSy8UD4yt6KMiSQ/f/Sd6zEpOcWGt01I5b51F53DMoRYI/TAj6u1uQfcB729vl+xb7hP
uE94TnjF8b7xWeP9szyPsQ+597K7skTeG0T5fIV3IjvePd4z3ivmunM9uV7GGQGevtv9uO/xrMf9
e7P2+kUr8qv+oH+Ef7X/Dv82/wd+0U/nxWl3xP1ENZr9lNUI5RUNGKhnaJMnT3YTbDTTg5hwwFhs
JEY6d8ZdNk46CdKsAbrsDZhPqmuIJ3toAs/oM1hVNYWuzsFY+ylQY2Mt7VWwrWFLWayFqvXIn+rr
slTSPnSZdaCZ1EpWVCs50QLQUpnWqpvT4lczSD6Pj/hsmN5pg4rgj0pd3FI/rekY8qX6URY4f6p/
1KhRzRh2yRZsCY20VowckrdC3sjcjDDmWV5gjeejaufXP4+NXtTctFRMfuHB4usfnrt0Slny7KVO
zCW/fxBLv99ffcXMqxZdfVPWF7/66sUF3fNrzjRG6CxNgbXig1kqQB9qpRsdbznITVlbssgu5llu
t/0gc5g7aP/I/YlHdNrxvc57XSQkK4jFLpszFFBUo9yDczVjg4I1ZSuotwqGDYxo5oCt2EZslLy2
XT4OA8kPqMBXwH9AnFJIZndFlYSxD+bA6FRPbghsDewM7Au8HOAC/cLJhlyc6405T7rW4JPIU3hh
MZ3JLCfgQEtlcUtmQqhHo+0DmBKzMkNSSlUgKpAPtdjy9LWlU0+ocF4g41hSVkqvSwlU50DhnNwp
WFVWTLtizYrLR9YHVtzYNGniYkNy0Hfda2uP37Lk3fU7kn/57RvJ7/CdoaXX39F29c2Oz5hlV0xu
Wtg67M6dc+649u5XbvAdvfOV5OnPYD0BcdlaoKuMFPSpVmkMKpWS0WOMGacbrzH+ycgPKJhnnWwe
m69MVOYou5VDyuuKhImIjLwicLJBEZDRqCg9+EUNNAg7A0KUGFmFUQgrI0FT+pQTEDmC85EIJtpL
BxHLAgLqwU0vcVtlLNOJsKrCTuFlgRG85mqygRDiMR3Gl+GJ+qo+1Q7b4BRY23RhV4OdARoeJSFo
EEgH1Hxg04bDEOsWGS8xTjG+bfzEyKE00wJ5Y7A5lOMyC1gPFmzBZP3gHnLz1wcPJk8n9+HoWeZn
56/6Nvkhycb/TBqA4+YAx5Vzu0AuqFqBaAoaK6wTrJM8jyg/Ne2wfmSSrBabNWQJW++0gjjCigxU
sFosPaRTc5oUu8mkWGU7vWGsYaYRbwOB9yP2OqRzl0+B7XW2pgTkYpnIlBHlXXbKfAa7Mx60l9g1
O2Pvwc9pdjDx1GKVDKnxul5P27KZzSbWrAI7nnBhzYVd3oCu+luVNfjoCYQ1tBPto9tZ9ru9+NKM
mKTMeQqYVA9Qcanq0gISYhd4taXdkiatCUiLL/Crzqs/YtSoDegqgNqFgENhr8idg93G1VOa1q2d
t7b11Dby+eDfhl01/whml21N/jqF8Fr/3OVbt23ceE2IfJ/817+Kk6c/PHDfqx8BL14BFC8EXnSh
MDqmjbnasErcKO7w7OZ2i8+a9tp6TQctx2x9luM2xcGNtNSq65wHyG/VE3bhCDoO6CwW3FbVFwSh
RUmYDSTy7TIrgVBxiKSNnl3VEtakE1JKYqQe3NC9D2NMiZUTYIthWjR9ThwcLOc12ScbjNjozXOf
tHpy/8c+eSYtZM+0AAEzOyZlSJRZy8BqmIvoSxeoYtWXLGygCGQhtl+gG8+ak6flGeObb1KXPZ74
Pnnu+B+Sf8KFf9v9+8En10+burRtxrQ2dnr2jMbOwZuTZ977Y/I0bsab8AN44ZHzX256aN2WrXdu
AC69AtavG7jUgO7qRWyqXxthtsRlg9cwmh0lT+RmGfYafm542/ChQQ6BBs8IKGAoNpBiQ7WhwcAY
6IgNh6k6hJ8/RAhmBdEowtLsLhaw0ENaNRNpYDDjVTDyGDNUqKI7DazHQX2bVQd01sFDY4+BjuTg
CXGFrNaKK5hX1py9FSf/Wxh4nX0Sc79ZlZyctL2KS8iN/wKenJ76C+vi6PvvXFSClQMloj8Qj/Sk
zmnXQuANyxu233G/E9hV6mr7HSoTQYXGkWiMsQ5dZryeXSCCVuRYE90Y3aE87H5aedb9rHdX9u7o
rmHPlvR6D2W71tjust1l3xhld8A87gBKZQ1/GEIxiYbzmOF06NXDG4aT4YfJfbCV9Wmq0x1vy+rI
Ip1ZOCuLt+brpikUK8nX8kl+D7lPU6xKdU5DDljIkJxDU7w8FzgprYmdbDBjs7fUc5JZk3fS6Rnx
77uBrl61VINNqur7QAxMVJ1s1Om8k9kJwOKMxfDFtgyV+2w4RzdybBexEHNRGE+8bsFn777z+dWt
6zYkB3/35p1PrO6d29DYOnfqtFbvmuYrVqxsXrKIcQ1/svXpDz54evHOwhFHb/p1ctnNJ9e8gafN
uGrujIa5rYOXrLztltVLbrmPaqdvwbb7Jzain6YN13zMKMzzo1hZ2scQwkdwkCvhCLdPfPs53U6h
x2ZVZ2GI1QNpPRmUActbVNPFHkah8Pw/hvReek73MncY6pVxTS8SUic1qaIyzueDJ+hEzy+P8xp4
EDupNYaikAdeASoE+ZAvFxtHoQqu2ng1uposYhZzS8Ul8heMeTKPqXHFyJLEChLGQSTYERJ4iWWD
HG/nOF6UNa9/rKwLWK8/LucRhuFZ+p5PM/EC4VgWI9HocnlBC5unGQJY/0SkAzO4h+RqUkDCJVKH
RKTDJBexUEIKgo7qMVy1YEjN94BEAPHqHpw6YVHtX4YOw6YMWOhZ2GAsph91bbxFP+oCIKhVVRt/
8Yv0rvWSFJcUMG7pdlWfMEyvT2RPmw1qG5NKdomsfDiVBEqd38+zo0ZlNK60vhYKMfDgkI1huJeT
P+8YPLg2+ToZgysLf/U6npLs5g6f30yCg/30/GA7UH4+UN4GmucwdFKrXlOIl5puLPwLe5ZlpZBD
4vOHhfKc1oCjwUFKHPscxOGwh3PyrDYxaM/DiPiibXwHT/j6/Og+WE1UaZUMcdjE7gFrarg2vHF4
6/C24R3Dtw3vHC4Gh5fA8rLnBFHQVgKKVg/Z0l00YvqQqj4I6mpL+9lYWlXST16p0xeHrrA6Uh1d
/koHVVi9FHTst1EdtRkKDcnbC7Qy02N3OQh0oYpUqDSbDC0cHtRPLgRqcGlF+nAmEmYsoUwkEt5O
Jr/43MbZy+feta3lydWTk58lFZz/6guFl11RP3nYO3uxtTM2brq29lfcYf+Vj8xd8nwsenTDwmPt
ikjY15MvcNIVl9bOlLjB3uSNkrFl6rgrC6m2Oi/1OXcV2Npe9IE29S5pk32Tcyd6mH9Deo95z/BP
RsqT8o35SoG9wLmKWyXdxYmCTXC5bC5XASlk8jghn3uE2yG9xfzSwFXjBtAdLlcR7kenYfFQklvc
+mFgtwz80oNnay53ESuaNJM1bqqfa8ZUFGkOdxxsiHwtx1okM+ZvTLPQN0ivylsC4s0R7RSwWQgI
JaB1wex1+9ZPv6AbTFVhZ8tsc2dAup+KUUgDLelTF1D1OZ4NB+muFgq6nK70dge2IYgjthoHxiXf
/jr5cfJuvA7HsbJnYWny995nVv/s1292rt5LfHNOf4m34tn4evzQzqsSdSvu+Cr5XfKrr7dT2fAg
cOg84FAVzOgNWlk+LPdLXYvYRUau0FXpmuhsdi51cpWukb6Nvke47QYuYKFsabPmmVXRE91Ht600
T9JRabaOEA6GSmD/t1iBC9USlaiUC4P/kQsvsCAdZTumbORy6kcbPH3CaSYaSyjfABc9SPyHWm/t
aS2qWDzl9vlPD76L8z+5uWLi3Kqqa6ePPcAdzoq8mvz8vw7c3rmgvjDAvnq+3GSd9cu9ew8utpoo
j+yAnfsOGKmEVmjVIsfyXJ4QFEvEl8VPRbZY3CYSUUQMmweWsoREoZpvgHV3OQNKNPEGDSUGYmCl
IKbmIzAFjEm+eEz6FNJdOm0MD9LDeNimB6voAWeVfhLhCOluBzMwOIYsHHycO3wu+cy5wftp3+4B
7yXoG4OW9yIOCFoaj3OUsOE8HWrVdlcccRrXyHVw/RwX4Fq5Nu40x3Zw1LJnkEiYDzFCCdSPmD7K
t7SfJyDGouvZETszrLYi88qlWj9Mal8Bi5sekdyD87nD39VBP54AGj3DvYg4dInmbRRo3SysDySy
nFcgzMXD50f0Xjz8JK13ymCmalpryPEEzif93IvfT/qWjhEGyntgjEbi1gwGJiJGDGC7YAbkjCZl
jY7LwdFj4hI9gMxA7ems4ZAKHi+J8p+lr2WQmbJsI1msKgXkMBnGBqVieQlZyi6SrpbXkBvZp6W9
8gHpsHxW+k527mS3STvl16W35N+Rk+wH0ofy5+QL9jPpK1lZI90o307uYW+X7pG3EaHJsIhczS6R
lsqryVpWqCX1bK1UL18hXiE1yYJbLjbFyWg2Lo2Rq00CNbd4SZIdxMu6JCFjAgWAULLEGQWhlDcZ
S/UjLiI2ikrcQD19lCaDEhc1UzRuoB4kPa6pNGAQGQzaNBFksNlgD6uuoie16d2mBRcPqO8N0ATQ
ssdoRdBKkBUlqTRt/BGDLJcyBIIEqmGMLCFGGXZkQQzoLyWUbvqhz2EySmerOS1pdnJNnxHnSgVN
2CBi8dgGmIVjhqDBSHrIKM0KfKRBQaRBIVQaoNsOVKOMWAWKx5l2erJf9Te1yutRB9sH26u8bnrQ
DwlgOKL0i6jqKujtj3fezC5rmw7bjJjq328I0i21Rf/pfBhDsXbKNhjrR4qw9O/HR7CMBXw0OZD8
JPnn5B9gY3UzX3xXx972/XrqgKceBukVpmsa/5dmkhhe9DAukbXCagDqom6roZrpSQ+bQq0QRsSU
CqJdEERGJERgJKAX0Iph6YhZOmK2lD+un4Rt0TyaodHQamDaDB0G0mnoM5C0HBClTKWSfqgzfXpc
KtVXRh89V9JFw6oLawOUEBB4MMizmZi+/uh+WonAbRxOBw8USvMRVT/6NQm4QgymeaTvkES5RldV
6GY8omS8XqrjoKFc7DCU6wO7xDs8Lk4Hj2OcTCmjMWwdcyeItE6xSzzF8L9gjosfiUyQKRbjzBix
QfwJs1PsZPaJCeZl0ZBWAcvK40Qr01XAfk0pLo2TIPUEezmk7NCk0PA4mQGeXrouOwgx8EQiCG7C
uIRhJCqMIWXCVKIJV5JZgmQnPmEKmSA8Kjwn/Jp8SL4gnwv/IoYoyRcmCzcKdwvPE57KoBU/XA4Y
YoVmpHMClSHY8jAOkiZsS/5ucD8wQBHz7nd1zNHztXTvegik1TmYfTPyoTVaHs/12nvdzKUcXsJ9
wBGrJU8xmZBPpRLdjETnv+1WzoC/xN/qb/N3+Dm/ar5YumX9eMO6sF9lRPsPexZ0FDbkjNoTDnsI
7MgZrech/Htsunz93vk7pl791itP7Vs9/qqJ5Z3cYWfok30be5ZZHIO/Y19Ntg6fX9O4VJGh4ebU
dXw2aDEOlA8KxS13+TeGHkWP2h93Pu7ib1Rvca0J3iXfZbpbvdu+ySfyfinP67P77SFP3jWudUhc
iXCzsBRIu9a7NnttcLOwybLJe1fwEeFRw3bLs8JB5+vOD5yWCl+TZZmwTF6H1go8gy9DV6JrEZvr
zIlGc50CYngSySoyM9EectmBSENOkUQoxcDGJT14umZm3pOkSCTgiZL6fYXYmqGmlVIzWFKoFbYW
thV2FHYW8sHC04WkMBDtNGKzMWAsMTJUae0uWP/aBbVnSA84NQgqKKo+MxBTB5Ngz+DM28LKyszb
QHrWmae/BYxE+SEtCFlATcgbOfSuiapCFZFohZMbcV3HdeM106Ft+5IvJm8Fa2ISrsPry/OThysr
+w8c+OMfn9cqZ7dM/8nhqcPfsYeFm6rxfXgpXoK3JtuTj/x82/Xa+J/flPz+/GAylXKMCT1bSveu
yWA5/x1mZhg+oV3Sa+nxH8x/fRgLSqQDlEiHO7aIW5S/kr9RWZn/ofGDsLFZnmmamdMcXmpcbF0S
Wpa/ZNga/13+7SGjVX/Blh2IU6gt8njj03KmhV/JeSXMtue0h2/NuTX8x5w/hvmYXKjk5uSGK5V4
uF6uV2pzxoevVhaF1yrrcjYpm3N2ybuVPTk2SZYUPocPe2SP4swRcsKywmLXLLfmCcaXu/Fy9043
cR8mi5AP1qzRWxnwYV+RnUETMV3Ek7zBOD0ua8SteBvuxAnch0X8N1bzVqosZosKJfc3KRd2aTZX
3FUvRCPe4TCfagJ0unr8jSW9WDxFv82slPrpTfuRNqpZty7ABgcYW0FVWvr+OHYqDVfETsHkphe6
bnPkAD18/rFAjxMZ+OcuW2UOkAcAxN7qstLYCc1srVSC1kpZd2aa9oVmMkKaUim7qbNV/uiezNCx
umO0PFopzykHOk5SxufUhXfJz+bIKP1CkyqdF87WovoDJv/IsiCbVrEF+hLTyerrm+rfk3HQu3Pj
1vsvuSze+7fWjRu+eRbbsUtInrTdcsutk4qHjcKJ46vuSaGXk18lP8CfZN1/99pp8Uk+6/Axs9a+
2Pba4r//SmlfUJ5TGc8rXnzdsS3rP74GY8pfw0CS9eoW+gotXCyVsCVco9QGtu82SeAxR/JYhghI
lMBUZjfQ3QkXaTIvgLWM6EcmNGphTI2kjXSQbYQlHnHw+cysTGvaT2BWqtInuuCBpXwqI8mqdGWN
HtNSzRR/mpzC3pucyr567tz39BOXB8CqyoVeedBmbZQgCpKgukSndKl4qSRcIc1St6s7LA87HnPu
Vg85f+f4jD/LGxSjEcwDIc8mGQ1B5ThVQWAjzdF8jfRCS5uvw0eCvhJfp6/Px/owaKlBT4mnz8N4
qND1XrRt6lZCes+soqccVZk3oO22kAWmxKmLWdghVBMJ51DzoPwBnG+wbb15fYcX55fcevLF3364
3u6HLeMvx0bNvm7J9heZ2Plk8txH25vnPTZz/Vm6f0xKfcEOZ8eiMCrF7dpSwStmcX6nd7JvYtak
vN+rn1qkkZ46zxWRxZ4lkbsiP/E84N3l7fW94X3TZ+R5xeHkPc4oX+Bo9qwhd5Fd/AH+dd74cvxD
lfhzS0dYhim5Wmx4PFfLyQfP448vzz2fS3Lr9DdBJSZz/BI/pm+sEv5/+Vm/fxguQxqk0veZBM0M
aVmW6pDmU8Fze+OhHrLyACsYFXkYlbmQp0PI1iGUGAYlNM1uyB4REQukfKU5YNxpJKC9pUCB00zO
uNHbEMfxVpjX+0owxmUFobku/KkLN7jmupa7GJenbFnNkJ0Aa7d9oIUaNbF07JQ+C7CsgHFAidFX
tG7ixNLLrKvYj9ubB4aUmFxQW3z++IzchbmkJdZMT05AnjP6JY/0mRssvehIet7mdDB2pyuky3Ye
ppKuv4qRFZlDNrqr6jcI9NdceFEq9tvjR3vqGV9e8iuDKjATn255+tisx37yy8sal9fPwFeN/Cq3
oqn2sgllqoH8afijDzZvOpTsuefOy7IqPGJdXdfds++tz8oLZk2bMCb5W2upO1o1ZlZppCJ3EZB8
I3DDg7o2kYWe6EXW1DlthKGywnepj1hn8bPkWc5Z7uasbwW+nB2jjLGV+yaw9Uq9bYLvQeERSTaa
wFhCXnpFkBPsdC5sBoMZya6Q6G3LxtlqAWEiZnqF3ojbUAeVnP7qNL3bq6YMDFb9ZSpoGWkdY4Dy
u7734ZbxTZphMb9YXuxc7F6WxbWAbqTbbPSkG/ZBIFjUYYMFceFcciP23Nb1ajI52Dtnv2aNT1rb
cvsdSxbdxR0ePP1g8vPkv5Knkx/NaX6cFD7T0LbzuYNPPqHfEICxV8NK8KA/atOazM1WMPvNy6zL
nLe413p2kB3G19XX3b9TP3B/yX8pfmn70nGOt42yjXJMtk521rmbjcuMwmhrhbPCzazh1pg3cneZ
N3n2WHc7e60HnZJJ51Bf3KRfnrLHTWUKTfFkx5WMbqEcxiySgWZWiwFpUBRpUA6VbQM+PQwSkoWs
oEvANBWHULFCA0qoAcSL1yeE7B5vU80PbwxapgzEQJmghyktp2LpsxSAaXnfPvSuYOheCs9feFvA
jkj+1bSgYdktG65pXOzA9tiZt79M/hU7B179jHxdOn3G/XuPPT5nefHPX8URzIJlkrebSpEZQLt5
Gb7ZphVZm/lmudma5paHgTXOSVJbdkc2Gc3EjaMdcc9kptY42VHreUSS7Dq7GCjXgHEomMwwFbKr
wKREMOUUsxl5t1LeCYkef1PVhRG2n01zjC7F0yfAiGpJwCvKMn6ZvMya5ha+pTkUKs8M0FpW6gJ9
+mJWYeclv6/ZP/tQ8vvkq123Yc+gtbh23by771iycOPjc5pxFLQBE/Y8SNTzbXsvu/6Zpw89uRPG
WwPjjQKv2FEW/lkvUmGd1BkqH5EeVbare7jd8hHpiNLjFUU7nkgu5evkhuw9ykH+oPcN+U3jB/JJ
4znhW0XJMmc5NJAQDs1kiZsdLzuOOxiHzg3Z1To0uQCSezWj2WRtNLWaiMltpVrLQY8vjsus+nGc
P5g+lsspSMNYURq6s3SomUGcdurfL0O351qt9B4va7C6KblzDQIK4WJHmomKs+dmL8/emc1mm0Oi
ppjjQPCMNIz96HxugN4jtru1fHu1W8s2gwci2E1lta5zVA/qSo0VOgEldPUYClkzoprCrqGiIGZ1
PUVHQJBhraSd7nJRkOiW5LF6tCZUrV/gbT5FJWiL3rxJAyqZaKMm2rxJA2KljUP90BtUK7Avy/Td
EqQF5jIXtiiPIyak7522tHbjIt9h98gv9yX/eucybH93AFv5QY25bd642VHmxllXVlVhfHnxo08e
uP8T4IVY8o3ksVu2TMTXrtswfvwNOu8np7Gt+g5ajKdq89f4N/qJ1ai0jbhL6RjBBnGYhJkSXEbK
GA2PJ+OZOeZme3PerIJZ0NVrzOcs52zWMUqZc0x+2TBQcp31+bXDThsHXfJ9sGcZjIqh0KhETU6X
o0gxghrmzqUccEDnAH2iTRadSN0GYxrmF6YZIJyXhiPiaUaQHD5945vL0QUXMEcpMMlFlBEMDsHt
4QsLDBGvmy46yePxereOwCNgCfZoMirLDVk9JRdW35nM+lMH1MFTQ8J68EzGih3a/5DeOb3xLskY
16fvB7OGOkFUh0R8u75uzcvsy/KWFCyOLSvmqZR3cU7X0L5Xzusvo+gEuspB/wGdJwgb5cWvptbi
GtGfP+v6ijybsr7vg1vmY/zyLzuwMLbtyNbk3/90/vbWJffdvXTR7XXRUY7skHNE+KrHnj+w9X1s
wN4XHjp/6dHDV1f13mcitz/7xJM/fabzCSDWT0AnbQa55kRdWsyMA7iSTqQ6Do+z/AH/C0sC5+Ry
SZNlqYXDmNjsFquNsRNspkT1M4Iky3aH7ETIIEdESQvmxvdJOCVhyavflnLm5Ma3uTvdpM192k2+
cWM3skecDn3ZQtlOBz7twA6PqzpNeLAXMsesEDqbienyj543DVTSS41UvRCrMi+E6QaZTRywU8Z1
cc/TIH7u7mPzHm/wJz8PTruk7vqy5OewLX62c2Lb3VsH7ycjds8ur9101+DXMGjgbf2umX4mK6A1
vUiip7AWuVqTGiXSISWkPumE9I3EBaRWaYPUCQkcwwuIYxkzvQVAz14Z1AI6Ac/xAisTAfYMnRdD
uXHWI2bG9cM4QB9vaU8fF6sZJWlFbOjF3gPpF3vsQcwmz38/mY18/9HQbTi9h9P1U2OtgPaPa+RI
B5fg+rgT3Dfpo+INXCckcNAZBrZZJoLRUE+Qh/23nmTazlyey5wMr0eIfxhWehSP6UUFgN0CbYFk
MTp4pzHOxMW4Ox6uJRPECe7asDHIFBdMl1oLOgp2FjzN7xZ2GQ/wB4yJghMF/QUmVFBc0AgZLxd8
WsAXaN6seDXEO/RMTgixgtfvpAtUFqjWq2WzgmqxRH1ZWZGoDOQ0qxGrRZtd3mrBy4E4PaROM3t9
EX8WpC3Pwq1ZOAvSXsoDo4Duol0IRfWNRaqmUBsJ/Y5C0ahWA64KXG40HtVGXxIvjh6PfhplzNFA
tCPKoGgwWhJNRdmoJ//PVUOKcca4TK//qrMgw8GMOtveEqv6gR31I9D0OcbQO7MVMary4pgt5KA6
r0vXfF1OnT2jF9jzB05dj5ktfYu3l9Q9deWqp/KBX/3RaWOWDk9+nl09smZpUfJzNnL/szNmzpwx
98rahwebydyfDq+auGV7kpC6x2YPq7vjkcHz6RN3thnmzIl2am7B5rLNFpeKbA+LYbbUWrHW/KXK
8fpytQgmhTcaDKB+EBxxIn25Ipyid9/+l+UqGyJGE6WvohgvrFojPg2S+8erVqfUvy1c/Zj4guYS
+tEy1YkEi5dtTn6eO61y0soYMD+35d2WRxsCJPv5RaMa7+hKBtjI4y+NX3rHTXStXg46yaMwUgU0
2B3axC/w5+K3tm8d7BvkC45YPZxHIs3qLNssZ7N7B3mYf1jcYeyR3ie/5z6W3jd+zn3Of6Gou8Vf
k9/wr4mvG7lV4ib+DpGx6FxocFES2VnBXil4W31tPuIzhdCPVM604p5WxIYkurRMXQx62DI3i6k4
xy22uDV9aYfeKIvkXSS7L988+Ph/43jyra9/kvx2Mw5uv/76hx66/vrtJOcezG9OvvHNfydfuyO1
56d79nQ+vmcPHe+W5LXsDhivCjrno9rwUbaJNmKNM5VKpS3uq2UmKZNstb5/+SRqtwzpomeFf/lE
WD8X2yhOg0E1m4ZsFEuByWSOqKqufBr+p5UyZUD/huTUv9kpurylexi1Uy7SPel7YQfldJQxVKj6
+cOot2C+7MWrezFJnu9t2toAU+y8b/H82+5asORumNrGhck/JAeTZ5Mf1s0c/JLp7X7uie7dT+0E
htyIEFOhj32Plr+Dw5IJT+cWc6s4ptjaZFpqarOyskTPHMlWY8pIqo0NRmLsIWu0AkEA/mYIL+cj
SZVKpDaJlbwbrDutZK51g3Wf9YSVtaooghl9/IR04E4w6jyW6l6chYZMtQvsfLbFM+UUcqd1cODu
ytI0KdpRfcI1nX46R9+Ql45q1u/EpinhEnQmt+BOytHjr6ltbb7i0kvGXF7MRnZcU1v+z+E1e5P/
DWMsAX5WYYyF5FWtj7fwYTHqsrjCD1sftu+IPlQoCfY6O7EeUXpNb4Q+C59TzubwBcpMZZHykGGH
dXdOr1GoCWu5tZElOQsjG60b7Xfl3J4rVUQm8HWGyUqDuS40LkfIyY1GKozlIXo2Vp4r8DJnkUJu
JWrMyckJC7k52rAbjDfa1zpWF6wqvNtxR+GjjocKX8p5Kax04K2ue9yPFD5bmBjGu0JOLRSOO7Ws
QDzgxJ86sbNMDDXmbc0jeZrbH8/zDtNfOIHUbRyGS4bh4mF4WHaoRMVqGZhyGcmcfssuV6f3Jfqu
wxO7sYeS/DxIW/00IiNB9NsLVA4PoMwBXzmPMY+dOJIzMlQXmoGbXQvxMtdZLGMXYb2hHJJvU4wk
3zuXxWxdvqHRi711NqF6kF4NGvo2hbqWdh89jvw11SJDPWmYox/X5tJ4f3cgNx33ePW45oPANQoe
mVOX87DyYM4vct7L4UM5RoVlvSijp6IyqrF2u4qqcUap1+M5eXH9BNYPex/C6TNYthV34NOYQVjV
T2RZvaTNCSUx1qYgFs9lT7OEDsGpQdXOMpcG9bo0qNSllVfEXfS0yaXlFYAH9ZpdAf1gh3XN9Gog
vc1e3OhNeUlm8PqhrP6jtw1a2um9gxXpaJoYmVPUzA0p+LWkbzXnpt7SJIO12pwPHtDh64NKpdFu
rKTBLiM9l/1qv6ESZT5VbEZDtxfpLeVoJJobzdyz+tEBK71tSw9/SrDXev2C6yry7I5JyefnrP/o
s4/ey09+a5nbtLwkmBXBrzQ3nfnmw0FcHLt8Zn5WcdBht9SPnfXI5qP3bRkxdlzAGc52ZC2eXH/X
T36bQPQfKH1B7ueegD3hba0giMAckQvMo02TTc1mweNAbsbpQC6rzY5dVmLHbkYSZMHopuQ2I1en
K+FiWgH0uRhXD2a7HJiKzG7koF85gr1uNEjFcjECy2euftmU1fLdTMRlnemotu+077MzrfYO+zb7
CftpO4fsqp1eL2XtHu+NnUPKRH2iAuTEGP3WkT3VRw9pz6fPaNUzHipaBvSvI6HoKXoZtGzo6zzs
CFvsOk1dfObw0xIuLyvPs5B1fYZoVnSye/7Nl62rNEi33oq9bKQ/OeO2WJbvo8KyaRNGPISP97/7
dHIT0OdekDLT2QjoB49rrissSyzbOUbiPXwVqbLUk3rL50TQtXkLa3Ai2WG3yxJvs0ccDkQFpMmp
awlOnII1/39oCZJ4QT0Q8WkRi/+7Up/eYv6HdtCSPsKIROhxr/2Hk19m6uhjy67Zexn2BC6vnrii
EHt2zpx/1d7tpDPp7l80pmHVKdwHajKM0wB60GwYpwH7NAeX7y2OC9TjqSdSj+lJnewGqCvoQe/o
+KMs5hmDKMpGA1ghxMp4Ja+cg4oMbxiMsLZPa05/MC4jzmBHHkMeKjTE0WjDRiRlbpbJWDHqdRkk
V5zFSMI8klE1fa9fqd9BrPRpVgOSWYMsSYRgHsJSJT0N09xZ+XGDElBKFE1hFZfLq8rVcoPMyD2k
RDOwpNLAVrMNLMMeJiWgoHVoZmM5wkF66xl7jL8A3vJQ5oq5pwzoX4F59Jtxejx9f1T/UBRDF/Sl
HaP3tNOfFNMrbS569GoLYXwoOQNH3xzt4k3qr3AoCdQb/NOBCc6iIpKdpqkRaNqq0/RhbUu+8CZL
HhZ68cf4feG0womCl3Xz+XwFGiVOxM34ZrxKkCM4JozEo4U6PFl42HCOPydIeWxEKJTj7Gh5PDtV
fo0VL5NnsM3yQvY6+UZ8i/wgu104LL/PfiyflxWGFcCmdLJBtlAuY6vlOlZysB55tDxVvkbezR5i
35LPspIAk9NtddOZPNntcFHYrzmMljhmZYFFRAcikkR6QaD/YEFRPKVfQenXzM7cOBMhkp0QieMN
hkz2aQOmQc0F2YYI4uwIcTzHgRYhSpIBcT3kui6+TAKgGcRFDcpOpV9hFIYmkzIDTbaeTh9BpT9S
WvTDHLW76XGCZ4raclYPoeI032e+0I21D92fSIfwhY8o01x2QA5KIX2AXQCRLqv1T/Xa21dg6pVh
mFE6mSGGMeINyfvxFUdfx5OTD+NNyd0nPyJhwiQ/xrlJafAdPCl5iM6qKTmNvRxm1YbjL1nzOWyj
Q3cbzXHRqZjjAvV46nFOSKNvhrUArBYwA1nFYOJVgmw8ayMsw2BQNG2tsMn34H3A6mal2JSPgo4S
R6uDoYa9vgtG4rq9b83Kjjvom61KRnN74vQ2Sg+OahLRYwQTGrPiSqRljYxn3jraf5GRorEpgx7w
KcenL4ECtVZMUc+cAn28pTjN73jo+w969lIpmPSTlwzXt9QnVBDCo0EId7EqOpyC2Uqd3s+oWL/1
qW95HH3VqFiqbarNA57VXc1RNoMIhV0QT9fVbNPpLZiYcE40qh8vm3AseQ6Hk5vG542/YkPjtKme
ceXzr/LAcjKRv58nvS3zL8mxfKzc0Ewv6Y784cF+0k7amcnwbGXeY95jQTVlK9kmeD7ifNzf+EeF
qNAvrhNPSrvhee/iR77fMMHwJ+MV8DyjP1/SR3GZaswaPHvUVeoqyzOW09bW//zYltg+s99u/x7E
OnZ+AypE3//X3rn0NBGFYfiFjtM2JVjuTGFkIyUkTRgshBLZGGnBgqH3UktidMsKce3WxEsi4m3j
hpUhGnVnTFwbV/oLjElX7rrRBVHq+w0dEmI0wIrFd5485zbn5MzMyexO5nP52f/FSlofIufJq8HM
4De7YO/au2e+D71QFEVRFEVRFEVRFEVRFEVRFEVRFEVRFOVv5O/6zUiM3fC5MaQj1JQzAjHf2fRy
fmklOF+sLKZKYWc2Gy939/SOd+QWkmNWf6Sva7RqmwPDcyPRzERiyh9qbzte3OYTmQzccXND3k99
stFg3iK5BJKBnKGIyd+BkcYy8ljCCoKYRxEVLCKFEsJwMIss4ijz3fagF+PoQA4LSGIMlht7vg9d
GEUVNkwMYBhzGEEUGUwggSn4EUK7nH/iip1uSHhwXAAoFM5NJm46jnsFLQ8kNMIhU+Bgs45640BH
MyqnKTFzmrZOY19jHZ00JZ76iCti6zZW6UXReImiyPo6x2+KHFuiWzROL9MordJy07zIOZ/+JdcB
fUKv0UeiUcNTT65zz5Pt5+Y2NjjmmWsJj81pVETW07weY//mUeT9XeK82yyLLAssL7hKvYaH3rN6
+m3cYv8GzdG7os925zt8niG277MeIm3cY+x9e/9Jsi/m13dv37x+f/X0zI+AtbeRW7UZW8rPa9nr
Ozu/focRuMGxQW8f/wD3BxdQCmVuZHN0cmVhbQplbmRvYmoKMTUgMCBvYmoKMTg0NDYKZW5kb2Jq
CjE2IDAgb2JqCjw8L0xlbmd0aDEgNDI2NzYvRmlsdGVyL0ZsYXRlRGVjb2RlL0xlbmd0aCAxNyAw
IFI+PnN0cmVhbQp4nKS8CXyUxf0wPjPPtfc+e5/ZI5vdQBZISAIhEMnD6YEccoQEiQQBuUSOAN4S
VA4jClrFW/AGlbIkAcLRQpVqRSn8irXVelCLim1RavnhAdn9f2eefZaN2vft+/nv7sx8n5l5ZuaZ
+d4zzyKMEDKjFsShcWMnlJYj9nl+A0R1MxZMX6ReP/s7hPCrM5Yvjby8+sMbIONDhMQp1y2aveCt
/R8ICEkDIPxm9vU3X6fWD/RAaPZDc2ZNn3my6plzCL14G2T2nwMZ9grPOoQsF+C6aM6CpTdl+zsF
7S+5fuGM6er1nfsRipgWTL9pkWuY6TBC1ghkRm6YvmBWtv5SiPyLFjYvVa9fnErLFy2Ztei+6Okh
UF9ByPiFsBf5IPiFl5CPTyAvQpkvIJyiaXpu5hQtpyn5O9zdmQ0IbUHb8Fy0DR1Ar+EzcNd2tAd1
oN8hDxqOnkS3oYfQGiSiKZBzDxoPXwHyH8K+TAcqRc/APD6DjkDdyegOtBe5sTfzJVqBVnHH4a5V
MNOFaAgahxai+/CVmWVoKvqEvwtVoSvRDWgRbsnUZ+7PPJh5Hr2A9nC/y3QhI/KjGfA9kvlK+HPm
Q9Qb7ngYPYY+wQ/qdyIFemmBmk+hJehxrpHHmdmZH2AEUXQjjIFHo9ERfJAkofVZ6Avsxbdxw6CV
5zKpzCGoFUSNaA56HO3F/fClJCpMzYzOHEFu6OMmaPUx1IZ2wbcT/Qp9gE3CmczzmTPIh3qhy+F5
OtDv8UEu3bUyXQszJsAs9UTVULIQ/Rq9iY7hGP4NWSiYhHJBEW7JvIucqC+aBKN9Ce78HH9L7oDv
Cu4NfmRmKLLAvDxAZxv9Fv0V+3EpHovrSE+ykDzNLUE66LEvfGeiuTDfj0LrH+Mk3kVM5Cj3HP8K
f14sSJ/IWGBFEugJ9BT6DTbDk0ZwM74Tv4f/RoaRaeQJ8in3EL+V/4M0HZ76GrQA3YdeQd9iOx6A
r8JX4zn4NrwGP4Afw0fwMXyKDCETyXzyNTeHW8z9ih8K3wl8M3+XsFq4VzyVrk8fSv9P+ttMeWY1
ugrwYSWM/mH0NDzZHnQUvQ/fT9CnWMBGbIFvBEfxJHwrfO/A9+Fn8Ra8FXdAL8fwp/hL/A3+X3ye
IPiKJECipBC+MbKE3EgeIk+So/A9Rv5Jvuc8XCGX5PpxNVwDtxBGtYbbAN+d3F95P3+Uz8A8lwsb
hU3CFuEV4TXhjGiS7tQh3TsXnusq6fo4jdJr0xvTbemOzF+RC9bQD7MQRjUw+unwnQfrvREwbjs6
jk0wd35cggfjK2FmpuF5eDG+CWbybvw4foGN/Zd4P8zSn/DXMGYzCbIx9yH9yFAyFr7XkFlkMdlA
HiQd5D3yAydxRs7KubgS7lKukZvFLeVu5jZyKe4d7iPuU+4cdwG+Gd7Ah/lCPsEn+Uv5afwy/mn+
C/4LYarwtvCZaBAXiKvFTvFfUn9psDROukpqlNZLu6R3dU2Ana+jnWg3yvvgE9xKbgS3E91PKngf
+T35PeDzNDSTG00AU8kWvJbcjjtIkXCTOIgMwmPQGT4Bc/0G2UTOkUHcaDwKT0DzSF+1NdHJvwxJ
Df86Os3vh2f7PbR8k2jCd5CvRRNqw4hUQ5+/5cr4JPc2+oD7BEv8M+gvvAF78GnyEjcOsOBX/GCh
HkW5J9EvucX4drSTjEDIcF63DvB4DH4Z+MJEXI6/4zKII2MAi6q4v6G70HzyZ3Qa6HgtegTP5Gej
+1EFvg19gV4Equgp3CCWiC78FpnLtxIH7kCE3wpPV42LMCc40d24kXtc/Jq8j5aho7wBfcy9CqM/
Sn7JjebPCOPxHKCA29FqtDizEt0s1PN/wLMRh+tQnD8B3O02rpyPQroCuMpU4Gm7gLr3Ah8Ywo2G
HC9gzpWAF5OAQzwO30eBT/CAQXOBxicDF/s96hAnkk40W7Bg4DoI8W+nx6MpmRfRY5nZ6IbMg6g3
8IM1mdugxS3oM7QebcGr0reiRSgElPMxvlIYSY4KIzO9SSt5n0wgG7uvL8x2HHvR3+H7S7gYLOxD
rfyf0ARUm1mX+SNgdw/gsI+ha9EV6CQ85VfQw2XcQVSRHkN2ZEZyi+B5P0FXZV7KhLEBzclcj8ai
/egFSUDTpSSscQr/AZ73VjSLjM8s5Wal58I8rIdZUGC2lgH/uYdfzN/Ff4+QMrJ+ct2kiUOU2sGX
1AwaWD2gql9lRXnfstI+vXslS3r2KE7Ei2KF0Ug4VBAM+H1ej9vldNhtstViNhkNep0kCjxHMOo1
IjayKZJKNKX4ROyyy3rT69h0yJiel9GUikDWyO51UpEmVi3SvaYCNa/7UU1FrankamI5UoNqeveK
jIhFUkeGxyKdeMpV9QDfNzzWEEmdZvBoBm9gsBngaBRuiIzwzhkeSeGmyIjUyOVzWkc0DYfmdhgN
w2LDZhl690I7DEYAjQClPLFFO7BnMGYA8YwYuIMgnRkGlfLHho9I+WLD6QhSXHzE9JmpcVfVjxge
iEYbevdK4WEzYtemUGxoyppkVdAw1k1KHJaSWDeRufRp0L2RHb0Otq7rlNG1TUnTzNjM6VPrU9z0
BtqHLQn9Dk95bjnpvXgJjduH1a/JLw1wrSO8cyP0srV1TSS1+ar6/NIojRsaoA24l8RHNrWOhK7X
wSSOmhCB3siqhvoUXgVdRuiT0KdSn29WbATNaZoXSeljQ2NzWuc1wdL4W1No/M3RNr9f2ZM5gfwj
Iq0T62PRVG0g1jB9eHCHE7WOv7ndp0R83Ut699oh29SJ3WGxZgGTOR+YlStjEKtOoVHjczOL6Yhi
lwNCpCIzIjCS+hg80wAazRqAWmcMgGrwacBwV2omrMjclH5YU6s8kObT+1NCXI5FWv8XAQbETv+z
e870bI4Yl/8XUZDiSQ7VoFyDU8lkqqSEoog0DNYUxjiYXffr3Wt5J4nFFskRSGD60DiY2+kNA0th
+qNRusD3diroWrhItVxVr15H0LWBNqSUJhtSpImWHNRKXJNoSYtWkru9KQaY3IGo5utK6RK5n1V2
O0bMGZjC7v9D8Sy1fNSE2KirptRHRrQ2Zed21MRuV2r5gFxZFko5htVzAZKFSIBjpYCUU3OV6UW9
KcXH4ScypJ7ZKekAK1kOjoxMyU2XqXGDIRr9L2/qzJyhd7Hk4m3ZYaYGJrtfD+p23W14plYOBgxS
c9TEKa2thm5lgGpqh5dnE8B4NLE+GhmWQpOAMuPw68wcHEBDQyClwJQNoxUA/9Ss7GW3ioEs3AAf
ip29e40ERtfaOjIWGdna1Dq9M9NybSwix1r3kNfIa62LRjRpiNOZ2XtvIDVyXQPM1Rw8EIiCoKE7
YnjtVTsUvHbClPo9MpgNayfWtxFMhjUNbdhRBGX1e8DaUFguobk0k15E6AUaheEh24iO1Q/sAUOj
hZXyLINdz+jEiOXptDyMZnQSNU9WO0qwjhTQ/GZ08mqJotXmIU+n5rWotXtka+ugRKYlexHIDsQK
1Q9lTsMm1uejHaNlWjA5WW8iraMmwKLRQsOAgCGvOEJvTOFYalrspugOaDNVF7s5CpmxVAQYHFTa
gS4NNrS2RuAbg+5n1NWrMS3CvYLQUkOq5VqtbiDYEMu7NMGtbCnag5Tscr3dqvW2BHqjQKvWXWrG
z/YGo0/hq2nMfmz4O/qjmNo/CDa109aprVNiUeCbBbTj7Djg0hJsYC3ASB6lI2GqBFitg9Nj0DAZ
/bD9h1tkltPtM5XmyALYQzLYIBwslwwaUQNoNQcyGbjCQ0xoIteLfkkhKkBh0NJLQLMOcyVtYkG4
k+vRnvCGj+3neqITEAjXsy1ZEN7DFXMFbYPCSicXa7e7yq1DenMR6KmUxRGIF0LYDuEABB5N40KQ
L0O8AkILhO0QDkA4BkFECGJaGoGwEMImCCdoCVfABdsiYXlIMeeDe30wYivnQV9DyEDgYJwe6NWD
xkKYBmE9hE0QRFaP5iyEsALCAQhnWInCedoerICxe9ruZUn7vOvL2eV09XJqI7tsn9ygpqOvUtPh
l6vVBqrV+laq2X2GqmlxLzW1x8tbaGowlx8c4ubc8JBuGPgiiDE5hKwYgwq6mXOhFATCidkchbO3
FyXKNx3geIQ5wmEwGcOZgxxuM9vKhxhIhnyN7ChMviKn1RJyut1iK9805AryKdoO4QAEjnwK37+S
v6IV5ASdc4hrIWyCcADCUQhfQxDJCfh+At+PycfISj5CpRBqIUyDsAnCAQhfQ5DIRxDL5EOKSSym
cC0EQj6EWCZ/gcf6C8RW8gFAH5APYGjH26qqy/cwIFmaBcLxLOAJZAG7u7yT/KHt+56AUQlYacCo
fVwhGowquMK2eF9AP29bzdxwJ/lbeyQZ3jykjLyLUhAIjORd6PldFIEwDkIThEUQRIDeA+g91AJh
A4TNEFIQAMsgliFEyGEI70B4D5VBUCCMg6Ajx9qgm05ytC0xNDzEDcbXm8gDM36E/I6l75A3WPo2
+S1L34I0BOlh8kZbKIyGGKEcwT0ypDKkpVAukN+0F9nDmSE2cgDmLgxxKYRaCGMhTIOwHoJIDpDC
tplhOzSyDx3WIajZhr5k6YvoWR1S5oWVxDBAwAiNEgMvAQiiTZFNCaIkNj4GlzRK3P8gQDRK3L0O
IBolblkJEI0S1y8HiEaJmfMAolFiyjSAaJQYOxEgiDrJ07uLisNVY+fjyBAruRFm6UaYpRthlm5E
PNj28EXf83RsT7SVlMCMPa4ke5aEW/bilv24ZTxueRa3zMItd+CWlbilBrdcg1uSuCWIW0K4RcEt
+/AAmIoWrHR0u6xWvLjlMG7ZhluacUsCt8RxSxFuieAqpZNE2y6vYMkIlrQPoUQH6SWDgftYSRRm
NAo4HwWecADioxAy7EqBSpFCtbIvRNPC9pJa9brPwPKFQD6vw42vwzK8jj6BwMMCvQ5o9Do08jo0
YIW4FsI0CAchfA0hA0GE2oUw8PUstkJcCqEWwjQIKyB8DUFkw/kaAkELs0PczgZGB12aHfhYCDx5
Hb7UgRIlUaVADspJ+TJufRBbQ3hsKBMiVcjtBpZut+lsndi861vzd9+akX6IntxP1lPWTTZk0/Vt
3wPrxo+2JfaFh7jwIyjEA+bhapTAcUgHoGZ23Q8FdTStREHyCqTlbcE6uM3alugV3ost9K5d4e+D
J8NfBjsJgKeC+8J/inTyuC38R8h5ZVf43eA94bdKO3WQsz/RiSHZG2FV9wQHhLcdZlVXQsHjbeE7
aLIrfHvw0vD8ICuYpRZc0wxXijU8PjElfBm0Nzx4bVhphjZ3hWuD14Rr1Fr96D27wmUwhKQKlsBg
ewZZp7EQa3BSVSeeo/SSNkr10lipv1Qu9ZKiUlgqkAKSU2fXyTqLzqQz6HQ6UcfriA7pnJ2ZE0qS
Sk2nyISnyNOYZ7BMaExUMUuwjoBJnnJwo8ioCUPxqNTBGWjUtZHUuQmxTmwAFVOIDcUp+yg0auLQ
1IDkqE4pMz5VlRyVksZdXb8D4/sbIDdF1oJqNbG+E2do1qoANeb2IIxtq+4L0LTHqvsaGpDXvbzW
W2sfbKseOfxnoqZsnLz48XaDC1IbR02oT71c0JAqp0CmoGFU6hfU2tuDv8FnRgzfg/9Fk4b6Pdxg
/M2I8TSfGzy8oWFUJ65j9VAE/wvqAcb8i9XTgXCm9VBEF1LrPa7Wi8P9UK+IJlBPr0dxVi+u17N6
PKb1djQXjRi+o6iI1fFEUDOr0+yJ5Nc5HIc68Tir425Bh1mdw+4WWic1mFUJBqFKKMiqYD8KsipB
7GdV6i5WKc1WuSdX5R7WE4cv1gmqdcwntDrmE1An+d9+Zg1NJnH7oIYZU6ml3BQbMQtCU+re5XO8
VGuL7JjRkDWhE03XzphD0+mzUg2xWcNTM2LDIzsGTf2Z4qm0eFBs+A40dcTE+h1TlVnD2wYpg0bE
pg9vaL90XGVVt77uyfVVOe5nGhtHG6ukfV1a9TPFVbT4UtpXFe2rivZ1qXIp6wsxHB9Xv0OHhjaA
YcbSdmI0AL42gbo61C0vGsyQd1DUe0dgL2gsW5AR7FRTbGjKDIEW9R7SewgtApqiRRbqDskWee8Y
FA3sxVuyRTJk22JDUXLpsuZlyDti7nD11wwfyFq6jE64Gieb/9MHykaklOnD6Z7KqFTJhFGpWjAW
dkgS5DbRR0oN1PKMxhFgkKmZfSBzIM3kuFxFmldD8/T6bMWfrv+ybDqMUkEL2deOlRBeipobuFRo
1EQCrGBi1u7cC/oUFRHNDfCAzTiJm7U2ssNOJpF6jegza2HpsiyUnYul2VS9E25p1qYk96GTlczN
2FLWLJvOJGL2FhLgC6JHQkM7CD4pSp3kMcWBBP4khwwSfxIjn04UThJuP+mL9Pgx3Ad5k/K5mq6a
MfLZmtFdNagWYPkCRH3LoraoLQ4R8Et0IcIdvKAI6DyK8AehL+TKfME3CMdRAORKX2VNj4IBBUTP
6wvIZOtux+7gm443g98ViJi4kJ7nnEgviDak10ky0hslOWAwSbLXbJVkj8Uu2jwWB+f0WNzE5bH4
iMtr9hNXwBDknAFDAef0mkOizWsOi7aAwRAIxJHeiZDe7PXGPRanx2NxkbiT45AsxW1iJ96lDLBY
zGaDQY8CXq/Hgwwup9MmD7ZIosiRwcj7kNnzkDluUWzVYy2bLMSyLGp4KKB/CNoFRNhpq6YGTid5
pj2ydQ6dmsbk6ZPyyVx6tqZGrlFjmCoAa9RY7oIps1WXQrxG6JO8XT60po+XJtYfffqW4cbGxR5H
rF+FI9ov6qjgaKhwxbioK8rFHFHOEXVEZ0/e+uYV6a9x6eSNk/GgyY9M3vb2KOxOvzN5Y136jcnL
8MBR6d/68MsP4/kP423pCTQ8nH744XQdfjldR2rxfMCByzO9eYc4BJWAXTgAv6vEbwthvqxX//6l
I6OTouNKG/vP42aU3sLdGG0uvbX/mmhL6f395b6dmY93G6tDkUhRZS8qPHtFYpXzdANK3KaqiLuk
LGpELlMV4AZyVUXLyg6bqpwmU1WZKVrFe8rFTvL8rnECFk7j4F7yPAqQbe2e8PFkJ+6nGJwud4vH
4xRQSSeuasOGcsjt6Hkcm4N7QWEJkEfb+jUnYAkUg726LKEkWhJcopOMV6wlbo8nHI5EBgwoL+/Z
E+7+heJGsKjJZN++RqPBUIYUsH6PAZJ2EpOiF/o0L5RXyETei+9HIh6gWGuFscIKYb3AC77qN+/1
JgHVG0efPrv4NKTq4mlLKOd/z8KSnz2Nas920QWvpZHcdZL9Gk/a7J7qNZY+yTWW2w+py+oddrNy
RbTK5CiKx+KFcU60JyxWs5WIVdF+Y3FFD4h6OfqMRWUmiPrHB4zF0UjVgMri8rGoory3LQnFSYez
1NoXqvQ1GREdQzIbqSmmHKAkWbJyJaYMpRE3Cv36kOIqt8ftsSWKE4l+lVX9q/pVuGiGlEgU29ye
EHE5RcnFiaLL6fY4+vfvV5koxuk1W2817HT1u3LewqV1jWuvbp/31NTl3r3yrPq1vSbOq/7qV/Pm
3jz71nlz75n+wPEO2+TX1hU+MLzJSC5xDSl7+fqDN46z19VZR1/7SnDeYnvX94WO+LwHJ+37Qb9L
7CGvbZx6W7zLbX6y+dobSyk7ujFzWNwEfMIIBlUAFaMKLCmGDb4NfjJH5w8EqGVh9fqcXq/PG3BZ
ff6+Sft+sgl40yxkIpsUI+f3+TgMpBzvQfPDkN+HbGqLG4P7yeMoCTyvL3m8vfDVfiK9dsG1FZrU
UypeVjl5CiNhWEb5HFvL012nczQLMLbZq6tpWMNIVl3E8biiZygZRhWRvmHcOwFQaRFAZmINIw/v
CmObASCHDqCSgh6gVUch6lXcJ4zKYhBZsCmM3QJEstEeRk4JIqTxb6wBK3Gjo7J/RbkblidWmMCF
dH0qyunycLgC4/9QduPTG1t37l69ageuHtYwZehwCFzhgxf+ij97+hEoWAMFA2nmiIYp/JSnPvzt
gb1vvYF/u/SJ+5qXPn5/8w/Nov77b/H9T/+FFryJDy19Yt1SWgCTdVv6KtIE6ySjSxRDsRW0Y7uk
k+VOXNGONll0kCo2aZPlGsTJXITjuFdtT61jk9t1jk4uMMPaGsrbcILYKBpWiBJ8XTLGnzz8+9FT
9q+8ufiSGDx/+qr9+Dts+eqDrvPHGlo37vtVOpyOdOt/lmLqQXrIRG+QMbLr6QgMmzgMaQfaxF1j
6cyc6ZBlMgmA7zqsVgac7DCbGfBPxWowkElWSxhY+6v27BjpjP9onI4YslUWA9UUVwC5uGTSRamq
8JLiW1bunzL6aPoqfAL/df+eja1T/nC+64Ov0t+kdTDKJeg0P5DfBdg8QAmjG/Tkex13gyCJ+hsM
vOF7Ad9QS8YSQnwminmMz5ytOV0jn6ypQaVnQTac7VsWtwHbt1XYgN3bCE4vxutfxuvTi0/jB7fQ
dEv6Bujn5fTH+C50BBnQmJ0GEOevgGQbpyQwV0MINuAaZCAcXCBxgDRwLJqGFqIVaDNQ22bjM4/C
E59tPHtSPs1k1GmG6rKK633LKoA9AEMo7t+/ateRcZPLq/tzR44svjcx2jf9auh3CO4k88gCkB69
FN8isogjo/Fo6DKGiF9YBBV8/KL76JOdbJQ/R6WjT/ctQ4thMvtFXUNIT9y5cyc1pvZCtAZGz6G4
4iV0sDXqELcjfjOUb+bZKM81NlKSVAe198iRI8wQy3xBqgEPODRhD+IyH7c5qwnIJCXirH6Ew4Tb
xG3nCLccYVACMKg8oNdwpxA5BfixFTrn22/xUm4JFK+uNZXGjYy2MaCBi9LW1g3pep/wzx+cVI+Z
BHqMTTgIeFeAP9pBqItaMfhDvOAMmc0efWfmFMMxCig+imR6GzLRHOQ2mSA20TxUCgh2BKIj8Dz0
iQI7xJ+2dBZaEmlLnwO2MuArxWc0irRJmeYg2WSiMc3LNXmxTWUML64ha41rrW9ZBL1k9JIRjitd
V/iGBSY6prqm+sYH5kvzjTMc17vm+5oCN5MbxeXGW6xrxEeljfJb3g/Ie+J7xr9Y/bkhDZEzZ5EJ
mbCC6pAn8w1gtTELf4fMyIwVxVbnadYr0VhlmR4jvawn+iEGuEmrqM+cUivurtNvCNtMJlMnVjrq
bBajUQV0ZjMA7XW2ZqpdKSZoKYLonoJWFemyVZFadVcd2hCiQlo+B48OEhhiCjYuZmB2KnDjYtSY
IsNSyrj6DjHik4PAFNpIxPjrzAnkhmCHYIUwgH4whIaGBodsp0zVbQdiB75anHDIlKva5ESsUBIn
zT++eXnb0qHzjj/z7s0P7Nl6221bt95x2xWN5Djm8SWvTmtPZz5Ip9Ovb3t0N34q/cjXZ/AcPO+r
uaspzn4CiHQecMiA/tluyD2ZBhi02UAaYFCfNffQSrSOU8y2yvn8CrKePKbjX+WxHokC4fQCNhF8
2MBmz0DXAWGmoGZOMB4IwN8VG0PHIENHC0NHmA3FR5FNwyiGXX6ToJitlQJty0LbEnBEUAQi+Ix7
cQ1ehVTCXqzOOPvAhWoV1Hqqsa2azjxq1KRYNGYTRakfsJIKcr5jyPGJj3xaupS/dfBt4V9eenga
jLIG6FGCeQnhb7P0oLfJZq/DIU4yU3Kw2RjwlaKXZYBCTiFEycxDK4RCtDQUtEBJyETHH+ok+xQT
MXg8kbBsIyQSBo5W+u4RGh9BpRQ9krU0PlROCZDkOjTZ7YR1qOitNqL1c0Ix2h1kUshJ82jbbdA0
JXejkUzyUEnC5vLneqM0SfujvbHOlEsHCYPEfcIBcZ/0pu6toHS5qcE00TLfNNNyi/0Wxz32/fbP
/J8FzvhNB4y7HSRkkHWieDjodwaDfl3QD3xN5w9y5pAMCnT7WBu2dWLvTjpORAfWjonJ0I1YDXnE
asgRq7nO0Ow5DghJCRbvIytRBMmgAJtsO2vJNLKQrCA82UuKQIlav4ORWCNVjJKUWzLaAgEFGpKq
3GJNw7UA81RlR5bgFH1ADsoFckgWf505gyQgMx2keggatQ1oQKCdLmloiLuiiSpAEKZ3xgqZ4FEV
G1AORImXLlQRT/y5x7/e8titdz6J9zi++5/j5y576bVnp4a2bRtSM+PgHYc+u27+L55sdRx9/+/b
6l/e//za6X0Bs+oyn/NuwKwkbssutNHnVeh6eYMIUwRPmuAC94wZzFaTNWQw9HSFgnyoZ1DoaY6Z
TV4fqBYRmZJMRErQVafVE6WU4x4ppV9kr66tBcF5Gtb79BvyG/Zq+VCynAa63mWC2W0eYV5t5kfY
JtuWB7jx7uvlec6Z7mXmm52rza3OewIvmA1Gk9nCSxj6w3Rh6S7rPkwPIprB9DGZXLyXWkk+MkfR
w+gEGJ7Z3m2d7XnrbM9jyvbmaZGFERLxUrqItEjdbpLybpLybpKaE4yTJzBKyAkCT312N70/saG3
txMPaPMdx9QSQzB5xhyf3tCrEz+YRZYk06M1Vnw22ZjjyF0nKVmcVg0jFXVy6NImRDigNkCLBso8
8OIGR5U7q84C463KgRpyUOyQaIxA+a3rCD88f8X2Z2+vuNJpNzZ3rp43d52zI/r3X950eP51M+/c
kD713m8y+C7vY2tSd972jPNpctPtM+68++7Izjdnt82c9mSf0K/uP5j+38+ppPcDN5KFvcClzfjf
+5Ep84M6ZR11ZjHLqgWNZ4saoM9xcQ0QNC4uaoA+x9c1QNJlK+s0QNLknE6Xq5MVAjoNEDRA1AC9
BmQlhlJVZ683zTE9btpqesskXMldaX6I5+zAPpBJ5CTBYOQkkDtm82GOd3Icz5kRMZl5idtH9iEd
InizYkA8D1XQYQPfSa7bLQgGpSBcadAEikHVThjwFVNTDGCuK2ZJKYxVSi3RftIGK6H0ZTQ7KxGR
SYRwhN5M7wHg5C56D9lp6cTrGNr8k0pxKk/OUv5cI38uM3ECxvS5Glt1Nc5aX7zqKgEBw9xtZlD8
7NXAs99VjBXVXGHvao4vKKihTTQAEkEdxWlSjNWmlnHVJiVRbSoMQtq7msmlhpyVlfdBySgo3rjC
VuGK2TgbJhu77iZP/eKNNzrS/fC0F7hdF654If0MMMiHu+ZTmU71wqjwIsiu19sd2trbNcBhyq6i
XQMcpuxS2QHYQ4lPZUx7EIbZMtPpwUGLIeRyBe1UkBmtPB8Kmi0YSV4Q4kzJZABjYlTEUCZEiQso
q+sQMB7KdyrtTBRaWTzKf3NBa8FGx0uO103vmf4S0OkdXkuJn3MYXHaH47DF6rQ4nBarGXiP4qBd
K5bNYBVZrIoLZ4ex28rj45QvgcBRbHRAtmkydZ+sl3n5v+YrXsZXvBh5ZS/xanzFuyFi34/7ISt+
GGoOaLPs/Dn+Eu7OX7pxmEZqvwBPYXPQaIMADPnkGl2fpADogvKFUoe+TCgz7gVZxDFeQ7nNYlD4
s37dfJYDfMZBfW3Aa5DLKVETe9KvXI9df2fHtnWT1/XYej95v2v32LsfOIh1S+87+7su3CK33nvo
2cfbxta6yb9eTS+fmj73P28+0HYChj8asMQFcqgAleQkUdiKw3ga5nCgR0gxY7MZ1I2AUBhymg0h
jOIyVUSYJSGHPDJddg+TQx5mSXiyav+Rd4/Iv9WWv/G0fKiRLn/v+T48XFJcw33DI1PsEyPzuZnS
TN08+8zIUt2y4Crd6uB7unfdNilC579YJUtxUowqOwEKRVkBHdY4M4GBBfDxaczdCRJIGySmsgDt
jHdb+3je2sfz1j7eLLO1B0tdBvYBz3ZmN9U45Q29gG8MaA9pBBPSWGMIONk+1k4IVyvmWs80z0LP
Cg/vkbMVYDYYq7PUedy0KY+bjtnTSYrakznDQJU9+bhyWhVETADBhOUQYw9VUDqKI7FItFPDDOaJ
ATbSgKVEMdP+RYmKHjvVS2KFyCZXUUGEnXlIw51v9/a6fH7dkEnXkiH7Z3d03Xjs7r+mTz51z6lt
H3VVjb1/zJLnn731lpf5CZZ5ZaPLBn/14Yym9Ld/aD19Bx6Fb8Nbf7PltQsfNb7c0Pn0o9u3U/4y
HaSRW3gJZnKRYjlkxjz8iI7XA8um7KGMYF5vMjdzHKETPJbpbxzxW3XN+n+gsYBf0whXC8lCvAIM
FJ8lS0bU57C4ZvTZ02Pkc1R3p1Yw1euqbdWqEgdUQa11EXGiFOtvt1dN53auS58e1d+6h7vz3/fw
P2xb93Danj7f+Zdt+O/4zSfpbsIEwHIfYLkHxVAZ+YWK5x0mFAj1oaIA9HUyqU8fezQkCj1CdnOI
Ck1mMp/dxSzmpJX6bCiqWzUFmwKs0OrlNIcOp9XiciTCFblMtLqLtehiJOK6aBl3N7upZn66ujpn
fe9mAxG1gYjqQE4yK9yqiaps/zQPgAtKIc2k3dI7XYzPutiTXnw+rTPoC5dmB6AFSqWj+7lxT/fl
7ssTn5u+LBP0Zfh2dDu+jV+qW2xcYlpmvsVzL2rF6/jVupXGu02rzfd53rG94bCbUMiLTNDT5j44
bzK70WEojw5DGh3uqgs1H9Bj/RA7mY2SebWTebWTeVSbbLYqEaBaK0ZW2UqsnfiBjnKvRqpejVS9
mknubU5xmOsks9uLtEpFWqUizcQvanZphmXEpbiIa0PfNzW+zpg5M+XP5nh7Tnm0VzeyqaSaQB7Z
FmZOtAUjfiDatkiklCa9I6CzntjRM8KoWOXxjUsWo8UNDZg6v7OWhaY7IshxOPMoOJ+c8bxF139+
4ODf5y9Yc1/63Pvvp889cO3q+XNW3XPd7LUDL98wYeWWbXeueIkL9Hx03uYPPtl83SM9ex1auz+D
MD64/jd44py775o2Y83dFzKjN4x9seXOl7dkPUmUSkKohMSyGGgMg1SM20AmnmMoRoUj48leaqL3
oDjmtTEkszFL3ea19Uoae4So/3KshbNYnGgcxsygMctgD2MqogupYUhn7lCysZxxu3I2eYB/lCBk
Kjs++m3OBs4bxEU1QylheoaN0dV/6LV7Xz/qqjS/I+XSgf4r3Ursavfk2HXc9e4F/tmxW/y3h9b5
7w097t7q3+//u/vzyLmI4xL30+5tbm5gz5ki6REaa5lG9ZEg7QQfH6dKog7abXhIcR4eh/PwOKzh
MYVxNTLm1TNmzuXqGfPqGcEItnVXUjb0onJuJ8g5DaPjGkbHNYyON9tyGG1TbMS2IdkNo0H8ZLE5
i8s5VeWi+NmHikEniWVOtEcjYkSzjRfjxoasGTSY9KssplIHUgQIbLcx71QCMzRVHf+Ltrlvmz7h
9nH9cf99C3ZdwNIb60/fesu/nn31A/L2C0tvatt62+3P4AnyLTdcueLPi0zeuvlY9+dPsPx4+m/p
b9JfpNt/eYCrfGLXoSfXgeghaA+In9V8gu0SD1AivIBESU/EGp6rwSJvIDWgdiJCvUzP6LLe5MVU
ioAtzNaekamjX4WLg7DnyJEjXMORIxdeOnIEkUwXQkID2FgSspAZQwpA9/0uz2lxIQfr8/KFPJjX
4DzTSeRzNpTJ9OvsLT+oCw3VRKPx19l7z2qZxKRl4ouZokGzvNyaQ05T3o2aAWgwaFaeBugt2jC0
HEnN2V2HLVaZGT3fdGSB7xhtEyq+GpjkYVJEYHGpXCbP1s3RN8lruQ3yW8Ib4kH5jGzUCQ24joyT
5xhT8r9N/zb/26LnTbyZt3BGg17geZPZohMlyQSwTjRJGCHoRrEyZ19EMjmhiHAczXPRPC7Cm5xw
lz4kCLqQyImdZJGiRzrTlwrBhOzFRmBhRsVuiqBZEjd+HH+U/4TnNvCY78RYMY4zHZQ+MXEbTNhE
r2WrdFQiK6QWiUi/sL73JxUZfBDg5wWE8Pvk06eRt7bGf7r2ZA3dcjhNHe7a/ncy6zsC7aN6jXzo
kOXQoTWCmoImMiplnDAqFbpqikorU+o7eCunk/ZmzoCR8J3K35csbvwZYy73ieEKHOPonjmXKBYl
jlT8D6n/6JWuJ555H//rsZGFwQph7w8j8f70cDIFb9xz4333Un1mI+heXwKW2pjevm0PRR2lJ/W6
8/zIWF3suliz/m69ONe/TFikbzbeJdxlFIvdes5bXBJyF+hBIp/Kw+RTP3WHK946vd5hD5WU9OyJ
ggUhmPpwKGRDOi/cm87d683ja17gXSZ2r6HOmxBNVCESwRhX4pRli3bKrkWRLrGooyMVGVKJTopw
4sR4t3a7a+tau3JdPGEK0nZNBtqaiaKpibZl8veCMf5EUzdoingowpzOkazH+RyTIgzIept/6GD4
qAKi6n82MJ9zY3LQVG/On9xY00WdAGPY9WjVi6R+LjogIQALpbvxVKAlbXR/FjOHEvM9V9iieS4i
C4nhaLnqXkzEwMIvr6IMlcIbSWLL283XzV61fnLLb9alf4EvWTngilEj73w6/Re84JrEsCkDJz68
Lr1N2NuwZ9Y1L1YU72+ZvaOpLzfe5r5u9OULe57fLJkGzB85/ua+VFu/LvOFsFw4DvjStXMGmVdA
sKrIsmc9pUyjUASVm2egRWhpQQu6u2ADelx4hXvBvIfrML9pPoZOFvy7wGaxF9gKCrgSsYetJBgJ
X2quc0521fnmCPMLbrXfa3+ce8zyeHALfp5ssf3R4kBO5Jedsp+nG2BtPaqZGhDpUS1bEeYDjpCJ
C4R4vZywXoESEZDX/rBHW0SPtoie7CIa6jyJiA4DB2WX5jodW3mdLzRjKrMaYHHYgsDa0MMRqjll
Y9MORXS/BWymJdgj8rHCIphie1FFOU9PGYCUIi6nncopvuO1S9Kvf3Y6/acntuNhr32Iew06UPHa
L7b+beqCz1c/9ykhfb8+/xt8wx8+w5N2nHi79+YHn01//cC+9Jet+6l/7mmQHVOAKq2oAPdW7JEw
HqZTaccmh6xI5+mG4933qzQcD9PH1OMwcwXrGcLqDWz/zstyGMozzuwPF8jaJMmGrO9GVpUBQHn5
v0b5bzWU/05D+dDPoHz2srEbnvctG3az0p8LSDpRJ+h4HS/6vH4vEY0GoFADJ7rcTrfDzYkBzhPF
dgtEXl0wit0GWxSxswQl8FmJGylNeNweN9ivBCgiHi3PetzBuI0+jb9/ZcodDUubx9zywJFV6R24
+oEX+o4Y/cj1Y7al3xH2ugquvDZ99NBL6fTW6eXb+vcd8eWLn39bQt/meBa4JP0HAiP6teIShZBO
J0mI4+mCGPQhI9JJFB+dsr1SmshdETFEzMTgN/N6kpOcmn80x0j0/w+MRK//DxzFNOjqLMZmJ3i0
xlQaR589+RMu0rdM3WtXw7N80YWnueSFP3J3C3u3pWtfTZu3wYi2wLOugmfVo1eVQvas6yWce1x4
1CcjJGIkxG/8//l8ilHdNc6yjvRPns4waOp/fLqTqi1PNcwfP9kW7qMLn5FU1zj6VAO3dV1HudYB
iFbCU3G4tp1ow+Y0gEjZ8XMADDFn1aXvc1SFNBiqCiaVQDgAclXPq0THqmbhXXV0tgjdYmwfcAnb
amyvqFTT3mVq2qOnmsbialoQUlOvX92aLDHLlRFhg7Bd4LgI6Dvr0WaUQnwpUtA49Ak6gwR7BDI3
IE5QHdF0ar3ZKf+nNuVfaVN+TpFVZYlN+bP8ew15xDlsan1bC2hEjQ2Ll9R05VQN6qFmjE/7VNgq
bAdeo7oEnVeqP5TAvAroXsWECc+FBKSLUB2KvLRTIrkZ5jQM4XIYwv3XFHDuJ3xF/Dm+8nmjivAU
GSg6bHyN/AHG+W+K1Y8iJFphnDJX2q4rMapLSADo5p/WwRQxrNRZzDamxsLcASDQndkeFDLZabFg
NXF6hIlOb7QgnZ4YjCJ9CqNMR26Eke+itYwyolsI2ef7Tnu+Cx3dzklQj03twYPysWMH6WZRMqnO
NtLOTYQltlwiizkW8ywWWKwDnVuJUYgwSuJEOlfEclHnNrBY0lRyHZ3GMNvtErApYrBXWlkkmDiE
LcDLdMDU6IPT1hjAGtlH6pAdyaROMWdJVtQWhTWLqP8nebb0LGPqtTU16sM05uGOevQroKxAxKpz
koCOX25abfodTKXpctPlVq4nHzf3stRzV/PLzTdZ1ph1RiLoqs39LWPJKG64pOhGm4daDI+Sx7iN
0kbdFu4lSbQTq8VSJhCnIBCdyWwuE3QA6kzjreOxAkq+TqcHU8dstlhkuk5N9hY7se8lW4BA+7YJ
EV0n7rvTpDdo5lDW5lH0dYaIYlphxMa98NgWbIS6pBMSK0ZDDHmmNWLi1kRNIBSxLpKx3EnqdkeE
JqFFAJokW9ptg4DIfPQYUmONt4uiKbMU4Mqfd3myESyH2ppupy79YE9QC2LN7cyAgKRvGcoZCvW/
QiZgNbrMe2BrvscMhFEpE5T1uGhE0M2j73ZYDLQwu5f07q5otaVXlO0n7aqqtpRXMXBnb8jN7hkl
G8DSQIsbqWGOKL2DmPX0r8JRW8yGY9j2KC7CV5e5ff3wNCzsS9dtT9cLe89/88Bl457gLvwwkn/7
fD/+xPkI5Q5Pgh4TprIEf9rOeTWTVafZDG11diM7zeFwVeq8Jjdzd57qyAJnlZjNNnSSzsRisOwi
kg5sPB2ROE6n5wnRSzqeA03ofE4T4vI0IU7L3wmcRhQFjSEKlGAYpxRUWga9RfEzgmqMGHHEOM7Y
ZFxkbDEKRl2+XMtKughmB1DMMOT/Tr7xWfn2U+XIMCiP/yYbkzUMH8CgZHxX7lK5L9CSnboEwWzk
GTKofIGeCDux22Sr1EUgQmx7p28ZVYVgpTt0yshqmMKDu0ZW65RyFSyvlgp97ATZLh+A5SpIc2Pq
uTJjrFqyOCE46PXZXQ4AC1SwAEAXBb/b4cpiiXb0hVG1iigVGPguYMiTb3Jk75sX0oAWK/kVgBIt
51voy9NgOXwkvIssKIArlFF+K3bKTmfAEwjwvMw7jR5jgN/q2WV5w8J5PN4AiRQotrGOsR7FXy/U
6yfLk2zTHFM807x1/smBez2PEdkX4jh7yKh3dVOHXXlI4NLU4V11rkQE9Jhf5x3ZkgDX6PJJmoiU
qPubLppE/YN03STNSSnRBWVMVfK3FOACqybYrBqKWHPmhTVBMSN3mitrZzjqkJjHN33BGRe1G83e
aMwhw+gfH/ECu8Mho2g5TzVbZnhUyaiiHNkqCdgdaAZei/u/jUe+0pHedeBoeu+W3+GCP/0FB27+
8oHfp/9EDuMF+KnX0i98+El6887f4Sm/Tn+bPoorcaAdG3+R/ixrc/BdQKtm5MWSEpplm+8ko+RR
zqvlq5280RQCRos8XlUHtHeb8J89mtFeZ0/o9sH0q14AS52OWdo6OSu0zip2Og86f8SP4ef3mrUZ
NWszas6pCub/V2Xyp6qyL19juGh9L1anPDvdmq7cVaOa2MyaoOfGSTRqAzhnSJCeD46+/sGGr9Jv
pdfiW/c/3Xhl37vT9wh7LfZZuxbsS3d1vcrhdSum3uUyw8w+A1wQjGvkRYVknBK1Gy3Y3j84JXyd
bkEYjFYmlFkssbgIiJVhITsYRgGTBhg1wN6Z+bTd7q+E9Ex7YXGljV4XFFfK2dSaTaH8z+0FCbUc
6svZlJYrlwMQt1wRvCIywTg1uCC4RH+T5WbrKsNa6yPmrdZO6ynLF1YZcD5iszptNqvNatLbAyTq
dxtEOz1LJnj1erfH7wt5fp05mGeBHlRcdBk8HhQtZPji9VqtFl2oG9J0377JUunOulDC8qSonTQV
tRUW6batj3l8ROblaYwULSpqKeKKCr3kJ3s1ObTx/rdoI/5HHh0btOXnbJAspfpOerNWrHqUnmFP
MknfnqguZSfH1INjQu7Ubd4HZbVuxaBTrNVWeaDNPpCyUryYyWoLcGS/r9oGPNsOwaIEq+VCJ4Qw
hBwTbshzAoHd64hxfQhgaIxhK9vgiT5DWg+9c8vh46N7TLoyc/a1STdM7h0d9Vf8zKqNYx55Ll0m
7B37u5uffK8gXjRmWXox7nv3ugFGqWsZV1F186Vz2JnOqZkv+H8Ix1EZ5xxiQ8V5uwmJPDi3Fw4r
IGfXxKcBfgCGhFk9c56X0JQHG/PgYB4c0GCwHbzZJSYagFVA6VE3g5vBN3NLeT5e3I+rDg7jLpeu
LBgRHl40sngC1yBNLZjc4x6HJUY91BQdijQgrgEJDSjWgBjDFLWyCsQ1IKEBxdSqGkmhHuZEESni
iuP9rZWx4fERpVMidbFJ8euN88zzLdc5Z3lvNt5ivsV6u7ysqDm+mms13mNutd4nryq6K/6geaN1
oyuUVfh7RxP2QMKvT/TECYR6+u18ed8EmgWMxNz75sA9ARKIu829Q8VxHBfcQs6NI4R660MhN8eE
RtJmr26EkE0a2Tm00tPqN6D0jhdZzEYhGiwIBXSSyHNExPGiQsgDgz/Q269QqlgPXPm0G/Vmvjam
Ksk4gsfhJrwIb8Ai7sQpxdQ7FHE4hk6iHQuUSM30ig4FnuAKfbcNW30exesvbtjqE6gn7kkFrsVC
JvWkz8OIsqe/PKptSEU12o5q+7AwRzhhpzodvcuu0bQ9tyNgn0hJ39d3xtXZtw9OJukpiaxfT5Ow
zLlHD6/KYPHSt5mSZ+lMAdlSVz31tTaA0k3PAWsfnH/BaNhRFSIV5VknUxF790g9vJd1CbqcHjfv
YTQqgtxOTN1tnva72xe+PGHc1EHp66+aO/uObx567vvVwl7rtq2pZ6oH4PfrW25Zff6pN9P/fgz/
Sb7hvslDm4ePmB3zTE9WPTdr4W9mzn1npeXe+1dePbaiYn6PQTuXLzvavPRLeNgykOB72Y7ThXZR
Y4uSBoiaj0P6v/o4RM3HIf1ffBzAYwUSAvRB7D/S9J2kuT2ibqDsFiOYlNK9cox34uxJ7VOKkfFi
XZYRf6NZ+J9qHPmCxoHTqlVJW9Tteizf2GevqnWdbPxcZm91qN7DPAcFPdzG3ihxpAv41nRAMG/b
9sO/6R4e6J+FMD9OHFYMCWs9X697S8e7KXq7QZ2v5AfpRvJX6JZbXxROWSUTIjZ6FFrUO7sJLmce
Gjs1wdVe50wQzcIgOQuDMH8LPRSoWhikMeLGEfc4N2lyL3K3uDn3f9R6dtWZExEDNmj2kSGSPZOo
SjGDhvGGnBQz8FmLXZVihpwUMzS6qKVxUYqpvtfRMuiU+drPafV1oCRqxBW2rJLZDzR69bCAjW96
bWb6/Lu/T/+w6LVLt93+3i5h74UdH6UvPHc/Nn/Jjb3QdmDnta9h9ibJFZlTfJAfjHqgKvKx0ktv
1pf4zP6SnuaSErDnXVWBgSWXlzSaG0vmmeeWNJW1mlf3fNz9hH+r2dVD20woZm+ZUOhF38s9dvn2
9TjkO9rjD66PeuiGu3GIsgkbfUy7/eK+Yj860WMpFPaEvcleJZXVfHWvy/nLetXpGpLX6eYml5vW
mN4yfW/+PmmrqrRgXi4tqvSUR53eaT0X9iQ9g6WWWst6yyZLxiJssmy3fG3hLPu0DdbddRZT9h2r
v2tvXZ1VnPS8voWdW7CI9FyDJZH1oFu8bGl31lksQc7TSV5u9/ZSpRVoJr0MhqGTvA87g0EJ5Z4F
jSg2lAc5Y8/p8nQESJfvZ7i4MYwyF7JKtRFMCkY38WgRRb6sYPuninxFPMWKInrMh54bKqK6I51P
AD6kdAgQG3CRRnVFneRqxVKs0DPRkURZYntCqKa6KOWwIPDeU4F9wNKzGn6ibzWzhUOxyrLqg9Vk
czWu9tAzj7Rxj07zpXji3sJS7fhuqcbTS1WEV2x1pUUHxKMiCYu1IhGd2pFiZ+4EsNpOnzrRwpRC
dvJJ9DJtkJ2IEpnWL1qYZsjOjoh9B+RUfYrui1Ven0yCdZ1kb9OcztECswSSn31G8f8kiAK4PKm+
2ZC7ebEqSbXT3IjpcexMJVocp0ydsfwq9u1XWawerBxMmAxwu+jbprEEJ0oWop5hgEpczcw987bv
v7T5sn7zP5iNK0asXXFzQcp7w7F71r48TtZ7CvcHPdceWji1fMHcOc8mCu6aNPKVVWNWjnFazP6i
uOGG3pc0LPYuvneUMv2KPjedOb/qkgH4ox5Bucfo0suarh57yY2UBlcDDVLfC32b6wvlViyYrEVC
P2GEINSGU2ESDhcGK4JDg4vCG8LiQEeNu8Z/pftKf6Ou0VxvbXRf45+nu948x3qD+wb/wfD7pg88
H/g+dfzT80/f3wpOhDNhX0QotZY6y4RaqyJcaR0nXCd8UPC//A+ySXZZeJGgQFCUsMEVtBjpRu9F
NdGbpxHkNn2Vwjpv0TEjlo2KscnYYuTVXSsjozejN+tVPafZQGcYHhu118iM9HQnXXYjUx4oGhiX
YhvJIpBNRaBddbYKZNeMct6sGeW86qVh0qaC07CTy54y99VxcUIOYtB4NuMUPoP5MK7FYzGHqYii
BIfpUbkCShqYYSZmPB/bKWZihpmY+kgpSbCqbjpk7KXjxWzLGvtCl1Z1Y88U6ZbUjKYeIJYHqgvz
BuUhM8VW+LEtYYqRoJYsQYujMWDXoHuAySqjWGExB6rHxXNevV/qWLLj2u2LlfQ3v9o/n1ROemD5
qy8sW/6qsLfrf9ePXX+4Of11+r2n8MYDk+498vaxN9g7iOMyp7jTwMn9pHQ/mHZntCMpBu1Iil4D
rBogawCd+Dz3ulJpWWHFVsrQxqFFiEO8PWiUvEHeiC0uSUdnUGIzKLGTWJJMZ1BidHjk3TeYWisf
aiyngZ2z0ptwODjMMcwzwTHB0+Ro8jxBnuAeNz8vP+836cw+wzwyl5snLDMtMreYXzTt1O8y7DSZ
3KbVpr8RzlI4zbrQusLKWTEwZiVRxnZUmmBYG9BmdAKdQXpktRrRxTEGYejshT8Nea055LXWWYss
OiYBCgOIeYjP5nHrr3LVUJExGcYYYYwVS1I1YJQslmIlO2u4v8oXI5DFsEdhqHMZQxg/Q5jLgy6N
nbo0hHVl2Wm0zlV0VMJhqVYikoX5sAy0AYnJSUk7mi+ZskQh9Q1UHsqpVypyXeSdjUuyf1LIzu4P
aIDSJWfpCYUl2slPW3Wp3HgSfkxPBjTUXjnAHvUAcSV71TCnC1N85Gp2FHz9yw/S3y758p5tH4a3
+1ZMWfvy83fPux+v8uw+iguw4VVMVm5/JjD/+tePv/fancDNRgIefqKeU8GfKbcZCG+OmyvNw81C
P2e/4GQy0TDeOSE4m8wUZulnOJuCB8PvCn90fOT7zPGZ82vPP3yfMa7lDoeTfsrqRvkp35P6kCJz
H/dA0s88iowwj3ReHpxsqDPPNn8mfuH+AZ+1yNjFWYyyFbiZUbIhYGccsDNDN3aWO6XlrcBon4YU
wGLiNms3zmf9WeQpqrPGZfmYDcs2xdZka7EB76OIr3JAm52yGBvTECgvtImUTGyMI9qYmklX2Gah
K2zT9mps2p6MbZ82OmB+S+263GsZ2jscKs7sqrMXSZqlTn1QFI8G1R2QjkqfSBmJp7g0VuKkECNI
JnilkEqoDL+Y8iP5GX75QpXj8ngZNZaYdpljXyyzhtlhwNNqTmYVTxouMjO6/xDtRwUrSFYVf4Cx
4fwTrANmHVrxx2Xz3r2raWNpe1fk1WXLX9hy603PrH563fnnNmGu9aohxPLDSGJ/5/Bv3vjgnUNU
Io4CiRgCXuYCHNqneMIo6CKTuEahUT/JOIubLyzUzzLqXOob9GwWTyrjKVQQZO8Q2N8XfnCe8/N9
7QN9fYND7KP9Q4JX2af6xgen2xf4pwdvEm9ynSPnvDJyY6vZ4xnnpko+5w5aN8ibZSLLfCBokNBe
8jKlJU12HFTY4snAEx52AJ+hRzvO/J9fRW6v8yhmUOKY/m/WXk4yU7WUroGZNqovLqlMmbHZH6b7
yfFEJU13U0UtjMPufZoKuavOXZHj1xcPgeg06SgXSUpRSaWGARriZNmIkqyjm5M5pAgypFCZTpCh
A3thgSJFdwHXmGRutJOQBwhybnG+EUK3nrNnR2u6Ftdkz1lmjzxRtWuJxllU/7dTijL7BEfZCwwi
d83eXl/t+TL9NXZ++EdswRdOGdpWzVjX9QG5yjSg7p7btuI6z3MdOAwi3IR7pD9Ofy9Htu+dgx9e
PWzOi8C9HYAqLcJx5MH9lZBTj62+Ul+ZT/Et8j1hetK81azzm3uYU76DPt5HJzrsD1cW6MycyRo0
YBdJOh08JyLDJid2ZhzqhO6ucyj8xWNIGhl6sidsjKAg84gjD2K2tdTed0Al22JKBsOVGxD2KZTw
fYoZCB85mfncg5nPhZQVoF5Zw/mbrCvTmXVl/p1pHGwDmr39DGYiex0FPef17cd7URSdwwbkTSbP
5VMmdW2eBbOakefp5OlGal/XsJdhq+k7z8NuBoPHJuolUQe6uqy3B5BNtAZwEqv/xQKEu6TCFutX
Qf9/BegW2D7l+i76plnbpk0O/13Lr5waGFA+fvjRo9zj6xbPrxw52f6UYWTTtesusHMaaxDiTjEb
fcse5IbZcHkq6TuTCtPu43w/bgS318yzLJfHV+nR2Uw2JydgZA0KktNoMHXjuaY8+jFp/FcprjPF
9UpF/8qMHh/UYzdjuG6FHZHqwWInnVs9NaVs7LAUUxL1flpPTz1+jO3qme2np3Y2Uzrp8Sp2fW4X
230fw1wLnsr+lSn3GTdZ5N7sTrkzbt5NnBoOODWKcmro4YyrG4wyDO8M/ROICDoGCgnPNjWyjuwf
FA9be1V11dFR5bYZf1AVTETYYhOm1o5xXTrOmy/VFyezRyYAytM68/5boEZVLquxXV11i2iR4hbR
FMBmHaw3ort/K1GSvgBfoeqcbrfLFrMx5iy6bGs67ji4/JejOpbNH3dfDSiY3zzY+PyTXdPIM2tu
nXD/7V37KD9eCwteQ8/kIAl/004MuRMimgmYO6UMwBBf1g11Ic8ovggLeTCvwR11xKjp8xogaoAE
QK7Rrjx97SIs5MG8BkOjfHb1OA0QNUACIG+k5pzD7CIs5MF8zpSvqtP3p+s4Vr9Bv1mf0h/Uf6I/
o5eQPqxfpG/Rb8pmndBn9IawHlRIiSecXuT2ZQ5mWyip4+7ASBRE3iBKcQHxm/jNfIo/yJ/gxYP8
GZ4gPsIfgyueV+0RMonPoRLPUIk30CHwjIXwGgvhNV8cT60XA0Urfozuxwi1pIa9ywpok8z9kxDl
2UvyvaXdP+zcPODO2o6ODv4fR4+ed/GJ8x8Ayt8FeFHFzmot6o4VOVfkz+DAj9Y6V/VnVvZHK5jX
6k/Wa3edwJaFncqqGqCezqrsp6ZlfdW0UD29pcSBV1mFsLBJ+ETgx0J0RuDCwiKhRcgIPFCygXAq
cdOWGJG7gE9uQvgg2Bskn9K/u0jpBXmUzpYny+F1Wfau+UkzGc1zml0kNIbvvkh0lai7I3uii139
+ENp+a4OdriL8WIxAfpSjLtkyIPIkcdK5W47vhdhWx5ckDefwTw4kAf78+CCvHcignlwIA/258Gm
PJ+sOQ+25MHWPJiOX4PlPNieB9vyYEeeGMkXKfY82JYHm7MnBXTakQEQBH9WRhvNlXH+JH9S/1fP
ZxHhj8K5CPHoIjG9NxDRc1wsFBRdQVhQCYsxv082HIvjDfHNcRL3ePyW+AYbtvHMAvAy7Z/59pgF
4GQvK7G/CKFIYSPMDjAxO4B59WzaJvhFa6ATN7Z7dT/ZLs26Qsx13viGAA6wngK5ngKspwA9AGej
PQWYoAkwmxJy06roC5honwHNkxiArnYhUhHTOolpAi+WVXqcdbE4PoYwNbdJGNWisSAGaHMqvrOD
dEjW9vTpXwxl5dsFTa85qziZoFORnakHyFcU78Q3tUcp4ifHnM3HfHXj9mSek6Ux/9A9ve4aM2LW
8M8Xg2kLKg/wstH0Py9s7P8/qjUxaHI6Ek6TLYDtZpcmBjXN9D8xOvqiPdsR8rDX8piUZGpRvrx8
pvzFecsfCd9x+OmX22NTBy96qKN+5pUrB/KJh8dMu7Z+7/ZdXcXkqeunDXz4+a5HSNtNN417/IGu
9zWN6XOgUjc+pDgETnSQLXKn/DfuC8cZ7pxD5OkZiELAwptl/Kh8zHvCm/HyEZ3T4nTbQWPCotts
MFtMlm5qkyWPji05tSlYZynyMi3JyzQmI9OVjExXMuZ0JSPjVcZCVoM55JizjulKcP191kFnyHru
zqnnQIxMHTNi+BnHeClv9FO9yXvGSxZ5N3tT3oNe3suRCpdbwyS3hltuTYdyM+56rsNmyx4f/Vl1
yfAjdcmWpy7xWV56ULH/WP0a42GvuOU+qgJ1lqlQ3QqS6hsc6r9i1taevqhDuUWb3qAzSAZOlBNg
yQew1WDPIhE9u74Y9KnFDFmynuM8TFnz7LKPmp4ZJxs6SuZf1vwSn3hk+4hFo8tv72omq29YMOTB
d7r2gwgZDhZuMeCCGfnwx7tc7D9RHHRHhb3eS7nFLAr5WIFdMvhMl4qX6erEBt1sca5OVykPtA90
9/OOkEfZR7lHeKcKU/Xj5UZ7o3u8d4GwQD9TXmBf4J7pvRG79KJgvpqbKEw0XG26npslzDJcbzJ4
grxkA25GN8zyN8nO5m2eaa/eynXOogDzcQQYMkm5v2+SmHcj6/7TnL0MyB7EUl9Rzh7WYgAY0UXx
yjIJI0mWImCo5jYpqIPrE+Bq7Hg0NX8BtmgoZNEwx5J1ng0BDEcmC7Wu2FtGiHkiUZChDLNrs4yG
MVrEXspHCnRNORhBmnPt4l96mbIOFtTXT03g7D955eMJGMCN55KNjd2xRzv5RR0h9IiIfoIwQX+t
cK2ex40N7MSdg72Yj7Kv6ee/3Dv8+Xt++xfsvvUf936SPr2nbc3qtvZVa9qIAxffvzz9164j/7gT
h7D5nbff+Z/fvn2Y6uFr0nP5KGCNHYXwy8pSk9xbvkQeJfO1kVSEhCM9TbGCcld5wdCCRZENEd1A
z8DAFZ4rAg26q01TPVMD83TzTXPlBZ75gYOR486PvB/5j4dOOk+GTkQyEXeMT8pJVz9+oDySv0Ke
In9m/EdBWjbaLJw7SDcIRHfQYkQWXzeE8eUhjC+HMME6X9ExA5YNiqHJ0GLgIwxtIkp2E/Rzxcj2
Rb3apijbLMg/yKxuFhgoFVjZBulS7KggFRfdYhpDyfrHFF+dPY7Qz/v+NZe/nOfyl7u5/M/92OXP
tihBbjCXf/jSKi/u5vPPufyTZ0/+1NvP3P226nxnv0MTK26Xk70nW2zj8tBgzfMDH5yz9ti8ZZ/c
OmV9H9uLy2965aWlzTvSc4VftV511brMo8+lz9975cCu89zzRw69/ce3D/+JSpNVgBBvAC7Y0BfK
oFIHlnkc4yv5YfwE/jp+KS/qbTq9Tm922PRmxOmwkS0iMuh7bNBhXWHEgR2k8Mf/eQdA7u/w/rN1
m9N5v1NseexaZLTXTe6rBq56jFKnGrj2Sw/9nIF7Um48u4S+KkLnrlr7jx0kv0X/q5bO5BL6xpA6
jaqrQgJeu+rZwXNrr75m8NChg65xhvjEM4svG/hS8aW1TUu63qVzVJs5xe2AOSrjPm/PuXVy+pQP
gCH/X3vXHtxGccZ3784n3Ut7utPrTpIty7KkWCJ2/ERgajl1EieBOI6JgoNDeDMxoSUEaAFD4yE0
0IZQoA0xk/JIGAYonQTskBAe8dA0UPog7Qx0Snl1eJR2Jp20E/4AxnZ39+5kGWin/Zc5/ea7+07a
u5N2v9vd79vv+9RBhTdbIciVQdzpOelEZvlUBV9XwScr+NoKPlEemkdKXDKQPENYJvSkSsnLkiPC
DmFr6lH9Z/mXWEUIm5Fw0/L8G+GqKLOaYdRmKEaGvEPCkDgkDclDyrB3WBgWh6VheViZSE9kEPFj
Sc1rT60VB6VL05dmr627NrUlda+4W74ne1/+J02PiI/LezOPZMfTv0yHss6EN+kwdQ6Tcpis5bho
lyFMncOkHCZOfJC16sJab6ZeFjkzkQ5y0vy4SRZokkaeGsWNLqPPWG/sM14zeGTUGN823jW4GuMu
gzFewGISxNJLravFACmukhgAFR6HDIAqJIGKk+OBUCu1uqo+fyuE84fiG+NMPBb0cNbyOFWKP3IU
34+KOpE1LjZfqjGhmTKKeqS1mZzeTI1yEWtL+gCD5sA0EuRMI0HOMuhStEFNoOTTbsHq0ZjzZ/MH
jZc8qQaSbDtWON4AG8ityWUaHA9IypDLNFj5kDDznNPo46UGk36X2kxD64XNk81MV/OWZqaZmJJT
IGLNoOnjkbCaAfeWhCHfkDAHyZdM2CNfqJRIIdqBIfpDUIJmJCETpADNVkJ9n61kS8gKES/6Syj5
LoBk7s4AY4Ft41236ZzKSFbc/+dOXLPCWXjP5TYRS2/FtPsEWUrKkQyCm+iqO9FLiScW2Vnr7vay
O55BFTOnVddVBfJpv6qpusrySSURBULWE4VVp+FNdQAf1vrqoiBZp8jeeWIUZjOCyOe4KKhR42Su
lZtNJm0nkR4dHS0nJc6RJSwS/lB+gxQqp0zKpDPzmbbW9o4vOXiF7fzSpB/uehrdcdPId9vq7z02
1td9esPdAze/sNa/X968YWQ4FGqMbj1yX2nDsZtf+xM8K3blNZf1nFUXqW9eOrpiyQ3ZmlzvTVdE
Vg2t6qiLxXUx1dI9MrT2wTVP4l4nNfMvpqFqDIRhoTsxR/uVKnhvBe+p4PkKXiQRZ+lWgchOCjNb
DAigrIiQBSFVyCERj8qshNQkSELlK4ZHO0gmiYdHGc54vIuERRd6rvZs8fzIwwE8/XrIs98z6Tnu
4aljve1hf4rKs4f4fNGVXksrtRnb5/4zKptkYkcGc8zx9vzOmsB6DjPDIALbn7r8C2YNmuHXMmh+
cKqTrieRDPRYeFpa1F9VON7Wh601JWKh9nfQ/GfUuYlRzbM7L96Y37p1/MABPZetfvhB9RuX7WEu
2Q49G6fv3D517zl5k1imcM//Hvl3arjzWWCSZZZguJVJ6CHiUH2yaGiB1pwOU149JEM9JOGB0Y9r
ErSE5mhXoYq5TqhCuwrVR8JEDTKpjhWm2lVYI7UVLjv1hOmoGC7rVWGqV4XLNugwVcTDRK9SSOXN
hOFkGIZXmNTLjahU5kmTudp8yNxvzpgcWSm3mld2mle2xupxYiMvD9Uk5W1COC68J3CCM1QL5aHa
No+L1ChObk1HaIHqVAI1QQsrjDnGKNvO/GXlyRq2qXdDZ8HO04QffJNTfQpSGN6KD8YKFCdHgeL1
RwFRnxoaRvGsCJ9pLxpm0tRvLUwf23bCs10jr1+wt0+VJiT/t/r7d5w5sXui96q+ts3MPVPjdy5Y
0j9w1+1M4fM3yfiOsEb9T9zKKiM9D5TZFV2yPvbiXFdWK7Cx0skhiKDEc4zAM7wiAhHRIQI1kilc
VxcNIo8eRBpESaPAkwFvpVFYi3ZyO71jvvvRZNUkP+n5NRJQMVQwWV0IKqbaBs+QRuEOyduoreEG
PYPSeb774C5xl3SQOSS/Ir3q+436Jvu68Hvlz+qHoqbZXsSSDDQ/iihYOog/fdFHOMQDRgGiyPA0
rohMhnKWvTZavJznWY9XECDPC1UcizsAhOtcgQgpqoSbn1EkVlZFHjFIVI+BYwKj2v8/wTLKMQUq
9TIbkGVWFASWZXg88sgyEPs0qC1VbpGTIrqIF24piodg9GCRX8lvoQk5vln0JdhbmGQfrval/pGj
diZW05haN2VGTqgfqqdO0LDO2b+XoFms7Zi4dXbuuwJC27xHt/nUo9YW7zw+tbPT2zlopVGc8EXi
BYkGOcULcjJcYDGR46drCyp1sQwWYLK2IBRjjmd9bpAq5MTjl2ZJa4GwJUwC4TowV8dmIIJbp8f+
snd+LF8//sfpu+EP337zjOm/MVk4/emSpoUtn0/LU7+Dywan1xGZqp3uZ/+BZcpk9o7jVnFWf8pe
HI5Rw2EQpygv2vbGU45fvc/5VHEYuVy+rMo6jOIw5IF2LlUWW/kLYhsXA4iV2JiBNF7i9aKGElJR
TtjiazTmzLfNyG9NQyU7OqGmY3J0HMUgIvW6OVbIBkpon8gWlSKWkUS2qVUlG48saCElomWkjJxR
2uV2pc035peyWlbvDQ1qg/pgcIO2Qd8QvIG/XrnBf2PgxuBtyg/827Xt+h2BXeJj0vPqc/7Dgb+L
fw18okypnwZmYtWaHvH5Fq62RT2kS7Eoh3rQVsQio/wjrGm/5fxOBLwDIVn1a5oIWCOg6/WaGMAH
SEZ+uV4S8YRR1IkzqcSTC4CYGmMaY0diTOwQ03UA4RopBg4x5xalLq2oMeu1IxqjHYILn0EwCRZF
RfIRrbNiQm6S+2R2pTwjM7gBFo43IlxDTNdENDGCBy5chVMkZQyWbhL4GVFPfWCQTNMnzIh6gnIg
QgYwR9S9JH9MFZZ1ny3r26hgH12+3zewfH9kNtjzOSuaYuZjkl52MOekDw3MvPNMR0FMdhR8uBs4
ECz47eCRQdLvgk3rHPmefYGcnrH8FDFgi06EXm+BJE1xXfJ7gTPznb1hf7pKmr7qpbdzyZrc+xPT
G7tTTSOl1ukrHlezqeiVKM5lp8auGx25nrny81f2LRwcIP/P2T4L6GGWsdUUF7BPYXzI1XI93MXc
/VXP872e67x3CY+Kz0pvyHnlCNJRdyXUH/t/rq3XM/pPA16KCYKgN/SL8KuRMWOxsdh8NHZjXIwf
+WrUjCYeqf24TkzFU2+l3qr/Q/odgsyeedc13JTbmd+d333a/MYNTbkF1QTNvS2vunDhwoULFy5c
uHDhwoULFy5cuHDhwoULFy5cuHDhwoULFy6+DECiDiBxCQABwJIdNDHxmEn3rO5lm5fG64dS/UtK
q4L6gkBbtuX8kBmJnrdyYM3ZKG9o8/q8Tf7GRb6MpIbljuXtntMX8zHw9Xhx4B665Uj9nCzMzOAt
JFt8zOFtGvSA1aAX11ozWArioB4MgRToB0tACawCQaCDBbhO20AWtIDzQQiYIAKi4DywEgyANeBs
gEAeGEAD80Af8IIm4AeNYBHwgQyQgArCQAYdYDloJ/+HBRYDHsTonTXcXgzmeHwOOPfc5raOa5ua
6Cckgq/qf/513rmHJ8HJmTlvWDKBbwPLxBRAmbjNIIhpqScOvuMQ8wQYcYh7ElyDyz2B+W68P2yf
sxrTu5g6MZUwmfZ752C6CNMAOcZln/1PVFWama4qgZ1VL4PLMT2A+T3c++Axh3CZIw7ha+3knwC7
cLndfAFcgss+QOll8DDeD+HyTZT/Pwhfcxmm72MBWIn3izEtx/fSCWF+G3wZ3F5Bt+L7byPvY+qx
99twndyGz+/C56Tw8a1lAlgmagGwnsP/8iJtw79z6Kn9+w6vR52feKNWY+55P9NA9q9t6r/gs31T
V6jA248PBact/w0LHlsUCmVuZHN0cmVhbQplbmRvYmoKMTcgMCBvYmoKMjE4OTIKZW5kb2JqCjkg
MCBvYmoKPDwvQmFzZUZvbnQvV1lYQkRaK1RUMjU4dDAwL0ZvbnREZXNjcmlwdG9yIDggMCBSL1R5
cGUvRm9udAovRmlyc3RDaGFyIDEvTGFzdENoYXIgNTMvV2lkdGhzWyA2NjcgMjc4IDI3OCA3MjIg
NjExIDYxMSA2MTEgMjc4IDU1NiA3MjIgNTU2IDM4OSA1NTYgMjc4IDU1Ngo1NTYgMzMzIDgzMyA1
NTYgNjExIDY2NyAzMzMgNTU2IDU1NiA1NTYgNzIyIDI3OCA2MTEgNjExIDYxMSA2MTEKNTU2IDU1
NiA1NTYgNTU2IDU1NiA2MTEgNTU2IDMzMyA1NTYgNTU2IDcyMiA2MTEgNzIyIDcyMiA4ODkgNzc4
CjcyMiA2MTEgNTU2IDMzMyA1ODQgMzMzXQovRW5jb2RpbmcgMTggMCBSL1N1YnR5cGUvVHJ1ZVR5
cGU+PgplbmRvYmoKMTggMCBvYmoKPDwvVHlwZS9FbmNvZGluZy9CYXNlRW5jb2RpbmcvV2luQW5z
aUVuY29kaW5nL0RpZmZlcmVuY2VzWwoxL3VuaTAwNTMvdW5pMDA0OS91bmkwMDIwL3VuaTAwNDEv
dW5pMDA2Ny91bmkwMDc1L3VuaTAwNzAvdW5pMDA2Qy91bmkwMDc5L3VuaTAwMjYvdW5pMDA2NS91
bmkwMDcyL3VuaTAwNzYvdW5pMDA2OS91bmkwMDYzL3VuaTAwNzMvdW5pMDAyRC91bmkwMDREL3Vu
aTAwNjEvdW5pMDA2RS91bmkwMDUwL3VuaTAwNzQvdW5pMDAzMS91bmkwMDMyL3VuaTAwMzMvdW5p
MDA0RS91bmkwMDJFL3VuaTAwNkYvdW5pMDA2OC91bmkwMDYyL3VuaTAwNEMvdW5pMDAzNi91bmkw
MDM1L3VuaTAwMzcvdW5pMDAzNC91bmkwMDMwL3VuaTAwNDYvdW5pMDA3OC91bmkwMDNBL3VuaTAw
MjMvdW5pMDAzOC91bmkwMDQyL3VuaTAwNjQvdW5pMDA0NC91bmkwMDQzL3VuaTAwNkQvdW5pMDA1
MS91bmkwMDU1L3VuaTAwNTQvdW5pMDAyNC91bmkwMDI4L3VuaTAwMkIvdW5pMDAyOV0+PgplbmRv
YmoKMTEgMCBvYmoKPDwvQmFzZUZvbnQvR09DU1hVK1RUMjU3dDAwL0ZvbnREZXNjcmlwdG9yIDEw
IDAgUi9UeXBlL0ZvbnQKL0ZpcnN0Q2hhciAxL0xhc3RDaGFyIDU3L1dpZHRoc1sgNzIyIDU1NiAz
MzMgNTU2IDI3OCA3NzggMjc4IDI3OCA2NjcgNTAwIDY2NyA1NTYgNTU2IDUwMCA1NTYKNTU2IDU1
NiA3MjIgNTU2IDcyMiA2NjcgNjY3IDUwMCA1NTYgNTU2IDU1NiA1NTYgNTU2IDgzMyA1NTYgMjc4
CjIyMiAyNzggMjc4IDU1NiAyNzggNjExIDIyMiA4ODkgODMzIDI3OCA1NTYgNTU2IDU4NCA3MjIg
MzMzIDMzMwo1NTYgMzMzIDYxMSA1NTYgNjY3IDU1NiA3MjIgNTAwIDU1NiA1NTZdCi9FbmNvZGlu
ZyAxOSAwIFIvU3VidHlwZS9UcnVlVHlwZT4+CmVuZG9iagoxOSAwIG9iago8PC9UeXBlL0VuY29k
aW5nL0Jhc2VFbmNvZGluZy9XaW5BbnNpRW5jb2RpbmcvRGlmZmVyZW5jZXNbCjEvdW5pMDA0My91
bmkwMDYxL3VuaTAwNzIvdW5pMDA2NS91bmkwMDIwL3VuaTAwNEYvdW5pMDA2Ni91bmkwMDNBL3Vu
aTAwNDIvdW5pMDA3OS91bmkwMDQxL3VuaTAwNkUvdW5pMDA2NC91bmkwMDczL3VuaTAwNkYvdW5p
MDAzMi91bmkwMDMwL3VuaTAwNEUvdW5pMDAzMS91bmkwMDUyL3VuaTAwNDUvdW5pMDA1MC91bmkw
MDc4L3VuaTAwMzMvdW5pMDAzNy91bmkwMDM1L3VuaTAwMzgvdW5pMDA3NS91bmkwMDZEL3VuaTAw
NzAvdW5pMDA3NC91bmkwMDY5L3VuaTAwMkMvdW5pMDA0OS91bmkwMDM2L3VuaTAwMkYvdW5pMDA0
Ni91bmkwMDZDL3VuaTAwMjUvdW5pMDA0RC91bmkwMDJFL3VuaTAwNEMvdW5pMDA2Mi91bmkwMDJC
L3VuaTAwNDQvdW5pMDAyOC91bmkwMDJEL3VuaTAwMzQvdW5pMDAyOS91bmkwMDU0L3VuaTAwNjgv
dW5pMDA1My91bmkwMDI0L3VuaTAwNTUvdW5pMDA2My91bmkwMDIzL3VuaTAwMzldPj4KZW5kb2Jq
CjggMCBvYmoKPDwvVHlwZS9Gb250RGVzY3JpcHRvci9Gb250TmFtZS9XWVhCRForVFQyNTh0MDAv
Rm9udEJCb3hbMCAtMjEwIDgyNCA3NzNdL0ZsYWdzIDQKL0FzY2VudCA3NzMKL0NhcEhlaWdodCA3
NzMKL0Rlc2NlbnQgLTIxMAovSXRhbGljQW5nbGUgMAovU3RlbVYgMTIzCi9NaXNzaW5nV2lkdGgg
NzUwCi9Gb250RmlsZTIgMTQgMCBSPj4KZW5kb2JqCjEwIDAgb2JqCjw8L1R5cGUvRm9udERlc2Ny
aXB0b3IvRm9udE5hbWUvR09DU1hVK1RUMjU3dDAwL0ZvbnRCQm94Wy0xIC0yMTAgODI3IDc4MV0v
RmxhZ3MgNAovQXNjZW50IDc4MQovQ2FwSGVpZ2h0IDc4MQovRGVzY2VudCAtMjEwCi9JdGFsaWNB
bmdsZSAwCi9TdGVtViAxMjQKL01pc3NpbmdXaWR0aCA3NTAKL0ZvbnRGaWxlMiAxNiAwIFI+Pgpl
bmRvYmoKMiAwIG9iago8PAovUHJvZHVjZXIgKEVYUCBTeXN0ZW1zIExMQyBcKHd3dy5leHAtc3lz
dGVtcy5jb21cKSkKL0NyZWF0aW9uRGF0ZSAoRDoyMDE4MTIwNzEwNTYxMSkKL01vZERhdGUgKEQ6
MjAxODEyMDcxMDU2NDgrMDYnMDAnKQovVGl0bGUgKFJlbW90ZSBEZXNrdG9wIFJlZGlyZWN0ZWQg
UHJpbnRlciBEb2MpCi9DcmVhdG9yIChQREYgcmVEaXJlY3QgdjIpCj4+CmVuZG9iagoyMCAwIG9i
ago8PAovTGVuZ3RoIDAKL0xDIC9pU1FQCj4+CnN0cmVhbQoKZW5kc3RyZWFtCmVuZG9iagp4cmVm
CjAgMjEKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAyNDkzIDAwMDAwIG4gCjAwMDAwNDU1NDkg
MDAwMDAgbiAKMDAwMDAwMjQyNCAwMDAwMCBuIAowMDAwMDAyMjQyIDAwMDAwIG4gCjAwMDAwMDAw
MTUgMDAwMDAgbiAKMDAwMDAwMjIyMiAwMDAwMCBuIAowMDAwMDAyNzExIDAwMDAwIG4gCjAwMDAw
NDUxNDUgMDAwMDAgbiAKMDAwMDA0MzM3NSAwMDAwMCBuIAowMDAwMDQ1MzQ2IDAwMDAwIG4gCjAw
MDAwNDQyMzUgMDAwMDAgbiAKMDAwMDAwMjc1MiAwMDAwMCBuIAowMDAwMDAyNzgyIDAwMDAwIG4g
CjAwMDAwMDI4MjMgMDAwMDAgbiAKMDAwMDAyMTM1NCAwMDAwMCBuIAowMDAwMDIxMzc2IDAwMDAw
IG4gCjAwMDAwNDMzNTMgMDAwMDAgbiAKMDAwMDA0MzczMSAwMDAwMCBuIAowMDAwMDQ0NjA5IDAw
MDAwIG4gCjAwMDAwNDU3NjQgMDAwMDAgbiAKdHJhaWxlcgo8PAovU2l6ZSAyMQovUm9vdCAxIDAg
UgovSW5mbyAyIDAgUgovSUQgWyAoy3mCakDsmbfcEl/zY0TE1SkgKMt5gmpA7Jm33BJf82NExNUp
IF0KPj4Kc3RhcnR4cmVmCjQ1ODI0CiUlRU9GCg==
`;

@Injectable()
export class CrmPdfStubService {
  retrieveInvoice(agvanceCutomerId: string, companyId: string, databaseId: string, invoiceNumber: string,
                  date: Date, location: string, s3FileNameGUID: string) {
    return of(base64InvoicePdf);
  }

  retrieveBooking(agvanceCustomerId: string, databaseId: string, lineItem: BookingLineItem) {
    return of(base64BookingPdf);
  }

  retrievePurchaseContract(agvanceCustomerId: string, contractNumber: string, location: string,
                           databaseId: string, s3FileNameGUID: string) {
    return of(base64ContractPdf);
  }

  retrieveStatement(agvanceCustomerId: string, databaseId: string, invoiceNumber: string, date: Date, location: string) {
    return of(base64StatementPdf);
  }

  retrieveBudgetBillingStatement(agvanceCustomerId: string, databaseId: string, invoiceNumber: string, date: Date, location: string) {
    return of(base64StatementPdf);
  }

  retrievePayment(agvanceCustomerId: string, databaseId: string, invoiceNumber: string, date: Date, location: string) {
    return of(base64PaymentPdf);
  }

  retrieveSettlement(agvanceCustomerId: string, databaseId: string, settlementNumber: string, location: string) {
    return of(base64SettlementPdf);
  }

    retrieveStatementPdfList(agvanceCustomerId: string, databaseId: string, startDate?: Date, endDate?: Date): Observable<CrmPdfInfo[]> {
    const expectedPdfInfoList: CrmPdfInfo[] = [
      {
        invoiceNumber: '100',
        location: 'Main',
        date: new Date(),
        s3FileNameGuid: ''
      },
      {
        invoiceNumber: '101',
        location: 'Main',
        date: new Date(),
        s3FileNameGuid: ''
      },
      {
        invoiceNumber: '102',
        location: 'Main',
        date: new Date(),
        s3FileNameGuid: ''
      }
    ];

    return of(expectedPdfInfoList);
  }

  retrieveBudgetBillingStatementPdfList(agvanceCustomerId: string, databaseId: string, startDate?: Date, endDate?: Date) {
    const expectedPdfInfoList: CrmPdfInfo[] = [
      {
        invoiceNumber: '200',
        location: 'East',
        date: new Date(),
        s3FileNameGuid: ''
      },
      {
        invoiceNumber: '201',
        location: 'East',
        date: new Date(),
        s3FileNameGuid: ''
      },
      {
        invoiceNumber: '202',
        location: 'North',
        date: new Date(),
        s3FileNameGuid: ''
      },
      {
        invoiceNumber: '203',
        location: 'South',
        date: new Date(),
        s3FileNameGuid: ''
      }
    ];

    return of(expectedPdfInfoList);
  }
}

