import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CognitoOperationsComponent } from './cognito-operations.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CognitoIdentityService } from './services/cognito-identity/cognito-identity.service';
import { CognitoSettingsService } from './services/cognito-settings/cognito-settings.service';
import { LoginOpsComponent } from './login-ops/login-ops.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ButtonModule, InputModule } from '@ssi/ng-stratus-7';
import { CognitoUserCreatorService } from './services/cognito-user-creator/cognito-user-creator.service';
import { GetCognitoUserPoolService } from './services/get-cognito-user-pool/get-cognito-user-pool.service';
import { ExchangeTokenService } from './services/exchange-token/exchange-token.service';

@NgModule({
  declarations: [
    CognitoOperationsComponent,
    LoginOpsComponent,
    ForgotPasswordComponent
  ],
  exports: [
    CognitoOperationsComponent
  ],
  providers: [
    {
      provide: CognitoIdentityService,
      useClass: CognitoIdentityService
    },
    {
      provide: CognitoSettingsService,
      useFactory: () => {
        if (!window.fetch) {
          throw new Error('Cognito settings service requires fetch object.');
        }
        return new CognitoSettingsService(window);
      }
    },
    {
      provide: CognitoUserCreatorService,
      deps: [GetCognitoUserPoolService],
      useFactory: (getUserPool: GetCognitoUserPoolService) => {
        return new CognitoUserCreatorService(getUserPool);
      }
    },
    {
      provide: ExchangeTokenService,
      useFactory: () => {
        if (!window.fetch) {
          throw new Error('Exchange token service requires fetch object.');
        }
        return new ExchangeTokenService(window);
      }
    },
    {
      provide: GetCognitoUserPoolService,
      deps: [CognitoSettingsService],
      useFactory: (settings: CognitoSettingsService) => {
        return new GetCognitoUserPoolService(settings);
      }
    }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    ButtonModule
  ]
})
export class CognitoOperationsModule { }
