import { Injectable } from '@angular/core';
import { CustomerMemoInvoicesParameters } from '../customer-memo-invoices-parameters';
import { HttpClientOptionsBase } from '../../../../shared/shared-services/http-client-with-storage/http-client-with-storage.service';

@Injectable()
export class MemoInvoicesOptionsGeneratorService {

  generate(params?: CustomerMemoInvoicesParameters): HttpClientOptionsBase {
    const options = new HttpClientOptionsBase();
    options.params['sort'] = '-MemoInvoiceDate';
    options.params['sort'] = '-MemoInvoiceNumber';

    if (!params) return options;

    Object.keys(params).forEach(key => {
      options.params[key] = `${params[key]}`;
    });

    return options;
  }
}
