import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HttpClientWithAuthHeadersService } from
  '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import { GrowerIdService } from '../grower-id/grower-id.service';
import { CustomerGrainAssemblyParameters } from './customer-grain-assembly-parameters';
import { Observable, from } from 'rxjs';
import { PaginatedResponse } from '../../../state/paginated-response/paginated-response.types';
import { GrainAssemblyData } from '../../../state/data/grain-assembly-data/grain-assembly-data.types';
import { HttpClientOptionsBase } from '../../../shared/shared-services/http-client-with-storage/http-client-with-storage.service';

@Injectable()
export class CustomerGrainAssemblyService {
  public static GET_GRAIN_ASSEMBLY_PATH = `${ENVIRONMENT.gatewayApiBaseUrl}/customergrainscaletickets/assemblies`;

  public constructor(
    private readonly httpClient: HttpClientWithAuthHeadersService,
    private readonly growerIdService: GrowerIdService
  ) { }

  retrieveGrainAssembliesByUrl(url: string): Observable<PaginatedResponse<GrainAssemblyData>> {
    return from(this.httpClient.get(url));
  }

  retrieveGrainAssemblies(
    agvanceCustomerId: string,
    additionalParameters?: CustomerGrainAssemblyParameters
  ): Observable<PaginatedResponse<GrainAssemblyData>> {

    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CustomerGrainAssemblyService.GET_GRAIN_ASSEMBLY_PATH}?customerId=${growerId}`;

          const options: HttpClientOptionsBase = new HttpClientOptionsBase();
          if (additionalParameters) {
            Object.keys(additionalParameters).forEach(key => {
              options.params[key] = `${additionalParameters[key]}`;
            });
          }

          return this.httpClient.get(url, options);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }
}
