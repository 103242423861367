import { Action, State, StateContext } from '@ngxs/store';
import { UpdateUserData } from './user-data.actions';
import { UserData } from './user-data.types';

export const USER_DATA_STATE = 'userData';

@State<UserData>({
  name: USER_DATA_STATE,
  defaults: new UserData()
})

export class UserDataState {
  @Action(UpdateUserData)
  updateUserData({ setState }: StateContext<UserData>, payload: UpdateUserData) {
    setState(payload.userData);
  }
}
