import { Injectable } from '@angular/core';

@Injectable()
export class ValidateStorageDataService {
  static readonly UndefinedError = 'Storage data cannot be undefined';

  validate(data: any): void {
    if (data === undefined) {
      throw new Error(ValidateStorageDataService.UndefinedError);
    }
  }
}
