import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CashBidsComponent} from './cash-bids.component';
import {DropdownModule} from '../dropdown/dropdown.module';
import {SharedPipesModule} from '../../core-services/pipes/shared-pipes.module';
import {CashBidDetailsComponent} from './cash-bid-details/cash-bid-details.component';
import {BasisPriceComponent} from './basis-price/basis-price.component';
import {MarketPriceComponent} from './market-price/market-price.component';
import {NetChangeComponent} from './net-change/net-change.component';
import {CashBidDetailsPageComponent} from './cash-bid-details-page/cash-bid-details-page.component';
import {LegalDisclaimerComponent} from './legal-disclaimer/legal-disclaimer.component';
import {CashBidsByBidCriteriaComponent} from './cash-bids-by-bid-criteria/cash-bids-by-bid-criteria.component';
import {CashBidsByLocationComponent} from './cash-bids-by-location/cash-bids-by-location.component';
import {CashBidCardComponent} from './cash-bid-card/cash-bid-card.component';
import {CommodityIconModule} from '../commodity-icon/commodity-icon.module';
import { BreadCrumbNavigationModule } from '../breadcrumb-navigation/breadcrumb-navigation.module';

@NgModule({
  imports: [CommonModule, DropdownModule, SharedPipesModule, BreadCrumbNavigationModule, CommodityIconModule],
  declarations: [
    CashBidsComponent,
    CashBidDetailsComponent,
    BasisPriceComponent,
    MarketPriceComponent,
    NetChangeComponent,
    CashBidDetailsPageComponent,
    LegalDisclaimerComponent,
    CashBidsByBidCriteriaComponent,
    CashBidsByLocationComponent,
    CashBidCardComponent,
  ],
  exports: [CashBidsComponent, CashBidDetailsComponent, CashBidDetailsPageComponent, LegalDisclaimerComponent]
})
export class CashBidsModule {
}
