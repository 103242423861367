import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { SubdomainService } from '../shared-services/subdomain/subdomain.service';

@Injectable()
export class SubdomainGuardService implements CanActivate {
  constructor(private subdomainService: SubdomainService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.subdomainService.checkValidSubdomain()
      .subscribe(
        response => {
          if (response === false) {
            this.navigateToInfo();
          }

          resolve(response);
        },
        () => {
          this.navigateToInfo();

          reject(false);
        }
      );
    });
  }

  private navigateToInfo(): void {
    this.router.navigate(['/notfound']);
  }
}
