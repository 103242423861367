import { AfterViewInit, Component, EventEmitter, Injectable, OnDestroy, OnInit, Output } from '@angular/core';
import { InputType } from '@ssi-nimbus/text-input';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export class RandomIdService {
  generateId(): string {
    return ('id_' + btoa(`${Math.random()}`) ).replace(/=/g, '');
  }
}
export class DomElementService {
  getFirstElement<T extends HTMLElement>(query: string): T {
    return document.querySelectorAll<T>(query)[0];
  }
}
export class DomElementEventService {
  getEvent<T extends HTMLInputElement>(element: T, eventName: string): Observable<Event> {
    return fromEvent(element, eventName);
  }
}

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnDestroy, AfterViewInit {
  @Output() amount = new EventEmitter<number>();
  readonly subscriptions: Subscription[] = [];
  readonly componentClass: string;
  autoFocus = true;
  inputType = InputType.Text;
  value = '';
  blurEvent: Observable<Event>;
  keyupEvent: Observable<Event>;

  constructor(private randomIdService: RandomIdService,
              private domElementService: DomElementService,
              private domElementEventService: DomElementEventService) {
    this.componentClass = this.randomIdService.generateId();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    const textInput = this.domElementService.getFirstElement<HTMLInputElement>(`.${this.componentClass} input`);
    this.blurEvent = this.domElementEventService.getEvent(textInput, 'blur');
    this.keyupEvent = this.domElementEventService.getEvent(textInput, 'keyup');

    const blurSubscription = this.blurEvent.pipe(
      map(event => event.target['value']),
      map(numStr => {
        const amount = parseFloat(numStr) || 0;
        return { amount, text: amount.toFixed(2) };
      })
    ).subscribe(
      val => {
        this.value = val.text;
        this.amount.emit(val.amount);
      }
    );

    const keyupSubscription = this.keyupEvent
      .subscribe(() => this.amount.emit(0));
    this.subscriptions.push(blurSubscription, keyupSubscription);
  }
}
