import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { MobileSidebarSubmenuComponent } from './mobile-sidebar-submenu/mobile-sidebar-submenu.component';
import {
  MobileSidebarSubmenuAccountComponent
} from './mobile-sidebar-submenu/mobile-sidebar-submenu-account/mobile-sidebar-submenu-account.component';
import { SidebarPipesModule } from './pipes/sidebar-pipes.module';
import { AccountProfileModule } from './account-profile/account-profile.module';
import { AccountPanelModule } from '../../pages/account-switch/account-panel/account-panel.module';
import { OverlayModule } from '../overlay/overlay.module';

@NgModule({
    declarations: [
      SidebarComponent,
      MobileSidebarSubmenuComponent,
      MobileSidebarSubmenuAccountComponent
    ],
    imports: [
      CommonModule,
      SidebarPipesModule,
      AccountProfileModule,
      OverlayModule,
      AccountPanelModule
    ],
    exports: [
      SidebarComponent,
      MobileSidebarSubmenuComponent,
      MobileSidebarSubmenuAccountComponent,
      SidebarPipesModule
    ]
  })
  export class SidebarModule { }
