import {GrainBalanceData} from './grain-balance-data.types';
import {Action, State, StateContext} from '@ngxs/store';
import {UpdateGrainBalanceData} from './grain-balance-data.actions';

export const GRAIN_BALANCE_DATA_STATE = 'grainBalanceData';
@State<GrainBalanceData[]>({
  name: GRAIN_BALANCE_DATA_STATE,
})

export class GrainBalanceDataState {

  @Action(UpdateGrainBalanceData)
  updateGrainBalanceData({ setState }: StateContext<GrainBalanceData[]>, payload: UpdateGrainBalanceData) {
    setState(payload.grainBalanceData);
  }
}
