import { Injectable } from '@angular/core';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { CognitoUserCreatorService } from '../cognito-user-creator/cognito-user-creator.service';
import { GetCognitoUserPoolService } from '../get-cognito-user-pool/get-cognito-user-pool.service';
import { CognitoIdentityResult } from '../../cognito-types';

@Injectable()
export class CognitoIdentityService {

  constructor(private readonly userCreatorService: CognitoUserCreatorService,
              private readonly getUserPool: GetCognitoUserPoolService) { }

  async signIn(email: string, password: string, companyId: string): Promise<CognitoIdentityResult> {
    const cognitoUser = await this.userCreatorService.getOrCreateCognitoUser(companyId, email);
    const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({ Username: email, Password: password });
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (session: CognitoUserSession) => {
          resolve({ type: 'success', data: session });
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          const data = { userAttributes, requiredAttributes };
          resolve({ type: 'newPasswordRequired', data });
        },
        onFailure: (err: any) => {
          reject(err);
        }
      });
    });
  }

  async setPermanentPassword(email: string, companyId: string, newPassword: string): Promise<CognitoIdentityResult> {
    const cognitoUser = await this.userCreatorService.getOrCreateCognitoUser(companyId, email);

    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: (session) => {
          resolve({ type: 'success', data: session});
        },
        onFailure: (err: any) => {
          reject(err);
        }
      });
    });
  }

  async signOut(companyId: string): Promise<void> {
    const userPool = await this.getUserPool.get(companyId);
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) return;
    cognitoUser.signOut();
  }

  async forgotPassword(email: string, companyId: string): Promise<CognitoIdentityResult> {
    const cognitoUser = await this.userCreatorService.getOrCreateCognitoUser(companyId, email);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data: any) => {
          resolve({ type: 'success', data });
        },
        onFailure: (err) => {
          reject(err);
        },
        inputVerificationCode: (data: any) => {
          resolve({ type: 'inputVerificationCode', data });
        }
      });
    });
  }

  async confirmNewPassword(verificationCode: string, newPassword: string,
                           email: string, companyId: string): Promise<CognitoIdentityResult> {
    const cognitoUser = await this.userCreatorService.getOrCreateCognitoUser(companyId, email);
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve({ type: 'success', data: null });
        },
        onFailure: (err: any) => {
          reject(err);
        }
      });
    });
  }
}
