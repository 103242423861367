<div *ngIf="!hasDtn" class="cash-bid-details-container">
  <div class="cash-bid-container">
    <div class="cash-bid-date">{{currentCashBid && (currentCashBid.aliasDescription || currentCashBid.futureMonth)}}</div>
    <div class="cash-bid-basis">
      <div class="small-padding-right header-text  text-align-center-desktop">Basis</div>
      <app-basis-price [cashBid]="currentCashBid" [textSize]="'desktop-large'"></app-basis-price>
    </div>
    <div class="cash-bid-price">
      <app-market-price [cashBid]="currentCashBid" [textSize]="'large'"></app-market-price>
    </div>
    <div class="cash-bid-change">
      <div class="small-padding-right header-text text-align-center-desktop">Change</div>
      <app-net-change [cashBid]="currentCashBid" [size]="'desktop-large'"></app-net-change>
    </div>
    <div class="cash-bid-commodity">
      <div class="commodity-name-text">{{currentCashBid && (currentCashBid.commodityName | uppercase)}}</div>
    </div>
  </div>
  <div class="cash-bid-futures-container">
    <div class="futures-headers-container">
      <div class="futures-row">
        <div class="header-text">Month</div>
        <div class="header-text small-padding-left">Cash Bid</div>
        <div class="header-text">Change</div>
        <div class="header-text small-padding-left">Basis</div>
      </div>
    </div>
    <div class="no-cash-bids" *ngIf="relatedCashBids && !relatedCashBids.length">
      There are no related cash bids.
    </div>
    <div class="futures-row" *ngFor="let relatedCashBid of relatedCashBids">
      <div class="small-cash-bid-date">{{relatedCashBid.aliasDescription || relatedCashBid.futureMonth}}</div>
      <app-market-price [cashBid]="relatedCashBid" [textSize]="'small-text'"  [precision]="'1.2-4'"></app-market-price>
      <app-net-change [cashBid]="relatedCashBid" [size]="'desktop-medium'" [precision]="'1.2-4'"></app-net-change>
      <app-basis-price [cashBid]="relatedCashBid" [textSize]="'desktop-medium'"></app-basis-price>
    </div>
  </div>
  <div class="futures-title">Futures</div>
  <div class="market-data-container">
    <div class="market-data-headers-container">
      <div class="market-data-row">
        <div class="header-text desktop">Symbol</div>
        <div class="header-text">Contract</div>
        <div class="header-text small-padding-left">Last</div>
        <div class="header-text">Change</div>
        <div class="header-text desktop">Open</div>
        <div class="header-text desktop">High</div>
        <div class="header-text desktop">Low</div>
        <div class="header-text">Last Trade</div>
      </div>
    </div>
    <div class="market-data-row" *ngFor="let cashBid of cashBidsWithUniqueSymbol">
      <div class="small-text desktop">{{cashBid.commoditySymbol}}</div>
      <div class="small-text">
        <ng-container *ngIf="cashBid.symbolData">
            {{cashBid.symbolData.name}}
        </ng-container>
      </div>
      <div class="small-text">{{cashBid.quote.lastPrice | currency:'USD':'symbol' : '1.2-4'}}</div>
      <app-net-change [cashBid]="cashBid" [size]="'desktop-medium'" [precision]="'1.2-4'"></app-net-change>
      <div class="small-text desktop">{{cashBid.quote.open | currency:'USD':'symbol' : '1.2-4'}}</div>
      <div class="small-text desktop">{{cashBid.quote.high | currency:'USD':'symbol' : '1.2-4'}}</div>
      <div class="small-text desktop">{{cashBid.quote.low | currency:'USD':'symbol' : '1.2-4'}}</div>
      <div class="small-text" *ngIf="cashBid.quote.tradeTimestampIsToday">{{cashBid.quote.tradeTimestamp | utcDate | date: 'shortTime'}}</div>
      <div class="small-text" *ngIf="!cashBid.quote.tradeTimestampIsToday">{{cashBid.quote.tradeTimestamp | utcDate | date: 'EEE hh:mm a'}}</div>
    </div>
  </div>
</div>
<div *ngIf="hasDtn" class="dtn-container">
  <div id="cash-bids-table-widget"></div>
  <div id="futures-table-widget"></div>
</div>
<div *ngIf="!hasDtn" class="disclaimer-container">
  <app-legal-disclaimer></app-legal-disclaimer>
</div>