import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyService } from '../../../core-services/company/company.service';
import { CustomerService } from '../../../core-services/customer/customer.service';
import { UserInfoService } from '../../../../shared/shared-services/user-info/user-info.service';
import { Router } from '@angular/router';
import { SidebarOptions } from '../../../components/sidebar/sidebar.types';
import { ApplicationErrorType } from '../../../../shared/pages/error/error.interface';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage/local-storage.service';
import { Select, Store } from '@ngxs/store';
import { Observable, SubscriptionLike as ISubscription, of } from 'rxjs';
import { UserData } from '../../../../state/data/user-data/user-data.types';
import { APP_STATE } from '../../../../state/app.state';
import { USER_INTERFACE_STATE } from '../../../../state/user-interface/user-interface.state';
import { DATA_STATE } from '../../../../state/data/data.state';
import { USER_DATA_STATE } from '../../../../state/data/user-data/user-data.state';
import { MOBILE_SIDEBAR_STATE } from '../../../../state/user-interface/mobile-sidebar/mobile-sidebar.state';
import { MobileSidebar } from '../../../../state/user-interface/mobile-sidebar/mobile-sidebar.types';
import { WindowSizeService } from '../../../core-services/window-size/window-size.service';
import { UpdateCustomerData } from '../../../../state/data/customer-data/customer-data.actions';
import { UpdateCompanyData } from '../../../../state/data/company-data/company-data.actions';
import { CUSTOMER_ID } from '../../../components/account-card/account-card.component';
import { AccountService } from '../../../core-services/account/account.service';
import { GrowerInfoListService } from '../../../core-services/grower-id/grower-info-list.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { UpdateAccountData } from '../../../../state/data/account-data/account-data.actions';
import * as _ from 'lodash';
import { TreatmentServiceFactory } from '../../../../shared/shared-services/split-io/treatment-service-factory';
import { CustomerHasGrainService } from '../../../../shared/shared-services/customer-has-grain/customer-has-grain.service';
import { SidebarMenuOption } from '../../../../state/user-interface/sidebar-selection/sidebar-selection.types';
import { CustomerData } from '../../../../state/data/customer-data/customer-data.types';
import { ENVIRONMENT } from '../../../../../environments/environment';

@Component({
  selector: 'app-grower360-root',
  templateUrl: './grower360-root.component.html',
  styleUrls: ['./grower360-root.component.scss']
})
export class Grower360RootComponent implements OnInit, OnDestroy {
  sidebarOptions: SidebarOptions;
  customerData: CustomerData;
  userName: string;
  menuOptions: SidebarMenuOption[];

  private userDataSubscription: ISubscription;

  @Select(state => state[APP_STATE][DATA_STATE][USER_DATA_STATE])
  userData$: Observable<UserData>;

  @Select(state => state[APP_STATE][USER_INTERFACE_STATE][MOBILE_SIDEBAR_STATE])
  mobileSidebar$: Observable<MobileSidebar>;

  constructor(
    private companyService: CompanyService,
    private customerService: CustomerService,
    private userInfoService: UserInfoService,
    private windowSizeService: WindowSizeService,
    private localStorage: LocalStorageService,
    private accountService: AccountService,
    private growerInfoListService: GrowerInfoListService,
    private store: Store,
    private treatmentServiceFactory: TreatmentServiceFactory,
    private router: Router,
    private customerHasGrainService: CustomerHasGrainService) {
  }

  async ngOnInit() {
    this.sidebarOptions = {
      companyName: '',
      customerName: '',
      companyIcon: 'domain',
      menuOptions: [],
      selectedMenuOption: {
        menuItemIcon: '',
        menuItemLink: '',
        menuItemName: '',
        sectionName: ''
      }
    };

    const settingsMenuItem = {
      menuItemName: 'Settings',
      menuItemLink: '/overview/settings',
      sectionName: 'GLANCE',
      menuItemIcon: 'settings-icon'
    };

    this.menuOptions = [
      {
        menuItemName: 'Overview',
        menuItemLink: '/overview',
        sectionName: 'GLANCE',
        menuItemIcon: 'overview-icon'
      },
      {
        menuItemName: 'Statements',
        menuItemLink: '/my-account/statements',
        sectionName: 'MY ACCOUNT',
        menuItemIcon: 'statement-icon'
      },
      {
        menuItemName: 'Bookings',
        menuItemLink: '/my-account/bookings',
        sectionName: 'MY ACCOUNT',
        menuItemIcon: 'booking-icon'
      },
      {
        menuItemName: 'Payments',
        menuItemLink: '/my-account/payments',
        sectionName: 'MY ACCOUNT',
        menuItemIcon: 'payment-icon'
      },
      {
        menuItemName: 'Invoices',
        menuItemLink: '/my-account/invoices',
        sectionName: 'MY ACCOUNT',
        menuItemIcon: 'invoice-icon'
      },
      {
        menuItemName: 'Fields',
        menuItemLink: '/my-account/fields',
        sectionName: 'MY ACCOUNT',
        menuItemIcon: 'fields-icon'
      }
    ];

    this.menuOptions.splice(1, 0, settingsMenuItem);

    this.userDataSubscription = this.userData$.subscribe(
      x => {
        if (x.userId !== undefined) {
          this.buildSidebarOptions(x);
        }
      }
    );

    this.fetchAccountPickerInfo();
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  async buildSidebarOptions(userData: UserData) {
    const grainMenuOptions = [
      {
        menuItemName: 'Scale Tickets',
        menuItemLink: '/grain/scaletickets',
        sectionName: 'GRAIN',
        menuItemIcon: 'scale-ticket-icon'
      },
      {
        menuItemName: 'Contracts',
        menuItemLink: '/grain/purchasecontracts',
        sectionName: 'GRAIN',
        menuItemIcon: 'purchase-contract-icon'
      },
      {
        menuItemName: 'Settlement Checks',
        menuItemLink: '/grain/settlementchecks',
        sectionName: 'GRAIN',
        menuItemIcon: 'settlement-icon'
      }];

    await this.checkAndAddProductsSidebarMenuOption();
    this.sidebarOptions.menuOptions = this.menuOptions;
    this.retrieveCompanyName(userData.companyId);
    this.retrieveCustomerInfo();
    this.userName = userData.name;
    const grainIsEnabled = await this.customerHasGrainService.checkCustomerGrainStatus();
    this.sidebarOptions.menuOptions = grainIsEnabled
      ? _.unionWith(this.sidebarOptions.menuOptions, grainMenuOptions, _.isEqual)
      : this.menuOptions;
  }

  retrieveCompanyName(companyId: string): void {
    this.companyService.retrieveCompany(companyId)
      .toPromise()
      .then(
        x => {
          this.sidebarOptions.companyName = x.companyName;
          this.store.dispatch(new UpdateCompanyData(x));
        }
      )
      .catch(() => this.routeToErrorPage());
  }

  retrieveCustomerInfo(): void {
    this.customerService.retrieveCustomer(this.localStorage.getItem(CUSTOMER_ID)).subscribe(
      customerData => {
        this.customerData = customerData;
        this.store.dispatch(new UpdateCustomerData(customerData));
      },
      () => this.routeToErrorPage()
    );
  }

  routeToErrorPage(): void {
    LocalStorageService.removeAuthToken();
    this.router.navigate(['/error', ApplicationErrorType.Default]);
  }

  fetchAccountPickerInfo() {
    this.userData$.pipe(
      filter((user) => !!user.userId),
      switchMap(userData => this.accountService.retrieveAccounts(userData.userId)),
      switchMap((accounts) => of(accounts.map(x => x.agvanceCustomerId))),
      switchMap(customerIds => this.growerInfoListService.fetch(customerIds)),
      map((growerInfos) => {
        const sortedGrowerInfos = _.sortBy(growerInfos, 'lastName');
        this.store.dispatch(new UpdateAccountData(sortedGrowerInfos));
      })).subscribe();
  }

  private async checkAndAddProductsSidebarMenuOption() {
    const hasProductsOption = Boolean(this.menuOptions.find(x => x.menuItemName === 'Products'));
    if (hasProductsOption) {
      return;
    }
    this.menuOptions.push({
      menuItemName: 'Products',
      menuItemLink: '/my-account/products',
      sectionName: 'MY ACCOUNT',
      menuItemIcon: 'products-icon'
    });
  }
}
