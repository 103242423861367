import { Injectable } from '@angular/core';
import {CustomerGrainPurchaseContractsService}
  from '../../../core/core-services/customer-grain-purchase-contracts/customer-grain-purchase-contracts.service';
import {CustomerGrainScaleTicketsService}
  from '../../../core/core-services/customer-grain-scale-tickets/customer-grain-scale-tickets.service';
import {CustomerGrainSettlementsService} from '../../../core/core-services/customer-grain-settlements/customer-grain-settlements.service';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {CUSTOMER_ID} from '../../../core/components/account-card/account-card.component';
import {combineLatest} from 'rxjs';

@Injectable()
export class CustomerHasGrainService {

  constructor(private grainPurchaseContractsService: CustomerGrainPurchaseContractsService,
              private grainScaleTicketsService: CustomerGrainScaleTicketsService,
              private grainSettlements: CustomerGrainSettlementsService,
              private localStorageService: LocalStorageService) { }

  checkCustomerGrainStatus(): Promise<boolean> {
    const custId = this.localStorageService.getItem(CUSTOMER_ID);
    return new Promise(resolve => {
      const purchaseContracts = this.grainPurchaseContractsService.retrieveGrainPurchaseContracts(custId);
      const scaleTickets = this.grainScaleTicketsService.retrieveGrainScaleTickets(custId);
      const settlements = this.grainSettlements.retrieveGrainSettlements(custId);
      combineLatest(purchaseContracts, scaleTickets, settlements)
        .subscribe(([purchaseContractData, scaleTicketsData, settlementsData]) => {
          const hasScaleTicketData = scaleTicketsData && scaleTicketsData.data;
          const isEnabled = !(purchaseContractData.length < 1 && !hasScaleTicketData && !settlementsData);
          resolve(isEnabled);
        });
    });
  }
}
