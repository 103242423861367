import { Pipe, PipeTransform } from '@angular/core';
import { AccountDropdownFontColors } from '../account-dropdown.types';

@Pipe({
  name: 'fontColor'
})
export class FontColorPipe implements PipeTransform {

  transform(item: AccountDropdownFontColors): string {
    const defaultStyle = 'dropdown-font-color-default';

    if (!item) {
      return defaultStyle;
    }

    switch (item) {
      case AccountDropdownFontColors.grower360LightBlue: {
        return 'dropdown-font-color-g360-light-blue';
      }
      default: {
        return defaultStyle;
      }
    }
  }

}
