import { Injectable } from '@angular/core';

@Injectable()
export class ProvideStorageKeyListService {

  provide(): string[] {
    const keyList = [];
    for (let i = 0; i < localStorage.length; i++) {
      keyList.push(localStorage.key(i));
    }
    return keyList;
  }
}
