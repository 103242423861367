import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {TreatmentServiceFactory} from '../shared-services/split-io/treatment-service-factory';

@Injectable()
export class TreatmentGuard implements CanActivate {
  constructor(private treatmentServiceFactory: TreatmentServiceFactory, private router: Router) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const treatmentIsOn = (await this.treatmentServiceFactory.isTreatmentOn(next.data['splitName']));
    if (!treatmentIsOn) {
      await this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}

