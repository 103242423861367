import { NgModule } from '@angular/core';
import { AccountPanelComponent } from './account-panel.component';
import { AccountCardModule } from '../../../components/account-card/account-card.module';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [ AccountPanelComponent ],
  imports: [ AccountCardModule,
    CommonModule ],
  exports: [ AccountPanelComponent ]
})

export class AccountPanelModule { }
