export class ApplicationError {
  errorTitle: string;
  errorText: string;
  buttonText: string;
  buttonLink: string;
  errorAction: ApplicationErrorAction;
}

export enum ApplicationErrorAction {
  Navigate
}

export enum ApplicationErrorType {
  Default,
  ExpiredResetToken
}
