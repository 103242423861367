import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { stubCashBids } from '../../../app.stub-data';
import { CashBidData } from '../../../state/data/cash-bid-data/cash-bid-data.types';

@Injectable()
export class CashBidsStubService {

  fetch(companyId: string): Observable<CashBidData[]> {
    return of(stubCashBids);
  }
}
