import { Pipe, PipeTransform } from '@angular/core';
import { GrainScaleTicketData } from '../../../../state/data/grain-scale-ticket-data/grain-scale-ticket-data.types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Pipe({
  name: 'sortRecentScaleTickets'
})
export class SortRecentScaleTicketsPipe implements PipeTransform {

  transform(scaleTickets: GrainScaleTicketData[]): GrainScaleTicketData[] {
    if (!scaleTickets) {
      return scaleTickets;
    }

    const dates = scaleTickets.map(x => moment(x.date));
    const latestDate = moment.max(dates);
    const dayBeforeLatest = moment(latestDate).subtract(1, 'days').startOf('day');

    return _.sortBy(scaleTickets.filter((x) => {
      const isBeforeLatest = moment(x.date).isSameOrBefore(latestDate);
      const isAfterDayBeforeLatest = moment(x.date).isSameOrAfter(dayBeforeLatest);

      return  isBeforeLatest && isAfterDayBeforeLatest;
    }), ['ticketNumber']).reverse();
  }
}
