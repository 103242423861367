<div class="payment-list" *ngIf="paymentData$ | async as payments">
  <ng-container *ngIf="isDoneLoading(payments) else loading">
    <div class="list-container">
      <div class="column-headers" data-id="column-headers">
        <div class="title-container" data-id="title">
          <div class="list-icon" data-id="icon"></div>
          <span class="header-title">Payments</span>
        </div>
        <div *ngFor="let column of columnsTitles" class="column-title">{{column}}</div>
      </div>
      <div class="rows">
        <div class="payment-for" *ngFor="let payment of payments | sortPayments">
          <div class="desktop-table-data">
            <div class="desktop-row">
              <div class="payment-badge">
                <img src="../../../../../../assets/images/payments-icon-white.svg">
              </div>
              <div class="payment-number">{{payment.number}}</div>
            </div>
            <div class="row-data">{{payment.date | utcDate | date: 'MM/dd/yyyy'}}</div>
            <div class="row-data">{{payment.amount | currency:'USD':'symbol' }}</div>
            <div class="desktop-row">
              <div class="payment-color" [ngClass]="paymentColor(payment.type)"></div>
              <div class="row-data">{{paymentType(payment.type)}}</div>
            </div>
            <div class="statement-download">
              <app-img-with-options
                imageName="download-image"
                [options]="clickableImageOptions"
                [showPlaceholder]="(fileData === '')"
                [showError]="fileData === null"
                [errorOptions]="clickableImageErrorOptions"
                (optionClicked)="handleClickableImageSelection($event, payment)"
                (optionsOpened)="handleClickableImageOpened($event, payment)">
              </app-img-with-options>
            </div>
          </div>

          <div class="mobile-table-data">
            <div class="payment-row">
              <div class="row">
                <div class="payment-badge">
                  <img src="../../../../../../assets/images/payments-icon-white.svg">
                </div>
                <div class="payment-number">{{payment.number}}</div>
                <div class="mobile-payment-download right-align">
                  <app-img-with-options
                    imageName="download-image"
                    [options]="clickableImageOptions"
                    [showPlaceholder]="(fileData === '')"
                    [showError]="fileData === null"
                    [errorOptions]="clickableImageErrorOptions"
                    (optionClicked)="handleClickableImageSelection($event, payment)"
                    (optionsOpened)="handleClickableImageOpened($event, payment)">
                  </app-img-with-options>
                </div>
              </div>
              <div class="row">
                <div class="row-title">Date</div>
                <div class="row-data">{{payment.date | utcDate | date: 'MM/dd/yyyy'}}</div>
              </div>
              <div class="row">
                <div class="row-title">Amount</div>
                <div class="row-data">{{payment.amount | currency:'USD':'symbol' }}</div>
              </div>
              <div class="row">
                <div class="payment-color" [ngClass]="paymentColor(payment.type)"></div>
                <div class="row-data">{{paymentType(payment.type)}}</div>
              </div>
              <div class="tablet-payment-download right-align">
                <app-img-with-options
                  imageName="download-image"
                  [options]="clickableImageOptions"
                  [showPlaceholder]="(fileData === '')"
                  [showError]="fileData === null"
                  [errorOptions]="clickableImageErrorOptions"
                  (optionClicked)="handleClickableImageSelection($event, payment)"
                  (optionsOpened)="handleClickableImageOpened($event, payment)">
                </app-img-with-options>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #loading>
  <app-recent-activity-skeleton></app-recent-activity-skeleton>
</ng-template>
