import { Injectable } from '@angular/core';
import { Observable,  of} from 'rxjs';
import { CompanyInfo } from './subdomain.interface';

@Injectable()
export class SubdomainStubService {
  parseSubdomain(): string {
    return 'acmeag';
  }

  checkValidSubdomain(): Observable<boolean> {
    return of(true);
  }

  retrieveCompanyInfoForSubdomain(subdomain: string): Observable<CompanyInfo> {
    return of({
      companyName: 'Acme Ag',
      companyId: 'e6ca39db-f163-4a54-9426-5b11416c0f5e'
    });
  }
}
