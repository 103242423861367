import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { APP_STATE } from '../../../../state/app.state';
import { DATA_STATE } from '../../../../state/data/data.state';
import { USER_DATA_STATE } from '../../../../state/data/user-data/user-data.state';
import { Observable ,  of } from 'rxjs';
import { UserData } from '../../../../state/data/user-data/user-data.types';
import { AccountService } from '../../../core-services/account/account.service';
import { GrowerInfoListService, GrowerInfoResponseDto } from '../../../core-services/grower-id/grower-info-list.service';
import {filter, map, switchMap} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-account-panel',
  templateUrl: 'account-panel.component.html',
  styleUrls: ['account-panel.component.scss']
})

export class AccountPanelComponent implements OnInit {
  accountCardData$: Observable<GrowerInfoResponseDto[]>;

  @Select(state => state[APP_STATE][DATA_STATE][USER_DATA_STATE])
  userData$: Observable<UserData>;

  constructor(private accountService: AccountService,
              private growerInfoListService: GrowerInfoListService) { }

  ngOnInit(): void {
    this.fetchAccountCardData();
  }

  fetchAccountCardData(): void {
    this.accountCardData$ = this.userData$.pipe(
      filter((user) => !!user.userId),
      switchMap(userData => this.accountService.retrieveAccounts(userData.userId)),
      switchMap(accounts => of(accounts.map(x => x.agvanceCustomerId))),
      switchMap(customerIds => this.growerInfoListService.fetch(customerIds)),
      map((growerInfos) => {
        return _.sortBy(growerInfos, 'lastName');
      }));
  }
}
