export class PaginatedResponse<T> {
  metaResponseData: MetaResponseData;
  linksMetaData: LinksMetaData;
  data: T[];
}

export class LinksMetaData {
  prev: string;
  next: string;
  last: string;
  self: string;
  first: string;
}

export class MetaResponseData {
  totalPages: number;
  totalRows: number;
}
