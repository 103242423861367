import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {

  transform(dateValue: string | Date): Date {
    if (dateValue) {
      const utcDate = moment(dateValue).utc().toDate();

      return utcDate;
    }
  }
}
