import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import { SignedContract } from './seller-signature.service';

@Injectable()
export class SellerSignatureStubService {
  saveSellerSignature(signatureData: string, purchaseContractGuid: string): Observable<SignedContract> {
    return of({ s3FileNameGuid: 'ae763eb7-f3ae-4c65-a4cf-d4f8342025c6'});
  }
}
