import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { APP_STATE } from '../../../../../state/app.state';
import { DATA_STATE } from '../../../../../state/data/data.state';
import { Observable } from 'rxjs';
import { InvoiceData } from '../../../../../state/data/invoice-data/invoice-data.types';
import { RECENT_ACTIVITY_INVOICE_DATA_STATE } from '../../../../../state/data/invoice-data/invoice-data.state';
import { USER_DATA_STATE } from '../../../../../state/data/user-data/user-data.state';
import { UserData} from '../../../../../state/data/user-data/user-data.types';
import { COMPANY_DATA_STATE } from '../../../../../state/data/company-data/company-data.state';
import { CrmPdfService } from '../../../../core-services/crm-pdf/crm-pdf.service';
import { Company } from '../../../../core-services/company/company.types';
import { PdfFileHandlerService } from '../../../../../shared/shared-services/pdf-file-handler/pdf-file-handler.service';
import { LocalStorageService } from '../../../../../shared/shared-services/local-storage/local-storage.service';
import { CUSTOMER_ID } from '../../../account-card/account-card.component';
import { Option, ErrorOption, OptionEmitter } from '../../../image-with-options/image-with-options.component';

@Component({
  selector: 'app-recent-activity-invoice-list',
  templateUrl: './recent-activity-invoice-list.component.html',
  styleUrls: ['./recent-activity-invoice-list.component.scss']
})
export class RecentActivityInvoiceListComponent implements OnInit {
  customerId: string;
  companyData = new Company();
  columnTitles: string[] = ['Field', 'Invoice Date', 'Due Date', 'Original', 'Unpaid', 'Status'];
  clickableImageOptions: Option[] = [
    { id: 'view-pdf', text: 'View pdf', activeText: true, image: 'view-image' },
    { id: 'download-pdf', text: 'Download pdf', activeText: true, image: 'download-image' }
  ];
  clickableImageErrorOptions: ErrorOption = {
    image: 'pdf-error-image',
    text: 'No PDF'
  };
  fileData = '';

  @Select(state => state[APP_STATE][DATA_STATE][RECENT_ACTIVITY_INVOICE_DATA_STATE])
  invoiceData$: Observable<InvoiceData>;

  @Select(state => state[APP_STATE][DATA_STATE][USER_DATA_STATE])
  userData$: Observable<UserData>;

  @Select(state => state[APP_STATE][DATA_STATE][COMPANY_DATA_STATE])
  companyData$: Observable<Company>;

  constructor(private crmPdfService: CrmPdfService,
              private pdfFileHandlerService: PdfFileHandlerService,
              private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.customerId = this.localStorage.getItem(CUSTOMER_ID);
    this.companyData$.subscribe(data => this.companyData = data);
  }

  getStatusMessage(status: string): string {
    if (status === 'Open') {
      return 'Unpaid';
    }

    return status;
  }

  handleClickableImageSelection(evt: OptionEmitter, invoice: InvoiceData) {
    if (evt.id === 'view-pdf') {
      this.fetchPdfData(invoice).subscribe((fileData) => {
        this.pdfFileHandlerService.openInNewWindow(fileData);
      });
    }

    if (evt.id === 'download-pdf') {
      this.fetchPdfData(invoice).subscribe((fileData) => {
        this.pdfFileHandlerService.downloadToFile(fileData, `invoice_${invoice.number}.pdf`);
      });
    }
  }

  handleClickableImageOpened(evt: boolean, invoice: InvoiceData) {
    if (evt) {
      this.fetchPdfData(invoice).subscribe(x => {
        this.fileData = x;
      });
    }
  }

  private fetchPdfData(invoice: InvoiceData) {
    return this.crmPdfService.retrieveInvoice(this.customerId, this.companyData.legacyDbID,
      invoice.number.toString(), invoice.date, invoice.location, invoice.s3FileNameGUID);
  }

  isDoneLoading(invoices): boolean {
    // https://github.com/ngxs/store/blob/0d18418afa54d0ff474d5d03afd3fb4ff8cf977a/packages/store/src/internal/state-factory.ts#L72
    // ngxs default store value is an empty object i.e. {}. We leverage this to tell whether the store has loaded
    return Array.isArray(invoices);
  }
}
