import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { LocalStorageService } from '../shared-services/local-storage/local-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { UserData } from '../../state/data/user-data/user-data.types';
import { APP_STATE } from '../../state/app.state';
import { DATA_STATE } from '../../state/data/data.state';
import { USER_DATA_STATE } from '../../state/data/user-data/user-data.state';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { UserInfoService } from '../shared-services/user-info/user-info.service';
import { UpdateUserData } from '../../state/data/user-data/user-data.actions';
import { PostSignInRoutingService } from '../../public/public-services/post-sign-in-routing/post-sign-in-routing.service';

@Injectable({
  providedIn: 'root'
})
export class AsyncAuthGuardService implements CanActivate {

  constructor(private router: Router,
              private storage: LocalStorageService,
              private jwtHelper: JwtHelperService,
              private store: Store,
              private userInfoService: UserInfoService,
              private postSignInRoutingService: PostSignInRoutingService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    const signInTree = this.router.createUrlTree(['/sign-in']);

    const authToken = this.storage.getItem(LocalStorageService.AUTH_TOKEN_KEY);
    if (!authToken) return this.recordIntendedRouteAndReturnSignInTree(state, signInTree);
    if (this.jwtHelper.isTokenExpired(authToken)) return this.recordIntendedRouteAndReturnSignInTree(state, signInTree);

    return this.store.select<UserData>(store => store[APP_STATE][DATA_STATE][USER_DATA_STATE])
      .pipe(
        switchMap((userData: UserData) => {
          return !userData.userId ? this.userInfoService.retrieveUserInfo() : of(userData);
        }),
        switchMap((userData: UserData) => {
          this.store.dispatch(new UpdateUserData(userData));
          return of(true);
        }),
        catchError(() => {
          return this.recordIntendedRouteAndReturnSignInTree(state, signInTree);
        })
      );
  }

  private recordIntendedRouteAndReturnSignInTree(state: RouterStateSnapshot, tree: UrlTree): Observable<UrlTree> {
    return from(this.postSignInRoutingService.savePostSignInUrl(state.url))
      .pipe(
        tap(() => this.storage.removeItem(LocalStorageService.AUTH_TOKEN_KEY)),
        switchMap(() => of(tree))
      );
  }
}
