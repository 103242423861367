export class AccountDropdownOptions {
  displayAvatar: boolean;
  avatarColor: AccountDropdownAvatarColors;
  fontColor: AccountDropdownFontColors;
  name: string;
  listOptions: AccountDropdownListOptions[];
}

export class AccountDropdownListOptions {
  iconClass: string;
  text: string;
  action: Function;
}

export enum AccountDropdownAvatarColors {
  default,
  grower360Gradient
}

export enum AccountDropdownFontColors {
  default,
  grower360LightBlue
}

