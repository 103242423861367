import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AccountDropdownAvatarColors, AccountDropdownFontColors} from './account-dropdown/account-dropdown.types';
import {LocalStorageService} from '../../../shared/shared-services/local-storage/local-storage.service';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {APP_STATE, AppStore} from '../../../state/app.state';
import {USER_INTERFACE_STATE} from '../../../state/user-interface/user-interface.state';
import {MobileSidebar, MobileSidebarSubMenu} from '../../../state/user-interface/mobile-sidebar/mobile-sidebar.types';
import {UpdateMobileSidebar} from '../../../state/user-interface/mobile-sidebar/mobile-sidebar.action';
import {UpdateAccountDropdown} from '../../../state/user-interface/account-dropdown/account-dropdown.action';
import { BroadcastClientService } from 'sky-services';
import {CUSTOMER_ID} from '../account-card/account-card.component';
import {DATA_STATE} from '../../../state/data/data.state';
import {USER_DATA_STATE} from '../../../state/data/user-data/user-data.state';
import {UserData} from '../../../state/data/user-data/user-data.types';
import {LAST_ACCESSED, LastAccessedCustomer} from './last-accessed.type';
import {AccountDropdown} from '../../../state/user-interface/account-dropdown/account-dropdown.types';
import {ACCOUNT_DROPDOWN_STATE} from '../../../state/user-interface/account-dropdown/account-dropdown.state';
import {CustomerService} from '../../core-services/customer/customer.service';
import {CognitoIdentityService} from '../../../public/cognito-operations/services/cognito-identity/cognito-identity.service';
import {NotificationsListService} from '../../core-services/notifications/notifications-list.service';
import {NotificationListParams} from '../../../shared/types/notification-list-params';
import {EmailUserInfo} from '../../../shared/types/email-user-info';
import {filter, switchMap} from 'rxjs/operators';
import {CustomerData} from '../../../state/data/customer-data/customer-data.types';
import { AbstractLocation } from '../../../app.types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() userName: string;
  @Input() customerData: CustomerData;
  userId: string;
  lastAccessedCustomer: LastAccessedCustomer;
  notifications: EmailUserInfo[] = [];
  unreadCount: number;
  accountDropdownOpts: AccountDropdown;
  displayBell = false;

  @Select(state => state[APP_STATE][USER_INTERFACE_STATE][ACCOUNT_DROPDOWN_STATE])
  accountDropdown$: Observable<AccountDropdown>;

  @Select(state => state[APP_STATE][DATA_STATE][USER_DATA_STATE])
  userSelection$: Observable<UserData>;

  constructor(private router: Router,
              private store: Store,
              private broadcastClient: BroadcastClientService,
              private localStorage: LocalStorageService,
              private customerService: CustomerService,
              private identityService: CognitoIdentityService,
              private notificationsListService: NotificationsListService,
              private locationObj: AbstractLocation) {
  }

  ngOnInit() {
    this.userName = this.userName || 'Error';
    this.userSelection$.subscribe(u => this.userId = u.userId);
    this.accountDropdownOpts = {
      name: this.userName,
      displayAvatar: true,
      avatarColor: AccountDropdownAvatarColors.grower360Gradient,
      fontColor: AccountDropdownFontColors.grower360LightBlue,
      listOptions: [
        {
          text: 'Signout',
          iconClass: 'signout-icon',
          action: () => {
            LocalStorageService.removeAuthToken();
            this.broadcastClient.sendMessage('sign-out', true);
            this.identityService.signOut(this.localStorage.getItem(CUSTOMER_ID));
            this.lastAccessedCustomer = {
              userId: this.userId,
              customerId: this.localStorage.getItem(CUSTOMER_ID)
            };
            this.localStorage.setItem(LAST_ACCESSED, JSON.stringify(this.lastAccessedCustomer));
            this.localStorage.removeItem(CUSTOMER_ID);
            this.store.reset({appState: new AppStore()});
            this.router.navigate(['/sign-in']);
          }
        }
      ]
    };

    this.store.dispatch(new UpdateAccountDropdown(this.accountDropdownOpts));

    this.accountDropdown$.subscribe(
      (data) => {
        if (!data) {
          this.store.dispatch(new UpdateAccountDropdown(this.accountDropdownOpts));
        }
      });

    this.fetchNotifications();
    this.router.events.pipe(
      filter(x => x instanceof NavigationEnd),
      switchMap(() => this.userSelection$),
      filter(u => !!u.userId),
    ).subscribe(() => {
        this.fetchNotifications();
    });
  }

  ngOnChanges(): void {
    this.store.dispatch(new UpdateAccountDropdown({...this.accountDropdownOpts, name: this.userName}));
  }

  async fetchNotifications() {
    const notificationInfo: NotificationListParams = {
      grower360UserId: this.userId,
      pageNumber: 1,
      pageSize: 6
    };
    const notificationsResult = await this.notificationsListService.searchNotifications(notificationInfo).toPromise();
    this.notifications = notificationsResult.data;
    this.unreadCount = this.notifications.filter(x => x.isRead === false).length;
    this.displayBell = true;
  }

  mobileMenuClicked() {
    const mobileSidebarOpts: MobileSidebar = {
      display: true,
      submenu: MobileSidebarSubMenu.None
    };
    this.store.dispatch(new UpdateMobileSidebar(mobileSidebarOpts));
  }

  onCustomerSwitch(growerGuid: string) {
    this.customerService.switchCustomer(growerGuid);
    this.locationObj.replace(this.locationObj.origin);
  }
}
