import {Component, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent {
  @Input() breadCrumbs: string[];
  @Output() backClicked = new EventEmitter();
  @Output() crumbClicked = new EventEmitter<string>();

  handleCrumbClick(breadCrumb: string) {
    this.backClicked.emit();
    this.crumbClicked.emit(breadCrumb);
  }

  getBreadCrumbWidthStyle(): string {
    switch (this.breadCrumbs.length) {
      case 2:
        return 'two-deep';
        break;

      case 3:
        return 'three-deep';
        break;

      case 4:
        return 'four-deep';
        break;

      case 5:
        return 'five-deep';
        break;
    }
  }
}
