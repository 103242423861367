<div class="account-card clickable" (click)="onLaunch()">
    <div class="header-section">
      <div class="title-container">
        <div class="avatar">{{ avatarLetter() }}</div>
        <div class="title-text">{{ formatCustomerName() }}
          <div class="growid">{{ growerId() }}</div>
        </div>
      </div>
    </div>
    <div class="balance-section">
        <div class="balance-banner-section">
            <div class="balance-banner"></div>
            <span class="balance-type-heading">REGULAR</span>
        </div>
        <div class="balance-amount">{{ regularBalance | currency:'USD':'symbol' }}</div>
        <a class="launch-button">LAUNCH</a>
    </div>
</div>
