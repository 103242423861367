import { Injectable } from '@angular/core';
import { HttpClientOptionsBase, HttpClientWithStorageService } from '../http-client-with-storage/http-client-with-storage.service';

@Injectable()
export class HttpClientWithAuthHeadersService {
  static readonly DefaultLifetime = '2m';
  static readonly AuthTokenStorageKey = 'Authorization';
  static readonly NoAuthTokenError = 'No authorization token was found, remote service call was cancelled';

  constructor(private httpClient: HttpClientWithStorageService) { }

  get<T>(url: string, options: HttpClientOptionsBase = null,
         lifetime: string = HttpClientWithAuthHeadersService.DefaultLifetime): Promise<T> {
    const authToken = localStorage.getItem(HttpClientWithAuthHeadersService.AuthTokenStorageKey);
    if (authToken === null) return Promise.reject(HttpClientWithAuthHeadersService.NoAuthTokenError);

    options = options || new HttpClientOptionsBase();
    options.headers['Authorization'] = `${authToken}`;
    if (!options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json';
    }
    if (!options.headers['Accept']) {
      options.headers['Accept'] = 'application/json';
    }

    return this.httpClient.get(url, options, lifetime);
  }

}
