import { UserInterface } from './user-interface.types';
import { State } from '@ngxs/store';
import { SidebarSelectionState } from './sidebar-selection/sidebar-selection.state';
import { SidebarExpansionState } from './sidebar-expansion/sidebar-expansion.state';
import { MobileSidebarState } from './mobile-sidebar/mobile-sidebar.state';
import { AccountDropdownState } from './account-dropdown/account-dropdown.state';

export const USER_INTERFACE_STATE = 'userInterface';

@State<UserInterface>({
  name: USER_INTERFACE_STATE,
  children: [
    SidebarSelectionState,
    SidebarExpansionState,
    MobileSidebarState,
    AccountDropdownState],
  defaults: new UserInterface()
})
export class UserInterfaceState {}
