
import {switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SubdomainService } from '../subdomain/subdomain.service';

import { Observable } from 'rxjs';

@Injectable()

export class CompanyLogoService {

  static readonly url = 'https://static-content.agvancesky.com/company-logos/';
  companyId: String;

  constructor(private subdomainService: SubdomainService ) {}

  getCompanyLogo(): Observable<string> {
    const subdomain = this.subdomainService.parseSubdomain();

    return Observable.create(emitter => {
    this.subdomainService.retrieveCompanyInfoForSubdomain(subdomain).pipe(
      switchMap((companyInfo) => {
        this.companyId = companyInfo.companyId;
        return this.imageCheck();
        }))
      .subscribe(() => { emitter.next(CompanyLogoService.url + this.companyId); emitter.complete(); },
                 () => { emitter.error('Error'); emitter.complete(); });
    });
  }

  private imageCheck(): Observable<any> {
    return Observable.create(emit => {
      const img = new Image();
      img.src = `${CompanyLogoService.url}${this.companyId}`;
      img.onerror = () => {
        emit.error();
        emit.complete();
      };
      img.onload = () => {
        emit.next();
        emit.complete();
      };
    });
  }

}
