import {Action, State, StateContext} from '@ngxs/store';
import { GrainSettlementData } from './grain-settlement-data.types';
import { UpdateGrainSettlementData } from './grain-settlement-data.actions';

export const GRAIN_SETTLEMENT_DATA_STATE = 'grainSettlementData';
@State<GrainSettlementData[]>({
  name: GRAIN_SETTLEMENT_DATA_STATE
})

export class GrainSettlementDataState {
  @Action(UpdateGrainSettlementData)
  updateGrainScaleTicketData({ setState }: StateContext<GrainSettlementData[]>, payload: UpdateGrainSettlementData) {
    setState(payload.grainSettlementData);
  }
}
