import { Injectable } from '@angular/core';
import {HttpClientWithAuthHeadersService} from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import { ENVIRONMENT } from '../../../../environments/environment';

@Injectable()
export class GrowerIdService {
  static readonly GrowerIdLifetime = '24h';
  static readonly NullCustomerIdError = 'Customer ID is required';

  constructor(private httpClient: HttpClientWithAuthHeadersService) { }

  fetch(customerId: string): Promise<string> {
    if (!customerId) return Promise.reject(GrowerIdService.NullCustomerIdError);

    const url = `${ENVIRONMENT.gatewayApiBaseUrl}/growers/${customerId}/id`;
    return this.httpClient
      .get<GrowerIdResponseDto>(url, null, GrowerIdService.GrowerIdLifetime)
      .then(dto => dto.growId);
  }
}

export class GrowerIdResponseDto {
  growId: string;
  growerGuid: string;
}
