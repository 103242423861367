import { Company } from '../../../core/core-services/company/company.types';
import { Action, State, StateContext } from '@ngxs/store';
import { UpdateCompanyData } from './company-data.actions';

export const COMPANY_DATA_STATE = 'companyData';
@State<Company>({
  name: COMPANY_DATA_STATE,
  defaults: new Company()
})

export class CompanyDataState {

  @Action(UpdateCompanyData)
  updateCompanyData({ setState }: StateContext<Company>, payload: UpdateCompanyData) {
    setState(payload.companyData);
  }
}
