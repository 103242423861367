export class InvoiceData {

  public constructor(init?: InvoiceData) {
    Object.assign(this, init);
  }

  number: number;
  date: Date;
  location: string;
  dueDate: Date;
  originalAmount: number;
  amountDue: number;
  status: string;
  hasPdfData: boolean;
  discount?: number;
  fieldId?: string;
  fieldDescription?: string;
  s3FileNameGUID?: string;
  amountDueAfterDiscount?: number;

  static fromMemoInvoice(memoInvoiceResponse: MemoInvoiceResponse): InvoiceData {
    const result = new InvoiceData();
    result.number = memoInvoiceResponse.invoiceNumber;
    result.date = memoInvoiceResponse.invoiceDate;
    result.location = memoInvoiceResponse.invoiceLocation;
    result.dueDate = memoInvoiceResponse.dueDate;
    result.originalAmount = memoInvoiceResponse.originalDue;
    result.amountDue = memoInvoiceResponse.totalDue;
    return result;
  }

  get field(): string {
    return this.fieldDescription ?
      this.fieldDescription :
      this.fieldId;
  }
}

export class MemoInvoiceResponse {
  invoiceNumber: number;
  invoiceDate: Date;
  invoiceLocation: string;
  dueDate: Date;
  originalDue: number;
  totalDue: number;
}
