import { GrowerIdService } from '../grower-id/grower-id.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HttpClientWithAuthHeadersService } from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import {CrmPdfInfo} from '../../../state/data/invoice-data/crm-pdf-info';
import * as moment from 'moment';
import {BookingLineItem} from '../../../state/data/booking-data/booking-data.types';

@Injectable()
export class CrmPdfService {
  static GET_CRM_PATH = `${ENVIRONMENT.gatewayApiBaseUrl}/crm`;
  constructor(private httpClient: HttpClientWithAuthHeadersService,
              private growerIdService: GrowerIdService) {}

  retrieveInvoice(agvanceCustomerId: string, databaseId: string, invoiceNumber: string,
                  date: Date, location: string, s3FileNameGuid: string) {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CrmPdfService.GET_CRM_PATH}/invoice` +
            `?databaseid=${databaseId}&location=${location}&date=${date}&invoicenumber=${invoiceNumber}` +
          `&customerid=${growerId}&s3FileName=${s3FileNameGuid}`;

          /* NOTE: cast to any to silence HttpClientOptionsBase warning. Contact: Kirby Bryant kbryant@8thlight.com */
          return this.httpClient.get(url, { headers: {}, responseType: 'text'} as any);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  retrieveBooking(agvanceCustomerId: string, databaseId: string, lineItem: BookingLineItem, s3FileNameGuid: string) {
    const date = lineItem.invoiceDate;
    const invoiceNumber = lineItem.invoiceNumber.toString();
    const location = lineItem.location;
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CrmPdfService.GET_CRM_PATH}/booking` +
            `?databaseid=${databaseId}&location=${location}&date=${date}&invoicenumber=${invoiceNumber}` +
          `&customerid=${growerId}&s3FileName=${s3FileNameGuid}`;

          /* NOTE: cast to any to silence HttpClientOptionsBase warning. Contact: Kirby Bryant kbryant@8thlight.com */
          return this.httpClient.get(url, { headers: {}, responseType: 'text'} as any);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  retrievePurchaseContract(agvanceCustomerId: string, contractNumber: string, location: string,
                           databaseId: string, s3FileNameGuid: string) {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CrmPdfService.GET_CRM_PATH}/PurchaseContract` +
            `?databaseid=${databaseId}&location=${location}&invoiceNumber=${contractNumber}&customerid=${growerId}` +
          `&s3FileName=${s3FileNameGuid}`;

          return this.httpClient.get(url, { headers: {}, responseType: 'text'} as any);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  retrieveStatement(agvanceCustomerId: string, databaseId: string, invoiceNumber: string, date: Date, location: string,
                    s3FileNameGuid: string) {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CrmPdfService.GET_CRM_PATH}/statement` +
            `?databaseId=${databaseId}&location=${location}&date=${date}&invoiceNumber=${invoiceNumber}&customerId=${growerId}` +
          `&s3FileName=${s3FileNameGuid}`;

          /* NOTE: cast to any to silence HttpClientOptionsBase warning. Contact: Kirby Bryant kbryant@8thlight.com */
          return this.httpClient.get(url, { headers: {}, responseType: 'text'} as any);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  retrieveBudgetBillingStatement(agvanceCustomerId: string, databaseId: string, invoiceNumber: string, date: Date, location: string) {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
      .then(growerId => {
        const url = `${CrmPdfService.GET_CRM_PATH}/budgetbillingstatement` +
          `?databaseId=${databaseId}&location=${location}&date=${date}&invoiceNumber=${invoiceNumber}&customerId=${growerId}`;

        return this.httpClient.get(url, { headers: {}, responseType: 'text'} as any);
      })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  retrievePayment(agvanceCustomerId: string, databaseId: string, invoiceNumber: string,
                  date: Date, location: string, s3FileNameGUID: string) {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CrmPdfService.GET_CRM_PATH}/paymentreceipt` +
            `?databaseId=${databaseId}&location=${location}&date=${date}&invoiceNumber=${invoiceNumber}&customerId=${growerId}` +
          `&s3FileName=${s3FileNameGUID}`;

          /* NOTE: cast to any to silence HttpClientOptionsBase warning. Contact: Kirby Bryant kbryant@8thlight.com */
          return this.httpClient.get(url, { headers: {}, responseType: 'text'} as any);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  retrieveSettlement(agvanceCustomerId: string, databaseId: string, settlementNumber: string, location: string) {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CrmPdfService.GET_CRM_PATH}/grainsettlement` +
            `?databaseid=${databaseId}&location=${location}&invoicenumber=${settlementNumber}` +
            `&customerid=${growerId}`;
          return this.httpClient.get(url, { headers: {}, responseType: 'text'} as any);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  retrieveStatementPdfList(agvanceCustomerId: string, databaseId: string, startDate?: Date, endDate?: Date): Observable<CrmPdfInfo[]> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          let url = `${CrmPdfService.GET_CRM_PATH}/document-list/statement?databaseid=${databaseId}&customerid=${growerId}`;
          url += startDate ? `&startDate=${moment(startDate).format('YYYY-MM-DD')}` : '';
          url += endDate ? `&endDate=${moment(endDate).format('YYYY-MM-DD')}` : '';

          return this.httpClient.get(url);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });

  }

  retrieveBudgetBillingStatementPdfList(agvanceCustomerId: string, databaseId: string, startDate?: Date, endDate?: Date):
    Observable<CrmPdfInfo[]> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          let url = `${CrmPdfService.GET_CRM_PATH}/document-list/budgetbillingstatement?databaseid=${databaseId}&customerid=${growerId}`;
          url += startDate ? `&startDate${moment(startDate).format('YYYY-MM-DD')}` : '';
          url += endDate ? `&endDate=${moment(endDate).format('YYYY-MM-DD')}` : '';

          return this.httpClient.get(url);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => {emitter.next(val); emitter.complete(); });
    });
  }
}
