import {Component, Input} from '@angular/core';
import {CashBidData} from '../../../../state/data/cash-bid-data/cash-bid-data.types';

@Component({
  selector: 'app-market-price',
  templateUrl: './market-price.component.html',
  styleUrls: ['./market-price.component.scss']
})
export class MarketPriceComponent {
  @Input() cashBid: CashBidData;
  @Input() textSize: string;
  @Input() precision: string;
}
