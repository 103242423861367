import { Component, OnInit, Input } from '@angular/core';
import { CashBidsService } from '../../../core-services/cash-bids/cash-bids.service';
import { BarchartsApiQuoteService } from '../../../core-services/cash-bids/barcharts-api-quote.service';
import { BarchartsApiSymbolService } from '../../../core-services/cash-bids/barcharts-api-symbol.service';
import { SubdomainService } from '../../../../shared/shared-services/subdomain/subdomain.service';
import { CashBidData, DtnApiKey } from '../../../../state/data/cash-bid-data/cash-bid-data.types';
import { BarchartsApiQuoteData, Quote } from '../../../../state/data/cash-bid-data/barcharts-api-quote-data.types';
import { BarchartsApiSymbolData } from '../../../../state/data/cash-bid-data/barcharts-api-symbol-data.types';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-cash-bid-details',
  templateUrl: './cash-bid-details.component.html',
  styleUrls: ['./cash-bid-details.component.scss']
})
export class CashBidDetailsComponent implements OnInit {
  @Input() commodityId: string;
  @Input() bidCriteria: string;
  @Input() location: string;
  currentCashBid: CashBidData;
  relatedCashBids: CashBidData[];
  cashBidsWithUniqueSymbol: CashBidData[];
  hasDtn = true;

  constructor(
    private cashBidsService: CashBidsService,
    private barchartsApiQuoteService: BarchartsApiQuoteService,
    private barchartsApiSymbolService: BarchartsApiSymbolService,
    private subdomainService: SubdomainService) {
  }

  async ngOnInit() {
    const subdomain = this.subdomainService.parseSubdomain();
    this.subdomainService.retrieveCompanyInfoForSubdomain(subdomain).subscribe(async (companyInfo) => {
      const allCashBids = await this.cashBidsService.fetch(companyInfo.companyId);
      this.hasDtn = !!allCashBids.find(x => x.source === 'dtn');

      if (this.hasDtn) {
        const commodities = _.uniq(allCashBids.map(x => x.commodityId));
        const symbols = _.uniq(allCashBids.map(x => x.commoditySymbol));
        const dtnApiKey = await this.cashBidsService.fetchDtnApiKey(companyInfo.companyId);
        await this.loadDtnWidgets(dtnApiKey, commodities, symbols);
      }
      if (!this.hasDtn) {
        const sortedAndFiltered = this.sortAndFilter(allCashBids);
        const symbols = _.uniq(sortedAndFiltered.map(x => x.commoditySymbol));
        const symbolData = await this.barchartsApiSymbolService.fetch(companyInfo.companyId, symbols);
        const quoteData = await this.barchartsApiQuoteService.fetch(companyInfo.companyId, symbols);

        this.setCashBidsWithSymbolData(sortedAndFiltered, symbolData);
        this.setCashBidsWithQuoteData(sortedAndFiltered, quoteData);
      }
    });
  }

  sortAndFilter(cashBids: CashBidData[]) {
    cashBids = _.sortBy(cashBids, ['startingDeliveryPeriod']);
    const startOfDay = moment().startOf('day');
    return cashBids.filter((currentCashBid) => {
      const commodityIdMatch = currentCashBid.commodityId === this.commodityId ;
      const bidCriteriaMatch = !this.bidCriteria || (currentCashBid.bidCriteria === this.bidCriteria);
      const locationMatch = !this.location || (currentCashBid.locationId === this.location);
      const futureMonthEndDateMatch = moment(currentCashBid.futureMonthEndDate).isSameOrAfter(startOfDay);
      return commodityIdMatch && bidCriteriaMatch && futureMonthEndDateMatch && locationMatch;
    });
  }

  findCurrentCashBid(cashBids: CashBidData[]) {
    const startOfDay = moment().startOf('day');
    const filtered = cashBids.filter((item) => {
      const futureMonthEndDateMatch = moment(item.futureMonthEndDate).isSameOrAfter(startOfDay);
      const startingDeliveryPeriodMatch = moment(item.startingDeliveryPeriod).isSameOrBefore(startOfDay);
      const endingDeliveryPeriodMatch = moment(item.endingDeliveryPeriod).isSameOrAfter(startOfDay);
      return futureMonthEndDateMatch && startingDeliveryPeriodMatch && endingDeliveryPeriodMatch;
    });
    return filtered.length ? filtered[0] : null;
  }

  setCashBidsWithQuoteData(cashBids: CashBidData[], quoteData: BarchartsApiQuoteData) {
    cashBids.forEach((cashBid) => {
      cashBid.quote = this.getQuoteForCashBid(cashBid, quoteData);
    });
    cashBids = cashBids.filter((cashBid) => !!cashBid.quote);
    this.currentCashBid = this.findCurrentCashBid(cashBids) || cashBids[0];
    this.relatedCashBids = cashBids.filter(x => x !== this.currentCashBid);
    this.cashBidsWithUniqueSymbol = quoteData.results
      .map(quote =>
        cashBids.find(cashBid => cashBid.commoditySymbol === quote.symbol) ||
        cashBids.find(cashBid =>
          this.firstThreeCharactersMatch(cashBid.commoditySymbol, quote.symbol) &&
          this.lastCharacterMatches(cashBid.commoditySymbol, quote.symbol)
        )
      )
      .filter(cashBid => !!cashBid);
  }

  getQuoteForCashBid(cashBid: CashBidData, quoteData: BarchartsApiQuoteData): Quote {
    const exactMatch = quoteData.results.find((result) => result.symbol === cashBid.commoditySymbol);
    const looseMatch = quoteData.results.find((result) => {
      const firstThreeMatch = this.firstThreeCharactersMatch(result.symbol, cashBid.commoditySymbol);
      const lastOneMatches = this.lastCharacterMatches(result.symbol, cashBid.commoditySymbol);
      return firstThreeMatch && lastOneMatches;
    });
    return new Quote(exactMatch || looseMatch);
  }

  setCashBidsWithSymbolData(cashBids: CashBidData[], symbolData: BarchartsApiSymbolData[]) {
    cashBids.forEach((cashBid) => {
      cashBid.symbolData = this.getSymbolForCashBid(cashBid, symbolData);
    });
  }

  getSymbolForCashBid(cashBid: CashBidData, symbolData: BarchartsApiSymbolData[]): BarchartsApiSymbolData {
    const exactMatch = symbolData.find((result) => result.symbol === cashBid.commoditySymbol);
    const looseMatch = symbolData.find((result) => {
      const firstThreeMatch = this.firstThreeCharactersMatch(result.symbol, cashBid.commoditySymbol);
      const lastOneMatches = this.lastCharacterMatches(result.symbol, cashBid.commoditySymbol);
      return firstThreeMatch && lastOneMatches;
    });
    return exactMatch || looseMatch;
  }

  private firstThreeCharactersMatch(value1, value2) {
    const value1Valid = value1 && value1.length >= 3;
    const value2Valid = value2 && value2.length >= 3;
    if (value1Valid && value2Valid) {
      return value1.substring(0, 3) === value2.substring(0, 3);
    }
  }

  private lastCharacterMatches(value1, value2) {
    const value1Valid = value1 && value1.length >= 1;
    const value2Valid = value2 && value2.length >= 1;
    if (value1Valid && value2Valid) {
      return value1.charAt(value1.length - 1) === value2.charAt(value2.length - 1);
    }
  }

  private async loadDtnWidgets(dtnKeys: DtnApiKey, commodities, symbols) {
    if (!window['dtn']) {
      await Promise.all([this.LoadCashBidsTable(), this.LoadFuturesTable()]);
    }
    const dtn = window['dtn'];

    this.loadCashBidsWidget(dtn, dtnKeys, commodities);
    this.loadFuturesWidget(dtn, dtnKeys);
  }

  async LoadCashBidsTable() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://content-services.dtn.com/ui-widgets/cash-bids-table-widget/cash-bids-table-widget-1.0-latest.js';
      script.onload = resolve;
      document.head.appendChild(script);
    });
  }

  async LoadFuturesTable() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://content-services.dtn.com/ui-widgets/futures-table-widget/futures-table-widget-1.0-latest.js';
      script.onload = resolve;
      document.head.appendChild(script);
    });
  }

  loadCashBidsWidget(dtn, dtnKeys: DtnApiKey, commodities: string[]) {
    dtn.cashBids.createCashBidsTableWidget({
      'apiKey': dtnKeys.apiKey,
      'container': '#cash-bids-table-widget',
      'siteId': dtnKeys.siteId,
      'showGroupByOptions': false,
      'view': 'DETAIL',
      'showViewOptions': true,
      'showLocationsSelect': true,
      'showCommoditiesSelect': false,
      'defaultLocation': {
        'locationName': this.location
      },
      'hideCommodities': commodities.filter(x => x !== this.commodityId),
      'visibleFields': [
        'DELIVERY_END',
        'CASH_PRICE',
        'BASIS_PRICE',
        'SYMBOL',
        'FUTURES_QUOTE',
        'FUTURES_CHANGE'
      ]
    });
  }

  loadFuturesWidget(dtn, dtnKeys: DtnApiKey) {
    const getSymbolFilter = () => {
      if (this.commodityId.startsWith('Corn')) {
        return '@C`##';
      }
      if (this.commodityId.startsWith('Wheat')) {
        return '@W`##';
      }
      if (this.commodityId.startsWith('Soy')) {
        return '@S`##';
      }
      return '';
    };

    const symbolFilter = getSymbolFilter();
    if (!symbolFilter) {
      return;
    }
    dtn.futures.createFuturesTableWidget({
      'apiKey': dtnKeys.futuresApiKey,
      'container': '#futures-table-widget',
      'symbol': getSymbolFilter(),
      'visibleFields': [
        'LAST',
        'CHANGE',
        'HIGH',
        'LOW'
      ]});
  }
}
