import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JwtService {
  private static helper: JwtHelperService = new JwtHelperService();

  constructor() { }

  static isTokenExpired(token: string): boolean {
    return JwtService.helper.isTokenExpired(token);
  }
}
