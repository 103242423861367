import {Injectable} from '@angular/core';
import {HttpClientWithAuthHeadersService} from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import {ENVIRONMENT} from '../../../../environments/environment';
import {Observable, from as fromPromise} from 'rxjs';

@Injectable()
export class GrowerInfoListService {
  static readonly Lifetime = '24h';

  constructor(private httpClient: HttpClientWithAuthHeadersService) { }

  fetch(customerIds: string[]): Observable<GrowerInfoResponseDto[]> {
    let url = `${ENVIRONMENT.gatewayApiBaseUrl}/legacyusers/growerids?`;

    customerIds.forEach((value, index) => {
      url = url + `customerid[${index}]=${value}&`;
    });

    return fromPromise(this.httpClient
      .get<GrowerInfoResponseDto[]>(url, null, GrowerInfoListService.Lifetime));
  }
}

export class GrowerInfoResponseDto {
  growId: string;
  growerGuid: string;
  firstName: string;
  lastName: string;
  regularBalance: number;
}
