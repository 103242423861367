import {Component, OnInit, Inject} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Store, Select} from '@ngxs/store';
import {APP_STATE} from './state/app.state';
import {DATA_STATE} from './state/data/data.state';
import {COMPANY_DATA_STATE} from './state/data/company-data/company-data.state';
import {filter, map, tap} from 'rxjs/operators';
import {AnalyticsService} from './analytics.service';
import {TitleService} from './title.service';
import {combineLatest, Observable} from 'rxjs';
import {StorageWithLifetimeService} from './shared/shared-services/storage-with-lifetime/storage-with-lifetime.service';
import {DOCUMENT} from '@angular/common';
import {CompanyLogoService} from './shared/shared-services/company-logo/company-logo.service';
import { USER_DATA_STATE } from './state/data/user-data/user-data.state';
import { UserData } from './state/data/user-data/user-data.types';
import {AuthTokenService, BroadcastClientService} from 'sky-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Select(state => state[APP_STATE][DATA_STATE][USER_DATA_STATE]) userData$: Observable<UserData>;

  constructor(private store: Store,
              private router: Router,
              private titleService: TitleService,
              private analyticsService: AnalyticsService,
              private broadcastClient: BroadcastClientService,
              private authTokenService: AuthTokenService,
              private lifetimeService: StorageWithLifetimeService,
              private companyLogoService: CompanyLogoService,
              @Inject(DOCUMENT) private _document: HTMLDocument) {
  }

  ngOnInit(): void {
    this.lifetimeService.clearData();
    this.setTitleIcon();
    const routerObservable = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      tap((e: NavigationEnd) => {
        this.analyticsService.sendPageView(e.urlAfterRedirects);
        this.analyticsService.sendEvent('navigate', 'grower360-navigation');
      }));

    const stateObservable = this.store.select(s => s[APP_STATE][DATA_STATE][COMPANY_DATA_STATE])
      .pipe(
        filter((s) => s),
        map((s) => s.companyName));

    combineLatest(routerObservable, stateObservable, this.userData$, (route, company, userData) => {
      this.titleService.set(company);
    }).subscribe();

    if (this.authTokenService.hasSavedToken()) {
      this.broadcastClient.sendMessage('sign-in/refresh', this.authTokenService.getAuthToken());
    }
  }

  private setTitleIcon(): void {
    this.companyLogoService.getCompanyLogo().subscribe(
      (logoUrl) => {
        this._document.getElementById('appFavicon').setAttribute('href', logoUrl);
      },
      () => {
      }
    );
  }
}
