import { UserData } from './user-data/user-data.types';
import { InvoiceData } from './invoice-data/invoice-data.types';
import { PaymentData } from './payment-data/payment-data.types';
import { GrainBalanceData } from './grain-balance-data/grain-balance-data.types';
import { GrainScaleTicketData } from './grain-scale-ticket-data/grain-scale-ticket-data.types';
import { GrainPurchaseContractData } from './grain-purchase-contract-data/grain-purchase-contract-data.types';
import { CustomerData } from './customer-data/customer-data.types';
import { GrainSettlementData } from './grain-settlement-data/grain-settlement-data.types';
import {FieldData} from './field-data/field-data.types';
import {GrowerInfoResponseDto} from '../../core/core-services/grower-id/grower-info-list.service';

export class Data {
  userData: UserData;
  customerData: CustomerData;
  invoiceData: InvoiceData[];
  paymentData: PaymentData[];
  grainBalanceData: GrainBalanceData[];
  grainScaleTicketData: GrainScaleTicketData[];
  grainPurchaseContractData: GrainPurchaseContractData[];
  grainSettlementData: GrainSettlementData[];
  fieldData: FieldData[];
  accountData: GrowerInfoResponseDto[];
}

export class DataStore {
  userData = new UserData();
  customerData = new CustomerData();
  invoiceData = [];
  paymentData = [];
  memoInvoiceData = [];
  grainBalanceData = [];
  grainScaleTicketData = [];
  grainPurchaseContractData = [];
  grainSettlementData = [];
  fieldData: FieldData[];
  accountData = [];
}
