import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceData } from '../../../../state/data/invoice-data/invoice-data.types';
import * as _ from 'lodash';

@Pipe({
  name: 'sortInvoices'
})
export class SortInvoicesPipe implements PipeTransform {

  transform(invoices: InvoiceData[]): InvoiceData[] {
    if (!invoices) {
      return invoices;
    }

    return _.sortBy(invoices, ['date', 'number']).reverse().slice(0, 3);
  }
}
