import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortRecentScaleTicketsPipe } from './sort-recent-scale-tickets.pipe';
import { SortInvoicesPipe } from './sort-invoices.pipe';
import { SortPaymentsPipe } from './sort-payments.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SortRecentScaleTicketsPipe,
    SortInvoicesPipe,
    SortPaymentsPipe
  ],
  exports: [
    SortRecentScaleTicketsPipe,
    SortInvoicesPipe,
    SortPaymentsPipe
  ],
})

export class RecentActivityPipesModule {}
