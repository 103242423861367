
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../environments/environment';
import { CashBidData, DtnApiKey } from '../../../state/data/cash-bid-data/cash-bid-data.types';
import { DexxHttp } from '@ssiagvance/dexx';
import { LocalStorageService } from '../../../shared/shared-services/local-storage/local-storage.service';

@Injectable()
export class CashBidsService {
  static readonly Path = `${ENVIRONMENT.gatewayApiBaseUrl}/cashbids`;

  constructor(private dexxHttp: DexxHttp) { }

  async fetch(companyId: string): Promise<CashBidData[]> {
    const url = `${CashBidsService.Path}/cashbids/${companyId}`;

    return this.dexxHttp.get(url, '5m', undefined, {}).then(x => x.body.map(cashBid => {
      if (cashBid.source !== 'dtn') {
        cashBid.locationId = cashBid.locationId || 'Not Specified';
        cashBid.bidCriteria = cashBid.bidCriteria || 'Not Specified';
      }
      return cashBid;
    }));
  }

  async fetchDtnApiKey(companyId: string): Promise<DtnApiKey> {
    const url = `${CashBidsService.Path}/dtnapikey/${companyId}`;
    return this.dexxHttp.get(url, '5m', undefined).then(x => x.body);
  }
}
