import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: 'overlay.component.html',
  styleUrls: ['overlay.component.scss']
})

export class OverlayComponent {
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();

  overlayClick(event): void {
    this.clickEmitter.emit(event);
  }
}
