<div data-id="account-dropdown"
     *ngIf="accountDropdownOptions$ | async let options"
     class="account-dropdown-container {{options.fontColor | fontColor}}" (click)="toggleDropdown()">
  <div class="user-display clickable">
    <div data-id="user-avatar" class="avatar-container {{options.avatarColor | avatarColor}}" *ngIf="options.displayAvatar">
      <div class="first-initial">{{options.name | slice:0:1 | uppercase}}</div>
    </div>
    <div data-id="user-name" class="user-name">{{options.name}}</div>
    <div>
      <div class="chevron-down" *ngIf="!showList"></div>
      <div class="chevron-up" *ngIf="showList"></div>
    </div>
  </div>
  <div data-id="dropdown-list" class="account-dropdown-list-container" *ngIf="showList">
    <div class="dropdown-list-item clickable" *ngFor="let option of options.listOptions" (click)="invokeAction(option.action)">
      <div data-id="list-item-icon" class="{{option.iconClass}}"></div>
      <div data-id="list-item-text" class="dropdown-list-item-text">{{option.text}}</div>
    </div>
  </div>
</div>
