import {AfterViewInit, Component, ElementRef, Renderer2} from '@angular/core';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {FIELD_DATA_STATE} from '../../../../state/data/field-data/field-data.state';
import {APP_STATE} from '../../../../state/app.state';
import {DATA_STATE} from '../../../../state/data/data.state';
import {CashBidData} from '../../../../state/data/cash-bid-data/cash-bid-data.types';
import {Location} from '@angular/common';

@Component({
  selector: 'app-cash-bid-details-page',
  templateUrl: './cash-bid-details-page.component.html',
  styleUrls: ['./cash-bid-details-page.component.scss']
})
export class CashBidDetailsPageComponent implements AfterViewInit {
  commodityId: string;
  bidCriteria: string;
  breadCrumbText: string;
  location: string;
  breadCrumbsFixed: boolean;

  @Select(state => state[APP_STATE][DATA_STATE][FIELD_DATA_STATE])
  cashBidData$: Observable<CashBidData[]>;

  constructor(private route: ActivatedRoute,
              private locationObj: Location,
              private elementRef: ElementRef,
              private renderer: Renderer2,
              private router: Router) {
    this.route.queryParams.subscribe((params) => {
      this.commodityId = params.commodityId;
      this.bidCriteria = params.bidCriteria;
      this.location = params.location;
      this.breadCrumbText = this.getBreadCrumbText(params);
    });
  }

  getBreadCrumbText(params: Params): string {
    const breadCrumbText = `${params.location ? params.location : ''} ${params.bidCriteria ? params.bidCriteria : ''}`.trim();
    return breadCrumbText || params.commodityId;
  }

  ngAfterViewInit(): void {
    this.breadCrumbPosition();
  }

  handleBreadcrumbLink(breadcrumb: string) {
    this.router.navigate(['overview']);
  }

  breadCrumbPosition(): void {
    const scrollContainer = this.elementRef.nativeElement.querySelector('.scroll-container');
    this.renderer.listen(scrollContainer, 'scroll', () => {
      this.toggleBreadCrumbs();
    });
  }

  public toggleBreadCrumbs() {
    if (this.elementRef.nativeElement.querySelector('.scroll-container').scrollTop > 10) {
      this.breadCrumbsFixed = true;
    } else {
      this.breadCrumbsFixed = false;
    }
  }
}
