<div *ngIf="scaleTicketData$ | async as scaleTickets" class="grain-scale-tickets">
  <ng-container *ngIf="isDoneLoading(scaleTickets) else loading">
    <ng-container>
      <div class="list-container">
        <div class="column-headers" data-id="column-headers">
          <div class="title-container" data-id="title">
            <div class="list-icon" data-id="icon"></div>
            <span class="header-title">Scale Tickets</span>
          </div>
          <div *ngFor="let column of columnTitles" class="column-title">{{column}}</div>
        </div>
        <div class="rows">
          <ng-container *ngIf="scaleTickets">
            <div *ngFor="let ticket of scaleTickets | sortRecentScaleTickets" class="scale-ticket-for">
              <div (click)="routeToScaleTicket(ticket.ticketNumber)" class="desktop-table-data clickable">
                <div class="desktop-row">
                  <div class="scale-ticket-badge">
                    <img src="../../../../../../assets/images/scale-ticket-icon-white.svg">
                  </div>
                  <div class="scale-ticket-number">{{ticket.ticketNumber}}</div>
                </div>
                <div class="row-data">{{ticket.date | utcDate | date: 'MM/dd/yyyy'}}</div>
                <div class="row-data">{{ticket.commodityName}}</div>
                <div class="row-data">{{ticket.netUnit}}</div>
                <div class="row-data">{{ticket.yourSplit}}</div>
              </div>

              <div (click)="routeToScaleTicket(ticket.ticketNumber)" class="mobile-table-data clickable">
                <div class="mobile-row">
                  <div class="scale-ticket-badge">
                    <img src="../../../../../../assets/images/scale-ticket-icon-white.svg">
                  </div>
                  <div class="scale-ticket-number">{{ticket.ticketNumber}}</div>
                </div>
                <div class="mobile-row">
                  <div class="row-title">Date</div>
                  <div class="row-data">{{ticket.date | utcDate | date: 'MM/dd/yyyy'}}</div>
                </div>
                <div class="mobile-row">
                  <div class="row-title">Commodity</div>
                  <div class="row-data">{{ticket.commodityName}}</div>
                </div>
                <div class="mobile-row">
                  <div class="row-title">Net Units</div>
                  <div class="row-data">{{ticket.netUnit}}</div>
                </div>
                <div class="mobile-row">
                  <div class="row-title">Your Split</div>
                  <div class="row-data">{{ticket.yourSplit}}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<ng-template #loading>
  <app-recent-activity-skeleton></app-recent-activity-skeleton>
</ng-template>
