<div class="image" tabindex="-1" (blur)="handleClickAway()" (mouseover)="mouseOver = true" (mouseout)="mouseOver = false" [ngClass]="{'image-hover': showOptions}" data-id="imageWrapper">
  <div (click)="toggleShowOptions()">
    <div [ngClass]="[imageName, 'image-icon']"></div>
  </div>
  <div class="options opened" *ngIf="showOptions" data-id="optionsWrapper">
    <ng-container *ngIf="!showError">
      <div class="menu-items" *ngFor="let opt of options" (click)="handleOptionClicked(opt)" data-id="optionElement">
        <div [ngClass]="[opt.image, 'icon-image']" *ngIf="!showPlaceholder" data-id="optionImage"></div>
        <div class="option-placeholder-icon" *ngIf="showPlaceholder" data-id="placeholderOptionImage"></div>
        <span [ngClass]="{'active' : opt.activeText}" *ngIf="!showPlaceholder" data-id="optionText">{{opt.text}}</span>
        <span class="option-placeholder-text" *ngIf="showPlaceholder" data-id="placeholderOptionText"></span>
      </div>
    </ng-container>
    <div class="error" *ngIf="showError" data-id="errorElement">
      <div class="{{errorOptions.image}} error-image"></div>
      <div class="error-text">{{errorOptions.text}}</div>
    </div>
  </div>
</div>
