<div *ngIf="cashBid && cashBid.quote" class="net-change-row">
  <div *ngIf="cashBid.quote.netChange !== 0" [class.down]="cashBid.quote.netChange < 0"
    [class.up]="cashBid.quote.netChange > 0">
    <img *ngIf="cashBid.quote.netChange >= 0" [ngClass]="size" class="arrow-image" src="../../../../../../assets/images/arrowGreen.svg">
    <img *ngIf="cashBid.quote.netChange < 0" [ngClass]="size" class="arrow-image" src="../../../../../../assets/images/arrowRed.svg">
  </div>
  <div *ngIf="!isNaN(cashBid.quote.netChange)" [ngClass]="size" class="light-grey-text small-padding-left">
    {{cashBid.quote.netChange | number : precision || '1.2-2'}}
  </div>
  <div *ngIf="isNaN(cashBid.quote.netChange)" [ngClass]="size" class="light-grey-text small-padding-left">
    {{cashBid.quote.netChange}}
  </div>
</div>
