import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ENVIRONMENT} from '../../../../environments/environment';
import {ContractSignatureData} from '../../../shared/types/contract-signature-data';
import {LocalStorageService} from '../../../shared/shared-services/local-storage/local-storage.service';
import {Observable} from 'rxjs';

export class SignedContract {
  s3FileNameGuid: string;
}

@Injectable({
  providedIn: 'root'
})
export class SellerSignatureService {
  static SAVE_SELLER_SIGNATURE_PATH = `${ENVIRONMENT.gatewayApiBaseUrl}/signature/contract`;

  constructor(private httpClient: HttpClient, private storage: LocalStorageService) { }

  saveSellerSignature(signatureData: string, purchaseContractGuid: string): Observable<SignedContract> {
    const authToken = this.storage.getItem(LocalStorageService.AUTH_TOKEN_KEY);
    const headers = {headers: {'Authorization': authToken, 'Accept': 'application/json'}};

    const body: ContractSignatureData = {
      Signature: signatureData,
      PurchaseContractGuid: purchaseContractGuid,
      UserLocalTime: new Date()
    };

    return this.httpClient.post<SignedContract>(SellerSignatureService.SAVE_SELLER_SIGNATURE_PATH, body, headers);
  }
}
