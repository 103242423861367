import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ENVIRONMENT} from '../../../../environments/environment';
import {BarchartsApiSymbolData} from '../../../state/data/cash-bid-data/barcharts-api-symbol-data.types';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class BarchartsApiSymbolService {
  static readonly Path = `${ENVIRONMENT.gatewayApiBaseUrl}/cashbids/barchartsapisymbol`;

  constructor(private httpClient: HttpClient) { }

  fetch(companyId: string, symbols: string[]): Promise<BarchartsApiSymbolData[]> {
    const url = `${BarchartsApiSymbolService.Path}/${companyId}/?symbols=${symbols.join(',')}`;

    return this.httpClient.get<BarchartsApiSymbolData[]>(url).toPromise();
  }
}
