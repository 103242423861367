import { Action, State, StateContext } from '@ngxs/store';
import { AccountDropdown } from './account-dropdown.types';
import { UpdateAccountDropdown } from './account-dropdown.action';
import { AccountDropdownAvatarColors, AccountDropdownFontColors }
from '../../../core/components/header/account-dropdown/account-dropdown.types';

export const ACCOUNT_DROPDOWN_STATE = 'accountDropdown';

@State<AccountDropdown>({
  name: ACCOUNT_DROPDOWN_STATE,
  defaults: {
    name: 'User Account',
    displayAvatar: true,
    avatarColor: AccountDropdownAvatarColors.grower360Gradient,
    fontColor: AccountDropdownFontColors.grower360LightBlue,
    listOptions: []
  }
})

export class AccountDropdownState {
  @Action(UpdateAccountDropdown)
  updateAccountDropdown({ setState }: StateContext<AccountDropdown>, payload: UpdateAccountDropdown) {
    setState(payload.accountDropdown);
  }
}
