
import {map, catchError} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable ,  throwError as _throw ,  of } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { Grower360HttpService } from '../grower360-http/grower360-http.service';
import { CompanyInfo } from './subdomain.interface';
import { ENVIRONMENT } from '../../../../environments/environment';


@Injectable()
export class SubdomainService {
  static readonly SubdomainCompanyInfoUrlPath = `${ENVIRONMENT.grower360ApiBaseUrl}/companysubdomain/companyinfo`;

  constructor( @Inject(DOCUMENT) private document, private httpClient: Grower360HttpService) { }

  parseSubdomain(): string {
    const url = document.location.hostname.split('.');
    return url.length < 3 ? '' : url[0];
  }

  checkValidSubdomain(): Observable<boolean> {
    const subdomain = this.parseSubdomain();

    if (subdomain === '') {
      return of(false);
    }

    return this.retrieveCompanyInfoForSubdomain(subdomain).pipe(
      catchError((err: any) => _throw(err)),
      map(() => true));
  }

  retrieveCompanyInfoForSubdomain(subdomain: string): Observable<CompanyInfo> {
    const path = `${SubdomainService.SubdomainCompanyInfoUrlPath}`;
    const params = new HttpParams().set('subdomain', subdomain);

    return this.httpClient.get<CompanyInfo>(path, {params});
  }
}
