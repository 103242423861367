import { Action, State, StateContext } from '@ngxs/store';
import { PaymentData } from './payment-data.types';
import { UpdatePaymentData } from './payment-data.actions';

export const PAYMENT_DATA_STATE = 'paymentData';
export const PAYMENT_DATA_LOADING = {};
@State<PaymentData[] | {}>({
  name: PAYMENT_DATA_STATE,
})
export class PaymentDataState {
  @Action(UpdatePaymentData)
  updatePaymentData({ setState }: StateContext<PaymentData[] | {}>, payload: UpdatePaymentData) {
    setState(payload.paymentData);
  }
}
