<div class="bread-crumb-container">
  <div class="bread-crumb grey-text" *ngFor="let breadCrumb of breadCrumbs; index as idx">
    <ng-container *ngIf="(idx + 1) !== breadCrumbs.length">
      <div class="clickable" [ngClass]="getBreadCrumbWidthStyle()" (click)="handleCrumbClick(breadCrumb)">{{breadCrumb}}</div>
    </ng-container>
    <ng-container *ngIf="(idx + 1) === breadCrumbs.length">
      <div>{{breadCrumb}}</div>
    </ng-container>
    <div class="arrow_right"></div>
  </div>
</div>
