import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarColorPipe } from './avatar-color.pipe';
import { FontColorPipe } from './font-color.pipe';

@NgModule({
  imports:        [CommonModule],
  declarations:   [AvatarColorPipe, FontColorPipe],
  exports:        [AvatarColorPipe, FontColorPipe],
})

export class AccountDropdownPipesModule {}
