import { Injectable } from '@angular/core';
import { ProvideTimeService } from '../provide-time/provide-time.service';

@Injectable()
export class ParseStorageExpirationService {
  static readonly InvalidLifetime = 'Valid lifetimes contain an integer unit value and single character time unit';

  constructor(private timeProvider: ProvideTimeService) { }

  parse(lifetime: string): number {
    const lifetimeVal = (lifetime || '').trim().toLowerCase();
    const regex = /^([0-9]+)([smh]{1})$/;
    const regexMatchArray = lifetimeVal.match(regex);
    if (regexMatchArray === null) {
      throw new Error(ParseStorageExpirationService.InvalidLifetime);
    }

    const num = parseInt(regexMatchArray[1], 10);
    const unit = regexMatchArray[2];
    let multiplier = 0;
    switch (unit) {
      case 'm': multiplier = 60; break; // # of seconds in minute
      case 'h': multiplier = 3600; break; // # of seconds in hour
      default: multiplier = 1;
    }

    const seconds = num * multiplier * 1000; // convert to milliseconds
    const now = this.timeProvider.currentTime();

    return now + seconds;
  }
}
