import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommodityIconComponent} from './commodity-icon.component';
import {SkyIconModule} from '@ssi-nimbus/icon';

@NgModule({
  imports: [CommonModule, SkyIconModule],
  declarations: [CommodityIconComponent],
  exports: [CommodityIconComponent]
})
export class CommodityIconModule {
}
