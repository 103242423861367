import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { stubCustomerMemoInvoices } from '../../../app.stub-data';
import { CustomerMemoInvoicesParameters } from './customer-memo-invoices-parameters';
import {InvoiceData} from '../../../state/data/invoice-data/invoice-data.types';

@Injectable()
export class CustomerMemoInvoicesStubService {

  retrieveInvoices(agvanceCustomerId: string, additionalParams?: CustomerMemoInvoicesParameters): Observable<InvoiceData[]> {

    return of(stubCustomerMemoInvoices);
  }
}
