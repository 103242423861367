<div class="root-container">
  <div class="navigation">
    <app-sidebar
                 [sidebarOptions]="sidebarOptions"
                 [ngClass]="(mobileSidebar$ | async)?.display ? 'show-sidebar' : 'sidebar'">
    </app-sidebar>
  </div>
  <div class="content-area">
    <div class="content-header">
      <app-header [userName]="userName" [customerData]="customerData" *ngIf="userName"></app-header>
    </div>
    <div class="content-outlet">
      <router-outlet></router-outlet>
      <sky-snackbar></sky-snackbar>
    </div>
  </div>
</div>
