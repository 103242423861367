import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { BreadcrumbScrollEvent } from '../breadcrumb-navigation-types';

export class BreadcrumbNavigationScrollService {
  public static readonly ScrollEventTimeout = 100;
  public static readonly ScrollTolerance = 2;
  private subscriptions = new Map<HTMLElement, Subscription>();
  private eventHistory = new Map<HTMLElement, BreadcrumbScrollEvent>();

  public startScrollObserver(navigationContainer: HTMLElement,
                             emitAllEvents = false,
                             eventSource?: Observable<any>): Observable<BreadcrumbScrollEvent> {
    const initEvent: BreadcrumbScrollEvent = {
      top: navigationContainer.scrollTop,
      height: navigationContainer.scrollHeight, scrollEvent: 'none', delta: 0
    };
    const emitter = new Subject<BreadcrumbScrollEvent>();
    this.eventHistory.set(navigationContainer, initEvent);

    const source$ = eventSource || fromEvent(navigationContainer, 'scroll');
    const subscription = source$.pipe(
      debounceTime(BreadcrumbNavigationScrollService.ScrollEventTimeout),
      map(() => {
        const lastEvent = this.eventHistory.get(navigationContainer);
        const scrollTop = navigationContainer.scrollTop;
        const scrollHeight = navigationContainer.scrollHeight;
        const rawDelta = (lastEvent.height - lastEvent.top) - (scrollHeight - scrollTop);
        const delta = Math.abs(rawDelta) < BreadcrumbNavigationScrollService.ScrollTolerance ? 0 : rawDelta;
        const newEvent: BreadcrumbScrollEvent = { top: scrollTop, height: scrollHeight, delta,
          scrollEvent:  delta === 0 ? 'none' : (delta > 0 ? 'scroll-down' : 'scroll-up')
        };
        this.eventHistory.set(navigationContainer, newEvent);
        return newEvent;
      }),
      filter((evt: BreadcrumbScrollEvent) => emitAllEvents ? true : evt.scrollEvent !== 'none')
    ).subscribe((evt: BreadcrumbScrollEvent) => {
      emitter.next(evt);
    });

    this.subscriptions.set(navigationContainer, subscription);
    return emitter;
  }

  public stopScrollObserver(navigationContainer: HTMLElement): void {
    if (this.subscriptions.has(navigationContainer)) {
      this.subscriptions.get(navigationContainer).unsubscribe();
    }
  }
}
