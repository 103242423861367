import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './app.state';
import { DataState } from './data/data.state';
import { GrainBalanceDataState } from './data/grain-balance-data/grain-balance-data.state';
import { OpenInvoiceDataState, RecentActivityInvoiceDataState, InvoiceDataState } from './data/invoice-data/invoice-data.state';
import { PaymentDataState } from './data/payment-data/payment-data.state';
import { UserDataState } from './data/user-data/user-data.state';
import { AccountDropdownState } from './user-interface/account-dropdown/account-dropdown.state';
import { MobileSidebarState } from './user-interface/mobile-sidebar/mobile-sidebar.state';
import { SidebarExpansionState } from './user-interface/sidebar-expansion/sidebar-expansion.state';
import { SidebarSelectionState } from './user-interface/sidebar-selection/sidebar-selection.state';
import { UserInterfaceState } from './user-interface/user-interface.state';
import { WindowSizeState } from './window-size/window-size.state';
import { GrainScaleTicketDataState } from './data/grain-scale-ticket-data/grain-scale-ticket-data.state';
import { GrainPurchaseContractDataState } from './data/grain-purchase-contract-data/grain-purchase-contract-data.state';
import { CustomerDataState } from './data/customer-data/customer-data.state';
import { GrainSettlementDataState } from './data/grain-settlement-data/grain-settlement-data.state';
import { CompanyDataState } from './data/company-data/company-data.state';
import { MemoInvoiceDataState } from './data/memo-invoice-data/memo-invoice-data.state';
import { FieldDataState } from './data/field-data/field-data.state';
import { AccountDataState } from './data/account-data/account-data.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      AppState,
      WindowSizeState,
      UserInterfaceState,
      SidebarSelectionState,
      SidebarExpansionState,
      UserDataState,
      OpenInvoiceDataState,
      InvoiceDataState,
      PaymentDataState,
      DataState,
      MobileSidebarState,
      AccountDropdownState,
      GrainBalanceDataState,
      GrainScaleTicketDataState,
      GrainPurchaseContractDataState,
      RecentActivityInvoiceDataState,
      CustomerDataState,
      GrainSettlementDataState,
      CompanyDataState,
      MemoInvoiceDataState,
      FieldDataState,
      AccountDataState
    ])
  ]
})
export class AppStateModule { }
