import { Component, Input, HostListener, AfterContentInit, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {Observable} from 'rxjs';
import { APP_STATE } from '../../../state/app.state';
import { UpdateMobileSidebar } from '../../../state/user-interface/mobile-sidebar/mobile-sidebar.action';
import { MOBILE_SIDEBAR_STATE } from '../../../state/user-interface/mobile-sidebar/mobile-sidebar.state';
import { MobileSidebar, MobileSidebarSubMenu } from '../../../state/user-interface/mobile-sidebar/mobile-sidebar.types';
import { UpdateSidebarExpansion } from '../../../state/user-interface/sidebar-expansion/sidebar-expansion.actions';
import { SidebarExpansion } from '../../../state/user-interface/sidebar-expansion/sidebar-expansion.types';
import { UpdateCurrentSidebarSelection } from '../../../state/user-interface/sidebar-selection/sidebar-selection.actions';
import { USER_INTERFACE_STATE } from '../../../state/user-interface/user-interface.state';
import { WindowSizeService } from '../../core-services/window-size/window-size.service';
import { SidebarOptions } from './sidebar.types';
import { SIDEBAR_SELECTION_STATE } from '../../../state/user-interface/sidebar-selection/sidebar-selection.state';
import { SidebarMenuOption } from '../../../state/user-interface/sidebar-selection/sidebar-selection.types';
import { SubdomainService } from '../../../shared/shared-services/subdomain/subdomain.service';
import { CompanyInfo } from '../../../shared/shared-services/subdomain/subdomain.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements AfterContentInit, OnInit {
  private isInTabletBreakpoint = false;
  private isInMobileBreakpoint = false;
  companyInfo$: Observable<CompanyInfo>;
  isExpansionButtonVisible: boolean;
  currentlyExpanded: boolean;
  itemHovered = '';

  @Input() sidebarOptions: SidebarOptions = {
    customerName: 'default customer name',
    companyIcon: '',
    companyName: 'default company name',
    menuOptions: [
      {
        menuItemIcon: '',
        sectionName: 'Default',
        menuItemLink: '/myaccount',
        menuItemName: 'default'
      }
    ],
    selectedMenuOption: {
      menuItemIcon: '',
      sectionName: 'Default',
      menuItemLink: '/myaccount',
      menuItemName: 'default'
    }
  };

  @Select(state => state[APP_STATE][USER_INTERFACE_STATE][MOBILE_SIDEBAR_STATE])
  mobileSidebar$: Observable<MobileSidebar>;

  @Select(state => state[APP_STATE][USER_INTERFACE_STATE][SIDEBAR_SELECTION_STATE])
  sidebarSelection$: Observable<SidebarMenuOption>;

  phoneBreakpoint$: Observable<boolean>;
  tabletBreakpoint$: Observable<boolean>;
  desktopBreakpoint$: Observable<boolean>;

  constructor(
    private router: Router,
    private store: Store,
    windowSizeService: WindowSizeService,
    private subdomainService: SubdomainService) {

    this.phoneBreakpoint$ = windowSizeService
      .observeMinBreakpoint(WindowSizeService.mobileBreakpointSize);
    this.phoneBreakpoint$.subscribe(this.phoneBreakpointChanged.bind(this));

    this.tabletBreakpoint$ = windowSizeService
      .observeRangeBreakpoint(WindowSizeService.mobileBreakpointSize, WindowSizeService.desktopBreakpointSize);
    this.tabletBreakpoint$.subscribe(this.tabletBreakpointChanged.bind(this));

    this.desktopBreakpoint$ = windowSizeService
      .observeMaxBreakpoint(WindowSizeService.desktopBreakpointSize);
    this.desktopBreakpoint$.subscribe(this.desktopBreakpointChanged.bind(this));

    this.router.events.subscribe((event) => {
      if (event['url'] && this.sidebarOptions) {
        const matchingMenuItems = this.sidebarOptions.menuOptions.filter(x => event['url'].includes(x.menuItemLink));
        const sortedMenuItems = _.sortBy(matchingMenuItems, x => -x.menuItemLink.length);
        const mostRelevantMenuItem = sortedMenuItems[0];
        this.sidebarOptions.selectedMenuOption = mostRelevantMenuItem;
      }
    });
  }

  ngOnInit(): void {
    this.sidebarSelection$.subscribe(
      (data) => {
        this.sidebarOptions.selectedMenuOption = data;
      }
    );
    this.retrieveCompanyInfo();
  }

  private retrieveCompanyInfo(): void {
    const subdomain = this.subdomainService.parseSubdomain();
    this.companyInfo$ = this.subdomainService.retrieveCompanyInfoForSubdomain(subdomain);
  }

  private expand() {
    this.currentlyExpanded = true;
    this.dispatchSidebarExpansion(this.currentlyExpanded);
  }

  private condense() {
    this.currentlyExpanded = false;
    this.dispatchSidebarExpansion(this.currentlyExpanded);
  }

  private toggleExpanded() {
    if (this.currentlyExpanded) {
      this.condense();
    }
    else {
      this.expand();
    }
  }

  private phoneBreakpointChanged(value: boolean) {
    this.isInMobileBreakpoint = value;
    if (value) {
      this.dispatchMobileSidebar(false, MobileSidebarSubMenu.None);
    }
  }

  private tabletBreakpointChanged(value: boolean) {
    this.isInTabletBreakpoint = value;
    if (value) {
      this.dispatchMobileSidebar(true, MobileSidebarSubMenu.None);
      this.condense();
      this.showExpansionButton();
    }
    else {
      this.hideExpansionButton();
    }
  }

  private desktopBreakpointChanged(value: boolean) {
    if (value) {
      this.dispatchMobileSidebar(true, MobileSidebarSubMenu.None);
      this.expand();
    }
  }

  private dispatchSidebarSelection(menuItem: SidebarMenuOption) {
    this.store.dispatch(new UpdateCurrentSidebarSelection(menuItem));
  }

  private dispatchMobileSidebar(display: boolean, submenu: MobileSidebarSubMenu) {
    const payload: MobileSidebar = {
      display: display,
      submenu: submenu
    };
    this.store.dispatch(new UpdateMobileSidebar(payload));
  }

  private dispatchSidebarExpansion(expanded: boolean) {
    const payload: SidebarExpansion = {
      expanded: expanded
    };
    this.store.dispatch(new UpdateSidebarExpansion(payload));
  }

  ngAfterContentInit(): void {
    if (this.sidebarOptions) {
      this.sidebarOptions.selectedMenuOption = this.sidebarOptions.menuOptions.filter(x => x.menuItemLink === this.router.url)[0];
    }
  }

  menuItemClicked(menuItem: SidebarMenuOption) {
    this.dispatchSidebarSelection(menuItem);
    this.dispatchMobileSidebar(false, MobileSidebarSubMenu.None);
    this.router.navigate([menuItem.menuItemLink]);
  }

  mobileExitClicked() {
    this.dispatchMobileSidebar(false, MobileSidebarSubMenu.None);
  }

  profileClicked() {
    this.dispatchMobileSidebar(true, MobileSidebarSubMenu.Profile);
  }

  expansionButtonClicked() {
    this.toggleExpanded();
  }

  showExpansionButton() {
    this.isExpansionButtonVisible = true;
  }

  hideExpansionButton() {
    if (this.isInTabletBreakpoint) {
      return;
    }

    this.isExpansionButtonVisible = false;
  }

  showSubmenu(submenu: MobileSidebarSubMenu): boolean {
    return submenu !== MobileSidebarSubMenu.None;
  }

  goToHome(): void {
    if (!this.isInMobileBreakpoint) this.router.navigate(['']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth > 767) {
      const mobileSidebarOpts: MobileSidebar = {
        display: true,
        submenu: MobileSidebarSubMenu.None
      };
      this.store.dispatch(new UpdateMobileSidebar(mobileSidebarOpts));
    }
  }
}
