import { Quote } from './barcharts-api-quote-data.types';
import { BarchartsApiSymbolData } from './barcharts-api-symbol-data.types';

export class CashBidData {
  commodityId?: string;
  commoditySymbol?: string;
  commodityName?: string;
  bidCriteria?: string;
  startingDeliveryPeriod?: string;
  endingDeliveryPeriod?: string;
  futureMonth?: string;
  futureMonthEndDate?: string;
  basisPrice?: number;
  quote?: Quote;
  symbolData?: BarchartsApiSymbolData;
  locationId?: string;
  locationName?: string;
  city?: string;
  state?: string;
  aliasDescription?: string;
  source?: string;
  cashPrice?: number;
  change?: string;
  realtime?: boolean;
}

export class DtnApiKey {
  apiKey: string;
  futuresApiKey: string;
  siteId: string;
}
