import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {Grower360RouterModule} from './app.routes';
import {PublicModule} from './public/public.module';
import {CoreModule} from './core/core.module';
import {AppStateModule} from './state/app-state.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  AuthTokenModule,
  BroadcastClientModule,
  LocalStorageModule,
  SkyHttpModule,
  UserInfoModule
} from 'sky-services';
import {MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import {TitleService} from './title.service';
import {AnalyticsService} from './analytics.service';
import {WindowRefService} from './core/core-services/window-ref/window-ref.service';
import {CashBidsModule} from './core/components/cash-bids/cash-bids.module';
import {ENVIRONMENT} from '../environments/environment';
import {SkyIconModule} from '@ssi-nimbus/icon';
import {SkyEnvironmentModule} from 'sky-environment';
import { DexxHttp, IdxStorage } from '@ssiagvance/dexx';
import { BreadCrumbNavigationModule } from './core/components/breadcrumb-navigation/breadcrumb-navigation.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import {SkyPersistentNotificationBarModule} from '@ssi-nimbus/persistent-notification-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AbstractLocation } from './app.types';

const SHARED_ACCESS_TOKEN_STORAGE_KEY = 'Authorization';

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        SharedModule,
        BrowserModule,
        Grower360RouterModule,
        PublicModule,
        CoreModule,
        AppStateModule,
        BrowserAnimationsModule,
        BroadcastClientModule,
        LocalStorageModule.forRoot({
            authorizationTokenKey: SHARED_ACCESS_TOKEN_STORAGE_KEY
        }),
        UserInfoModule.forRoot({
            legacyDbLocalStorageKey: 'LEGACY_DB',
            userInfoLocalStorageKey: 'USER_INFO_KEY',
            apiPath: `${ENVIRONMENT.skydentityBaseUrl}/connect/userinfo`
        }),
        AuthTokenModule.forRoot({
            localStorageKey: SHARED_ACCESS_TOKEN_STORAGE_KEY
        }),
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MatFormFieldModule,
        CashBidsModule,
        SkyHttpModule.forRoot({
            forbiddenPath: '/error',
            unauthorizedPath: '/error',
            authTokenStorageKey: SHARED_ACCESS_TOKEN_STORAGE_KEY
        }),
        SkyEnvironmentModule.forRoot({
            appBaseUrl: '',
            environmentName: ENVIRONMENT.name,
            mappingUrl: '',
            staticContentBaseUrl: ENVIRONMENT.staticContentBaseUrl
        }),
        SkyIconModule.forRoot(),
        BreadCrumbNavigationModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
        SkyPersistentNotificationBarModule
    ],
  providers: [
    Title,
    TitleService,
    AnalyticsService,
    WindowRefService,
    {
      provide: DexxHttp,
      useValue: new DexxHttp()
    },
    {
      provide: JwtHelperService,
      useValue: new JwtHelperService()
    },
    {
      provide: IdxStorage,
      useValue: new IdxStorage(null, null, null, null, null)
    },
    {
      provide: AbstractLocation,
      useValue: window.location
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
