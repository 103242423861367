import { UserDataState } from './user-data/user-data.state';
import { Data, DataStore } from './data.types';
import { State } from '@ngxs/store';
import { OpenInvoiceDataState, RecentActivityInvoiceDataState, InvoiceDataState } from './invoice-data/invoice-data.state';
import { PaymentDataState } from './payment-data/payment-data.state';
import { GrainBalanceDataState } from './grain-balance-data/grain-balance-data.state';
import { GrainScaleTicketDataState } from './grain-scale-ticket-data/grain-scale-ticket-data.state';
import { GrainPurchaseContractDataState } from './grain-purchase-contract-data/grain-purchase-contract-data.state';
import { CustomerDataState } from './customer-data/customer-data.state';
import { GrainSettlementDataState } from './grain-settlement-data/grain-settlement-data.state';
import { CompanyDataState } from './company-data/company-data.state';
import { MemoInvoiceDataState } from './memo-invoice-data/memo-invoice-data.state';
import { FieldDataState } from './field-data/field-data.state';
import { AccountDataState } from './account-data/account-data.state';

export const DATA_STATE = 'data';
@State<Data>({
  name: DATA_STATE,
  children: [UserDataState, OpenInvoiceDataState, InvoiceDataState, PaymentDataState, GrainBalanceDataState,
    GrainScaleTicketDataState, GrainPurchaseContractDataState, RecentActivityInvoiceDataState, CustomerDataState,
    GrainSettlementDataState, CompanyDataState, MemoInvoiceDataState, FieldDataState, AccountDataState],
  defaults: new DataStore()
})
export class DataState {}
