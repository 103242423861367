import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {Select} from '@ngxs/store';
import {APP_STATE} from '../../../../state/app.state';
import {Observable, of} from 'rxjs';
import {DATA_STATE} from '../../../../state/data/data.state';
import {GrowerInfoResponseDto} from '../../../core-services/grower-id/grower-info-list.service';
import {ACCOUNT_DATA_STATE} from '../../../../state/data/account-data/account-data.state';
import {CustomerData} from '../../../../state/data/customer-data/customer-data.types';
import {filter, map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-customer-picker',
  templateUrl: './customer-picker.component.html',
  styleUrls: ['./customer-picker.component.scss'],
  animations: [
    trigger(
      'toggleAnimation', [
        transition(':enter', [
          style( { transform: 'scaleY(0)' }),
          animate('250ms cubic-bezier(.05, .69, .14, 1)', style({ transform: 'scaleY(1)' }))
        ]),
        transition(':leave', [
          style({ transform: 'scaleY(1)' }),
          animate('250ms cubic-bezier(1, .14, .69, .05)', style({ transform: 'scaleY(0)' }))
        ])
      ]
    )
  ]
})
export class CustomerPickerComponent {
  @Input() customerData: CustomerData;
  @Output() selectedNewItem: EventEmitter<string> = new EventEmitter();

  @Select(state => state[APP_STATE][DATA_STATE][ACCOUNT_DATA_STATE])
  accountData$: Observable<GrowerInfoResponseDto[]>;

  growerGuid: string;
  showList = false;

  constructor(private elementRef: ElementRef) { }

  toggleDropdown() {
    this.showList = !this.showList;
  }

  onClickItem(account: GrowerInfoResponseDto): void {
    this.selectedNewItem.emit(account.growerGuid);
  }

  notCurrentCustomer(account: GrowerInfoResponseDto): boolean {
    return account.growerGuid !== this.customerData.uuid;
  }

  hasMultipleAccounts(accounts: GrowerInfoResponseDto[]): boolean {
    return accounts.length > 1;
  }

  hasSameNameOfAnotherGrower(account: GrowerInfoResponseDto, accountList: GrowerInfoResponseDto[]): boolean {
    const growers = accountList.filter(
        item =>
        item.firstName.toLowerCase() === account.firstName.toLowerCase() &&
        item.lastName.toLowerCase() === account.lastName.toLowerCase()
    );

    return growers.length > 1;
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onDocumentClick(clickEvent) {
    const eventTarget = <HTMLScriptElement> clickEvent.target;
    const accountControl = <HTMLScriptElement> this.elementRef.nativeElement
      .querySelector('.customer-picker-container');

    if (accountControl && accountControl.contains(eventTarget)) { return; }

    this.showList = false;
  }
}
