import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { CustomerData } from '../../../state/data/customer-data/customer-data.types';
import { stubCustomerResponse } from '../../../app.stub-data';

@Injectable()
export class CustomerStubService {

  retrieveCustomer(customerId: string): Observable<CustomerData> {
    return of(stubCustomerResponse);
  }

  retrieveCustomerByGrowerId(growerId: string): Observable<CustomerData> {
    return of(stubCustomerResponse);
  }

  switchCustomer() {}
}
