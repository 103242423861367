import { Injectable } from '@angular/core';

@Injectable()
export class NormalizeUrlService {
  static readonly InvalidUrl = 'The url provided is not in a proper form';

  normalize(url: string): string {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.toString();
    } catch (e) {
      throw new Error(NormalizeUrlService.InvalidUrl);
    }
  }
}
