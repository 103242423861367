import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbNavigationScrollService } from './scroll-service/breadcrumb-navigation.service';
import { BreadcrumbScrollEvent } from './breadcrumb-navigation-types';

@Component({
  selector: 'app-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss']
})
export class BreadcrumbNavigationComponent implements OnInit, OnDestroy {
  private scrollContainer: HTMLElement;
  private scrollSubscription$: Subscription;
  public lastScrollEvent: BreadcrumbScrollEvent;
  public currentScrollEvent: BreadcrumbScrollEvent;
  @Output() public crumbClicked = new EventEmitter<string>();
  @Output() public debugMessage = new EventEmitter<string>();
  @Input() public breadcrumbs: string[];

  constructor(private elementRef: ElementRef, private scrollService: BreadcrumbNavigationScrollService) {
  }

  ngOnInit() {
    this.scrollContainer = (this.elementRef.nativeElement as HTMLElement).parentElement as HTMLElement;
    this.lastScrollEvent = { scrollEvent: 'none',
      top: this.scrollContainer.scrollTop, height: this.scrollContainer.scrollHeight, delta: 0};
    this.currentScrollEvent = {...this.lastScrollEvent};
    this.scrollSubscription$ = this.scrollService.startScrollObserver(this.scrollContainer).subscribe(
      (event: BreadcrumbScrollEvent) => {
        const msg = `scroll top: '${event.top}', height: '${event.height}', delta: '${event.delta}'`;
        this.debugMessage.emit(msg);
        this.lastScrollEvent = { ...this.currentScrollEvent };
        this.currentScrollEvent = { ...event };
      }
    );
  }

  ngOnDestroy(): void {
    this.scrollService.stopScrollObserver(this.scrollContainer);
    if (this.scrollSubscription$) { this.scrollSubscription$.unsubscribe(); }
  }

  handleCrumbClicked(crumbName: string) {
    this.crumbClicked.emit(crumbName);
  }

  isAtTopOfPage() {
    return this.currentScrollEvent && this.currentScrollEvent.top === 0;
  }

  isScrollingDown() {
    return this.currentScrollEvent && this.currentScrollEvent.scrollEvent === 'scroll-down';
  }
}
