import { Injectable } from '@angular/core';
import { CognitoSettingsService } from '../cognito-settings/cognito-settings.service';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

@Injectable()
export class GetCognitoUserPoolService {
  private readonly cognitoPoolCreatorObj: any;

  constructor(private readonly settings: CognitoSettingsService, cognitoPoolCreator?: any) {
    this.cognitoPoolCreatorObj = cognitoPoolCreator || AmazonCognitoIdentity;
  }

  public async get(companyId: string): Promise<AmazonCognitoIdentity.CognitoUserPool> {
    const settings = await this.settings.getUserPoolSettings(companyId);
    return new this.cognitoPoolCreatorObj.CognitoUserPool({ClientId: settings.clientId, UserPoolId: settings.userPoolId});
  }
}
