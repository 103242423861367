import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ResetPasswordGuardService } from './route-guards/reset-password-guard.service';
import { VerifyResetPasswordTokenService } from './public-services/verify-reset-password-token/verify-reset-password-token.service';
import { CommonModule } from '@angular/common';
import { PostSignInRoutingService } from './public-services/post-sign-in-routing/post-sign-in-routing.service';

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    CommonModule
  ],
  providers: [
    ResetPasswordGuardService,
    VerifyResetPasswordTokenService,
    PostSignInRoutingService
  ]
})
export class PublicModule { }
