export class GrainScaleTicketData {
  commodityName: string;
  unitOfMeasure: string;
  decimals: number;
  ticketNumber: string;
  date: Date;
  location: string;
  grossUnit: number;
  netUnit: number;
  assembly: string;
  farmName: string;
  comment: string;
  isInProcess: boolean;
  isTransferred: boolean;
  yourSplit?: number;
}

export enum ScaleTicketStatus {
  verified = 'Verified',
  inYard = 'In Yard',
  unverified = 'Unverified'
}

export enum ScaleTicketStatusColor {
  green = 'green-status',
  yellow = 'yellow-status',
  red = 'red-status'
}
