import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Observable ,  of } from 'rxjs';

@Injectable()
export class AuthStubService {
  constructor() { }

  isSignedIn(): boolean {
    return LocalStorageService.getAuthToken() !== null;
  }

  signIn(email: string, password: string): Observable<boolean> {
    if (email === 'kdenton@agvance.net' && password === 'password1234') return of(true);

    LocalStorageService.setAuthToken('Bearer');
    return of(false);
  }
}
