import { NgModule } from '@angular/core';
import { AccountProfileComponent } from './account-profile.component';
import { AccountDropdownPipesModule } from '../../header/account-dropdown/pipes/account-dropdown-pipes.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
      AccountProfileComponent
    ],
    imports: [
      CommonModule,
      AccountDropdownPipesModule
    ],
    exports: [ AccountProfileComponent ]
  })
  export class AccountProfileModule { }
