import { Injectable } from '@angular/core';
import { Grower360AccountResponse } from './account.types';
import { Observable ,  from as fromPromise } from 'rxjs';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HttpClientWithAuthHeadersService } from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';

@Injectable()
export class AccountService {
  static readonly GetGrower360AccountsApiUrlPath = `${ENVIRONMENT.grower360ApiBaseUrl}/grower360accounts`;

  constructor(private httpClient: HttpClientWithAuthHeadersService) { }

  retrieveAccounts(grower360UserId: string): Observable<Grower360AccountResponse[]> {
    return fromPromise(
      this.httpClient.get<Grower360AccountResponse[]>(`${AccountService.GetGrower360AccountsApiUrlPath}/${grower360UserId}`, null, '30s'));
  }
}
