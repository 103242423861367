import { Injectable } from '@angular/core';
import { HttpClientOptions } from '../http-client-with-storage.service';
import * as js_sha1 from 'js-sha1';

@Injectable()
export class GenerateUrlStorageKeyService {
  static readonly KeyPrefix = 'URLKEY_';

  generate(url: string, httpClientOptions: HttpClientOptions): string {
    return GenerateUrlStorageKeyService.KeyPrefix +
      js_sha1(JSON.stringify({url: url, options: httpClientOptions}));
  }
}
