import { Injectable } from '@angular/core';
import { NormalizeUrlService } from './normalize-url/normalize-url.service';
import { NormalizeHttpOptionsService } from './normalize-http-options/normalize-http-options.service';
import { ParseStorageExpirationService } from '../parse-storage-expiration/parse-storage-expiration.service';
import { StorageWithLifetimeService } from '../storage-with-lifetime/storage-with-lifetime.service';
import { OnGoingProcessesService, ProcessInfo } from './ongoing-processes/on-going-processes.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { GenerateUrlStorageKeyService } from './generate-url-storage-key/generate-url-storage-key.service';

@Injectable()
export class HttpClientWithStorageService {
  static readonly DefaultLifetime = '2m';
  static readonly ProcessFailed = 'Data could not be retrieved from on-going process';
  static readonly RemoteServiceFailed = 'Data could not be retrieved from remote service';

  constructor(private normalizeUrl: NormalizeUrlService,
              private normalizeOptions: NormalizeHttpOptionsService,
              private parseLifetime: ParseStorageExpirationService,
              private generateKey: GenerateUrlStorageKeyService,
              private storageWithLifetime: StorageWithLifetimeService,
              private onGoingProcesses: OnGoingProcessesService,
              private httpClient: HttpClient) {}

  get<T>(url: string, options: HttpClientOptionsBase = null, lifetime: string = HttpClientWithStorageService.DefaultLifetime): Promise<T> {
    try {
      const normalizedUrl = this.normalizeUrl.normalize(url);
      const normalizedOptions: HttpClientOptions = this.normalizeOptions.normalize(options);
      this.parseLifetime.parse(lifetime); // throws on invalid input
      const dataKey = this.generateKey.generate(normalizedUrl, normalizedOptions);
      const processCreated = this.onGoingProcesses.registerProcess(dataKey);

      return new Promise((resolve, reject) => {
        this.storageWithLifetime.getData<T>(dataKey)
          .then(data => {
            this.onGoingProcesses.success(dataKey, data);
            resolve(data);
          })
          .catch(() => {
            if (processCreated) {
              return Promise.reject(null);
            }
            return new Promise((resolve2) => {
              this.onGoingProcesses.watch().subscribe(
                (processList: ProcessInfo[]) => {
                  const filteredList = processList.filter(x => x.key === dataKey && x.completed);
                  if (filteredList.length === 0) { return; }
                  filteredList[0].success ? resolve2(filteredList[0].data) :
                                            reject(HttpClientWithStorageService.ProcessFailed);
                }
              );
            });
          })
          .then((data: T) => resolve(data))
          .catch(() => {
            this.onGoingProcesses.registerProcess(dataKey);
            this.httpClient.get(normalizedUrl, normalizedOptions).subscribe(
              (response: HttpResponse<T>) => {
                this.storageWithLifetime.addData(dataKey, response.body, lifetime);
                this.onGoingProcesses.success(dataKey, response.body);
                resolve(response.body);
              },
              (response: HttpResponse<T>) => {
                this.onGoingProcesses.fail(dataKey);
                reject(response);
              }
            );
          });
      });
    } catch (e) {
      return Promise.reject(e.message);
    }
  }
}

export class HttpClientOptionsBase {
  headers?: {
    [header: string]: string | string[];
  };
  params?: {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  withCredentials?: boolean;

  constructor() {
    this.headers = {};
    this.params = {};
    this.reportProgress = false;
    this.withCredentials = false;
  }

}
export class HttpClientOptions extends HttpClientOptionsBase {
  observe: 'response';
  responseType?: 'json';
}
