import {Injectable} from '@angular/core';
import {CustomerData} from '../../../state/data/customer-data/customer-data.types';
import { Observable, from as fromPromise, from } from 'rxjs';
import {ENVIRONMENT} from '../../../../environments/environment';
import { HttpClientWithAuthHeadersService } from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import {GrowerIdService} from '../grower-id/grower-id.service';
import {AppStore} from '../../../state/app.state';
import {Store} from '@ngxs/store';
import {LocalStorageService} from '../../../shared/shared-services/local-storage/local-storage.service';
import {UpdatePaymentData} from '../../../state/data/payment-data/payment-data.actions';
import {PAYMENT_DATA_LOADING} from '../../../state/data/payment-data/payment-data.state';
import {UpdateRecentActivityInvoiceData} from '../../../state/data/invoice-data/invoice-data.actions';
import {RECENT_ACTIVITY_INVOICE_LOADING} from '../../../state/data/invoice-data/invoice-data.state';
import {GRAIN_SCALE_TICKET_LOADING} from '../../../state/data/grain-scale-ticket-data/grain-scale-ticket-data.state';
import {UpdateGrainScaleTicketData} from '../../../state/data/grain-scale-ticket-data/grain-scale-ticket-data.actions';
import { UpdateGrainPurchaseContractData } from '../../../state/data/grain-purchase-contract-data/grain-purchase-contract-data.actions';
import { GRAIN_PURCHASE_CONTRACT_LOADING } from '../../../state/data/grain-purchase-contract-data/grain-purchase-contract-data.state';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class CustomerService {
  static readonly GetCustomerApiUrlPath = `${ENVIRONMENT.gatewayApiBaseUrl}/customers`;

  constructor(
    private httpClient: HttpClientWithAuthHeadersService,
    private growerIdService: GrowerIdService,
    private store: Store,
    private localStorage: LocalStorageService) { }

  retrieveCustomer(customerId: string): Observable<CustomerData> {
    return fromPromise(this.growerIdService.fetch(customerId))
      .pipe(
        switchMap((growerId: string) => {
          const url = `${CustomerService.GetCustomerApiUrlPath}/${growerId}`;
          return this.httpClient.get(url, null, '0s');
        }),
        map((val: CustomerData) => new CustomerData(val))
      );
  }

  retrieveCustomerByGrowerId(growerId: string): Observable<CustomerData> {
    const url = `${CustomerService.GetCustomerApiUrlPath}/${growerId}`;
    return fromPromise(this.httpClient.get(url).then(x => new CustomerData(x as CustomerData)));
  }

  switchCustomer(growerGuid: string): void {
    const CUSTOMER_ID = 'customerId';
    this.localStorage.removeItem(CUSTOMER_ID);
    this.localStorage.setItem(CUSTOMER_ID, growerGuid);
    this.store.reset({ appState: new AppStore() });
    this.store.dispatch(new UpdatePaymentData(PAYMENT_DATA_LOADING));
    this.store.dispatch(new UpdateRecentActivityInvoiceData(RECENT_ACTIVITY_INVOICE_LOADING));
    this.store.dispatch(new UpdateGrainScaleTicketData(GRAIN_SCALE_TICKET_LOADING));
    this.store.dispatch(new UpdateGrainPurchaseContractData(GRAIN_PURCHASE_CONTRACT_LOADING));
  }
}
