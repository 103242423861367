import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NotificationsListService} from '../../../core-services/notifications/notifications-list.service';
import {UserInfoService} from '../../../../shared/shared-services/user-info/user-info.service';
import {UserInfo} from '../../../../shared/shared-services/user-info/user-info.types';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {EmailUserInfo} from '../../../../shared/types/email-user-info';
import {UpdateReadNotificationsService} from '../../../core-services/notifications/update-read-notifications.service';
import {PaginationEnvelope} from '../../../../shared/types/pagination-envelope';
import {NotificationListParams} from '../../../../shared/types/notification-list-params';
import {Router} from '@angular/router';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit, OnDestroy {
  static readonly PageSize = 10;
  static readonly SearchDebounceWait = 400;
  userInfo: UserInfo;
  notifications: EmailUserInfo[] = [];
  isLoading = false;
  noNotificationsFound = false;
  searchTermSubscription: Subscription;
  nextPageUrl: string;
  isTreatmentOn: boolean;
  searchHeaderText = 'No Search Results Found';
  noNotificationsHeader = 'No Notifications';
  noNotificationsText = 'When you receive a notification it will appear here.';
  @ViewChild('searchField') searchInput: ElementRef<HTMLInputElement>;
  public infiniteScrollSelector: string;
  public isLastResult = false;

  constructor(public notificationsListService: NotificationsListService,
              public userInfoService: UserInfoService,
              public updateReadNotificationsService: UpdateReadNotificationsService,
              private router: Router) {
    this.infiniteScrollSelector = '.notification-list-container';
  }

  async ngOnInit() {
    this.userInfo = await this.userInfoService.retrieveUserInfo().toPromise();
    this.searchTermSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(NotificationsListComponent.SearchDebounceWait),
      distinctUntilChanged(),
      switchMap(() => this.fetchNotifications(true))
    )
      .subscribe();
    await this.fetchNotifications(true);
    if (!this.noNotificationsFound) {
      await this.updateReadNotificationsService.updateReadNotifications(this.userInfo.userId);
    }
  }

  ngOnDestroy(): void {
    if (!this.searchTermSubscription) return;
    this.searchTermSubscription.unsubscribe();
  }

  async handleShowMoreClick() {
    await this.fetchNotifications(false);
  }

  getNotFoundText(): string {
    return `No results could be found for "${this.getSearchTerm()}"`;
  }

  handleBreadcrumbLink(breadcrumb: string) {
    this.router.navigate(['overview']);
  }

  onScroll() {
    this._checkForLastResult();
    if (this.nextPageUrl) {
      this.handleShowMoreClick();
    }
  }

  private _checkForLastResult() {
    this.isLastResult = this.nextPageUrl === null;
  }

  private async fetchNotifications(reset: boolean): Promise<void> {
    let result: PaginationEnvelope<EmailUserInfo>;
    this.isLoading = true;
    if (reset) {
      const paginationInfo: NotificationListParams = {
        grower360UserId: this.userInfo.userId,
        searchTerms: this.getSearchTerm(),
        pageNumber: 1,
        pageSize: NotificationsListComponent.PageSize
      };
      result = await this.notificationsListService.searchNotifications(paginationInfo).toPromise();
      this.notifications = result.data;
    } else {
      result = await this.notificationsListService.searchNotificationsWithPath(this.nextPageUrl).toPromise();
      this.notifications = [...this.notifications, ...result.data];
    }
    this.nextPageUrl = result.linksMetaData.next;
    this.isLoading = false;
    this.noNotificationsFound = this.notifications.length < 1;
  }

  private getSearchTerm(): string {
    return this.searchInput.nativeElement.value;
  }
}
