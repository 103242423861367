import { Pipe, PipeTransform } from '@angular/core';
import { SidebarMenuOption } from '../../../../state/user-interface/sidebar-selection/sidebar-selection.types';

@Pipe({
  name: 'filterMenuItems'
})
export class FilterMenuItemsPipe implements PipeTransform {

  transform(items: SidebarMenuOption[], filter: string): SidebarMenuOption[] {
    if (!items || !filter) {
      return items;
    }

    return items.filter(x => x.sectionName.indexOf(filter) !== -1);
  }

}
