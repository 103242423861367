import { NgModule } from '@angular/core';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { CommonModule } from '@angular/common';
import { BreadcrumbNavigationComponent } from './breadcrumb-navigation.component';
import { BreadcrumbNavigationScrollService } from './scroll-service/breadcrumb-navigation.service';

@NgModule({
  declarations: [ BreadCrumbComponent, BreadcrumbNavigationComponent ],
  providers: [ BreadcrumbNavigationScrollService ],
  imports: [ CommonModule ],
  exports: [ BreadCrumbComponent, BreadcrumbNavigationComponent ]
})
export class BreadCrumbNavigationModule {}
