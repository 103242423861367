import { Component, OnInit, Input } from '@angular/core';
import { EmailUserInfo } from '../../../../shared/types/email-user-info';

@Component({
  selector: 'app-notifications-bell',
  templateUrl: './notifications-bell.component.html',
  styleUrls: ['./notifications-bell.component.scss']
})
export class NotificationsBellComponent implements OnInit {
  @Input() notifications: EmailUserInfo[] = [];
  @Input() unreadCount: number;
  @Input() displayBell: boolean;
  isNotRead: boolean;
  notificationsOpened = false;
  mouseOver = false;

  ngOnInit() {
    this.isNotRead = this.notifications.some(y => y.isRead === false);
  }

  getDisplayCount(): string {
    return (this.unreadCount < 5 ? this.unreadCount : this.unreadCount - 1 + '+').toString();
  }

  toggleNotifications() {
    this.notificationsOpened = !this.notificationsOpened;
  }

  notificationsHandleClickAway() {
    if (!this.mouseOver) {
      this.notificationsOpened = false;
    }
  }
}
