import {Action, Selector, State, StateContext} from '@ngxs/store';
import {GrainPurchaseContractData} from './grain-purchase-contract-data.types';
import {UpdateGrainPurchaseContractData} from './grain-purchase-contract-data.actions';

export const GRAIN_PURCHASE_CONTRACT_LOADING = {};

export const GRAIN_PURCHASE_CONTRACT_DATA_STATE = 'grainPurchaseContractData';
@State<GrainPurchaseContractData[]>({
  name: GRAIN_PURCHASE_CONTRACT_DATA_STATE
})

export class GrainPurchaseContractDataState {
  @Selector()
  static getPurchaseContractByIndex(state: GrainPurchaseContractData[]) {
    return (index: number) => {
      return state[index];
    };
  }

  @Action(UpdateGrainPurchaseContractData)
  updateGrainPurchaseContractData({ setState }: StateContext<GrainPurchaseContractData[] | {}>, payload: UpdateGrainPurchaseContractData) {
    setState(payload.grainPurchaseContractData);
  }
}
