import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentActivityComponent } from './recent-activity.component';
import { SelectedActivityDirective } from './recent-activity.directive';
import { TabStripComponent } from '../tab-strip/tab-strip.component';
import { RecentActivityPipesModule } from './pipes/recent-activity-pipes.module';
import { RecentActivityScaleTicketComponent }
from './recent-activity-list/recent-activity-scale-ticket/recent-activity-scale-ticket.component';
import { RecentActivityInvoiceListComponent }
from './recent-activity-list/recent-activity-invoice-list/recent-activity-invoice-list.component';
import { RecentActivityPaymentListComponent }
from './recent-activity-list/recent-activity-payment-list/recent-activity-payment-list.component';
import { SharedPipesModule } from '../../core-services/pipes/shared-pipes.module';
import { ImageWithOptionsModule } from '../image-with-options/image-with-options-module';
import { RecentActivitySkeletonComponent } from './recent-activity-list/recent-activity-skeleton/recent-activity-skeleton.component';

@NgModule({
  declarations: [
    RecentActivityComponent,
    SelectedActivityDirective,
    TabStripComponent,
    RecentActivityPaymentListComponent,
    RecentActivityInvoiceListComponent,
    RecentActivityScaleTicketComponent,
    RecentActivitySkeletonComponent
  ],
  imports: [
    CommonModule,
    RecentActivityPipesModule,
    ImageWithOptionsModule,
    SharedPipesModule
  ],
  exports: [
    RecentActivityComponent
  ],
  entryComponents: [
    RecentActivityPaymentListComponent,
    RecentActivityInvoiceListComponent,
    RecentActivityScaleTicketComponent
  ],
})
export class RecentActivityModule { }
