import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../../../../environments/environment';
import { InvoiceData, MemoInvoiceResponse } from '../../../state/data/invoice-data/invoice-data.types';
import { CustomerMemoInvoicesParameters } from './customer-memo-invoices-parameters';
import { HttpClientWithAuthHeadersService } from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import { MemoInvoicesOptionsGeneratorService } from './memo-invoices-options-generator/memo-invoices-options-generator.service';
import { GrowerIdService } from '../grower-id/grower-id.service';

@Injectable()
export class CustomerMemoInvoicesService {

  constructor(private httpClient: HttpClientWithAuthHeadersService,
              private optionsGenerator: MemoInvoicesOptionsGeneratorService,
              private growerIdService: GrowerIdService) {}

  retrieveInvoices(agvanceCustomerId: string, additionalParams?: CustomerMemoInvoicesParameters): Observable<InvoiceData[]> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${ENVIRONMENT.gatewayApiBaseUrl}/customers/${growerId}/memoinvoices`;
          const opts = this.optionsGenerator.generate(additionalParams);

          return this.httpClient.get(url, opts, '0s');
        })
        .then((val: MemoInvoiceResponse[]) => {
          const memoInvoices = val.map(memoInvoiceResponse => InvoiceData.fromMemoInvoice(memoInvoiceResponse));
          emitter.next(memoInvoices);
          emitter.complete();
        })
        .catch(err => { emitter.error(err); emitter.complete(); });
    });
  }
}
