
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  throwError as _throw } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Grower360HttpOptions } from './grower360-http-options.interface';



import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable()
export class Grower360HttpService {

  constructor(private http: HttpClient) { }

  private static addAuthHeaders(options?: Grower360HttpOptions): Grower360HttpOptions {
    options = options || {};

    const token = LocalStorageService.getAuthToken();
    options.headers = options.headers || new HttpHeaders();
    options.headers = options.headers.append('Authorization', token);
    options.headers = options.headers.append('Content-Type', 'application/json');
    options.headers = options.headers.append('Accept', 'application/json');

    return options;
  }

  protected static handleUnsuccessfulResponse(err: any) {
    return _throw(err);
  }

  get<T>(url: string, options?: Grower360HttpOptions): Observable<T> {
    return this.http.get<T>(url, options).pipe(
      catchError(Grower360HttpService.handleUnsuccessfulResponse));
  }

  authGet<T>(url: string, options?: Grower360HttpOptions): Observable<T> {
    options = Grower360HttpService.addAuthHeaders(options);

    return this.http.get<T>(url, options).pipe(
      catchError(Grower360HttpService.handleUnsuccessfulResponse));
  }

  post<T>(url: string, body: any, options?: Grower360HttpOptions): Observable<T> {
    return this.http.post<T>(url, body, options).pipe(
      catchError(Grower360HttpService.handleUnsuccessfulResponse));
  }

  put<T>(url: string, body: any, options?: Grower360HttpOptions): Observable<T> {
    return this.http.put<T>(url, body, options).pipe(
      catchError(Grower360HttpService.handleUnsuccessfulResponse));
  }
}
