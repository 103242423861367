import {NgModule} from '@angular/core';
import {UtcDatePipe} from './utc-date-pipe/utc-date.pipe';

@NgModule({
  imports: [],
  declarations: [
    UtcDatePipe
  ],
  exports: [
    UtcDatePipe
  ]
})

export class SharedPipesModule {}
