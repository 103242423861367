import { Injectable } from '@angular/core';
import {FetchLikeObject} from 'sky-services';
import {ENVIRONMENT} from '../../../../environments/environment';
import {LocalStorageService} from '../../../shared/shared-services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateReadNotificationsService {

  constructor(private http: FetchLikeObject, private storage: LocalStorageService) { }

  async updateReadNotifications(userId: string): Promise<void> {
    const authToken = this.storage.getItem(LocalStorageService.AUTH_TOKEN_KEY);
    const headers = {'Authorization': authToken, 'Accept': 'application/json'};
    await this.http.fetch(`${ENVIRONMENT.grower360ApiBaseUrl}/email/updateread/${userId}`, {headers, method: 'post'});
  }
}
