import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GrowerInfoResponseDto } from './grower-info-list.service';

@Injectable()
export class GrowerInfoListStubService {
  fetch(customerIds: string[]): Observable<GrowerInfoResponseDto[]> {
    return of([]);
  }
}
