import { Injectable } from '@angular/core';

@Injectable()
export class DecodeStoredDataService {
  static readonly DecodeErrorMessage = 'An error occurred trying to decode the data';

  decode<T>(dataAsString: string): T {
    try {
      return JSON.parse(dataAsString);
    } catch (e) {
      throw new Error(DecodeStoredDataService.DecodeErrorMessage);
    }
  }

}
