import { InvoiceData } from './invoice-data.types';

export class UpdateOpenInvoiceData {
  static readonly type = '[InvoiceData[]] UpdateOpenInvoiceData';
  constructor(public readonly invoiceData: InvoiceData[]) {}
}

export class UpdateInvoiceData {
  static readonly type = '[InvoiceData[]] UpdateInvoiceData';
  constructor(public readonly invoiceData: InvoiceData[]) {}
}

export class UpdateRecentActivityInvoiceData {
  static readonly type = '[InvoiceData[] | {}] UpdateRecentActivityInvoiceData';
  constructor(public readonly invoiceData: InvoiceData[] | {}) {}
}
