import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../../../../environments/environment';
import { BarchartsApiQuoteData } from '../../../state/data/cash-bid-data/barcharts-api-quote-data.types';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class BarchartsApiQuoteService {
  static readonly Path = `${ENVIRONMENT.gatewayApiBaseUrl}/cashbids/barchartsapiquote`;

  constructor(private httpClient: HttpClient) { }

  fetch(companyId: string, symbols: string[]): Promise<BarchartsApiQuoteData> {
    const url = `${BarchartsApiQuoteService.Path}/${companyId}/?symbols=${symbols.join(',')}`;

    return this.httpClient.get<BarchartsApiQuoteData>(url).pipe(map((quote) => {
      if (quote.results) {
        this.applyUnitCodeAdjustments(quote);
      }
      return quote;
    })).toPromise();
  }

  private applyUnitCodeAdjustments(quote: BarchartsApiQuoteData) {
    quote.results.forEach((result) => {
      if (result.unitCode === '-1') {
        result.high = result.high / 100;
        result.low = result.low / 100;
        result.open = result.open / 100;
        result.lastPrice = result.lastPrice / 100;
        result.netChange = (parseFloat(result.netChange) / 100).toString();
      }
    });
  }
}
