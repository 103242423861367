import { Injectable } from '@angular/core';
import { ParseStorageExpirationService } from '../../parse-storage-expiration/parse-storage-expiration.service';
import { ProvideTimeService } from '../../provide-time/provide-time.service';
import { Observable ,  BehaviorSubject } from 'rxjs';

@Injectable()
export class OnGoingProcessesService {
  static readonly DefaultLifetime = '3s';
  private processes$: BehaviorSubject<ProcessInfo[]> = new BehaviorSubject<ProcessInfo[]>([]);

  constructor(private parseExpiration: ParseStorageExpirationService, private provideTime: ProvideTimeService) {}

  hasProcess(dataKey: string): boolean {
    this.processes$.next([...this.getCurrentlyRunningOrUnexpiredProcesses()]);
    return this.processes$.getValue().filter(x => x.key === dataKey).length > 0;
  }

  watch(): Observable<ProcessInfo[]> {
    return this.processes$;
  }

  registerProcess(dataKey: string): boolean {
    const newProcess: ProcessInfo = {
      key: dataKey, completed: false, success: false, data: null, expiration: 0
    };
    if (this.hasProcess(dataKey)) { return false; }
    this.processes$.next([...this.getCurrentlyRunningOrUnexpiredProcesses(), newProcess]);
    return true;
  }

  success(dataKey: string, data: any): void {
    const successfulProcess: ProcessInfo = {
      key: dataKey, completed: true, success: true, data: data,
      expiration: this.parseExpiration.parse(OnGoingProcessesService.DefaultLifetime)
    };
    const filteredList = this.getCurrentlyRunningOrUnexpiredProcesses().filter(x => x.key !== dataKey);
    this.processes$.next([...filteredList, successfulProcess]);
  }

  fail(dataKey: string): void {
    const failedProcess: ProcessInfo = {
      key: dataKey, completed: true, success: false, data: null,
      expiration: this.parseExpiration.parse(OnGoingProcessesService.DefaultLifetime)
    };
    const filteredList = this.getCurrentlyRunningOrUnexpiredProcesses().filter(x => x.key !== dataKey);
    this.processes$.next([...filteredList, failedProcess]);
  }

  private getCurrentlyRunningOrUnexpiredProcesses(): ProcessInfo[] {
    const currentTime = this.provideTime.currentTime();
    return this.processes$.getValue().filter(x => {
      if (!x.completed) { return true; }
      if (x.expiration > currentTime) { return true; }
      return false;
    });
  }
}

export class ProcessInfo {
  key: string;
  completed: boolean;
  success: boolean;
  data: any;
  expiration: number;
}
