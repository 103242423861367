import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import {
  stubCustomerInvoices,
  stubRecentActivityCustomerInvoices,
  stubProductTotalGroupsResponse,
  stubCustomerWithProductsWithInvoiceTotalsCsv,
  stubCustomerWithProductTotalsCsv
} from '../../../app.stub-data';
import { InvoiceData } from '../../../state/data/invoice-data/invoice-data.types';
import { CustomerInvoicesParameters } from './customer-invoices-parameters';
import {PaginatedResponse} from '../../../state/paginated-response/paginated-response.types';
import {
  ProductTotalGroupsRequest,
  ProductTotalGroupsResponse,
  CustomerWithProductsWithInvoiceTotalsCsv,
  CustomerWithProductTotalsCsv
} from '../../../state/data/product-totals-data/product-totals-data.types';

@Injectable()
export class CustomerInvoicesStubService {

  retrieveInvoices(agvanceCustomerId: string,
                   additionalParams?: CustomerInvoicesParameters): Observable<PaginatedResponse<InvoiceData>> {
    if (additionalParams && additionalParams.pageSize === 3 && additionalParams.page === 1)
      return of(stubRecentActivityCustomerInvoices);

    return of(stubCustomerInvoices);
  }

  public retrieveCustomerProductTotals(productTotalsRequest: ProductTotalGroupsRequest): Promise<ProductTotalGroupsResponse[]> {
    return Promise.resolve(stubProductTotalGroupsResponse);
  }

  public async getProductInvoicesTotalsCsv(productTotalsRequest: ProductTotalGroupsRequest):
    Promise<CustomerWithProductsWithInvoiceTotalsCsv[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        return resolve(stubCustomerWithProductsWithInvoiceTotalsCsv);
      }, 2000);
    });
  }

  public async getProductTotalsCSV(productTotalsRequest: ProductTotalGroupsRequest): Promise<CustomerWithProductTotalsCsv[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        return resolve(stubCustomerWithProductTotalsCsv);
      }, 2000);
    });
  }
}
