import { Component, ElementRef, HostListener } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ACCOUNT_DROPDOWN_STATE } from '../../../../state/user-interface/account-dropdown/account-dropdown.state';
import { AccountDropdown } from '../../../../state/user-interface/account-dropdown/account-dropdown.types';
import { USER_INTERFACE_STATE } from '../../../../state/user-interface/user-interface.state';
import { APP_STATE } from '../../../../state/app.state';

@Component({
  selector: 'app-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.scss']
})
export class AccountDropdownComponent {
  @Select(state => state[APP_STATE][USER_INTERFACE_STATE][ACCOUNT_DROPDOWN_STATE])
  accountDropdownOptions$: Observable<AccountDropdown>;

  showList: boolean;

  constructor(private elementRef: ElementRef) { }

  toggleDropdown() {
    this.showList = !this.showList;
  }

  invokeAction(callback: () => void) {
    callback();
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onDocumentClick(clickEvent) {
    const eventTarget = <HTMLScriptElement> clickEvent.target;
    const accountControl = <HTMLScriptElement> this.elementRef.nativeElement
      .querySelector('.account-dropdown-container');

    if (accountControl && accountControl.contains(eventTarget)) { return; }

    this.showList = false;
  }
}
