<div class="mobile-sidebar-submenu-container">
  <div class="back-navigation" (click)="backClicked()">
    <div class="arrow-wrapper">
      <div class="arrow-back"></div>
    </div>
    <div class="back-navigation-header">
      Back
    </div>
  </div>
  <div data-id="profile-submenu" class="profile-submenu" *ngIf="showProfileSubmenu((mobileSidebar$ | async)?.submenu)">
    <app-mobile-sidebar-submenu-account></app-mobile-sidebar-submenu-account>
  </div>
</div>
