import { MobileSidebar, MobileSidebarSubMenu } from './mobile-sidebar/mobile-sidebar.types';
import { AccountDropdown } from './account-dropdown/account-dropdown.types';
import { SidebarMenuOption } from './sidebar-selection/sidebar-selection.types';

export class UserInterface {
  sidebarSelection: SidebarMenuOption;
  mobileSidebar: MobileSidebar;
  accountDropdown: AccountDropdown;
}

export class UserInterfaceStore {
  sidebarSelection: SidebarMenuOption = {
    menuItemName: 'Overview',
    menuItemLink: '/overview',
    sectionName: 'GLANCE',
    menuItemIcon: 'overview-icon'
  };
  mobileSidebar: MobileSidebar = {
    display: false,
    submenu: MobileSidebarSubMenu.None
  };
}
