import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { stubBarchartsApiSymbol } from '../../../app.stub-data';
import { BarchartsApiSymbolData } from '../../../state/data/cash-bid-data/barcharts-api-symbol-data.types';
import { CashBidData } from '../../../state/data/cash-bid-data/cash-bid-data.types';

@Injectable()
export class BarchartsApiSymbolStubService {

  fetch(companyId: string, cashBids: CashBidData[]): Observable<BarchartsApiSymbolData[]> {
    return of(stubBarchartsApiSymbol);
  }
}
