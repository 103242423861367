import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {Observable} from 'rxjs';

export interface DeactivationGuarded {
  canRoute: boolean;
  canDeactivate(): Promise<boolean> | Observable<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<DeactivationGuarded> {
  canDeactivate(component: DeactivationGuarded): Observable<boolean> | Promise<boolean> | boolean {
    if (component.canRoute) {
      return true;
    }
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
