<ng-container>
  <div class="notifications-bell" tabindex="-1" (blur)="notificationsHandleClickAway()" (mouseover)="mouseOver = true" (mouseout)="mouseOver = false">
    <div *ngIf="displayBell">
      <mat-icon svgIcon="notification-filled" matBadge="{{getDisplayCount()}}" [matBadgeHidden]="unreadCount<1"
                class="notifications-filled" (click)="toggleNotifications()"></mat-icon>
    </div>
    <div class="notifications-wrapper" *ngIf="notificationsOpened" >
      <div class="notifications-text-wrapper">
        <div class="notifications-text">Notifications</div>
        <mat-icon svgIcon="settings-outline" class="settings-icon" (click)="toggleNotifications()" routerLink="/overview/settings"></mat-icon>
        <mat-icon svgIcon="close-outline" class="close-icon" (click)="toggleNotifications()"></mat-icon>
      </div>
      <div class="notification-list" *ngFor="let notification of notifications" routerLink="/notifications" (click)="toggleNotifications()"
        [ngClass]="{'not-read': !notification.isRead}">
        <div class="time-stamp" [ngClass]="{'not-read-timestamp': !notification.isRead}">{{ notification.timeStamp | utcToLocalDate | date:'M-d-yy h:mm a' }}</div>
        <div class="subject">{{notification.subject}}</div>
        <div class="message-body" [innerHTML]="notification.messageBody | slice:0:100">
          <span class="ellipsis" *ngIf="notification.messageBody.length > 100">...</span>
        </div>
      </div>
      <div class="view-all-wrapper" routerLink="/notifications" (click)="toggleNotifications()">
        <div class="view-all">View all</div>
        <mat-icon svgIcon="arrow-right-outline" class="arrow-icon"></mat-icon>
      </div>
    </div>
  </div>
</ng-container>
