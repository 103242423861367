<div class="invoice-list" *ngIf="invoiceData$ | async as invoiceData">
  <ng-container *ngIf="isDoneLoading(invoiceData) else loading">
    <div class="list-container">
      <div class="column-headers" data-id="column-headers">
        <div class="title-container" data-id="title">
          <div class="list-icon" data-id="icon"></div>
          <span class="header-title">Invoices</span>
        </div>
        <div *ngFor="let column of columnTitles" class="column-title">{{column}}</div>
      </div>
      <div class="rows">
        <div class="invoice-for" *ngFor="let invoice of invoiceData | sortInvoices">
          <div class="desktop-table-data">
            <div class="desktop-row">
              <div class="invoice-badge">
                <img src="../../../../../../assets/images/invoices-icon-white.svg">
              </div>
              <div class="invoice-number">{{invoice.number}}</div>
            </div>
            <div class="row-data field" *ngIf="invoice.field">{{invoice.field}}</div>
            <div class="row-data" *ngIf="!invoice.field">--</div>
            <div class="row-data">{{invoice.date | utcDate | date: 'MM/dd/yyyy'}}</div>
            <div class="row-data">{{invoice.dueDate | utcDate | date: 'MM/dd/yyyy'}}</div>
            <div class="row-data">{{invoice.originalAmount | currency:'USD':'symbol'}}</div>
            <div class="row-data">{{invoice.amountDue | currency:'USD':'symbol'}}</div>
            <div class="status-row">
              <div class="status-box"
                   [ngClass]="{ 'open-status-box': invoice.status === 'Open', 'paid-status-box': invoice.status === 'Paid'}">
              </div>
              <div>{{getStatusMessage(invoice.status)}}</div>
            </div>
            <div class="desktop-ellipsis">
              <app-img-with-options
                imageName="download-image"
                [options]="clickableImageOptions"
                [showPlaceholder]="(fileData === '')"
                [showError]="fileData === null"
                [errorOptions]="clickableImageErrorOptions"
                (optionClicked)="handleClickableImageSelection($event, invoice)"
                (optionsOpened)="handleClickableImageOpened($event, invoice)">
              </app-img-with-options>
            </div>
          </div>

          <div class="mobile-table-data">
            <div class="mobile-row">
              <div class="invoice-number-container">
                <div class="invoice-badge">
                  <img src="../../../../../../assets/images/invoices-icon-white.svg">
                </div>
                <div class="invoice-number">{{invoice.number}}</div>
              </div>
              <div class="mobile-ellipsis">
                <app-img-with-options
                  imageName="download-image"
                  [options]="clickableImageOptions"
                  [showPlaceholder]="(fileData === '')"
                  [showError]="fileData === null"
                  [errorOptions]="clickableImageErrorOptions"
                  (optionClicked)="handleClickableImageSelection($event, invoice)"
                  (optionsOpened)="handleClickableImageOpened($event, invoice)">
                </app-img-with-options>
              </div>
            </div>
            <div class="mobile-row" *ngIf="invoice.field">
              <div class="row-title">Field</div>
              <div class="row-data">{{invoice.field}}</div>
            </div>
            <div class="mobile-row">
              <div class="row-title">Invoice Date</div>
              <div class="row-data">{{invoice.date | utcDate | date: 'MM/dd/yyyy'}}</div>
            </div>
            <div class="mobile-row">
              <div class="row-title">Due Date</div>
              <div class="row-data">{{invoice.dueDate | utcDate | date: 'MM/dd/yyyy'}}</div>
            </div>
            <div class="mobile-row">
              <div class="row-title">Original</div>
              <div class="row-data">{{invoice.originalAmount | currency:'USD':'symbol'}}</div>
            </div>
            <div class="mobile-row">
              <div class="row-title">Unpaid</div>
              <div class="row-data">{{invoice.amountDue | currency:'USD':'symbol'}}</div>
            </div>
            <div class="mobile-row">
              <div class="status-row">
                <div class="status-box"
                     [ngClass]="{ 'open-status-box': invoice.status === 'Open', 'paid-status-box': invoice.status === 'Paid'}">
                </div>
                <div>{{getStatusMessage(invoice.status)}}</div>
              </div>
            </div>
          </div>

          <div class="tablet-table-data">
            <div class="table-column">
              <div class="mobile-row">
                <div>
                  <div class="invoice-number-container">
                    <div class="invoice-badge">
                      <img src="../../../../../../assets/images/invoices-icon-white.svg">
                    </div>
                    <div class="invoice-number">{{invoice.number}}</div>
                  </div>
                </div>
              </div>
              <div class="mobile-row" *ngIf="invoice.field">
                <div class="row-title">Field</div>
                <div class="row-data">{{invoice.field}}</div>
              </div>
            </div>
            <div class="table-column">
              <div class="mobile-row">
                <div class="row-title">Invoice Date</div>
                <div class="row-data">{{invoice.date | utcDate | date: 'MM/dd/yyyy'}}</div>
              </div>
              <div class="mobile-row">
                <div class="row-title">Original</div>
                <div class="row-data">{{invoice.originalAmount | currency:'USD':'symbol'}}</div>
              </div>
            </div>
            <div class="table-column">
              <div class="mobile-row">
                <div class="row-title">Due Date</div>
                <div class="row-data">{{invoice.dueDate | utcDate | date: 'MM/dd/yyyy'}}</div>
              </div>
              <div class="mobile-row">
                <div class="row-title">Unpaid</div>
                <div class="row-data">{{invoice.amountDue | currency:'USD':'symbol'}}</div>
              </div>
            </div>
            <div class="table-column">
              <div class="mobile-row">
                <div class="tablet-ellipsis">
                  <app-img-with-options
                    imageName="download-image"
                    [options]="clickableImageOptions"
                    [showPlaceholder]="(fileData === '')"
                    [showError]="fileData === null"
                    [errorOptions]="clickableImageErrorOptions"
                    (optionClicked)="handleClickableImageSelection($event, invoice)"
                    (optionsOpened)="handleClickableImageOpened($event, invoice)">
                  </app-img-with-options>
                </div>
              </div>
              <div class="mobile-row">
                <div class="status-row">
                  <div class="status-box"
                       [ngClass]="{ 'open-status-box': invoice.status === 'Open', 'paid-status-box': invoice.status === 'Paid'}">
                  </div>
                  <div>{{getStatusMessage(invoice.status)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #loading>
  <app-recent-activity-skeleton></app-recent-activity-skeleton>
</ng-template>
