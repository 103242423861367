import { Injectable } from '@angular/core';
import { Company } from './company.types';
import { Observable ,  from as fromPromise } from 'rxjs';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HttpClientWithAuthHeadersService } from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';

@Injectable()
export class CompanyService {
  static readonly GetCompanyApiUrlPath = `${ENVIRONMENT.skydentityBaseUrl}/v1/agvancecompanies`;

  constructor(private httpClient: HttpClientWithAuthHeadersService) { }

  retrieveCompany(companyId: string): Observable<Company> {
    return fromPromise(this.httpClient.get<Company>(`${CompanyService.GetCompanyApiUrlPath}/${companyId}`));
  }
}
