
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Grower360HttpService } from '../../../shared/shared-services/grower360-http/grower360-http.service';
import { Observable ,  throwError as _throw } from 'rxjs';
import { ENVIRONMENT } from '../../../../environments/environment';

@Injectable()
export class VerifyResetPasswordTokenService {
  static readonly VerifyTokenApiUrlPath = `${ENVIRONMENT.skydentityBaseUrl}/v1/verifygrower360resetpasswordtoken/`;

  constructor(private httpClient: Grower360HttpService) { }

  isValid(token: string): Observable<void> {
    return this.httpClient.get(VerifyResetPasswordTokenService.VerifyTokenApiUrlPath + token).pipe(
      map(() => null),
      catchError(() => _throw(null)));
  }
}
