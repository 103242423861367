import { NgModule } from '@angular/core';
import { AccountCardComponent } from './account-card.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ AccountCardComponent ],
  imports: [ CommonModule ],
  exports: [ AccountCardComponent ]
})

export class AccountCardModule { }
