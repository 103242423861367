import { NgModule } from '@angular/core';
import { AuthService } from './shared-services/auth/auth.service';
import { LocalStorageService } from './shared-services/local-storage/local-storage.service';
import { SubdomainService } from './shared-services/subdomain/subdomain.service';
import { Grower360HttpService } from './shared-services/grower360-http/grower360-http.service';
import { RouterModule } from '@angular/router';
import { SubdomainGuardService } from './route-guards/subdomain-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { JwtService } from './shared-services/jwt/jwt.service';
import { UserInfoService } from './shared-services/user-info/user-info.service';
import { SubdomainGuardStubService } from './route-guards/subdomain-guard-stub.service';
import { AuthGuardStubService } from './route-guards/auth-guard-stub.service';
import { createDependency } from '../app.stub-data';
import { ENVIRONMENT } from '../../environments/environment';
import { ProvideTimeService } from './shared-services/provide-time/provide-time.service';
import { ParseStorageExpirationService } from './shared-services/parse-storage-expiration/parse-storage-expiration.service';
import { HttpClientWithStorageService } from './shared-services/http-client-with-storage/http-client-with-storage.service';
import { GenerateUrlStorageKeyService } from
  './shared-services/http-client-with-storage/generate-url-storage-key/generate-url-storage-key.service';
import { NormalizeHttpOptionsService } from
  './shared-services/http-client-with-storage/normalize-http-options/normalize-http-options.service';
import { NormalizeUrlService } from './shared-services/http-client-with-storage/normalize-url/normalize-url.service';
import { OnGoingProcessesService } from './shared-services/http-client-with-storage/ongoing-processes/on-going-processes.service';
import { DecodeStoredDataService } from './shared-services/storage-with-lifetime/decode-stored-data/decode-stored-data.service';
import { EncodeStoredDataService } from './shared-services/storage-with-lifetime/encode-stored-data/encode-stored-data.service';
import { NormalizeStorageKeyService } from './shared-services/storage-with-lifetime/normalize-storage-key/normalize-storage-key.service';
import { ProvideStorageKeyListService } from
  './shared-services/storage-with-lifetime/provide-storage-key-list/provide-storage-key-list.service';
import { ValidateStorageDataService } from './shared-services/storage-with-lifetime/validate-storage-data/validate-storage-data.service';
import { StorageWithLifetimeService } from './shared-services/storage-with-lifetime/storage-with-lifetime.service';
import { HttpClientWithAuthHeadersService } from './shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import { CommonModule } from '@angular/common';
import { AuthStubService } from './shared-services/auth/auth-stub.service';
import { SubdomainStubService } from './shared-services/subdomain/subdomain-stub.service';
import { UserInfoStubService } from './shared-services/user-info/user-info-stub.service';
import { GrowerIdService } from '../core/core-services/grower-id/grower-id.service';
import { GrowerInfoListService } from '../core/core-services/grower-id/grower-info-list.service';
import { GrowerInfoListStubService } from '../core/core-services/grower-id/grower-info-list-stub-service';
import { CompanyLogoService } from './shared-services/company-logo/company-logo.service';
import { TreatmentServiceFactory } from './shared-services/split-io/treatment-service-factory';
import { CustomerGrainPurchaseContractsService }
  from '../core/core-services/customer-grain-purchase-contracts/customer-grain-purchase-contracts.service';
import { CustomerGrainScaleTicketsService } from '../core/core-services/customer-grain-scale-tickets/customer-grain-scale-tickets.service';
import { CustomerGrainSettlementsService } from '../core/core-services/customer-grain-settlements/customer-grain-settlements.service';
import { CustomerHasGrainService } from './shared-services/customer-has-grain/customer-has-grain.service';
import { CustomerHasGrainStubService } from './shared-services/customer-has-grain/customer-has-grain-stub.service';
import { GrowerIdStubService } from '../core/core-services/grower-id/grower-id-stub.service';
import { TreatmentGuard } from './route-guards/treatment.guard';
import { CanDeactivateGuard } from './route-guards/can-deactivate-guard.service';
import { CustomerGrainAssemblyService } from '../core/core-services/customer-grain-assembly/customer-grain-assembly.service';
import { CustomerGrainCommodityService } from '../core/core-services/customer-grain-commodity/customer-grain-commodity.service';
import { AsyncAuthGuardService } from './route-guards/async-auth-guard.service';
import { PrefMiscService } from './shared-services/pref-misc/pref-misc.service';
import { PrefMiscStubService } from './shared-services/pref-misc/pref-misc-stub.service';
import { DexxHttp } from '@ssiagvance/dexx';
import {
  CurrencyInputComponent,
  DomElementEventService,
  DomElementService,
  RandomIdService
} from './pages/currency-input/currency-input.component';
import { SkyInputModule } from '@ssi-nimbus/text-input';
import { fromEvent } from 'rxjs';
import { el } from '@angular/platform-browser/testing/src/browser_util';

const SUBDOMAIN_GUARD = createDependency(SubdomainGuardService, SubdomainGuardStubService, ENVIRONMENT.name);
const AUTH_GUARD = createDependency(AsyncAuthGuardService, AuthGuardStubService, ENVIRONMENT.name);
const AUTH_Service = createDependency(AuthService, AuthStubService, ENVIRONMENT.name);
const SUBDOMAIN_Service = createDependency(SubdomainService, SubdomainStubService, ENVIRONMENT.name);
const USER_INFO_Service = createDependency(UserInfoService, UserInfoStubService, ENVIRONMENT.name);
const GROWER_ID_SERVICE = createDependency(GrowerIdService, GrowerIdStubService, ENVIRONMENT.name);
const GROWER_ID_LIST_SERVICE = createDependency(GrowerInfoListService, GrowerInfoListStubService, ENVIRONMENT.name);
const HAS_GRAIN_SERVICE = createDependency(CustomerHasGrainService, CustomerHasGrainStubService, ENVIRONMENT.name);
const PREFMISC_SERVICE = createDependency(PrefMiscService, PrefMiscStubService, ENVIRONMENT.name);

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    CommonModule,
    SkyInputModule
  ],
  declarations: [
    CurrencyInputComponent
  ],
  exports: [
    CurrencyInputComponent
  ],
  providers: [
    {
      provide: TreatmentServiceFactory,
      useFactory: (userInfoService: UserInfoService) => {
        return new TreatmentServiceFactory(userInfoService, null);
      },
      deps: [UserInfoService]
    },
    SUBDOMAIN_GUARD,
    LocalStorageService,
    AUTH_GUARD,
    Grower360HttpService,
    JwtService,
    AUTH_Service,
    SUBDOMAIN_Service,
    USER_INFO_Service,
    GROWER_ID_SERVICE,
    GROWER_ID_LIST_SERVICE,
    HAS_GRAIN_SERVICE,
    ProvideTimeService,
    ParseStorageExpirationService,
    HttpClientWithStorageService,
    GenerateUrlStorageKeyService,
    NormalizeHttpOptionsService,
    NormalizeUrlService,
    OnGoingProcessesService,
    DecodeStoredDataService,
    EncodeStoredDataService,
    NormalizeStorageKeyService,
    ProvideStorageKeyListService,
    ValidateStorageDataService,
    StorageWithLifetimeService,
    HttpClientWithAuthHeadersService,
    CompanyLogoService,
    CustomerGrainPurchaseContractsService,
    CustomerGrainScaleTicketsService,
    CustomerGrainAssemblyService,
    CustomerGrainCommodityService,
    CustomerGrainSettlementsService,
    TreatmentGuard,
    CanDeactivateGuard,
    PREFMISC_SERVICE,
    RandomIdService,
    DomElementService,
    DomElementEventService,
    {provide: DexxHttp, useValue: new DexxHttp()}
  ]
})
export class SharedModule {
}
