import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HttpClientWithAuthHeadersService } from
  '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import { Observable, from } from 'rxjs';
import { GrainScaleTicketData } from '../../../state/data/grain-scale-ticket-data/grain-scale-ticket-data.types';
import { HttpClientOptionsBase } from '../../../shared/shared-services/http-client-with-storage/http-client-with-storage.service';
import { GrowerIdService } from '../grower-id/grower-id.service';
import { CustomerGrainScaleTicketsParameters } from './customer-grain-scale-tickets-parameters';
import { PaginatedResponse } from '../../../state/paginated-response/paginated-response.types';
import { ScaleTicketsExportCsvParameters } from '../customer-grain-scale-tickets-export-csv/scale-tickets-export-csv-parameters';
import { ScaleTicketsExportCsvData } from '../../../state/data/grain-scale-ticket-export-csv-data/grain-scale-ticket-export-csv-data.types';
import * as _ from 'lodash';

@Injectable()
export class CustomerGrainScaleTicketsService {
  static GET_GRAIN_SCALE_TICKETS_PATH = `${ENVIRONMENT.gatewayApiBaseUrl}/customergrainscaletickets`;
  static EXPORT_CSV_PATH = `/exportcsv`;

  public constructor(private httpClient: HttpClientWithAuthHeadersService, private growerIdService: GrowerIdService) { }

  public retrieveGrainScaleTickets(agvanceCustomerId: string,
    additionalParams?: CustomerGrainScaleTicketsParameters): Observable<PaginatedResponse<GrainScaleTicketData>> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CustomerGrainScaleTicketsService.GET_GRAIN_SCALE_TICKETS_PATH}/${growerId}`;

          const options: HttpClientOptionsBase = this._fetchBindedQueryParameters(additionalParams);

          return this.httpClient.get(url, options);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  public retrieveGrainScaleTicketsByUrl(nextPageUrl: string): Observable<PaginatedResponse<GrainScaleTicketData>> {
    return from(this.httpClient.get(nextPageUrl));
  }

  public retrieveGrainScaleTicketsExportCsv(
    agvanceCustomerId: string,
    additionalParams?: ScaleTicketsExportCsvParameters): Observable<ScaleTicketsExportCsvData> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          let url = `${CustomerGrainScaleTicketsService.GET_GRAIN_SCALE_TICKETS_PATH}` +
            `${CustomerGrainScaleTicketsService.EXPORT_CSV_PATH}?customerId=${growerId}`;

          if (additionalParams) {
            const queryParameterUrl = this._fetchBindedQueryParametersAsString(additionalParams);
            url = url + queryParameterUrl;
          }

          return this.httpClient.get(url);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  private _fetchBindedQueryParameters(additionalParams: CustomerGrainScaleTicketsParameters | ScaleTicketsExportCsvParameters) {
    const options: HttpClientOptionsBase = new HttpClientOptionsBase();
    if (additionalParams) {
      Object.keys(additionalParams).forEach(key => {
        options.params[key] = `${additionalParams[key]}`;
      });
    }
    return options;
  }

  private _fetchBindedQueryParametersAsString(
    additionalParams: CustomerGrainScaleTicketsParameters | ScaleTicketsExportCsvParameters): string {
    let queryParameterUrl = '&';

    if (additionalParams.startDateRange) {
      queryParameterUrl += `startDateRange=${additionalParams.startDateRange}&`;
    }

    if (additionalParams.endDateRange) {
      queryParameterUrl += `endDateRange=${additionalParams.endDateRange}&`;
    }

    if (additionalParams.commodityIds && additionalParams.commodityIds.length > 0) {
      queryParameterUrl += _.reduce(additionalParams.commodityIds, (id, value, index) => {
        return id += `commodityIds[${index}]=${value}&`;
      }, '');
    }

    if (additionalParams.fieldIds && additionalParams.fieldIds.length > 0) {
      queryParameterUrl += _.reduce(additionalParams.fieldIds, (id, value, index) => {
        return id += `fieldIds[${index}]=${value}&`;
      }, '');
    }

    if (additionalParams.farmNames && additionalParams.farmNames.length > 0) {
      queryParameterUrl += _.reduce(additionalParams.farmNames, (id, value, index) => {
        return id += `farmNames[${index}]=${value}&`;
      }, '');
    }

    return queryParameterUrl;
  }
}
