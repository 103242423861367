import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ENVIRONMENT } from '../../environments/environment';
import { createDependency } from '../app.stub-data';
import { AccountDropdownComponent } from './components/header/account-dropdown/account-dropdown.component';
import { AccountDropdownPipesModule } from './components/header/account-dropdown/pipes/account-dropdown-pipes.module';
import { AccountStubService } from './core-services/account/account-stub.service';
import { AccountService } from './core-services/account/account.service';
import { CompanyStubService } from './core-services/company/company-stub.service';
import { CompanyService } from './core-services/company/company.service';
import { CustomerStubService } from './core-services/customer/customer-stub.service';
import { CustomerService } from './core-services/customer/customer.service';
import { WindowRefService } from './core-services/window-ref/window-ref.service';
import { WindowSizeService } from './core-services/window-size/window-size.service';
import { CustomerPickerComponent } from './components/header/customer-picker/customer-picker.component';
import { Grower360RootComponent } from './pages/shared/grower360-root/grower360-root.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { RecentActivityModule } from './components/recent-activity/recent-activity.module';
import { CustomerInvoicesService } from './core-services/customer-invoices/customer-invoices.service';
import { CustomerInvoicesStubService } from './core-services/customer-invoices/customer-invoices-stub.service';
import { CustomerPaymentsService } from './core-services/customer-payments/customer-payments.service';
import { CustomerPaymentsStubService } from './core-services/customer-payments/customer-payments-stub.service';
import { BroadcastClientModule, FetchLikeObject } from 'sky-services';
import { CrmPdfService } from './core-services/crm-pdf/crm-pdf.service';
import { CrmPdfStubService } from './core-services/crm-pdf/crm-pdf-stub.service';
import { Base64ConverterService } from '../shared/shared-services/pdf-file-handler/base64-converter/base64-converter.service';
import { AccountSwitchComponent } from './pages/account-switch/account-switch.component';
import { OverlayModule } from './components/overlay/overlay.module';
import { AccountPanelModule } from './pages/account-switch/account-panel/account-panel.module';
import { CustomerMemoInvoicesStubService } from './core-services/customer-memo-invoices/customer-memo-invoices-stub.service';
import { CustomerMemoInvoicesService } from './core-services/customer-memo-invoices/customer-memo-invoices.service';
import { MemoInvoicesOptionsGeneratorService } from
    './core-services/customer-memo-invoices/memo-invoices-options-generator/memo-invoices-options-generator.service';
import { SharedPipesModule } from './core-services/pipes/shared-pipes.module';
import { BarchartsApiQuoteService } from './core-services/cash-bids/barcharts-api-quote.service';
import { BarchartsApiSymbolService } from './core-services/cash-bids/barcharts-api-symbol.service';
import { CashBidsService } from './core-services/cash-bids/cash-bids.service';
import { BarchartsApiQuoteStubService } from './core-services/cash-bids/barcharts-api-quote-stub.service';
import { BarchartsApiSymbolStubService } from './core-services/cash-bids/barcharts-api-symbol-stub.service';
import { CashBidsStubService } from './core-services/cash-bids/cash-bids-stub.service';
import { CognitoOperationsModule } from '../public/cognito-operations/cognito-operations.module';
import { NotificationsListComponent } from './pages/notifications/notifications-list/notifications-list.component';
import { SkyIconModule } from '@ssi-nimbus/icon';
import { NoSearchResultsModule, SpinnerModule } from '@ssi/ng-stratus-7';
import { SharedModule } from '../shared/shared.module';
import { ShowMoreModule } from './components/show-more/show-more.module';
import { MatBadgeModule } from '@angular/material';
import { UtcToLocalDatePipe } from '../shared/pipes/utc-to-local-date-pipe/utc-to-local-date-pipe';
import { BreadCrumbNavigationModule } from './components/breadcrumb-navigation/breadcrumb-navigation.module';
import { NotificationsDemoListService, NotificationsListService } from './core-services/notifications/notifications-list.service';
import {SkyIllustrationModule} from '@ssi-nimbus/illustration';
import {LocationObject} from '../shared/types/location-object';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { NotificationsBellComponent } from './components/header/notifications-bell/notifications-bell.component';
import {SellerSignatureService} from './core-services/seller-signature/seller-signature.service';
import {SellerSignatureStubService} from './core-services/seller-signature/seller-signature-stub.service';
import {SkyPersistentNotificationBarModule} from '@ssi-nimbus/persistent-notification-bar';
import { SkySnackbarModule } from '@ssi-nimbus/snackbar';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { MaskNumberPipe } from './components/bank-account-info/mask-number-pipe/mask-number.pipe';
import { HelpInformationComponent } from './components/header/help-information/help-information.component';

const COMPANY_SERVICE = createDependency(CompanyService, CompanyStubService, ENVIRONMENT.name);
const ACCOUNT_SERVICE = createDependency(AccountService, AccountStubService, ENVIRONMENT.name);
const CUSTOMER_SERVICE = createDependency(CustomerService, CustomerStubService, ENVIRONMENT.name);
const CUSTOMER_INVOICES_SERVICE = createDependency(CustomerInvoicesService, CustomerInvoicesStubService, ENVIRONMENT.name);
const CUSTOMER_PAYMENTS = createDependency(CustomerPaymentsService, CustomerPaymentsStubService, ENVIRONMENT.name);
const CRM_PDF_SERVICE = createDependency(CrmPdfService, CrmPdfStubService, ENVIRONMENT.name);
const CUSTOMER_MEMO_INVOICES_SERVICE = createDependency(CustomerMemoInvoicesService, CustomerMemoInvoicesStubService, ENVIRONMENT.name);
const BARCHARTS_API_QUOTE_SERVICE = createDependency(BarchartsApiQuoteService, BarchartsApiQuoteStubService, ENVIRONMENT.name);
const BARCHARTS_API_SYMBOL_SERVICE = createDependency(BarchartsApiSymbolService, BarchartsApiSymbolStubService, ENVIRONMENT.name);
const CASH_BIDS_SERVICE = createDependency(CashBidsService, CashBidsStubService, ENVIRONMENT.name);
const NOTIFICATIONS_LIST_SERVICE = createDependency(NotificationsListService, NotificationsDemoListService, ENVIRONMENT.name);
const SELLER_SIGNATURE_SERVICE = createDependency(SellerSignatureService, SellerSignatureStubService, ENVIRONMENT.name);

@NgModule({
  declarations: [
    Grower360RootComponent,
    HeaderComponent,
    AccountDropdownComponent,
    CustomerPickerComponent,
    AccountSwitchComponent,
    NotificationsListComponent,
    UtcToLocalDatePipe,
    NotificationsBellComponent,
    MaskNumberPipe,
    HelpInformationComponent,
  ],
  imports: [
    RouterModule,
    AccountDropdownPipesModule,
    SidebarModule,
    CommonModule,
    AccountPanelModule,
    RecentActivityModule,
    BroadcastClientModule,
    OverlayModule,
    SharedPipesModule,
    CognitoOperationsModule,
    SkyIconModule,
    NoSearchResultsModule,
    SpinnerModule,
    SharedModule,
    ShowMoreModule,
    MatBadgeModule,
    BreadCrumbNavigationModule,
    SkyIllustrationModule,
    PdfViewerModule,
    SkyPersistentNotificationBarModule,
    SkySnackbarModule,
    InfiniteScrollModule
  ],
  exports: [
    MaskNumberPipe
  ],
  providers: [
    ACCOUNT_SERVICE,
    BARCHARTS_API_QUOTE_SERVICE,
    BARCHARTS_API_SYMBOL_SERVICE,
    CASH_BIDS_SERVICE,
    COMPANY_SERVICE,
    CUSTOMER_SERVICE,
    CUSTOMER_INVOICES_SERVICE,
    CUSTOMER_PAYMENTS,
    CRM_PDF_SERVICE,
    CUSTOMER_MEMO_INVOICES_SERVICE,
    NOTIFICATIONS_LIST_SERVICE,
    SELLER_SIGNATURE_SERVICE,
    WindowSizeService,
    WindowRefService,
    MemoInvoicesOptionsGeneratorService,
    Base64ConverterService,
    {
      provide: FetchLikeObject,
      useValue: window
    },
    {
      provide: LocationObject,
      useValue: window.location
    }
  ]
})
export class CoreModule {
}
