import {GrainScaleTicketData} from './grain-scale-ticket-data.types';
import {Action, State, StateContext} from '@ngxs/store';
import {UpdateGrainScaleTicketData} from './grain-scale-ticket-data.actions';

export const GRAIN_SCALE_TICKET_DATA_STATE = 'grainScaleTicketData';
export const GRAIN_SCALE_TICKET_LOADING = {};
@State<GrainScaleTicketData[] | {}>({
  name: GRAIN_SCALE_TICKET_DATA_STATE
})

export class GrainScaleTicketDataState {
  @Action(UpdateGrainScaleTicketData)
  updateGrainScaleTicketData({ setState }: StateContext<GrainScaleTicketData[] | {}>, payload: UpdateGrainScaleTicketData) {
    setState(payload.grainScaleTicketData);
  }
}
