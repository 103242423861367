import {Component, OnInit} from '@angular/core';
import {PaymentData} from '../../../../../state/data/payment-data/payment-data.types';
import {Select} from '@ngxs/store';
import {PAYMENT_DATA_STATE} from '../../../../../state/data/payment-data/payment-data.state';
import {DATA_STATE} from '../../../../../state/data/data.state';
import {APP_STATE} from '../../../../../state/app.state';
import {Observable} from 'rxjs';
import {ErrorOption, Option, OptionEmitter} from '../../../image-with-options/image-with-options.component';
import {CrmPdfService} from '../../../../core-services/crm-pdf/crm-pdf.service';
import {PdfFileHandlerService} from '../../../../../shared/shared-services/pdf-file-handler/pdf-file-handler.service';
import {COMPANY_DATA_STATE} from '../../../../../state/data/company-data/company-data.state';
import {Company} from '../../../../core-services/company/company.types';
import {CUSTOMER_ID} from '../../../account-card/account-card.component';
import {LocalStorageService} from '../../../../../shared/shared-services/local-storage/local-storage.service';

@Component({
  selector: 'app-recent-activity-payment-list',
  templateUrl: './recent-activity-payment-list.component.html',
  styleUrls: ['./recent-activity-payment-list.component.scss']
})
export class RecentActivityPaymentListComponent implements OnInit {
  legacyDbId: string;
  customerId: string;
  clickableImageOptions: Option[] = [
    {id: 'view-pdf', text: 'View pdf', activeText: true, image: 'view-image'},
    {id: 'download-pdf', text: 'Download pdf', activeText: true, image: 'download-image'}
  ];
  clickableImageErrorOptions: ErrorOption = {
    image: 'pdf-error-image',
    text: 'No PDF'
  };
  fileData = '';
  columnsTitles: string[] = ['Date', 'Amount', 'Type', ''];

  @Select(state => state[APP_STATE][DATA_STATE][PAYMENT_DATA_STATE])
  paymentData$: Observable<PaymentData[]>;

  @Select(state => state[APP_STATE][DATA_STATE][COMPANY_DATA_STATE])
  companyData$: Observable<Company>;

  constructor(private crmPdfService: CrmPdfService,
              private pdfHandlerService: PdfFileHandlerService,
              private localStorage: LocalStorageService) {
  }

  ngOnInit() {
    this.customerId = this.localStorage.getItem(CUSTOMER_ID);
    this.companyData$.subscribe(x => this.legacyDbId = x && x.legacyDbID);
  }

  paymentType(letter: string): string {
    switch (letter) {
      case 'R':
        return 'Regular';
        break;
      case 'P':
        return 'Prepay';
        break;
      case 'B':
        return 'Budget';
        break;
      default:
        return 'Regular';
    }
  }

  paymentColor(letter: string): string {
    switch (letter) {
      case 'R':
        return 'blue-payment';
        break;
      case 'P':
        return 'green-payment';
        break;
      case 'B':
        return 'yellow-payment';
        break;
      default:
        return 'blue-payment';
    }
  }

  isDoneLoading(payments): boolean {
    // https://github.com/ngxs/store/blob/0d18418afa54d0ff474d5d03afd3fb4ff8cf977a/packages/store/src/internal/state-factory.ts#L72
    // ngxs default store value is an empty object i.e. {}. We leverage this to tell whether the store has loaded
    return Array.isArray(payments);
  }

  handleClickableImageSelection(evt: OptionEmitter, payment: PaymentData) {
    if (!this.fileData) return;
    if (evt.id === 'view-pdf') {
      this.pdfHandlerService.openInNewWindow(this.fileData);
    }

    if (evt.id === 'download-pdf') {
      this.pdfHandlerService.downloadToFile(this.fileData, `payment_${payment.number}.pdf`);
    }
  }

  handleClickableImageOpened(evt: boolean, payment: PaymentData) {
    this.fileData = '';
    this.crmPdfService.retrievePayment(this.customerId, this.legacyDbId, `${payment.number}`,
      payment.date, payment.location, payment.s3FileNameGUID)
      .subscribe(fileData => this.fileData = fileData);
  }
}
