import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {stubCustomerPayments} from '../../../app.stub-data';
import {PaymentData} from '../../../state/data/payment-data/payment-data.types';

@Injectable()
export class CustomerPaymentsStubService {

  retrievePayments(agvanceCustomerId: string, companyId: string): Observable<PaymentData[]> {
    return of(stubCustomerPayments);
  }
}
