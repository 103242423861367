import { SidebarMenuOption } from '../../../state/user-interface/sidebar-selection/sidebar-selection.types';

export class SidebarOptions {
  companyIcon: string;
  companyName: string;
  customerName: string;
  menuOptions: SidebarMenuOption[];
  selectedMenuOption: SidebarMenuOption;
}

