import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Injectable()
export class TitleService {
  constructor(private activatedRoute: ActivatedRoute,
              private title: Title) {}

  set(name: string): void {
    const firstChild = this.getInnermostPrimaryChildRoute();
    const routeTitle = firstChild.data ? firstChild.data['title'] : undefined;
    const title = this.buildTitleString(routeTitle, name);

    if (title)
      this.title.setTitle(title);
  }

  private getInnermostPrimaryChildRoute(): ActivatedRouteSnapshot {
    let routeSegment = this.activatedRoute.snapshot.firstChild;
    while (routeSegment.firstChild)
      if (routeSegment.firstChild.outlet === 'primary')
        routeSegment = routeSegment.firstChild;
    return routeSegment;
  }

  private buildTitleString(routeTitle, name): string {
    let title: string;
    if (!routeTitle && !name) {
      return '';
    }
    if (!routeTitle || !name) {
      title = routeTitle || name;
    }
    if (routeTitle && name) {
      title = `${routeTitle} | ${name}`;
    }
    return title;
  }
}
