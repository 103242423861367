import { Component, Input } from '@angular/core';
import { CashBidData } from '../../../../state/data/cash-bid-data/cash-bid-data.types';

@Component({
  selector: 'app-basis-price',
  templateUrl: './basis-price.component.html',
  styleUrls: ['./basis-price.component.scss']
})
export class BasisPriceComponent {
  @Input() cashBid: CashBidData;
  @Input() textSize: string;
}
