import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-img-with-options',
  templateUrl: './image-with-options.component.html',
  styleUrls: ['./image-with-options.component.scss']
})

export class ImageWithOptionsComponent  {
  @Input() imageName: string;
  @Input() options: Option[];
  @Input() errorOptions: ErrorOption;
  @Input() showPlaceholder: boolean;
  @Input() showError: boolean;
  @Input() dataId: string;
  @Output() optionClicked: EventEmitter<any> = new EventEmitter();
  @Output() optionsOpened: EventEmitter<boolean> = new EventEmitter();
  mouseOver = false;
  showOptions = false;

  toggleShowOptions() {
    this.showOptions = !this.showOptions;

    if (this.showOptions) {
      this.optionsOpened.emit(true);
    }
  }

  handleOptionClicked(evt: Option): void {
    if (!this.showPlaceholder) {
      const emitter: OptionEmitter = {
        id: evt.id,
        dataId: this.dataId
      };
      this.optionClicked.emit(emitter);
    }
  }

  handleClickAway() {
    if (!this.mouseOver) {
      this.showOptions = false;
    }
  }
}

export interface Option {
  id: string;
  text: string;
  activeText: boolean;
  image?: string;
}

export interface ErrorOption {
  text: string;
  image?: string;
}

export interface OptionEmitter {
  id: string;
  dataId: string;
}
