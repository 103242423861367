import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  static readonly AUTH_TOKEN_KEY = 'Authorization';

  constructor() { }

  static setAuthToken(token: string): void {
    localStorage.setItem(LocalStorageService.AUTH_TOKEN_KEY, token);
  }

  static getAuthToken(): string {
    return localStorage.getItem(LocalStorageService.AUTH_TOKEN_KEY);
  }

  static removeAuthToken(): void {
    localStorage.removeItem(LocalStorageService.AUTH_TOKEN_KEY);
  }

  setItem(key: string, data: string): void {
    localStorage.setItem(key, data);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  getItem(key: string): string {
    return localStorage.getItem(key);
  }

}
