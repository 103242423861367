import {Component} from '@angular/core';
import {GrainScaleTicketData} from '../../../../../state/data/grain-scale-ticket-data/grain-scale-ticket-data.types';
import {Select} from '@ngxs/store';
import {APP_STATE} from '../../../../../state/app.state';
import {DATA_STATE} from '../../../../../state/data/data.state';
import {Observable} from 'rxjs';
import {GRAIN_SCALE_TICKET_DATA_STATE} from '../../../../../state/data/grain-scale-ticket-data/grain-scale-ticket-data.state';
import {GrainScaleTicketStatusService} from '../../../../../state/data/grain-scale-ticket-data/grain-scale-ticket-status.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-recent-activity-scale-ticket',
  templateUrl: './recent-activity-scale-ticket.component.html',
  styleUrls: ['./recent-activity-scale-ticket.component.scss']
})
export class RecentActivityScaleTicketComponent {

  columnTitles: string[] = ['Date', 'Commodity', 'Net Units', 'Your Split'];
  @Select(state => state[APP_STATE][DATA_STATE][GRAIN_SCALE_TICKET_DATA_STATE])
  scaleTicketData$: Observable<GrainScaleTicketData[]>;

  constructor(private scaleTicketStatusService: GrainScaleTicketStatusService, private router: Router) {
  }

  isDoneLoading(scaleTickets): boolean {
    // https://github.com/ngxs/store/blob/0d18418afa54d0ff474d5d03afd3fb4ff8cf977a/packages/store/src/internal/state-factory.ts#L72
    // ngxs default store value is an empty object i.e. {}. We leverage this to tell whether the store has loaded
    return Array.isArray(scaleTickets);
  }

  routeToScaleTicket(ticketNumber: string): void {
    this.router.navigate(['grain', 'scaletickets', 'ticket', ticketNumber]);
  }
}
