import { Pipe, PipeTransform } from '@angular/core';
import { AccountDropdownAvatarColors } from '../account-dropdown.types';

@Pipe({
  name: 'avatarColor'
})
export class AvatarColorPipe implements PipeTransform {

  transform(item: AccountDropdownAvatarColors): string {
    const defaultStyle = 'avatar-container-color-default';

    if (!item) {
      return defaultStyle;
    }

    switch (item) {
      case AccountDropdownAvatarColors.grower360Gradient: {
        return 'avatar-container-color-g360';
      }
      default: {
        return defaultStyle;
      }
    }
  }
}
