import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import {Grower360RootComponent} from './core/pages/shared/grower360-root/grower360-root.component';
import {SubdomainGuardService} from './shared/route-guards/subdomain-guard.service';
import {ResetPasswordGuardService} from './public/route-guards/reset-password-guard.service';
import {AccountSwitchComponent} from './core/pages/account-switch/account-switch.component';
import {CashBidDetailsPageComponent} from './core/components/cash-bids/cash-bid-details-page/cash-bid-details-page.component';
import {NotificationsListComponent} from './core/pages/notifications/notifications-list/notifications-list.component';
import {AsyncAuthGuardService} from './shared/route-guards/async-auth-guard.service';

const APP_ROUTES: Route[] = [
  {
    path: '',
    component: Grower360RootComponent,
    canActivate: [SubdomainGuardService, AsyncAuthGuardService],
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        redirectTo: '/overview',
        pathMatch: 'full',
        data: { title: 'Overview' }
      },
      {
        canActivate: [AsyncAuthGuardService],
        path: 'overview',
        loadChildren: 'app/core/pages/overview/overview.module#OverviewModule',
        data: { title: `Overview` }
      },
      {
        canActivate: [AsyncAuthGuardService],
        path: 'my-account',
        loadChildren: 'app/core/pages/my-account/my-account.module#MyAccountModule',
        data: {title: `Account`}
      },
      {
        canActivate: [AsyncAuthGuardService],
        path: 'grain',
        loadChildren: 'app/core/pages/grain/grain.module#GrainModule',
        data: {title: `Grain`}
      },
      {
        canActivate: [AsyncAuthGuardService],
        path: 'news',
        loadChildren: 'app/core/pages/news/news.module#NewsModule',
        data: {title: `News`}
      },
      {
        path: 'notifications',
        component: NotificationsListComponent
      }
    ]
  },
  {
    path: 'account-switch',
    component: AccountSwitchComponent,
    canActivate: [SubdomainGuardService, AsyncAuthGuardService],
    pathMatch: 'prefix',
    data: {title: `Account Switch`}
  },
  {
    path: 'notfound',
    loadChildren: 'app/public/pages/info/info.module#InfoModule',
    data: {title: `Not Found`}
  },
  {
    path: 'sign-in',
    canActivate: [SubdomainGuardService],
    loadChildren: 'app/public/pages/sign-in/sign-in.module#SignInModule',
    data: {title: `Sign In`}
  },
  {
    path: 'unsubscribe',
    canActivate: [SubdomainGuardService],
    loadChildren: 'app/public/pages/unsubscribe/unsubscribe.module#UnsubscribeModule',
    data: {title: 'Unsubscribe'}
  },
  {
    path: 'change-password',
    canActivate: [SubdomainGuardService],
    loadChildren: 'app/public/pages/change-password/change-password.module#ChangePasswordModule',
    data: {title: `Change Password`}
  },
  {
    path: 'create-password',
    canActivate: [SubdomainGuardService, ResetPasswordGuardService],
    loadChildren: 'app/public/pages/reset-password/reset-password.module#ResetPasswordModule',
    data: {isCreatePassword: true, title: `Create Password`}
  },
  {
    path: 'reset-password',
    canActivate: [SubdomainGuardService, ResetPasswordGuardService],
    loadChildren: 'app/public/pages/reset-password/reset-password.module#ResetPasswordModule',
    data: {isCreatePassword: false, title: `Reset Password`}
  },
  {
    path: 'cash-bid-details',
    canActivate: [SubdomainGuardService],
    component: CashBidDetailsPageComponent,
    data: { title: 'Cash Bid Details' }
  },
  {
    path: 'error/:type',
    loadChildren: 'app/shared/pages/error/error.module#ErrorModule',
    data: {title: `Error`}
  },
  {
    path: '**',
    loadChildren: 'app/shared/pages/error/error.module#ErrorModule',
    data: {title: `Error`}
  },
];

export const Grower360RouterModule: ModuleWithProviders
  = RouterModule.forRoot(APP_ROUTES);
