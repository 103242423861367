import { Injectable } from '@angular/core';
import {SkyHttpService} from 'sky-services';
import { Observable, of } from 'rxjs';
import { ENVIRONMENT } from '../../../../environments/environment';
import {EmailUserInfo} from '../../../shared/types/email-user-info';
import {PaginationEnvelope} from '../../../shared/types/pagination-envelope';
import {NotificationListParams} from '../../../shared/types/notification-list-params';

@Injectable()
export class NotificationsListService {

  constructor(private http: SkyHttpService) { }

  searchNotifications(info: NotificationListParams): Observable<PaginationEnvelope<EmailUserInfo>> {
    const search = (info.searchTerms || '').trim() || null;
    const endpoint = '/grower360/email/userinfo?';
    const grower360User = `&grower360userid=${info.grower360UserId}`;
    const searchTerms = search ? `&searchterms=${search}` : '';
    const pageSize = `&pagesize=${info.pageSize}`;
    const pageNumber = `&pagenumber=${info.pageNumber}`;
    const url = `${endpoint}${grower360User}${pageSize}${pageNumber}${searchTerms}`;
    return this.searchNotificationsWithPath(url);
  }

  searchNotificationsWithPath(urlPath: string): Observable<PaginationEnvelope<EmailUserInfo>> {
    const url = `${ENVIRONMENT.gatewayApiBaseUrl}${urlPath}`;
    return this.http.authGet(url);
  }
}

export class NotificationsDemoListService {
  searchNotifications(info: NotificationListParams): Observable<PaginationEnvelope<EmailUserInfo>> {
    const emailUserInfoList: EmailUserInfo[] = [];
    for (let i = 0; i < info.pageSize; i++) {
      emailUserInfoList.push({
        isRead: false,
        emailType: 'Normal',
        messageBody: `Message Body ${i}`,
        subject: `Subject ${i}`,
        timeStamp: (new Date()).toDateString()
      });
    }
    const envelope: PaginationEnvelope<EmailUserInfo> = {
      linksMetaData: {
        next: null
      },
      data: emailUserInfoList
    };
    return of(envelope);
  }
}
