import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { APP_STATE } from '../../../../state/app.state';
import { USER_INTERFACE_STATE } from '../../../../state/user-interface/user-interface.state';
import { ACCOUNT_DROPDOWN_STATE } from '../../../../state/user-interface/account-dropdown/account-dropdown.state';
import { Observable } from 'rxjs';
import { AccountDropdown } from '../../../../state/user-interface/account-dropdown/account-dropdown.types';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent {
  @Select(state => state[APP_STATE][USER_INTERFACE_STATE][ACCOUNT_DROPDOWN_STATE])
  accountDropdownOptions$: Observable<AccountDropdown>;
}
