import { Injectable } from '@angular/core';
import { IdxStorage } from '@ssiagvance/dexx';

@Injectable({
  providedIn: 'root'
})
export class PostSignInRoutingService {
  public static readonly PostSignInRoutingKey = 'post-sign-in-url';
  public static readonly PostSignInExpiration = '10m';

  constructor(private dexxStorage: IdxStorage) { }

  async savePostSignInUrl(url: string) {
    try {
      await this.dexxStorage.save(url, { id: [PostSignInRoutingService.PostSignInRoutingKey] },
        PostSignInRoutingService.PostSignInExpiration);
    } catch (e) {
      return null;
    }
  }

  async popPostSignInUrl(): Promise<string | null> {
    const url = await this.getPostSignInUrl();
    if (!url) return null;
    await this.removePostSignInUrl();
    return url || null;
  }

  private async getPostSignInUrl(): Promise<string | null> {
    try {
      return await this.dexxStorage.getFirst({ id: [PostSignInRoutingService.PostSignInRoutingKey] }, true);
    } catch (e) {
      return null;
    }
  }

  private async removePostSignInUrl(): Promise<void> {
    try {
      await this.dexxStorage.remove({ id: [PostSignInRoutingService.PostSignInRoutingKey] }, true);
    } catch (e) { return; }
  }

}
