import { Component, Input, OnInit } from '@angular/core';
import { CashBidData } from '../../../../state/data/cash-bid-data/cash-bid-data.types';
import * as moment from 'moment';

@Component({
  selector: 'app-legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
  styleUrls: ['./legal-disclaimer.component.scss']
})
export class LegalDisclaimerComponent implements OnInit {
  @Input() cashBids: CashBidData[];
  source: 'dtn' | 'barcharts';
  boldDisclaimer: string;
  disclaimer: string;

  ngOnInit(): void {
    const hasDtn = this.cashBids && this.cashBids.find(x => x.source === 'dtn');
    this.source = hasDtn ? 'dtn' : 'barcharts';
    if (this.source === 'dtn') {
      this.boldDisclaimer = `Last Updated ${moment().format('ddd h:mm a')}`,
      this.disclaimer = ' Futures: at least 10 minute delayed.' +
      ' Information is provided \'as is\' and solely for informational purposes, not for trading purposes or advice.' +
      ' To see all exchange delays and terms of use, please see disclaimer.';
    }
    else {
      this.boldDisclaimer = 'Copyright © 2018. All market data is provided by Barchart Solutions. ',
      this.disclaimer = ' Futures: at least 10 minute delayed.' +
      ' Information is provided \'as is\' and solely for informational purposes, not for trading purposes or advice.' +
      ' To see all exchange delays and terms of use, please see disclaimer.';
    }
  }
}
