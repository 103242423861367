import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SkyHttpService } from 'sky-services';
import { ENVIRONMENT } from '../../../../environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable()
export class PrefMiscService {
  public static readonly BASE_URL = `${ENVIRONMENT.gatewayApiBaseUrl}/preferences`;
  static readonly NoAuthTokenError = 'No authorization token found. Cannot complete request.';
  private static readonly status404 = 404;

  public constructor(
    private readonly httpService: SkyHttpService,
    private readonly localStorageService: LocalStorageService
  ) { }

  public fetch(keyName: string): Observable<string> {
    const authToken = this.localStorageService.getItem(LocalStorageService.AUTH_TOKEN_KEY);
    if (!authToken) {
      throw new Error(PrefMiscService.NoAuthTokenError);
    }
    const headers = { 'Authorization': authToken, 'Accept': 'application/json' };
    return this.httpService.get<string>(`${PrefMiscService.BASE_URL}/${keyName}`, headers).pipe(
      map((json: any) => json.theText),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === PrefMiscService.status404) {
          return of('');
        }

        throw new Error(this.getHttpErrorMessage(errorResponse));
      })
    );
  }

  private getHttpErrorMessage(errorResponse: HttpErrorResponse): string {
    let errorMessage: string;
    if (typeof (errorResponse.error) === 'string') {
      errorMessage = `Error: ${errorResponse.error}`;
    } else if (errorResponse.error && errorResponse.error.message && typeof (errorResponse.error.message) === 'string') {
      errorMessage = `Error: ${errorResponse.error.message}`;
    } else {
      errorMessage = `Error: ${errorResponse.message}`;
    }

    return errorMessage;
  }
}
