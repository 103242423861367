import {Action, State, StateContext} from '@ngxs/store';
import {GrowerInfoResponseDto} from '../../../core/core-services/grower-id/grower-info-list.service';
import {UpdateAccountData} from './account-data.actions';

export const ACCOUNT_DATA_STATE = 'accountData';
@State<GrowerInfoResponseDto[]>({
  name: ACCOUNT_DATA_STATE,
})

export class AccountDataState {
  @Action(UpdateAccountData)
  updateAccountData({ setState }: StateContext<GrowerInfoResponseDto[]>, payload: UpdateAccountData) {
    setState(payload.accountData);
  }
}
