import {Injectable} from '@angular/core';
import {TreatmentService} from '../../types/treatment.service';

@Injectable()
export class TreatmentServiceStub implements TreatmentService {
  isTreatmentOn(treatmentName: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  getTreatment(name: string): string {
    return 'on';
  }

  ready(): Promise<void> {
    return Promise.resolve();
  }
}
