import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-help-information',
  templateUrl: './help-information.component.html',
  styleUrls: ['./help-information.component.scss']
})
export class HelpInformationComponent implements OnInit {
  ngOnInit() { }
}
