import { Injectable } from '@angular/core';
import { FetchLikeObject } from 'sky-services';
import { ENVIRONMENT } from '../../../../../environments/environment';

export interface UserPoolSettings {
  userPoolId: string;
  clientId: string;
}

@Injectable()
export class CognitoSettingsService {
  public static readonly BadRequest = 'No settings found wit the given company ID';

  public constructor(private fetchObject: FetchLikeObject) {}

  async getUserPoolSettings(companyId: string): Promise<UserPoolSettings> {
    const url = `${ENVIRONMENT.grower360ApiBaseUrl}/cognitouserpoolinfo?customerId=${companyId}`;
    const result = await this.fetchObject.fetch(url, { method: 'get' });
    if (!result.ok) {
      throw new Error(CognitoSettingsService.BadRequest);
    }
    return await result.json();
  }
}
