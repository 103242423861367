
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ApplicationErrorType } from '../../shared/pages/error/error.interface';
import { VerifyResetPasswordTokenService } from '../public-services/verify-reset-password-token/verify-reset-password-token.service';
import { Observable ,  of } from 'rxjs';


@Injectable()
export class ResetPasswordGuardService implements CanActivate {
  constructor(
    private router: Router,
    private verifyTokenService: VerifyResetPasswordTokenService) { }

  canActivate(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const token: string = snapshot.queryParams['token'];
    const hasRouteData = (snapshot === undefined || snapshot.data === undefined || snapshot.data['isCreatePassword'] === undefined)
      ? false : true;

    if (!token || !hasRouteData) {
      return this.routeToErrorPage();
    }

    return this.verifyTokenService.isValid(token).pipe(
      map(() => true),
      catchError(() => this.routeToErrorPage()));
  }

  private routeToErrorPage(): Observable<boolean> {
    this.router.navigate(['/error', ApplicationErrorType.ExpiredResetToken]);
    return of(false);
  }
}
