<div class="sidebar" [ngClass]="currentlyExpanded ? 'expanded-sidebar-container' : 'condensed-sidebar-container'"
     (mouseenter)="showExpansionButton()" (mouseleave)="hideExpansionButton()">
  <div class="content-container">
    <div class="sidebar-heading">
      <div class="company-container" (click)="goToHome()">
        <div class="company-icon-container">
          <img data-id="company-logo" class="g360-icon"
               src="https://static-content.agvancesky.com/company-logos/{{(companyInfo$|async)?.companyId}}"
               onerror="this.src ='../../../../assets/images/grower360-default-icon.svg'">
        </div>
      </div>
      <div class="mobile-exit" (click)="mobileExitClicked()">
        <div class="clear-icon">
          <img src="../../../../assets/images/clear.svg">
        </div>
      </div>
    </div>
    <div data-id="sidebar-menu" class="sidebar-menu" *ngIf="!showSubmenu((mobileSidebar$ | async)?.submenu)">
      <div class="menu-items-container">
        <div *ngFor="let header of sidebarOptions.menuOptions | uniqueMenuHeaders">
          <div data-id="header-name" class="header-name">
            {{header}}
          </div>
          <ng-container *ngIf="sidebarOptions">
            <div data-id="menu-item" class="menu-item"
                 *ngFor="let item of sidebarOptions.menuOptions | filterMenuItems:header"
                 [ngClass]="{highlight: item.menuItemName === (sidebarOptions.selectedMenuOption && sidebarOptions.selectedMenuOption.menuItemName),
                'hover-highlight': item.menuItemName !== (sidebarOptions.selectedMenuOption && sidebarOptions.selectedMenuOption.menuItemName)
                && itemHovered === item.menuItemName,
                shift: currentlyExpanded !== true}"
                 (click)="menuItemClicked(item)"
                 (mouseenter)="itemHovered = item.menuItemName"
                 (mouseleave)="itemHovered = ''">
              <div class="menu-item-icon">
                <div class="{{item.menuItemIcon}}"></div>
              </div>
              <div data-id="menu-item-name" class="menu-item-name">
                {{item.menuItemName}}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="mobile-profile" (click)="profileClicked()">
        <app-account-profile></app-account-profile>
        <div class="mobile-profile-arrow"></div>
      </div>
    </div>
    <div data-id="sidebar-submenu" class="sidebar-submenu" *ngIf="showSubmenu((mobileSidebar$ | async)?.submenu)">
      <app-mobile-sidebar-submenu></app-mobile-sidebar-submenu>
    </div>
  </div>
</div>
<div class="{{currentlyExpanded ? 'arrow-left' : 'arrow-right'}} expansion-arrow" (click)="expansionButtonClicked()"
     [ngClass]="isExpansionButtonVisible ? 'show-expansion-arrow' : 'hide-expansion-arrow'"></div>
