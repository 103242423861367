import { Component, Input } from '@angular/core';
import {CustomerService} from '../../core-services/customer/customer.service';
import { PostSignInRoutingService } from '../../../public/public-services/post-sign-in-routing/post-sign-in-routing.service';
import { Router } from '@angular/router';
import {GrowerInfoResponseDto} from '../../core-services/grower-id/grower-info-list.service';

export const CUSTOMER_ID = 'customerId';
export const ALLOWED_CHARACTERS = 20;

@Component({
  selector: 'app-account-card',
  templateUrl: 'account-card.component.html',
  styleUrls: ['account-card.component.scss']
})

export class AccountCardComponent {
  @Input() customerId: string;
  @Input() customerName: string;
  @Input() regularBalance: number;
  @Input() agvanceGrowerId: string;
  @Input() customers: GrowerInfoResponseDto[];

  constructor(private customerService: CustomerService,
              private router: Router,
              private postSignInRoutingService: PostSignInRoutingService) { }

  avatarLetter() {
    return this.customerName.trim()[0];
  }

  formatCustomerName() {
    return this.customerName.length <= ALLOWED_CHARACTERS ? this.customerName : this.customerName.substr(0, ALLOWED_CHARACTERS) + '...';
  }

  growerId() {
    return (this.customers != null && this.customers.filter(x => x.firstName + ' ' + x.lastName === this.customerName).length > 1)
      ? this.agvanceGrowerId
      : '';
  }

  onLaunch(): void {
    this.customerService.switchCustomer(this.customerId);
    this.postSignInRoutingService.popPostSignInUrl()
      .then((route: string) => {
        const url = route || '';
        return this.router.navigateByUrl(url);
      })
      .catch(() => {});
  }
}
