import { Injectable } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { GetCognitoUserPoolService } from '../get-cognito-user-pool/get-cognito-user-pool.service';

@Injectable()
export class CognitoUserCreatorService {
  private cognitoUserCreatorObj: any;

  constructor(
    private readonly getCognitoUserPool: GetCognitoUserPoolService,
    cognitoUserCreator?: any,
    private userStorage = new Map<string, CognitoUser>()) {
    this.cognitoUserCreatorObj = cognitoUserCreator || AmazonCognitoIdentity;
  }

  public async getOrCreateCognitoUser(companyId: string, email: string): Promise<CognitoUser> {
    const key = btoa(`${companyId}/${email}`);
    if (this.userStorage.has(key)) {
      return this.userStorage.get(key);
    }
    else {
      const userPool = await this.getCognitoUserPool.get(companyId);
      const user = new this.cognitoUserCreatorObj.CognitoUser({ Pool: userPool, Username: email });
      this.userStorage.set(key, user);
      return user;
    }
  }
}

