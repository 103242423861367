import { Action, State, StateContext } from '@ngxs/store';
import { SidebarMenuOption } from './sidebar-selection.types';
import { UpdateCurrentSidebarSelection } from './sidebar-selection.actions';

export const SIDEBAR_SELECTION_STATE = 'sidebarSelection';

@State<SidebarMenuOption>({
  name: SIDEBAR_SELECTION_STATE,
  defaults: {
    menuItemName: 'Overview',
    menuItemLink: '/my-account',
    sectionName: 'GLANCE',
    menuItemIcon: 'overview-icon'
  }
})
export class SidebarSelectionState {

  @Action(UpdateCurrentSidebarSelection)
  updateCurrentSidebarSelection({ setState }: StateContext<SidebarMenuOption>, payload: UpdateCurrentSidebarSelection) {
    setState(payload.sidebarSelection);
  }
}
