import { Pipe, PipeTransform } from '@angular/core';
import { PaymentData } from '../../../../state/data/payment-data/payment-data.types';
import * as _ from 'lodash';

@Pipe({
  name: 'sortPayments'
})
export class SortPaymentsPipe implements PipeTransform {

  transform(payments: PaymentData[]): PaymentData[] {
    if (!payments) {
      return payments;
    }

    return _.sortBy(payments, ['date', 'number']).reverse().slice(0, 3);
  }
}
