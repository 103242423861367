import { Injectable } from '@angular/core';
import { HttpClientOptions, HttpClientOptionsBase } from '../http-client-with-storage.service';

@Injectable()
export class NormalizeHttpOptionsService {

  normalize(options: HttpClientOptionsBase): HttpClientOptions {
    return { observe: 'response', responseType: 'json', ...options };
  }
}
