import {ENVIRONMENT} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {PaymentData} from '../../../state/data/payment-data/payment-data.types';
import {Observable} from 'rxjs';
import {HttpClientOptionsBase} from '../../../shared/shared-services/http-client-with-storage/http-client-with-storage.service';
import {HttpClientWithAuthHeadersService} from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import {GrowerIdService} from '../grower-id/grower-id.service';
import {CustomerPaymentsParameters} from './customer-payments-parameters';

@Injectable()
export class CustomerPaymentsService {
  static GET_PAYMENTS_PATH = `${ENVIRONMENT.grower360ApiBaseUrl}/customerpayments`;

  constructor(private httpClient: HttpClientWithAuthHeadersService, private growerIdService: GrowerIdService) {
  }

  retrievePayments(agvanceCustomerId: string,
                   additionalParams?: CustomerPaymentsParameters): Observable<PaymentData[]> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CustomerPaymentsService.GET_PAYMENTS_PATH}?growerid=${growerId}`;
          const options: HttpClientOptionsBase = new HttpClientOptionsBase();

          if (additionalParams) {
            Object.keys(additionalParams).forEach(key => {
              options.params[key] = `${additionalParams[key]}`;
            });
          }
          return this.httpClient.get(url, options, '0s');
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }
}
