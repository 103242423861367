import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../environments/environment';
import { GrainSettlementData } from '../../../state/data/grain-settlement-data/grain-settlement-data.types';
import {Observable,  from as fromPromise } from 'rxjs';
import { HttpClientOptionsBase } from '../../../shared/shared-services/http-client-with-storage/http-client-with-storage.service';
import { HttpClientWithAuthHeadersService } from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import {GrowerIdService} from '../grower-id/grower-id.service';
import { CustomerGrainSettlementsParameters } from './customer-grain-settlements-parameters';
import { PaginatedResponse } from '../../../state/paginated-response/paginated-response.types';

@Injectable()
export class CustomerGrainSettlementsService {
  static GET_GRAIN_SETTLEMENTS_PATH = `${ENVIRONMENT.gatewayApiBaseUrl}/grainsettlement`;

  constructor(private httpClient: HttpClientWithAuthHeadersService, private growerIdService: GrowerIdService) { }

  retrieveGrainSettlements(agvanceCustomerId: string,
    additionalParams?: CustomerGrainSettlementsParameters): Observable<PaginatedResponse<GrainSettlementData>> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url =
            `${CustomerGrainSettlementsService.GET_GRAIN_SETTLEMENTS_PATH}/?CustomerId=${growerId}&includeclosed=true&disposition=priced`;
          const options: HttpClientOptionsBase = new HttpClientOptionsBase();

          if (additionalParams) {
            Object.keys(additionalParams).forEach(key => {
              options.params[key] = `${additionalParams[key]}`;
            });
          }

          return this.httpClient.get(url, options);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }
}
