<div class="legal-disclaimer-container">
  <a *ngIf="source === 'dtn'" href="https://www.cmegroup.com/education" target="_blank">
    <img class="logo-image" src="../../../../../../assets/images/Dtn-Logo.png"/>
  </a>
  <a *ngIf="source === 'barcharts'" href="http://www.cmegroup.com" target="_blank">
    <img class="logo-image" src="../../../../../../assets/images/CME-Group-Logo.png"/>
  </a>
  <div class="disclaimer">
    <div><b>{{boldDisclaimer}}</b></div>
    <div>{{disclaimer}}</div>
  </div>
</div>
