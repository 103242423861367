import { NgModule } from '@angular/core';
import { FilterMenuItemsPipe } from './filter-menu-items.pipe';
import { UniqueMenuHeadersPipe } from './unique-menu-headers.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  imports:        [CommonModule],
  declarations:   [FilterMenuItemsPipe, UniqueMenuHeadersPipe],
  exports:        [FilterMenuItemsPipe, UniqueMenuHeadersPipe],
})

export class SidebarPipesModule {}
