import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { Grower360AccountResponse } from './account.types';
import { stubAcctResponse } from '../../../app.stub-data';

@Injectable()
export class AccountStubService {

  retrieveAccounts(grower360UserId: string): Observable<Grower360AccountResponse[]> {
    return of([stubAcctResponse]);
  }
}
