<ng-container *ngIf="customerData && accountData$ | async as accountData; else loading">
  <div data-id="customer-picker" class="customer-picker-container" (click)="toggleDropdown()">
    <div data-id="selected-customer" [ngClass]="{'toggle-available': hasMultipleAccounts(accountData)}" class="header-item selected-item">
      <div data-id="selected-customer-icon" class="item-icon-customer-picker icon-customer-picker">
        <img src="../../../../../assets/images/myAccount.svg">
      </div>
      <div class="item-text-customer-picker">
        <div class="text-customer-picker" data-id="selected-feature-text">
          {{customerData.name}}
        </div>
        <div class="chevron-container" [class.picker-opened]="showList" *ngIf="hasMultipleAccounts(accountData)">
          <img src="../../../../../assets/images/chevronPurple.svg">
        </div>
      </div>
    </div>
    <div data-id="customer-picker-list" class="item-list" *ngIf="showList && accountData.length" [@toggleAnimation]>
      <ng-container *ngFor="let account of accountData">
        <div data-id="list-item-customer-picker" class="item" *ngIf="notCurrentCustomer(account)" (click)="onClickItem(account)">
          <div data-id="item-icon-customer-picker" class="list-item-icon">
            <img src="../../../../../assets/images/eyeball.svg">
          </div>
          <div data-id="{{account.growerGuid}}" class="list-item-text">
            {{account.firstName}} {{account.lastName}}
          </div>
          <div data-id="{{account.growId}}" class="list-item-text-growid" *ngIf="hasSameNameOfAnotherGrower(account, accountData)">
            {{account.growId}}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="loading-header-item">
    <div data-id="selected-customer-icon" class="item-icon-customer-picker icon-customer-picker">
      <img src="../../../../../assets/images/myAccount.svg">
    </div>
  </div>
</ng-template>
