import { Action, State, StateContext } from '@ngxs/store';
import { UpdateOpenInvoiceData, UpdateRecentActivityInvoiceData, UpdateInvoiceData } from './invoice-data.actions';
import { InvoiceData } from './invoice-data.types';

export const OPEN_INVOICE_DATA_STATE = 'openInvoiceData';
@State<InvoiceData[]>({
  name: OPEN_INVOICE_DATA_STATE,
})

export class OpenInvoiceDataState {
  @Action(UpdateOpenInvoiceData)
  updateOpenInvoiceData({ setState }: StateContext<InvoiceData[]>, payload: UpdateOpenInvoiceData) {
    setState(payload.invoiceData);
  }
}

export const INVOICE_DATA_STATE = 'invoiceData';

@State<InvoiceData[]>({
  name: INVOICE_DATA_STATE,
})

export class InvoiceDataState {
  @Action(UpdateInvoiceData)
  updateInvoiceData({ setState }: StateContext<InvoiceData[]>, payload: UpdateInvoiceData) {
    setState(payload.invoiceData);
  }
}

export const RECENT_ACTIVITY_INVOICE_DATA_STATE = 'recentActivityInvoiceData';
export const RECENT_ACTIVITY_INVOICE_LOADING = {};
@State<InvoiceData[] | {}>({
  name: RECENT_ACTIVITY_INVOICE_DATA_STATE,
})

export class RecentActivityInvoiceDataState {
  @Action(UpdateRecentActivityInvoiceData)
  updateRecentActivityInvoiceData({ setState }: StateContext<InvoiceData[] | {}>, payload: UpdateRecentActivityInvoiceData) {
    setState(payload.invoiceData);
  }
}

