<div class="account-submenu-container">
  <div class="profile-header">
    PROFILE
  </div>
  <div class="profile-avatar">
    <app-account-profile></app-account-profile>
  </div>
  <div class="account-submenu-items">
    <div data-id="dropdown-list" class="account-submenu-list-container">
      <div class="dropdown-list-items clickable" *ngFor="let option of (accountDropdownOptions$ | async)?.listOptions"
           (click)="invokeAction(option.action)">
        <div class="icon-wrapper">
          <div data-id="list-item-icon" class="{{option.iconClass}}"></div>
        </div>
        <div data-id="list-item-text" class="dropdown-list-item-text">{{option.text}}</div>
      </div>
    </div>
  </div>
</div>
