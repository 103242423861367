<div class="container">
  <div class="header">
    <div class="close-icon clickable"></div>
    <div class="panel-title">Choose Account</div>
  </div>
  <div class="card-grid">
    <ng-container *ngFor="let accountData of accountCardData$ | async">
      <app-account-card [customerId]="accountData.growerGuid"
                        [customerName]="accountData.firstName + ' ' + accountData.lastName"
                        [regularBalance]="accountData.regularBalance"
                        [agvanceGrowerId]="accountData.growId"
                        [customers]="accountCardData$ | async">
      </app-account-card>
    </ng-container>
  </div>
</div>
