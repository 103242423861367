import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { UpdateUserData } from '../../state/data/user-data/user-data.actions';
import { stubUserInfo } from '../../app.stub-data';

@Injectable()
export class SubdomainGuardStubService implements CanActivate {

  constructor(private store: Store) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.store.dispatch(new UpdateUserData(stubUserInfo));
    return true;
  }
}
