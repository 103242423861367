import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utcToLocalDate'
})
export class UtcToLocalDatePipe implements PipeTransform {
  transform(utcDate: string): string {
    return moment.utc(utcDate).toDate().toString();
  }
}
