import { Component, Input, OnInit } from '@angular/core';
import { CashBidData } from '../../../../state/data/cash-bid-data/cash-bid-data.types';

@Component({
  selector: 'app-net-change',
  templateUrl: './net-change.component.html',
  styleUrls: ['./net-change.component.scss']
})
export class NetChangeComponent implements OnInit {
  @Input() cashBid: CashBidData;
  @Input() size: string;
  @Input() precision: string;
  isNaN;

  ngOnInit() {
    this.isNaN = isNaN;
  }
}
