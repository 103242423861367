import * as moment from 'moment';

class Status {
  code: number;
  message: string;
}

export class Quote {
  public constructor(init?: Quote) {
    Object.assign(this, init);
  }

  symbol?: string;
  lastPrice?: number;
  netChange?: string;
  open?: number;
  high?: number;
  low?: number;
  tradeTimestamp?: string;
  unitCode?: string;

  get tradeTimestampIsToday(): boolean {
    return moment(this.tradeTimestamp).isSame(moment(), 'day');
  }
}

export class BarchartsApiQuoteData {
  status?: Status;
  results: Quote[];
}
