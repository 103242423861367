export class CustomerData {
  firstName: string;
  lastName: string;
  regularBalance: number;
  unappliedCashBalance: number;
  prepayBalance: number;
  budgetDueBalance: number;
  uuid: string;
  locationId: string;

  get name(): string {
    return this.firstName + ' ' + this.lastName;
  }

  public constructor(init?: CustomerData) {
    Object.assign(this, init);
  }
}
