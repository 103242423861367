import {Injectable, Optional} from '@angular/core';
import {WindowRefService} from './core/core-services/window-ref/window-ref.service';

export class GoogleAnalyticsService { ga: (operation: string, subject: string, type?: string, info?: string) => void; }

@Injectable()
export class AnalyticsService {
  private googleAnalyticsService: GoogleAnalyticsService;

  constructor(private windowRefService: WindowRefService, @Optional() private googleService: GoogleAnalyticsService) {
    this.googleAnalyticsService = googleService || windowRefService.getNativeWindow() as any as GoogleAnalyticsService;
  }

  sendPageView(url: string): void {
    this.googleAnalyticsService.ga('set', 'page', url);
    this.googleAnalyticsService.ga('send', 'pageview');
  }
  sendEvent(type: string, info: string): void {
    this.googleAnalyticsService.ga('send', 'event', type, info);
  }
}
