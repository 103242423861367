import { Injectable } from '@angular/core';
import { from as fromPromise, Observable, of, throwError } from 'rxjs';
import { UserInfo } from './user-info.types';
import { ENVIRONMENT } from '../../../../environments/environment';
import { DexxAttributeMap, DexxHttp, DexxHttpResponse } from '@ssiagvance/dexx';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class UserInfoService {
  static readonly GetUserInfoApiUrlPath = `${ENVIRONMENT.skydentityBaseUrl}/connect/userinfo`;
  static readonly NoAuthToken = 'No authorization token found for this user';
  static readonly NoUserInfoFound = 'No user info found for this user';
  static readonly InfoExpiration = '1m';

  constructor(private dexxHttp: DexxHttp, private storage: LocalStorageService) { }

  retrieveUserInfo(): Observable<UserInfo> {
    const authToken = this.storage.getItem(LocalStorageService.AUTH_TOKEN_KEY);
    if (!authToken) return throwError(new Error(UserInfoService.NoAuthToken));

    const headers = { Authorization: authToken, Accept: 'application/json' };
    const attributes: DexxAttributeMap = { 'token': [authToken] };
    return fromPromise(this.dexxHttp.get(UserInfoService.GetUserInfoApiUrlPath, UserInfoService.InfoExpiration, attributes, headers)).pipe(
      switchMap((response: DexxHttpResponse) => {
        return response.status === 200 ? of(response.body) : throwError(new Error(UserInfoService.NoUserInfoFound));
      })
    );
  }
}
