import { Injectable } from '@angular/core';
import { FetchLikeObject } from 'sky-services';
import { ENVIRONMENT } from '../../../../../environments/environment';

@Injectable()
export class ExchangeTokenService {
  public static readonly ExchangeTokenError =
    'Cognito token could not be exchanged for a skydentity token';

  constructor(private readonly fetchObject: FetchLikeObject) {}

  async exchangeCognitoRefreshTokenForSkydentityAccessToken(refreshToken: string, companyId: string): Promise<string> {
    const skydentityUrl = `${ENVIRONMENT.skydentityBaseUrl}/connect/token`;
    const skydClientId = ENVIRONMENT.skydentityClientInfo.clientId;
    const skydSecret = ENVIRONMENT.skydentityClientInfo.clientSecret;
    const skydScopes = ENVIRONMENT.skydentityClientInfo.clientScopes;
    const tokenCall = await this.fetchObject.fetch(skydentityUrl, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'x-g360-refresh': refreshToken,
        'x-g360-company-id': companyId
      },
      body: `client_id=${skydClientId}&client_secret=${skydSecret}&grant_type=password&scope=${skydScopes}&username=u&password=p`
    });
    if (tokenCall.ok) {
      return (await tokenCall.json())['access_token'];
    }
    else {
      throw new Error(ExchangeTokenService.ExchangeTokenError);
    }
  }


}
