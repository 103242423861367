import { CustomerData } from './customer-data.types';
import { Action, State, StateContext } from '@ngxs/store';
import { UpdateCustomerData } from './customer-data.actions';

export const CUSTOMER_DATA_STATE = 'customerData';
@State<CustomerData>({
  name: CUSTOMER_DATA_STATE,
  defaults: new CustomerData()
})

export class CustomerDataState {

  @Action(UpdateCustomerData)
  updateCustomerData({ setState }: StateContext<CustomerData>, payload: UpdateCustomerData) {
    setState(payload.customerData);
  }
}
