import {Action, Selector, State, StateContext} from '@ngxs/store';
import {FieldData} from './field-data.types';
import {UpdateFieldData} from './field-data.actions';

export const FIELD_DATA_STATE = 'fieldData';
@State<FieldData[]>({
  name: FIELD_DATA_STATE,
  defaults: []
})
export class FieldDataState {
  @Action(UpdateFieldData)
  updateFieldData({ setState }: StateContext<FieldData[]>, payload: UpdateFieldData) {
    setState(payload.fieldData);
  }
}
