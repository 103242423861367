import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HttpClientWithAuthHeadersService } from
  '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import { GrowerIdService } from '../grower-id/grower-id.service';
import { CustomerGrainCommodityParameters } from './customer-grain-commodity-parameters';
import { GrainCommodityData } from '../../../state/data/grain-commodity-data/grain-commodity-data.types';
import { PaginatedResponse } from '../../../state/paginated-response/paginated-response.types';
import { Observable, from } from 'rxjs';
import { HttpClientOptionsBase } from '../../../shared/shared-services/http-client-with-storage/http-client-with-storage.service';

@Injectable()
export class CustomerGrainCommodityService {
  public static GET_GRAIN_COMMODITY_PATH = `${ENVIRONMENT.gatewayApiBaseUrl}/customergrainscaletickets/commodities`;

  public constructor(
    private readonly httpClient: HttpClientWithAuthHeadersService,
    private readonly growerIdService: GrowerIdService
  ) { }

  retrieveGrainCommoditiesByUrl(url: string): Observable<PaginatedResponse<GrainCommodityData>> {
    return from(this.httpClient.get(url));
  }

  retrieveGrainCommodities(
    agvanceCustomerId: string,
    additionalParameters?: CustomerGrainCommodityParameters
  ): Observable<PaginatedResponse<GrainCommodityData>> {

    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url = `${CustomerGrainCommodityService.GET_GRAIN_COMMODITY_PATH}?customerId=${growerId}`;

          const options: HttpClientOptionsBase = new HttpClientOptionsBase();
          if (additionalParameters) {
            Object.keys(additionalParameters).forEach(key => {
              options.params[key] = `${additionalParameters[key]}`;
            });
          }

          return this.httpClient.get(url, options);
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }
}
