import {Injectable} from '@angular/core';
import {ENVIRONMENT} from '../../../../environments/environment';
import {HttpClientWithAuthHeadersService} from
    '../../../shared/shared-services/http-client-with-auth-headers/http-client-with-auth-headers.service';
import {Observable} from 'rxjs';
import {GrainPurchaseContractData} from '../../../state/data/grain-purchase-contract-data/grain-purchase-contract-data.types';
import {GrowerIdService} from '../grower-id/grower-id.service';

@Injectable()
export class CustomerGrainPurchaseContractsService {
  static GET_GRAIN_PURCHASE_CONTRACTS_PATH = `${ENVIRONMENT.gatewayApiBaseUrl}/customergrainpurchasecontracts`;

  constructor(private httpClient: HttpClientWithAuthHeadersService, private growerIdService: GrowerIdService) {}

  retrieveGrainPurchaseContracts(agvanceCustomerId: string): Observable<GrainPurchaseContractData[]> {
    return Observable.create(emitter => {
      this.growerIdService.fetch(agvanceCustomerId)
        .then(growerId => {
          const url =
            `${CustomerGrainPurchaseContractsService.GET_GRAIN_PURCHASE_CONTRACTS_PATH}/${growerId}?includeSignatureStatus=true`;
          return this.httpClient.get(url, null, '0s');
        })
        .catch(err => { emitter.error(err); emitter.complete(); })
        .then(val => { emitter.next(val); emitter.complete(); });
    });
  }

  retrieveGrainPurchaseContract(purchaseContractGuid: string): Promise<GrainPurchaseContractData[]> {
      const url =
        `${CustomerGrainPurchaseContractsService.GET_GRAIN_PURCHASE_CONTRACTS_PATH}?purchaseContractGUID=${purchaseContractGuid}` +
          `&includeSignatureStatus=true`;
      return this.httpClient.get<GrainPurchaseContractData[]>(url);
  }
}
