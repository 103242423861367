import { filter, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject ,  Observable } from 'rxjs';

import { UpdateWindowSize } from '../../../state/window-size/window-size.actions';
import { WindowSize } from '../../../state/window-size/window-size.types';
import { WindowRefService } from '../window-ref/window-ref.service';

class BreakpointHandler {
  private previouslyWithinBreakpoint = false;

  constructor(private withinBreakpoint: (windowSize: WindowSize) => boolean) { }

  handleMap(windowSize: WindowSize): boolean {
    return this.withinBreakpoint(windowSize);
  }

  handleFilter(currentlyInBreakpoint: boolean): boolean {
    if (currentlyInBreakpoint !== this.previouslyWithinBreakpoint) {
      this.previouslyWithinBreakpoint = currentlyInBreakpoint;

      return true;
    }
    else {
      return false;
    }
  }
}

@Injectable()
export class WindowSizeService {
  static readonly desktopBreakpointSize = 1024;
  static readonly mobileBreakpointSize = 767;

  private previousWindowSize: WindowSize = new WindowSize();
  private breakpointSubject: BehaviorSubject<WindowSize> = new BehaviorSubject<WindowSize>(new WindowSize());

  constructor(private store: Store, windowService: WindowRefService) {
    const nativeWindow = windowService.getNativeWindow();

    this.dispatchNewState(nativeWindow.innerWidth, nativeWindow.innerHeight);

    nativeWindow.addEventListener('resize', this.onWindowResized.bind(this));
  }

  observeRangeBreakpoint(min: number, max: number): Observable<boolean> {
    return this.makeBreakpointObservable((currentWindowSize: WindowSize): boolean => {
      return (currentWindowSize.width >= min && currentWindowSize.width <= max);
    });
  }

  observeMaxBreakpoint(min: number): Observable<boolean> {
    return this.makeBreakpointObservable((currentWindowSize: WindowSize): boolean => {
      return (currentWindowSize.width >= min);
    });
  }

  observeMinBreakpoint(max: number): Observable<boolean> {
    return this.makeBreakpointObservable((currentWindowSize: WindowSize): boolean => {
      return (currentWindowSize.width <= max);
    });
  }

  private onWindowResized(event) {
    this.dispatchNewState(event.target.innerWidth, event.target.innerHeight);
  }

  private dispatchNewState(width: number, height: number) {
    const windowSize: WindowSize = {
      width: width,
      height: height
    };
    this.store.dispatch(new UpdateWindowSize(windowSize));
    this.breakpointSubject.next(windowSize);

    this.previousWindowSize.width = width;
    this.previousWindowSize.height = height;
  }

  private makeBreakpointObservable(withinBreakpoint: (windowSize: WindowSize) => boolean): Observable<any> {
    const breakpointHandler = new BreakpointHandler(withinBreakpoint);
    return this.breakpointSubject.pipe(
      map(breakpointHandler.handleMap.bind(breakpointHandler)),
      filter(breakpointHandler.handleFilter.bind(breakpointHandler)));
  }
}
