import { Injectable } from '@angular/core';

@Injectable()
export class NormalizeStorageKeyService {
  static readonly InvalidKeyError =
    'Data keys must be between 1 and 64 characters and contain only letters, numbers and underscores';
  static readonly InvalidPrefixError =
    'Data key prefixes must be between 1 and 10 characters and contain only letters, numbers and underscores';
  static readonly MaxKeyLength = 64;
  static readonly MaxPrefixLength = 10;

  normalize(key: string, prefix: string): string {
    let keyVal = key || '';
    keyVal = keyVal.replace(/[^a-z0-9_]/gi, '');
    if (keyVal.length < 1 || keyVal.length > NormalizeStorageKeyService.MaxKeyLength) {
      throw new Error(NormalizeStorageKeyService.InvalidKeyError);
    }

    let prefixVal = prefix || '';
    prefixVal = prefixVal.replace(/[^a-z0-9_]/gi, '');
    if (prefixVal.length < 1 || prefix.length > NormalizeStorageKeyService.MaxPrefixLength) {
      throw new Error(NormalizeStorageKeyService.InvalidPrefixError);
    }

    return prefixVal + keyVal;
  }
}
